import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
main:{
  padding:'1rem'
},
heading:{
  display:'flex',
  flexDirection:'row',
  flexWrap:'wrap',
  alignItems:'center',
  justifyContent:'space-between'
},
closeSvg:{
  cursor:'pointer',
  height:24,
  width:24,
  padding:5
},
searchInput:{
    height: '2.5rem',
    width: '50%',
    background: 'transparent',
    borderRadius: '5px',
    border: '1px solid darkgray',
    padding: '1%',
    outline: 'none',
    color: '#050608',
    fontSize: 'medium',
    paddingRight: '5%'
},
fabBtn:{
  background: 'linear-gradient(90deg, #0EC8E1 0.06%, #0866D5 99.99%)',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
},
searchIcon:{
  position: 'relative',
  left: '-2rem'
},
attach:{
  height:'18px',
  width:'18px',
  marginRight:'5px',
},
upload:{
  height:'22px',
  width:'22px',
  marginRight:'10px',
},
imgTagD:{
  cursor:'pointer',
  height:'1.5rem',
  width:'1.5rem',
},
playTagD:{
  marginLeft: '2%',
  cursor:'pointer',
  height:'1.5rem',
  width:'1.5rem',
  transform: 'scale(1.5)',
  position: 'relative',
  top:'2px'
},
checked: {
  width: 13,
  height: 13,
  objectFit: "contain",
},
tableData:{
  height:'60vh'
},
imgTag:{
  cursor:'pointer',
  height:'1.5rem',
  width:'1.5rem',
  marginRight:'0.6rem'
},
phoneInput:{
  width:'100%',
  marginTop:3,
  border:'1px #E7E7E7 solid',
  padding:6,
  borderRadius:5,
  fontFamily:'inherit',
  outline:'none'
},
emailInput:{
  width:'100%',
  marginTop:3,
  border:'1px #E7E7E7 solid',
  padding:6,
  borderRadius:5,
  fontFamily:'inherit',
  outline:'none'
},
}));
