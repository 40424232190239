import React from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useStyles } from '../../../components/DataTableComponent/styles';

type DropDownProps = {
    dropDownlist: { id: string, value: string }[],
    selectedItem?: string,
    onChange: any,
    isDisabled?: boolean
}
const Dropdown = (props: DropDownProps) => {
    const styles = useStyles()

    const { dropDownlist, selectedItem, onChange, isDisabled } = props;
    return (
        <FormControl sx={{ mx: 2, width: 120 }} size="small">
            <InputLabel id="demo-select-small">Status</InputLabel>
            <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={selectedItem}
                label="Age"
                onChange={onChange}
                disabled={isDisabled}
                className={styles.removePadding}
            >
                {dropDownlist && dropDownlist.map((listItem: { id: string, value: string }) => {
                    return (<MenuItem className={listItem.id === 'ACCEPTED' ? styles.accepted : listItem.id === 'VIDEO_UPLOADED' ? styles.videoUploaded : (listItem.id === 'VIDEO_NOT_UPLOADED' || listItem.id === "ALL") ? styles.videoNotUploaded : styles.rejected} key={listItem.id} value={listItem.id}>{listItem.value}</MenuItem>)
                })}
            </Select>
        </FormControl>
    )
}

export default Dropdown