import '../MessageHistory/messageStyles.css';

import DataTableComponent from 'app/components/DataTableComponent';
import DateComponent from 'app/components/DateComponent';
import { actions as userAction } from 'app/containers/UserManagement/slice';
import { actions as MessageActions } from 'app/containers/MessageHistory/slice';
import closeSvg from 'assets/close.svg';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Autocomplete, Button, Fab, Modal, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { GridColDef } from '@mui/x-data-grid';

import {
    selectFilters, selectFromUserData, selectLoading, selectRows, selectTotalMessage,
    selectToUserData
} from './selectors';
import { actions } from './slice';
import { useStyles } from './styles';

type Props = {
    label?: string | any,
    array?: any,
    setUser?: void | any,
    user?: string | any
};

const currentDate = new Date();

const MessageHistory = (props: Props) => {
    const [visible, setVisible] = useState(false);
    const [clickedObj, setClickedObj] = useState<any>()
    // useInjectReducer({ key: sliceKey, reducer: reducer });
    // useInjectSaga({ key: sliceKey, saga: userMsg });

    const dispatch = useDispatch()



    useEffect(() => {
        // dispatch(actions.getDateFrom(currentDate)))
        // dispatch(actions.getDateTo(currentDate)))
        dispatch(actions.getFromUser(''))
        dispatch(actions.getToUser(''))
        dispatch(actions.getMessage())
        return () => {
            dispatch(userAction.getSelectedUser([]))
        }
    }, [dispatch])

    const dispatcher = () => {
        dispatch(actions.setAddMoreMessages(false))
        dispatch(actions.getPageNo(0))
        dispatch(actions.getPageSize(10))
        dispatch(actions.getMessage())
    }

    const data = useSelector(selectFilters)

    const rows = useSelector(selectRows)
    const totalMessages = useSelector(selectTotalMessage)
    const fromUserData = useSelector(selectFromUserData)
    const toUserData = useSelector(selectToUserData)
    const loading = useSelector(selectLoading)

    const { endDate, startDate, fromUser, toUser } = data

    useEffect(() => {
        dispatch(actions.getDateFrom(new Date()));
        dispatch(actions.getDateTo(new Date()));
        dispatch(actions.setAddMoreMessages(false))
    }, [])


    const dispachDateFrom = (value) => {
        dispatch(actions.getDateFrom(value));
        dispatcher()
    }
    const dispachDateTo = (value) => {
        dispatch(actions.getDateTo(value));
        dispatcher()
    }
    const dispachFromUser = (value) => {
        dispatch(actions.getFromUser(value));
        dispatcher()
    }
    const dispachToUser = (value) => {
        dispatch(actions.getToUser(value));
        dispatcher()
    }

    const ModalDiv = () => {
        const closeMsg = () => setVisible(false)
        return (
            <Modal
                open={visible}
                onClose={closeMsg}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={
                    {
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '35rem',
                        bgcolor: 'background.paper',
                        boxShadow: 10,
                        p: 3,
                        borderRadius: 2
                    }

                }>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }} >
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: 'space-between', flex: 1 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', flex: 0.7, justifyContent: 'space-between' }}>
                                <div>
                                    <Typography sx={{ fontSize: 13, color: '#6C7389', fontWeight: 'normal' }} >Date/Time</Typography>
                                    <Typography sx={{ fontSize: 14, color: '#6C7389', fontWeight: 'normal' }} >{clickedObj?.dateTime}</Typography>
                                </div>
                                <div>
                                    <Typography sx={{ fontSize: 13, color: '#6C7389', fontWeight: 'normal' }} >From User</Typography>
                                    <Typography sx={{ fontSize: 14, color: '#6C7389', fontWeight: 'normal' }} >{clickedObj?.fromUser}</Typography>
                                </div>
                                <div>
                                    <Typography sx={{ fontSize: 13, color: '#6C7389', fontWeight: 'normal' }} >To User</Typography>
                                    <Typography sx={{ fontSize: 14, color: '#6C7389', fontWeight: 'normal' }} >{clickedObj?.toUser}</Typography>
                                </div>
                            </div>
                            <img src={closeSvg} alt='closeIcon' onClick={() => setVisible(false)} className={styles.closeSvg} />
                        </div>
                    </div>

                    <div style={{ margin: '1.5rem 0rem 0rem 0rem', display: 'flex', flexDirection: 'column' }} >
                        <Typography sx={{ fontSize: 13, color: '#6C7389', fontWeight: 'normal', marginBottom: '0.5rem' }} >Message</Typography>
                        <Typography sx={{ fontSize: 13, color: '#6C7389', fontWeight: 'normal', }} >{clickedObj?.message}</Typography>
                    </div>
                </Box >
            </Modal >
        )
    }

    const columns: GridColDef[] = [
        {
            field: "dateTime",
            headerName: "Date/Time",
            width: 140,
            // flex: 1,
            renderCell: e => {
                return (<div>
                    {moment(e.row.dateTime).format('DD/MM/yyyy | hh:mm a')}
                </div>)
            }
        },
        {
            field: "fromUser", headerName: "From User",
            // width: 150,
            flex: 1
        },
        {
            field: "toUser", headerName: "To User",
            //  width: 150,
            flex: 1

        },

        {
            field: "message", sortable: false, headerName: "Message",
            //  width: 500
            flex: 2.5,
        },
        {
            field: "button", headerName: "", sortable: false,
            // width: 140,
            flex: 1,
            align: 'right',
            renderCell: (e) => <Button
                variant="outlined"
                onClick={() => {
                    setVisible(true)
                    setClickedObj(e.row)
                }}
                sx={{
                    color: '#fff', backgroundColor: '#12BFE0', '&:hover': {
                        backgroundColor: "#12bfe0"
                    }, fontSize: '0.75rem', padding: '0px 10px', fontWeight: '400', cursor: 'auto', borderRadius: "20px", marginLeft: 2
                }}
            >Show all</Button>
        },
    ];

    const handleUserChange = (event: any, type: string) => {
        if (type === 'FROM USER') {
            if (event?.target?.innerText) { dispachFromUser(event?.target?.innerText) }
            else { dispachFromUser('') }
        }
        if (type === 'TO USER') {
            if (event?.target?.innerText) { dispachToUser(event?.target?.innerText) }
            else { dispachToUser('') }
        }
    }

    const styles = useStyles();

    const fromUserArr = fromUserData.map((x) => {
        let u = { label: x }
        return u
    })
    const toUserArr = toUserData.map((x) => {
        let u = { label: x }
        return u
    })

    return (

        <div style={{ height: '80vh' }} className={styles.contentDiv} >
            <Typography variant="body1" component="p" sx={{ fontSize: 22, fontWeight: '400', color: '#086ED6' }} color={'#323232'} lineHeight={"18px"} fontSize={12}>
                Direct Messages
            </Typography>
            <div className={styles.dateInput} >
                <Button onClick={() => {
                    dispatch(actions.getDateFrom(null));
                    dispatch(actions.getDateTo(null));
                    dispatcher()
                }} style={{ fontSize: 10, border: '1px solid lightgray', color: 'currentColor' }} className={styles.allDates}>All Dates</Button>
                <DateComponent wd='15%' setDate={dispachDateFrom} date={startDate === '' ? currentDate : startDate} name={'Date from'} />
                <DateComponent wd='15%' setDate={dispachDateTo} date={endDate === '' ? currentDate : endDate} name={'Date to'} />
                <Autocomplete
                    onChange={(e) => handleUserChange(e, 'FROM USER')}
                    size='small'
                    disablePortal
                    style={{ marginTop: 0 }}
                    filterOptions={(x) => x}
                    autoComplete
                    id="combo-box-demo"
                    options={fromUserArr}
                    sx={{ width: '30%', m: 2 }}
                    renderInput={(params) => <TextField {...params} label="From User" />}
                />
                <Autocomplete
                    onChange={(e) => handleUserChange(e, 'TO USER')}
                    size='small'
                    disablePortal
                    style={{ marginTop: 0 }}
                    filterOptions={(x) => x}
                    autoComplete
                    id="combo-box-demo"
                    options={toUserArr}
                    sx={{ width: '30%', m: 2 }}
                    renderInput={(params) => <TextField {...params} label="To User" />}
                />
                {/* <DropDownComponent setUser={dispachFromUser} user={fromUser} array={fromUserArr} label={'From User'} /> */}
                {/* <DropDownComponent setUser={dispachToUser} user={toUser} array={toUserArr} label={'To User'} /> */}
            </div>
            <ModalDiv />
            <div style={{ height: '80%', width: '100%' }}>
                <DataTableComponent header columns={columns} rows={rows} mainType={'MESSAGE'}
                    loading={loading}
                    pageSize={data.pageSize}
                    page={data.pageNo}
                    rowCount={totalMessages}
                    pageCount={rows.length}
                    onPageChange={(dummypageNo) => {
                        dispatch(actions.setAddMoreMessages(true))
                        dispatch(actions.getMessage())
                    }}
                    onPageSizeChange={(pageSize) => {
                        dispatch(actions.getPageSize(pageSize))
                        dispatch(actions.getMessage())
                    }}
                />
            </div>
        </div>

    );
};

export default MessageHistory;
