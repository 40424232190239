import DropMultipleComponent from 'app/components/DataMultipleComponent';
import DataTableComponent from 'app/components/DataTableComponent';
import closeSvg from 'assets/close.svg';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Fab, Modal, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { GridColDef } from '@mui/x-data-grid';

import ShowAllModal from './Components/ShowAllModal';
import {
    selectAbuseReports, selectLoading, selectReportedData, selectReporterData,
    selectTotalAbuseReports, selectVideoData
} from './selector';
import { actions } from './slice';
import { useStyles } from './styles';
import ShowAllModalMedia from './Components/ShowAllModalMedia';

type Props = {};

const AddAnswerVideoModal = ({ openModal, url }) => {
    const dispatch = useDispatch();
    const styles = useStyles();
    const clearAll = () => {
        dispatch(actions.setVideoData({ url: null, videoSelected: false }))
    }

    return (
        <Modal
            open={openModal}
            onClose={() => dispatch(actions.setVideoData({ url: null, videoSelected: false }))}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '25rem',
                    height: '35rem',
                    bgcolor: 'background.paper',
                    boxShadow: 10,
                    // p: 3,
                    borderRadius: 2,
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flex: 1,
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography sx={{ fontSize: 16, fontWeight: '900' }}>
                        Answer Video
                    </Typography>
                    <img
                        src={closeSvg}
                        alt="closeSvg"
                        onClick={() => { clearAll() }}
                        className={styles.closeSvg}
                    />
                </div>
                <div
                    style={{
                        // display: 'flex',
                        // flex: 1,
                        // justifyContent: 'start',
                        // alignItems: 'center',
                        height: '100%',
                    }}
                >

                    <video width="100%" height="100%" controls >
                        <source src={url} type="video/mp4" />
                    </video>

                </div>


            </Box>
        </Modal>
    );
};

const AbuseReports = (props: Props) => {
    const styles = useStyles();
    const dispatch = useDispatch()

    const rows = useSelector(selectAbuseReports);
    const reportedData = useSelector(selectReportedData);
    const videoData = useSelector(selectVideoData);
    const reporterData = useSelector(selectReporterData);
    const totalData = useSelector(selectTotalAbuseReports);
    const isLoading = useSelector(selectLoading);

    const columns: GridColDef[] = [
        {
            field: "dateTime",
            headerName: "Date",
            // flex: 1,
            width: 140,
            renderCell: e => {
                return (<div>
                    {moment(e.row.dateTime).format('DD/MM/yyyy | hh:mm a')}
                </div>)
            }
        },
        { field: "fromUser", headerName: "Reporter Full Name", flex: 1 },
        { field: "toUser", headerName: "Reported Full Name", flex: 1 },
        { field: "message", sortable: false, headerName: "Video ID", flex: 1 },
        { field: "mediaId", sortable: false, headerName: "Media ID", flex: 1, renderCell: e => {
                return (<>
                    <div className={styles.clickableRow} onClick={() => {
                        setshowMediaObj(e.row)
                        setMediaVisible(true)
                    }}>{e.row.mediaId}</div>
                </>)
            }
        },
        {
            field: "id", sortable: false, headerName: "Message ID", flex: 1, renderCell: e => {
                return (<>
                    <div className={styles.clickableRow} onClick={() => {
                        setShowObj(e.row)
                        setVisible(true)
                    }}>{e.row.messageId}</div>
                </>
                )
            }
        },
        {
            field: "reportedUserId", sortable: false, headerName: "User ID", flex: 1, renderCell: e => {
                return (<>
                    <div className={styles.clickableRow} onClick={() => {
                        setShowObj(e.row)
                        setVisible(true)
                    }}>{e.row.reportedUserId}</div>
                </>
                )
            }
        },
        { field: "tags", sortable: false, headerName: "Category", flex: 1 },
    ];

    const [reporterName, setReporterName] = useState<string[]>([])
    const [reportedName, setReportedName] = useState<string[]>([])
    const [visible, setVisible] = useState<boolean>(false);
    const [showObj, setShowObj] = useState<any>()
    const [showMediaObj, setshowMediaObj] = useState<any>()
    const [mediaVisible, setMediaVisible] = useState<boolean>(false);


    const closeMsg = () => setVisible(false)
    const closeMediaModel = () => setMediaVisible(false)

    useEffect(() => {
        if (reporterName) {
            let reporterIds: string[] = []
            for (let i = 0; i < reporterName.length; i++) {
                for (let j = 0; rows.length; j++) {
                    if (rows[j].fromUser == reporterName[i]) {
                        reporterIds.push(rows[j].fromUserId)
                        break
                    }
                }
            }
            reporterIds = Array.from(new Set(reporterIds))
            if (reporterIds.length > 0) {
                //
                dispatch(actions.setReporterIds(reporterIds))
                dispatch(actions.getAbuseReport())
            } else {
                dispatch(actions.setReporterIds(null))
                dispatch(actions.getAbuseReport())
            }
        }
    }, [reporterName])

    useEffect(() => {
        if (reportedName) {
            let reportedIds: string[] = []
            for (let i = 0; i < reportedName.length; i++) {
                for (let j = 0; rows.length; j++) {
                    if (rows[j].toUser == reportedName[i]) {
                        reportedIds.push(rows[j].toUserId)
                        break
                    }
                }
            }
            reportedIds = Array.from(new Set(reportedIds))
            if (reportedIds.length > 0) {
                //
                dispatch(actions.setReportedIds(reportedIds))
                dispatch(actions.getAbuseReport())
            } else {
                dispatch(actions.setReportedIds(null))
                dispatch(actions.getAbuseReport())
            }
        }
    }, [reportedName])

    useEffect(() => {
        dispatch(actions.getAbuseReport());
        dispatch(actions.setAddMoreReports(false));
    }, [])

    useEffect(() => {
        if (videoData.videoSelected) {
            console.log("video-selected", videoData.url)
        }
    }, [videoData])

    return <div className={styles.main} >
        <div className={styles.header} >
            {videoData.videoSelected &&
                <AddAnswerVideoModal openModal={videoData.videoSelected} url={videoData.url} />
            }
            <div>
                <DropMultipleComponent setMulti={setReportedName} array={reportedData} multi={reportedName} label={'Reported Full Name'} />
                <DropMultipleComponent label={'Reporter Full Name'} array={reporterData} setMulti={setReporterName} multi={reporterName} />
            </div>
            <Fab sx={{ backgroundColor: '#0EC8E1' }} aria-label="like">
                <Typography sx={{ colors: '#086ED6', fontSize: 22, fontWeight: '500' }}>{totalData}</Typography>
            </Fab>
        </div>
        <div className={styles.dataTable}>
            <DataTableComponent
                loading={isLoading}
                rows={rows}
                columns={columns}
                mainType={'REPORT'}
                rowCount={totalData}
                pageCount={rows.length}
                onPageChange={(dummypageNo) => {
                    dispatch(actions.setAddMoreReports(true))
                    dispatch(actions.getAbuseReport())
                }}
                header
            />
        </div>
        <ShowAllModal
            visible={visible}
            closeMsg={closeMsg}
            showObj={showObj}
        />
        <ShowAllModalMedia
            visible={mediaVisible}
            closeMsg={closeMediaModel}
            showObj={showMediaObj}
        />
    </div>;
};

export default AbuseReports;
