import queryString from 'query-string';
import { toast } from 'react-toastify';
import { select } from 'redux-saga/effects';
import { APP_API_URL } from 'utils/constants';
import { request } from 'utils/request';

import { call, delay, put, takeLatest } from '@redux-saga/core/effects';

import { actions as questionActions } from '../Questions/slice';
import { selectAddMoreAnswer, selectAnswerState } from './selectors';
import { actions } from './slice';
import { InitialAnswerState } from './types';

export function* getAnswerRequest() {
  yield delay(500)
  const data: InitialAnswerState = yield select(
    selectAnswerState
  )
  const getIsAnswerAdd = yield select(selectAddMoreAnswer);
  const options = {
    method: 'POST',
    body: JSON.stringify(
      {
        "category": data.category,
        "page_no": getIsAnswerAdd ? data.page_no + 1 : data.page_no,
        "page_size": data.page_size,
        "status": data?.status,
        "questionQuery": data?.searchQuestion,
        "orderBy": data?.filters?.orderBy,
        "orderByField": data?.filters?.orderByField,
      }
    ),
  };
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/question-answer/admin/answers/get`,
      options
    )
    if (getIsAnswerAdd) {
      yield put(actions.setPageNoIncrement())
    }
    !getIsAnswerAdd ? yield put(actions.getAnswerSuccess(response))
      : yield put(actions.getAnswerSuccess({ ...response, getIsAnswerAdd: getIsAnswerAdd }))

    yield put(questionActions.getAnswerCategory())
  }
  catch (err: any) {
    yield put(actions.getAnswerFailure(err))
  }
}

export function* getQuestionFullRequest() {
  yield delay(500)
  const data: InitialAnswerState = yield select(
    selectAnswerState
  )
  const options = {
    method: 'POST',
    body: JSON.stringify(
      {
        "page_no": 0,
        "page_size": 500,
        "category_id": data.addAnswer.category_id
      }
    ),
  };
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/question-answer/questions`,
      options
    )
    yield put(actions.getQuestionFullSuccess(response))

  }
  catch (err: any) {
    yield put(actions.getQuestionFailure(err))
  }
}

export function* getSearchResultRequest(action: any) {
  yield delay(500)
  const options = {
    method: 'GET',
  };
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/question-answer/questions/get/${action.payload}`,
      options
    )
    yield put(actions.getSearchResultSuccess(response))
  }
  catch (err: any) {
    yield put(actions.getSearchResultFailure(err))
  }
}

export function* deleteAnswerRequest(action: any) {
  yield delay(500)
  const data: InitialAnswerState = yield select(
    selectAnswerState
  )
  const options = {
    method: 'DELETE'
  };
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/question-answer/${action.payload.id}`,
      options
    )
    yield put(actions.deleteAnswerSuccess({ ...response, deletedAnswerId: action.payload.id }))
    toast.success('Deleted successfully')
    yield put(actions.getAnswer())
  }
  catch (err: any) {
    yield put(actions.deleteAnswerFailure(err))
  }
}

export function* updateAnswerRequest(action: any) {
  yield delay(500)

  const options = {
    method: 'GET'
  };
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/question-answer/answers/change-status/${action.payload.id}`,
      options
    )
    yield put(actions.updateAnswerSuccess({ ...response, id: action.payload.id }))
    yield put(actions.setAddMoreAnswers(false))
    yield put(actions.getAnswer())
  }
  catch (err: any) {
    yield put(actions.updateAnswerFailure(err))
  }
}

export function* uploadAnswerRequest(action: any) {
  const queries = queryString.stringify({ fileName: action.payload.fileName, contentType: action.payload.contentType })
  const options = {
    method: 'GET'
  };
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/categories/upload/url/fetch?${queries}`,
      options
    )
    yield put(actions.uploadAnswerSuccess(response));

  } catch (err: any) {
    yield put(actions.uploadAnswerVideoError(err));
  }
}

export function* uploadAnswerVideoRequest(action: any) {
  const options = {
    method: "POST",
    body: JSON.stringify(
      {
        "questionId": action.payload.questionId,
        "videoUrl": action.payload.videoUrl,
        "email": action.payload.email,
        "mobile_no": '+972' + action.payload.mobile_no,
        "videoLength": action.payload.videoLength
      }
    )
  };

  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/categories/question/reply`,
      options
    )
    toast.success('Answer added successfully')
    yield put(actions.uploadAnswerVideoSuccess(response));

  } catch (err: any) {
    toast.error('Answer added failed')
    yield put(actions.uploadAnswerVideoError(err));
  }
}

export function* checkUserRequest(action: any) {
  const options = {
    method: "POST",
    body: JSON.stringify(
      {
        "questionId": action.payload.questionId,
        "videoUrl": action.payload.videoUrl,
        "email": action.payload.email,
        "mobile_no": '+972' + action.payload.mobile_no,
        "videoLength": action.payload.videoLength
      }
    )
  };

  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/categories/question/reply/check`,
      options
    )
    yield put(actions.checkUserSuccess(response));
    if (response.message === 'NO USER FOUND') toast.error(response?.message);
  } catch (err: any) {

    yield put(actions.checkUserError(err));
  }
}

export function* userAns() {
  yield takeLatest(actions.getQuestions.type, getQuestionFullRequest)
  yield takeLatest(actions.getAnswer.type, getAnswerRequest)
  yield takeLatest(actions.getSearchResult.type, getSearchResultRequest)
  yield takeLatest(actions.deleteAnswer.type, deleteAnswerRequest)
  yield takeLatest(actions.updateAnswer.type, updateAnswerRequest)
  yield takeLatest(actions.uploadAnswer.type, uploadAnswerRequest)
  yield takeLatest(actions.uploadAnswerVideo.type, uploadAnswerVideoRequest)
  yield takeLatest(actions.uploadAnswerVideoSuccess.type, getAnswerRequest)
  yield takeLatest(actions.checkUser.type, checkUserRequest)
}