import { selectreportFilteres } from 'app/containers/AbuseReports/selector';
import { actions as abuseReportActions } from 'app/containers/AbuseReports/slice';
import { selectAnswerFilters } from 'app/containers/Answers/selectors';
import { actions as AnswerActions } from 'app/containers/Answers/slice';
import { selectFilters as MessageFilters } from 'app/containers/MessageHistory/selectors';
import { actions as MessageActions } from 'app/containers/MessageHistory/slice';
import { actions as QuestionActions } from 'app/containers/Questions/slice';
import { selectFilters, selectSelectedRows } from 'app/containers/UserManagement/selectors';
import { actions } from 'app/containers/UserManagement/slice';
import upArrow from 'assets/upArrow.png';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Box } from '@mui/system';
import { DataGrid, GridRowIdGetter } from '@mui/x-data-grid';

import Loader from '../Loader';
// import InfiniteScroll from 'react-infinite-scroll-component';
import { useStyles } from './styles';

type Props = {
    rows: any,
    columns: any,
    header?: boolean,
    setHeader?: any,
    setHead?: any,
    className?: string,
    pageSize?: number,
    onPageSizeChange?: any,
    onPageChange?: any,
    page?: number,
    selectedActionStatus?: string,
    loading?: boolean,
    rowCount?: number,
    pageCount?: number,
    mainType?: string,
    openAnswerModal?: any,
    updateAnswer?: any,
    fromPage?: string,
    userListTable?: boolean,
    setSelectedUsers?: React.Dispatch<React.SetStateAction<number>>
}

const DataTableComponent = (props: Props) => {
    const styles = useStyles()
    const navigate = useNavigate()
    const rowArr = useSelector(selectSelectedRows);
    const filters = useSelector(selectFilters);
    const msgfilters = useSelector(MessageFilters);
    const ansfilters = useSelector(selectAnswerFilters);
    const reportfilters = useSelector(selectreportFilteres);
    const dispatch = useDispatch()

    const [hasMore, setHasMore] = useState(true);
    const [superFunction, setSuperFunction] = useState(() => () => console.log("default ooops"));
    useEffect(() => {
        const element = document.querySelector(".MuiDataGrid-virtualScroller");
        element?.addEventListener("scroll", handleScroll, true);
        return () => {
            element?.removeEventListener('scroll', handleScroll, true)
        }
    }, [props.loading]);
    useEffect(() => {
        if (props?.rowCount && props?.pageCount && props?.rowCount <= props?.pageCount) {
            if (props?.mainType == 'QUESTIONS') {
                dispatch(QuestionActions.setStopFetching(true))
            }
            setHasMore(false)
        }
    }, [props?.pageCount])


    const scroll = () => {
        props.onPageChange(1)
    }

    function throttle(callbackFn, limit) {
        let wait = false;
        return function () {
            if (!wait) {
                callbackFn.call();
                wait = true;
                setTimeout(function () {
                    wait = false;
                }, limit);
            }
        }
    }

    const handleScroll = (e: any) => {
        if (props.loading) {
            return
        }
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        if (scrollTop + clientHeight >= (scrollHeight - 100) && hasMore) {
            let optimizedFun = throttle(scroll, 100)
            setSuperFunction(optimizedFun)
        }
    }
    useEffect(() => {
        if (superFunction) { superFunction() }

    }, [superFunction])
    const getId: GridRowIdGetter = (row) => row.id
    const filter = props.selectedActionStatus === "ALL" ? props.rows : props.rows.filter((row: any) => row.actionStep === props.selectedActionStatus) //selectedActionStatus
    return (
        <DataGrid
            rows={filter}
            getRowClassName={(params) => {
                if (params.row.actionStep) {
                    return (params.row.actionStep === 'VIDEO_NOT_UPLOADED') ? styles.videoNotUploaded : (params.row.actionStep === 'VIDEO_UPLOADED') ? styles.videoUploaded : (params.row.actionStep === 'ACCEPTED') ? styles.accepted : (params.row.actionStep === 'REJECTED') ? styles.rejected : ''
                } else {
                    return ''
                }
            }}
            className={props.className}
            columns={props.columns}
            loading={props.loading}
            page={props.page}
            pagination
            rowCount={props.rowCount}
            paginationMode={'server'}
            sortingMode={'server'}
            checkboxSelection={!props.header}
            disableColumnMenu
            onColumnHeaderClick={(e) => {
                dispatch(actions.setCellClick(true))
            }}
            onCellClick={(e) => {
                console.log(e.row.isDisabled);
                if (e.row.isDisabled) {
                    return
                }
                dispatch(actions.setCellClick(true))
                if (props.mainType === 'REPORT' && e.field == "message" && e?.row?.url) {
                    let obj = {
                        url: e?.row?.url,
                        videoSelected: true
                    }
                    dispatch(abuseReportActions.setVideoData(obj))
                } else {
                    return
                }
            }}
            onCellDoubleClick={(e) => {
                console.log(e.row.isDisabled);
                if (e.row.isDisabled) {
                    console.log("Double Click");
                    toast.error('User is edited by another admin.');
                    return
                }
                if (props?.userListTable) {
                    dispatch(actions.getUserEdit())
                    dispatch(actions.setSelectUserId(e.row.id))
                    dispatch(actions.getUserProfileMedia())
                    dispatch(actions.getSelectedUserOnly([e.row.id]));
                    dispatch(actions.getSelectedUserObj(e.row))
                    navigate(`${e.row.id}`)
                }
            }}
            hideFooter

            selectionModel={rowArr}
            onSelectionModelChange={(selection: any) => {
                if (selection.length > 1) {
                    const result = selection[selection.length - 1]
                    const selectedRow = props.rows.find((val) => val.id === result);
                    console.log(selectedRow, "This si selectedRow");
                    if (selectedRow && selectedRow.isDisabled) {
                        return;
                    }
                    // setSelectionModel(selection);
                    dispatch(actions.getSelectedUser(selection))
                    if (props.setSelectedUsers) {
                        props.setSelectedUsers(prevState => {
                            return selectedRow ? prevState + 1 : prevState - 1
                        })
                    }

                } else {
                    const selectedRow = props.rows.find((val) => val.id === selection[0]);
                    console.log(selectedRow, "This si selectedRow");
                    if (selectedRow && selectedRow.isDisabled) {
                        return;
                    }
                    // setSelectionModel(selection);
                    dispatch(actions.getSelectedUser(selection))
                    if (props.setSelectedUsers) props.setSelectedUsers(prevState => {
                        return selectedRow ? prevState + 1 : prevState - 1
                    })
                }


                // const checkRow = props.rows.find(val => val.id === e[0]);
                // if(checkRow.isDisabled){
                //     return
                // }
                // dispatch(actions.getSelectedUser(e))
            }}
            sortModel={
                filters.orderByField ? [{
                    field: filters.orderByField,
                    sort: props?.mainType == 'MESSAGE' ?
                        msgfilters.orderBy == -1 ?
                            'asc' : 'desc'
                        : props?.mainType == 'ANSWER' ?
                            ansfilters.orderBy == -1 ?
                                'asc' : 'desc'
                            : props?.mainType == 'REPORT' ?
                                reportfilters.orderBy == -1 ?
                                    'asc' : 'desc'
                                : props?.mainType == 'USERS' ?
                                    reportfilters.orderBy == -1 ?
                                        'asc' : 'desc'
                                    : filters.orderBy == -1 ?
                                        'asc' : 'desc',
                }] : []
            }

            onSortModelChange={(newSortModel) => {
                if (newSortModel?.length === 0) {
                    return
                }
                if (props?.mainType === 'MESSAGE') {
                    let sort = msgfilters.orderBy == -1 ? 'desc' : 'asc';
                    let msgModal = {
                        field: newSortModel[0].field,
                        sort: sort
                    }
                    dispatch(MessageActions.setSortValues(msgModal));
                    dispatch(MessageActions.getMessage());
                } else if (props?.mainType === 'ANSWER') {
                    let sort = ansfilters.orderBy == -1 ? 'desc' : 'asc';
                    let ansModal = {
                        field: newSortModel[0].field,
                        sort: sort
                    }
                    dispatch(AnswerActions.setSortValues(ansModal));
                    dispatch(AnswerActions.getAnswer());
                } else if (props?.mainType === 'REPORT') {
                    let sort = reportfilters.orderBy == -1 ? 'desc' : 'asc';
                    let orderByField;
                    if (newSortModel[0].field == 'dateTime') {
                        orderByField = 'createdAt'
                    } else if (newSortModel[0].field == 'toUser') {
                        orderByField = 'reportedId.fullName'
                    } else if (newSortModel[0].field == 'fromUser') {
                        orderByField = 'reporterId.fullName'
                    }
                    let reportModal = {
                        field: orderByField,
                        sort: sort
                    }
                    dispatch(abuseReportActions.setAddMoreReports(false))
                    dispatch(abuseReportActions.setSortValues(reportModal));
                    dispatch(abuseReportActions.getAbuseReport());
                }
                else {
                    let sort = filters.orderBy == -1 ? 'desc' : 'asc';
                    let userModal = {
                        field: newSortModel[0].field,
                        sort: sort
                    }
                    dispatch(actions.setAddMoreUsers(false))
                    dispatch(actions.setSortValues(userModal));
                    dispatch(actions.getUser());
                }
            }}
            getRowId={getId}
            disableSelectionOnClick
            components={{
                ColumnSortedAscendingIcon: () => {
                    return (
                        <Box
                            width={18}
                            height={18}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            {props?.mainType == 'MESSAGE' && msgfilters.orderBy == -1 && (<img
                                className={styles.checked}
                                style={{ transform: "rotate(180deg)" }}
                                src={upArrow}
                                alt="Up-Arrow"
                            />)}
                            {props?.mainType == 'MESSAGE' && msgfilters.orderBy == 1 && (<img
                                className={styles.checked}
                                // style={{ transform: "rotate(180deg)" }}
                                src={upArrow}
                                alt="Up-Arrow"
                            />)}
                            {props?.mainType == 'ANSWER' && ansfilters.orderBy == -1 && (<img
                                className={styles.checked}
                                style={{ transform: "rotate(180deg)" }}
                                src={upArrow}
                                alt="Up-Arrow"
                            />)}
                            {props?.mainType == 'ANSWER' && ansfilters.orderBy == 1 && (<img
                                className={styles.checked}
                                // style={{ transform: "rotate(180deg)" }}
                                src={upArrow}
                                alt="Up-Arrow"
                            />)}
                            {props?.mainType == 'REPORT' && reportfilters.orderBy == -1 && (<img
                                className={styles.checked}
                                style={{ transform: "rotate(180deg)" }}
                                src={upArrow}
                                alt="Up-Arrow"
                            />)}
                            {props?.mainType == 'REPORT' && reportfilters.orderBy == 1 && (<img
                                className={styles.checked}
                                // style={{ transform: "rotate(180deg)" }}
                                src={upArrow}
                                alt="Up-Arrow"
                            />)}
                            {props?.mainType == 'USERS' && filters.orderBy == -1 && (<img
                                className={styles.checked}
                                style={{ transform: "rotate(180deg)" }}
                                src={upArrow}
                                alt="Up-Arrow"
                            />)}
                            {props?.mainType == 'USERS' && filters.orderBy == 1 && (<img
                                className={styles.checked}
                                src={upArrow}
                                alt="Up-Arrow"
                            />)}
                            {props?.mainType != 'MESSAGE' && props?.mainType != 'ANSWER' && props?.mainType != 'REPORT' && props?.mainType != 'USERS' && (<img
                                className={styles.checked}
                                style={{ transform: "rotate(180deg)" }}
                                src={upArrow}
                                alt="Up-Arrow"
                            />)}
                        </Box>
                    )
                },
                ColumnSortedDescendingIcon: () => {
                    return (
                        <Box
                            width={18}
                            height={18}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <img className={styles.checked} src={upArrow} alt="" />
                        </Box>
                    )
                },
            }}
            sx={{
                maxheight: '600px',
                marginTop: 2,
                border: 'none',
                backgroundColor: '#F9FAFB',
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "#12BFE0",
                    color: "#fff",
                    height: "34px",
                    borderRadius: '6px 6px 0px 0px',
                    padding: props.header ? '0rem 1rem' : 0
                },
                "& .MuiDataGrid-columnSeparator": {
                    display: "none",
                },
                "& .MuiDataGrid-cellCheckbox": {
                    backgroundColor: "#0866D4",
                    border: "1px solid #0EC8E1",
                },
                "& .MuiDataGrid-cell": {
                    color: props.selectedActionStatus === 'REJECTED' ? "white" : "#6C7389",
                    fontSize: 12,
                    lineHeight: 18,
                    fontWeight: 400,
                    padding: props.header ? "0 1rem" : '0 0.5rem',
                },
                "& .MuiDataGrid-columnHeaderTitleContainer": {
                    justifyContent: "space-between",
                },
                "& .MuiDataGrid-columnHeader": {
                    padding: 0,
                },
            }}
        />
    )
}

export default DataTableComponent