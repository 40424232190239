import { UserDetails } from 'types/UserDetails';

/* --- STATE --- */
export interface AuthState {
  username: string;
  password: string;
  loading: boolean;
  error?: LoginErrorType | null;
  token: string;
  email?: string;
  message?: string;
  confirmPassword?: string;
  userDetail: UserDetails;
}

export enum LoginErrorType {
  USERNAME_EMPTY = 10,
  PASSWORD_EMPTY = 20,
  USER_NOT_FOUND = 404,
  SUCCESS = 200,
  UNAUTHORIZED = 400,
  ERROR = 500,
  NO_RECORD_FOUND = "No record found",
  NO_PERMISSIONS = 401,
  PASSWORD_NOT_MATCH,
  INVALID_USER,
  INVALID_EMAIL,
  LOGIN_FAILED = 504,
  MESSAGE_ADMIN_USER_EXISTS,
  MESSAGES_AUTH_LINK_EXPIRED,
}

/*
  If you want to use 'ContainerState' keyword everywhere in your feature folder,
  instead of the 'HomePageState' keyword.
*/
export type ContainerState = AuthState;
