import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  closeSvg:{
    cursor:'pointer',
    height:24,
    width:24,
    padding:5
  },
  titleInput:{
    width:'100%',
    marginTop:3,
    border:'1px #E7E7E7 solid',
    padding:6,
    borderRadius:5,
    fontFamily:'inherit',
    outline:'none'
  },
  textArea:{
    borderRadius:5,
    outline:'none',
    padding:6,
    border:'1px #E7E7E7 solid',
    maxHeight:'50%',
    resize:'none',
    height:'10rem',
    fontFamily:'inherit'
  }
}));
