import { editValidPhone, validMail } from 'app/components/AddModal';
import DateComponent from 'app/components/DateComponent';
import DropDownComponent from 'app/components/DropDownComponent';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, TextField, Typography } from '@mui/material';

import { selectUserObj } from '../selectors';
import { actions } from '../slice';
import { useStyles } from './styles';

type Props = {
    head: any,
    setPhoneNo: any,
    phoneNumber: any,
    pressed: any,
    phone: any,
    setEmail: any,
    email: any,
    userGenderArr: any,
    setUserGender: any,
    userGender: any,
    lookingArr: any,
    looking_for: any,
    setLF: any,
    dob: any,
    handleSubmit: any,
    err: any,
    onBack?: any,
};
const PersonalInfoSection = ({
    onBack, head, setPhoneNo, phoneNumber, pressed, setEmail, setUserGender, userGenderArr, userGender, lookingArr, setLF, handleSubmit, err }: Props) => {
    // =====consts===============================
    const styles = useStyles();
    const dispatch = useDispatch();

    const userData = useSelector(selectUserObj);

    const { last_login_date, enroll_date, looking_for, phone, email, dob, logins, followers, following, postCount, rangeInMeters, ageFrom, ageTo } = userData;

    const dateFormatter = (date: string | undefined) => {
        const day = date?.split('/')[0]
        const month = date?.split('/')[1]
        const year = date?.split('/')[2];
        if (date === '') {
            return new Date()
        }
        return new Date(`${month}/${day}/${year}`)
    }

    return (
        <>
            <div className={styles.inputDivs} style={{ width: '640px' }}>
                <TextField disabled label={'UserName'} defaultValue={head.fullName} sx={{ backgroundColor: '#fff', width: '170px', borderRadius: '10px' }} size="small" />
                <TextField label={'Phone'} onChange={setPhoneNo} defaultValue={phoneNumber}
                    value={`+972${phoneNumber}`}
                    sx={{ backgroundColor: '#fff', width: '170px' }} size="small" />
                {pressed && editValidPhone(phone) && <Typography sx={{ color: '#911' }}>{editValidPhone(phone)}</Typography>}
                <TextField label={'Email'} onChange={setEmail} value={email} sx={{ backgroundColor: '#fff', width: '262px' }} size="small" />
                {pressed && validMail(email) && <Typography sx={{ color: '#911' }}>{validMail(email)}</Typography>}
                <div className={styles.PersonalInfoSexInfo}>
                    <DropDownComponent isDisable setUser={setUserGender} array={userGenderArr} user={userGender} label='Gender' widthNo={'110px'} />
                    <DropDownComponent label='Looking for Gender' array={lookingArr} setUser={setLF} user={looking_for} widthNo={'135px'} />
                    <DateComponent readOnly wd={'120px'} name='Birth Date' date={dateFormatter(dob)} />
                </div>
                <div className={styles.PersonalInfoSexInfo}>
                    <DateComponent readOnly wd={'120px'} name='Enrolment Date' date={dateFormatter(enroll_date)} />
                    <DateComponent readOnly wd={'120px'} name='Last Login' date={dateFormatter(last_login_date)} />
                    <TextField disabled label={'Logins'} defaultValue={logins} sx={{ backgroundColor: '#fff', width: '80px', borderRadius: '10px' }} size="small" />
                    <TextField disabled label={'Age'} defaultValue={head.age} sx={{ backgroundColor: '#fff', width: '80px' }} size="small" />
                </div>
                <div className={styles.PersonalInfoSexInfo}>
                    <TextField disabled label={'No. Of Posts'} defaultValue={postCount?.length} sx={{ backgroundColor: '#fff', width: '120px', borderRadius: '10px' }} size="small" />
                    <TextField disabled label={'Followers'} defaultValue={followers} sx={{ backgroundColor: '#fff', width: '80px' }} size="small" />
                    <TextField disabled label={'Following'} defaultValue={following} sx={{ backgroundColor: '#fff', width: '80px' }} size="small" />
                </div>
                <div className={styles.PersonalInfoSexInfo}>
                    <TextField disabled label={'Range'} defaultValue={`${rangeInMeters / 1000}km`} sx={{ backgroundColor: '#fff', width: '80px', borderRadius: '10px' }} size="small" />
                    <TextField disabled label={'Age Range'} defaultValue={`${ageFrom} - ${ageTo}`} sx={{ backgroundColor: '#fff', width: '80px' }} size="small" />
                </div>
            </div>

            <div className={styles.inputDivs} style={{ justifyContent: 'end', marginBottom: '20px' }}>
                <Button
                    disableElevation
                    onClick={handleSubmit}
                    variant="contained"
                    sx={{ color: '#fff', fontSize: '16px', padding: '0.4rem 3rem', fontWeight: '400', cursor: 'auto', borderRadius: "4px", background: "linear-gradient(90deg, #0EC8E1 0.06%, #0866D5 99.99%);" }}
                >Save</Button>
                <Button
                    variant="text"
                    onClick={() => {
                        onBack();
                        dispatch(actions.getSelectedUserOnly([]));
                    }}
                    sx={{
                        color: '#323232', backgroundColor: '#E7E7E7', '&:hover': {
                            backgroundColor: "#E7E7E7"
                        }, fontSize: '16px', padding: '0.4rem 3rem', fontWeight: '400', cursor: 'auto', borderRadius: "4px"
                    }}
                >Cancel</Button>
                {err === 'MESSAGE_ADMIN_USER_EXISTS' && <Typography sx={{ color: '#911' }}>{'This user already exist'}</Typography>}
                {err === 'MESSAGE_ADMIN_USER_UPDATED' && setTimeout(() => { onBack() }, 1000) && <Typography sx={{ color: '#191' }}>{'User updated successfully'}</Typography>}
            </div>
        </>
    )
}
export default PersonalInfoSection;