export const APP_API_URL = process.env.REACT_APP_API_URL || '';
export const APP_VERSION = process.env.REACT_APP_VERSION || '';
export const APP_GOOGLE_MAP_API_KEY = 'AIzaSyDs3PuvlX0H3B6hnvo4FmUEhP25dUg7fRI';
export const APP_PUBLIC_URL = process.env.PUBLIC_URL || '';
export const IS_PRODUCTION = process.env.NODE_ENV !== 'production';
export const IS_DEVELOPMENT = process.env.NODE_ENV !== 'development';
export const APP_SOCKET_URL = process.env.REACT_APP_SOCKET_URL || '';

export const LOCK_ICONS = {
  red: '/locks/lock-red.png',
  green: '/locks/lock-green.png',
  gray: '/locks/lock-gray.png',
};

export const CLUSTER_MARKER_LOCMOTIVES = {
  url: "/cluster_markers/loco/loco",
  ext: 'png'
};

export const CLUSTER_MARKER_LOCKS = {
  url: "/cluster_markers/lock/lock",
  ext: 'png'
};

export const LOCOMOTIVE_ICONS = {
  green: '/locks/Loco_green.png',
  gray: '/locks/Loco_gray.png',
};

export const TABS = {
  LOCO: 'Loco',
  LOCKS: 'Lock',
  STATIONS: 'Station',
  POLYGONS: 'Polygon',
};

export const LAYER_NAMES = {
  LOCO: 'layers.loco',
  LOCKS: 'layers.lock',
  STATIONS: 'layers.station',
  POLYGONS: 'layers.polygon'
};

export const DEFAULT_MAP_CENTER = {

  lat: parseFloat(process.env.REACT_APP_DEFAULT_LATITUDE || '0'),
  lng: parseFloat(process.env.REACT_APP_DEFAULT_LONGITUDE || '0'),
};

export const LOGIN_HEADER_HEIGHT = 96
export const DASHBOARD_MENU_WIDTH = '20%'
export const DASHBOARD_TOP_BAR_HEIGHT = 116;

export const validateName = /^[a-zA-Z]+ [a-zA-Z]+$/;
export const validateEmail = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;