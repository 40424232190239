import { createSlice } from 'utils/@reduxjs/toolkit';

import { PayloadAction } from '@reduxjs/toolkit';

import {
  addEditAdminUser, adminDetail, AdminState, adminUsersFullName, permissionList
} from './types';

export const initialState: AdminState = {
  adminUserLists: [],
  adminForm: {
    type: "Add",
    fullName: "",
    email: "",
    permission: [],
    fullNameError: '',
    emailError: '',
    permissionError: '',
  },
  selectedAdminId: "",
  adminListFilter: "",
  loading: false,
  error: "",
  isDeleteOpen: false,
  isAddEditOpen: false,
  isEnableDisableOpen: false,
  filters: {
    page_no: 0,
    page_size: 100,
    total: 0,
    fullName: '',
    orderBy: 0,
    orderByField: ''
  },
  adminUsersFullname: [],
  permissionList: []
};

const getAdminManagementOnSlice = createSlice({
  name: 'adminManagement',
  initialState,
  reducers: {
    // ==============get admin users==================
    getAdminUsers(state) {
      state.loading = true;
      state.error = '';
    },
    getAdminUsersSuccess(state, action: PayloadAction<adminDetail[]>) {
      state.adminUserLists = action.payload;
      state.loading = false;
      state.error = '';
    },
    getAdminUsersFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    // ==============get admin user full name===================
    getAdminUserFullName(state) {
      state.loading = true;
      state.error = '';
    },
    getAdminUserFullNameSuccess(state, action: PayloadAction<adminUsersFullName[]>) {
      state.adminUsersFullname = action.payload;
      state.loading = false;
      state.error = '';
    },
    getAdminUserFullNameFailure(state, action: PayloadAction<any>) {
      state.adminUsersFullname = [];
      state.loading = false;
      state.error = action.payload;
    },
    // ===================update filters========================
    updateFilters(state, action: PayloadAction<{ page_no: number, page_size: number, total: number }>) {
      state.filters.page_no = action.payload.page_no;
      state.filters.page_size = action.payload.page_size;
      state.filters.total = action.payload.total;
    },
    updateFilterFullName(state, action: PayloadAction<string>) {
      state.filters.fullName = action.payload;
    },
    // ===================add new admin user====================
    addAdminUser(state, action: PayloadAction<{ callback: () => void }>) {
      state.loading = true;
      state.error = '';
    },
    addAdminSuccess(state) {
      state.loading = false;
      state.error = '';
    },
    addAdminFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    // ===================update admin user=====================
    updateAdminFormValue(state, action: PayloadAction<addEditAdminUser>) {
      state.adminForm.email = action.payload.email;
      state.adminForm.fullName = action.payload.fullName;
      state.adminForm.permission = action.payload.permission;
      state.adminForm.type = action.payload.type;
      state.adminForm.emailError = action.payload.emailError;
      state.adminForm.fullNameError = action.payload.fullNameError;
      state.adminForm.permissionError = action.payload.permissionError;
    },
    updateAdminFormError(state, action: PayloadAction<{ name: string, value: any }>) {
      state.adminForm[action.payload.name] = action.payload.value;
    },
    updateSingleAdminFormValue(state, action: PayloadAction<{ name: string, value: any }>) {
      state.adminForm[action.payload.name] = action.payload.value;
    },
    updateSelectedAdminUserId(state, action: PayloadAction<string>) {
      state.selectedAdminId = action.payload;
    },
    // ===================update admin request=============================
    updateAdminUser(state, action: PayloadAction<{ callback: () => void }>) {
      state.loading = true;
      state.error = '';
    },
    updateAdminSuccess(state) {
      state.loading = false;
      state.error = '';
    },
    updateAdminFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    // =============================delete admin user===================
    deleteAdminUser(state, action: PayloadAction<{ callback: () => void }>) {
      state.loading = true;
      state.error = '';
    },
    deleteAdminUserSuccess(state) {
      state.loading = false;
      state.error = '';
    },
    deleteAdminUserFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    // =============================toggle admin user===================
    toggleAddEditPopup(state) {
      state.isAddEditOpen = !state.isAddEditOpen
    },
    toggleDeletePopup(state) {
      state.isDeleteOpen = !state.isDeleteOpen
    },
    toggleEnableDisablePopup(state) {
      state.isEnableDisableOpen = !state.isEnableDisableOpen
    },
    // ==========================permissions list===========================
    getPermissionsList(state) {
      state.loading = true;
      state.error = '';
    },
    getPermissionsListSuccess(state, action: PayloadAction<permissionList[]>) {
      state.permissionList = action.payload;
      state.loading = false;
      state.error = '';
    },
    getPermissionsListFailure(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loading = false;
    },
    // ==============================status change============================
    updateStatus(state, action: PayloadAction<{ callback: () => void }>) {
      state.loading = true;
      state.error = '';
    },
    updateStatusSuccess(state) {
      state.loading = false;
      state.error = '';
    },
    updateStatusFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
})

export const { actions, reducer, name: sliceKey } = getAdminManagementOnSlice