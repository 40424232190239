import DataTableComponent from 'app/components/DataTableComponent';
import PopOverDiv from 'app/containers/Questions/commonComponent/Popover';
import deleteSvg from 'assets/delete.svg';
import eyeSvg from 'assets/eye.svg';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate } from 'utils/DateFormate';

import { Box } from '@mui/system';
import { GridColDef } from '@mui/x-data-grid';

import {
    selectedUserMediaData, selectLoading, selectPageNo, selectPageSize, selectTotalUsers
} from '../selectors';
import { actions } from '../slice';
import DeletePopOverDiv from './DeletePopOver';
import IOSSwitchComponent from './IOSSwitchComponent';
import ShowUserProfile from './ShowUserProfile';
import { useStyles } from './styles';

const MediaInfoSection = () => {
    // =====consts===============================
    const styles = useStyles();
    const dispatch = useDispatch();
    const pageSize = useSelector(selectPageSize)
    const pageNo = useSelector(selectPageNo)
    const totalUsers = useSelector(selectTotalUsers)
    const rows = useSelector(selectedUserMediaData) ?? [];
    const data_rows = rows?.map(({ _id, ...rest }) => ({ id: _id, ...rest }))
    const loading = useSelector(selectLoading)
    const [openProfileModal, setOpenProfileModal] = useState(false)
    const [selectedProfileData, setSelectedProfileData] = useState({
        isSelectedImage: false,
        url: ''
    })
    const [anchor, setAnchor] = useState<any>(null);

    const handleShowPost = (event, row) => {
        const url = row?.posts[0]?.url ?? ''
        if (url.length > 0) {
            setOpenProfileModal(true)
            setSelectedProfileData({
                isSelectedImage: true,
                url: url
            })
        }
    };
    const deleteSelectedMediacallback = () => {
        dispatch(actions.getMedia())
    }

    const handleDelete = () => {
        dispatch(actions.deleteSelectedMedia({ callback: deleteSelectedMediacallback }))
        setAnchor(null);
    };
    const handleDeleteClick = (event, row) => {
        setAnchor(event.currentTarget)
        dispatch(actions.updateSelectedMediaId(row.id))
    };

    const handleCloseEl = () => {
        setAnchor(null);
        dispatch(actions.updateSelectedMediaId(''))
    };

    const handleOnChangeToggle = (rowInfo) => dispatch(actions.updateSelectedMediaId(rowInfo.id))

    const onStatusChangeSuccess = () => {
        dispatch(actions.updateSelectedMediaId(''))
        dispatch(actions.getMedia());
    }

    const handleOnChangeSubmit = () => dispatch(actions.updateStatus({ callback: onStatusChangeSuccess }))

    const columns: GridColDef[] = [
        {
            field: "dateTime", headerName: "Uploaded Date/ Time", flex: 1, renderCell: e => {
                return formatDate(e.row.createdAt, 'dd/mm/yy', true)
            }
        },
        {
            field: "mediaSize", headerName: "Media Size", flex: 1,
            renderCell: e => {
                return `${(+e.row.posts[0].size / 1000000).toFixed(2)}mb`
            }
        },
        {
            field: 'actions',
            headerName: 'Actions',
            sortable: false,
            flex: 1,
            maxWidth: 130,
            renderCell: e => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            flex: 0.7,
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <PopOverDiv />

                        <div className={styles.deleteImg}>
                            <img
                                src={deleteSvg}
                                onClick={r => handleDeleteClick(r, e.row)}
                                alt="deleteSvg"
                            />
                        </div>
                        <div className={styles.deleteImg} style={{ marginLeft: '8px' }}>
                            <img
                                src={eyeSvg}
                                onClick={r => handleShowPost(r, e.row)}
                                alt="eyeSvg"
                            />
                        </div>
                        <IOSSwitchComponent
                            header={`${e.row.status ? "Disable" : "Enable"} this Media?`}
                            isChecked={e.row.status}
                            onChangeToggle={() => handleOnChangeToggle(e.row)}
                            onSubmitToggle={handleOnChangeSubmit}
                        />
                    </div>
                );
            },
        },
    ];

    // =====================states=====================

    // =====handleChange===============================

    // =====useEffects===============================

    useEffect(() => {
        dispatch(actions.setSendMessageData());
        dispatch(actions.getMedia());
    }, [])

    return (
        <>
            <div className={styles.inputDivs} style={{ marginTop: '10px' }}>
                <Box sx={{ height: 400, width: '100%' }}>
                    <DataTableComponent
                        mainType={'USERS'}
                        pageSize={pageSize}
                        page={pageNo}
                        pageCount={data_rows?.length}
                        rowCount={totalUsers}
                        onPageChange={
                            (pageNo) => {
                                dispatch(actions.setAddMoreUsers(pageNo != 0 ? true : false))
                                dispatch(actions.getMedia())
                            }
                        }
                        onPageSizeChange={() => {
                            dispatch(actions.getPageSize(pageSize))
                            dispatch(actions.getMedia())
                        }}
                        loading={loading}
                        className={styles.userDataTable}
                        rows={data_rows}
                        columns={columns}
                        header
                    />
                </Box>
            </div>
            <ShowUserProfile
                isImage={selectedProfileData?.isSelectedImage}
                openProfileModal={openProfileModal}
                setOpenProfileModal={setOpenProfileModal}
                url={selectedProfileData?.url}
            />
            <DeletePopOverDiv
                anchor={anchor}
                handleCloseEl={handleCloseEl}
                handleDelete={handleDelete}
                onClose={handleCloseEl}
            />
        </>
    )
}
export default MediaInfoSection;