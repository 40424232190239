import { createSlice } from 'utils/@reduxjs/toolkit';

import { InitialAbuseReportState } from './types';

export const initialState: InitialAbuseReportState = {
  meta: [
    {
      total: 0,
      page_no: -1,
      pageSize: 0
    }
  ],
  data: [],
  reporterData: [],
  reportedData: [],
  loading: false,
  addMoreReports: false,
  error: '',
  reportedIds: [],
  reporterIds: [],
  videoData: {
    url: '',
    videoSelected: false
  },
  filters: {
    orderBy: -1 || null,
    orderByField: "dateTime" || null
  },
}

const getAbuseReportSlice = createSlice({
  name: 'abuseReportState',
  initialState,

  reducers: {
    getAbuseReport(state) {
      state.loading = true;
      state.error = null
    },
    setAddMoreReports(state, action) {
      state.addMoreReports = action.payload
      // if (action.payload) {
      // } else {
      //   state.meta[0].page_no = 0
      // }
    },
    setPageNoIncrement(state) { state.meta[0].page_no += 1 },

    getAbuseReportSuccess(state, action) {
      // state.questions = action.payload;
      if (action.payload.meta.length > 0) {
        state.meta = action.payload.meta;
      }
      state.data = [...state.data, ...action.payload.data];
      let reportedData = state.data.map((item) => {
        return item.toUser
      })
      let reporterData = state.data.map((item) => {
        return item.fromUser
      })
      reportedData = reportedData.filter(function (element) {
        return element !== undefined;
      });
      reporterData = reporterData.filter(function (element) {
        return element !== undefined;
      });
      state.reportedData = Array.from(new Set(reportedData))
      state.reporterData = Array.from(new Set(reporterData))
      state.loading = false;
      state.error = null;
    },
    getAbuseReportFailure(state, action) {
      state.loading = false;
      state.error = action.payload
    },
    setSortValues(state, action) {
      if (action.payload && action.payload.sort) {
        state.filters = {
          ...state.filters,
          orderBy: action.payload.sort == 'asc' ? -1 : 1,
          orderByField: action.payload.field
        }
        state.addMoreReports = false
      } else {
        state.filters = {
          ...state.filters,
          orderBy: null,
          orderByField: null
        }
        state.addMoreReports = false
      }
    },
    setReportedIds(state, action) {
      state.reportedIds = action.payload
    },
    setReporterIds(state, action) {
      state.reporterIds = action.payload
    },
    setVideoData(state, action) {
      state.videoData.url = action.payload.url;
      state.videoData.videoSelected = action.payload.videoSelected
    },
    getUserCountsSuccess(state, action) {
      console.log("media-payload", action.payload)
      state.meta[0].total = action.payload.abuseReport
    },


  }
})
export const { actions, name: sliceKey, reducer } = getAbuseReportSlice