import React, { useEffect } from 'react';
import { useMatch } from 'react-router-dom';

import { Box } from '@mui/material';

import UserManagement from './';
import AddEditUserManagement from './Components/AddEditUserManagement';

export const Routes = () => {
    const match = useMatch("/dashboard/user-management/:id")
    useEffect(() => {
        console.log('Scroll: Mount')
        return () => {
            console.log('Scroll: Unmount')
        }
    }, [])
    return <>
        <Box sx={{ display: match?.params.id ? 'none' : 'block' }}>
            < UserManagement />
        </Box>
        {match?.params.id &&
            <Box>
                <AddEditUserManagement />
            </Box>
        }

    </>
}

export default Routes;