import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useLgStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    height: '100vh'
  },
  banner: {
    backgroundImage: `url(${require('./assets/back.png')})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  paper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '400px',
    position: 'relative',
  },
  logoWrap: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'linear-gradient(32.62deg, #0EC8E1 4.82%, #0866D5 104.82%)',
    opacity: 0.5,
  },
  logo: {
    width: '100%',
  },
}));

export const useSmStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  banner: {
    backgroundImage: `url(${require('./assets/back.png')})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  paper: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'space-between',
    minWidth: '350px',
    borderRadius: '3%',
    position: 'relative',
    [theme.breakpoints.up('xs')]: {
      minWidth: '250px',
      flex: 3,
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: '250px',
      flex: 2,
    },
  },
  logoWrap: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'linear-gradient(32.62deg, #0EC8E1 4.82%, #0866D5 104.82%)',
    opacity: 0.5,
  },
  logo: {
    width: '100%',
  },

}));
