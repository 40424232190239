import React from 'react';

import { CircularProgress, Modal } from '@mui/material';
import { Box } from '@mui/system';

import closeSvg from '../../../../assets/close.svg';
import { useStyles } from './styles';

type Props = {
    isVideo?: boolean
    openProfileModal: boolean
    setOpenProfileModal: any
    setSelectedProfileData: any
    url: string
    isImage?: boolean
    showVideoLoader: boolean
}

const ShowUserProfileNew = ({ isVideo, openProfileModal, setOpenProfileModal, setSelectedProfileData, url, isImage, showVideoLoader }: Props) => {
    const styles = useStyles();
    console.log("URLS..", url);
    return (
        <Modal
            open={openProfileModal}
            onClose={() => {setOpenProfileModal(false)}}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
<div>

            <Box className={styles.ShowUserModalContainer}
                sx={{ bgcolor: 'background.paper', boxShadow: 10, borderRadius: 2, }}
            >
                <div className={styles.ShowUserModalSection} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <img
                        src={closeSvg}
                        alt="closeSvg"
                        onClick={() => { setOpenProfileModal() }}
                        className={styles.closeSvg}

                    />
                </div>

                <div style={{ height: '95%' }}>

                {showVideoLoader && ( <Box sx={{ display: 'flex', position: 'absolute', top: '50%', color: '#f00', left: '40%' }}>
                                <CircularProgress />
                            </Box>)}
                  { !showVideoLoader && openProfileModal && isVideo && (<video  id={url} width="100%" height="100%" src={url} controls>
         {/* <source src={url} type="video/mp4" /> */}
     </video>)
      }                    {!isVideo && (<img src={url} className={styles.profileImage} alt='profileImg' />)}
                </div>
            </Box>

            </div>
        </Modal>
    );
};

export default ShowUserProfileNew