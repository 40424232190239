
import closeSvg from 'assets/close.svg';
import moment from 'moment';
import React from 'react';

import { Modal, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { useStyles } from '../styles';

const ShowAllModal = ({ visible, closeMsg, showObj }) => {
    const styles = useStyles()
    return (
        <Modal
            open={visible}
            onClose={closeMsg}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={
                {
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '35rem',
                    bgcolor: 'background.paper',
                    boxShadow: 10,
                    p: 3,
                    borderRadius: 2
                }
            }>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }} >
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: "flex-start", justifyContent: 'space-between', flex: 1 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', flex: 0.9, justifyContent: 'space-between' }}>
                            <div>
                                <Typography sx={{ fontSize: 13, color: '#6C7389', fontWeight: 'normal' }} >Date/Time</Typography>
                                <Typography sx={{ fontSize: 14, color: '#6C7389', fontWeight: 'normal' }} >{moment(showObj?.dateTime).format('DD/MM/yyyy | hh:mm a')}</Typography>
                            </div>
                            <div>
                                <Typography sx={{ fontSize: 13, color: '#6C7389', fontWeight: 'normal' }} >Reporter Full name</Typography>
                                <Typography sx={{ fontSize: 14, color: '#6C7389', fontWeight: 'normal' }} >{showObj?.fromUser ?? ""}</Typography>
                            </div>
                            <div>
                                <Typography sx={{ fontSize: 13, color: '#6C7389', fontWeight: 'normal' }} >Reported Full name</Typography>
                                <Typography sx={{ fontSize: 14, color: '#6C7389', fontWeight: 'normal' }} >{showObj?.toUser ?? ""}</Typography>
                            </div>
                        </div>
                        <img src={closeSvg} alt='closeSvg' onClick={closeMsg} className={styles.closeSvg} />
                    </div>
                </div>

                <div style={{ margin: '1.5rem 0rem 0rem 0rem', display: 'flex', flexDirection: 'column' }} >
                    <Typography sx={{ fontSize: 13, color: '#6C7389', fontWeight: 'normal', marginBottom: '0.5rem' }} >{(showObj?.category === "user_follower" || showObj?.category === "user_following") ? 'User Type' : 'Message' }</Typography>
                    <Typography sx={{ fontSize: 13, color: '#6C7389', fontWeight: 'normal', }} >{(showObj?.category === "user_follower") ? 'follower' : (showObj?.category === "user_following") ? 'following' : showObj?.message}</Typography>
                </div>
            </Box >
        </Modal >
    )
}

export default ShowAllModal;