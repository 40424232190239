import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  main: {
      display: 'flex',
      // backgroundColor: '#abb'
  },
//   content: {
//       backgroundColor: '#F9FAFB',
//       height:'70vh',
//       padding: '2rem 1rem 0rem 1.7rem'
//   },
  dateInput: {
      paddingTop: 20,
      borderRadius: 10,
      gap: 10,
      display: 'flex'
  },
  noOfUser: {
      width: '100%',
      height: '4.7%',
      // margin: theme.spacing(3, 0, 2),
      // padding: theme.spacing(1, 4),
      // [theme.breakpoints.down('xs')]: {
      //   // margin: theme.spacing(1, 0, 1),
      // },

  },
  content: {
      padding: '1%',
      height: '80vh',
      backgroundColor: '#F9FAFB'
  },
  graphHead:{
    display:'flex',
    flexDirection:'row',
    justifyContent:'space-between',
    alignItems:'center',
    padding:'0.5rem 1rem',
    flex:1
  },
  dropDowns:{
    display:'flex',
    flex:1,
    flexDirection:'row',
  },
  callender:{
    border:'1px solid',
    padding:'0.5rem 0.7rem',
    borderColor:'#E7E7E7',
    borderRadius:'10px',
    display:'flex',
    alignItems:'center',
    flexWrap:'wrap',
    flexDirection:'row',
    justifyContent:'space-between'
  },
  calanderInput:{
    outline:'none',
    border:'none'
  },
  individual:{
    display:'flex',
    alignItems:'center',
    flexDirection:'row',
    cursor:'pointer'
  }
}));