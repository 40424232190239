import { actions as answerActions } from 'app/containers/Answers/slice';
import { selectImageUploadUrl, selectUploadUrl } from 'app/containers/mediaSaga/selectors';
import { actions as mediaActions } from 'app/containers/mediaSaga/slice';
import {
    selectAddUser, selectAddUserModalOpen, selectDefaultQuestionId, selectError, selectImageFile,
    selectVideoFile
} from 'app/containers/UserManagement/selectors';
import { actions } from 'app/containers/UserManagement/slice';
import attach from 'assets/attach.svg';
import closeSvg from 'assets/close.svg';
import dropDown from 'assets/dropdown.svg';
import uploadSvg from 'assets/upload.svg';
import moment from 'moment';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { uploadFile } from 'utils/s3Helper';

import { Button, CircularProgress, Menu, MenuItem, Modal, Typography } from '@mui/material';
import { Box } from '@mui/system';

import DateComponent from '../DateComponent';
import { useStyles } from './styles';

type Props = {
    setOpenModal: any,
    openModal: boolean,
}
export const validPhone = phone => {
    if (!phone?.trim()) {
        return 'Phone cannot be empty';
    }
    const regPhone = /\+?(\d[\d-. ]+)?(\([\d-. ]+\))?[\d-. ]+\d$/;

    if (!regPhone.test(phone) || phone.length < 9 || phone.length > 12) {
        return 'Please enter valid phone number';
    }
    return null;
};

export const editValidPhone = phone => {
    if (!phone?.trim()) {
        return 'Phone cannot be empty';
    }
    const regPhone = /\+?(\d[\d-. ]+)?(\([\d-. ]+\))?[\d-. ]+\d$/;

    if (!regPhone.test(phone) || phone.length < 13 || phone.length > 14) {
        return 'Please enter valid phone number';
    }
    return null;
};
export const validMail = (mail) => {
    if (!mail?.trim()) {
        return 'Email Id cannot be empty';
    }
    const regMail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!regMail.test(mail)) {
        return 'Please enter valid Email id';
    }
    return null;
};

const AddModal = (props: Props) => {
    const styles = useStyles()
    const dispatch = useDispatch()
    const closeFinalAdd = () => {
        props.setOpenModal(false);
        // dispatch(actions.getAddClose());
    }
    const closeAdd = () => {
        props.setOpenModal(false);
        dispatch(actions.getAddClose());
    }
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [gender, setGender] = useState('Gender')
    const [pressed, setPressed] = useState<boolean>(false)
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setPressed(false)
        setAnchorEl(event.currentTarget);
    };
    const data = useSelector(selectAddUser)
    const selectedVideoFile: any = useSelector(selectVideoFile);
    const selectedImageFile: any = useSelector(selectImageFile);
    const uploadUrl = useSelector(selectUploadUrl)
    const imageUploadUrl = useSelector(selectImageUploadUrl)
    const defaultQuestionId: string = useSelector(selectDefaultQuestionId);
    const isAddUserModalOpen = useSelector(selectAddUserModalOpen);
    const err = useSelector(selectError)
    const { fullName, mobile_no, dob, email: mail } = data

    const [inputRef, setInputRef] = useState<HTMLInputElement | null>()
    const [inputImageRef, setInputImageRef] = useState<HTMLInputElement | null>()
    const [fileProgress, setFileProgress] = useState<any>(0)
    const [addUserLoader, setAddUserLoader] = useState(false);
    const handleChoose = () => {
        inputRef?.click()
        // inputRef.current.click()
    }
    const handleImageChoose = () => {
        inputImageRef?.click()
        // inputRef.current.click()
    }

    const validFile = (file) => {
        console.log("asdfasdfasdf")
        console.log(file)
        if (!file) {
            return 'Please select a video'
        }
        if (file.type.search("mp4") == -1) {
            return 'Please select a mp4 video file'
        }
        console.log("file-size", file.size / 1024000)
        if (file.size / 1024000 > 100) {
            return 'Please select less than 100mb'
        }
        return null
    }
    const validImageFile = (file) => {
        console.log("asdfasdfasdf")
        console.log(file)
        if (!file) {
            return 'Please select a image'
        }
        if (!file.type.includes('image')) {
            return 'Invalid image format'
        }
        console.log("file-size", file.size / 1024000)
        if (file.size / 1024000 > 100) {
            return 'Please select less than 100mb'
        }
        return null
    }


    const handleClose = (item: any) => {
        typeof item == 'string' &&
            setGender(item); dispatch(actions.getAddGender(item === 'Men' ? 'MEN' : 'WOMEN'))
        setAnchorEl(null);
    };

    const menu2Arr = ['Men', 'Women'];
    const validUser = (name) => {
        if (!name?.trim()) {
            return 'Full name cannot be empty';
        }
        return null;
    }
    const validMail = (mail) => {
        if (!mail?.trim()) {
            return 'Email Id cannot be empty';
        }
        const regMail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!regMail.test(mail)) {
            return 'Please enter valid Email id';
        }
        return null
    }

    const validGender = (gender) => {

        if (gender === 'Gender') {
            return 'Please select a gender'
        }
        return null
    }
    const validDate = (date) => {
        if (date === undefined || date === null || date === 'Invalid Date') {
            return 'select valid date'
        }
        return null
    }

    const handleAddUser = () => {
        setPressed(true)
        if (!validUser(fullName) && !validPhone(mobile_no) && !validDate(dob) && !validGender(gender) && !validMail(mail) && !validFile(selectedVideoFile) && !validImageFile(selectedImageFile)) {
            if (mobile_no.startsWith('0')) {
                toast.error("Mobile number can't start with 0")
                return
            }
            setAddUserLoader(true)
            setPressed(false)
            dispatch(mediaActions.getImageUploadUrl({ fileName: selectedImageFile?.name, contentType: selectedImageFile?.type }));
            dispatch(mediaActions.getUploadUrl({ fileName: selectedVideoFile?.name, contentType: selectedVideoFile?.type }));
        }
    }
    const onProgress = (fileName: any, data: any) => {
        if (data !== 'start' && data !== "end") {
            setFileProgress(data)
        }
        if (data == "end") {
            var video: any = document.createElement('video');
            video.preload = 'metadata';
            video.onloadedmetadata = function () {
                window.URL.revokeObjectURL(video.src);
                // var duration = video.duration;
                const dur = Math.floor(parseInt(video.duration, 10));
                const duration = `${moment.duration(dur, 'seconds').minutes()} min ${moment
                    .duration(dur, 'seconds')
                    .seconds()} sec `;
                dispatch(mediaActions.getUploadTranscoder({ fileName: uploadUrl?.mediaKey }))
                let uploadAnsData = { questionId: defaultQuestionId, videoUrl: uploadUrl.mediaKey, videoLength: duration, email: mail, mobile_no: mobile_no }
                dispatch(answerActions.setAddUserAnswerData(uploadAnsData))
                // dispatch(answerActions.uploadAnswerVideo({ questionId: defaultQuestionId, videoUrl: uploadUrl.mediaKey, videoLength: duration, email: mail, mobile_no: mobile_no }))
                let data = {
                    title: selectedVideoFile.name,
                    size: selectedVideoFile.size,
                    url: uploadUrl.mediaKey,
                };
                dispatch(
                    mediaActions.uploadQuestionVideo({
                        questionId: defaultQuestionId,
                        videoUrl: uploadUrl?.mediaKey,
                        videoLength: duration,
                        intro: true
                    }));
                dispatch(mediaActions.setProfileVideoForm(data));
                dispatch(actions.getAddUser())
                dispatch(mediaActions.clearAddData())
                dispatch(mediaActions.clearUploadUrl())
                closeFinalAdd()
                setAddUserLoader(false)
                //   dispatch(mediaActions.addMediaProfileVideo({}));

            };
            video.src = selectedVideoFile.url;
        }
    }
    const imageOnProgress = (fileName: any, data: any) => {
        if (data !== 'start' && data !== "end") {
            setFileProgress(data)
        }
        if (data == "end") {
            let data = {
                title: selectedImageFile.name,
                size: selectedImageFile.size,
                url: imageUploadUrl.mediaKey,
            };
            dispatch(mediaActions.setProfileImageForm(data));
            // dispatch(mediaActions.getUploadTranscoder({ fileName: imageUploadUrl?.mediaKey }))
            var image: any = document.createElement('image');
            image.preload = 'metadata';
            image.onloadedmetadata = function () {
                window.URL.revokeObjectURL(image.src);
                // var duration = image.duration;
                // const dur = Math.floor(parseInt(image.duration, 10));
                // const duration = `${moment.duration(dur, 'seconds').minutes()} min ${moment
                //     .duration(dur, 'seconds')
                //     .seconds()} sec `;


                // dispatch(actions.getAddUser())
                // dispatch(mediaActions.clearAddData())
                // dispatch(mediaActions.clearUploadUrl())
                // closeFinalAdd()
                //   dispatch(mediaActions.addMediaProfileImage({}));

            };
            image.src = selectedImageFile.url;
        }
    }

    const uploadFilesToS3 = async () => {
        dispatch(mediaActions.startLoading());
        setFileProgress(0)
        uploadFile(selectedVideoFile, uploadUrl.mediaUrl, onProgress);
    }
    const uploadImageFilesToS3 = async () => {
        dispatch(mediaActions.startLoading());
        setFileProgress(0)
        uploadFile(selectedImageFile, imageUploadUrl.mediaUrl, imageOnProgress);
    }

    useEffect(() => {
        if (uploadUrl && uploadUrl.mediaUrl && isAddUserModalOpen) {
            console.log(selectedVideoFile);
            uploadFilesToS3();
        }
    }, [uploadUrl])//for profile video
    useEffect(() => {
        if (imageUploadUrl && imageUploadUrl.mediaUrl && isAddUserModalOpen) {
            uploadImageFilesToS3();
        }
    }, [imageUploadUrl])//for profile image

    const handleFileChange = (event) => {
        dispatch(actions.setVideoFile({
            file: {
                name: event.target.files[0].name,
                type: event.target.files[0].type,
                size: event.target.files[0].size,
                url: URL.createObjectURL(event.target.files[0])
            }
        }))
        console.log(event.target.files[0]);

    }
    const handleImageFileChange = (event) => {
        dispatch(actions.setImageFile({
            file: {
                name: event.target.files[0].name,
                type: event.target.files[0].type,
                size: event.target.files[0].size,
                url: URL.createObjectURL(event.target.files[0])
            }
        }))
        console.log(event.target.files[0]);

    }

    const setBirthDate = (e) => {
        dispatch(actions.getAddBirthDate(e))
    }
    const handleUserChange = (e) => {
        setPressed(false)
        dispatch(actions.getAddUserName(e?.nativeEvent?.target?.value))
    }
    const handlePhoneChange = (e) => {
        setPressed(false)
        dispatch(actions.getAddMobile(e?.nativeEvent?.target?.value))
    }
    const handleEmailChange = (e) => {
        setPressed(false)
        dispatch(actions.getAddEmail(e?.nativeEvent?.target?.value))
    }
    const bDate = new Date().getFullYear() - 18
    const mDate = new Date().getFullYear() - 40

    const maxDate = new Date(new Date().setFullYear(bDate))
    const minDate = new Date(new Date().setFullYear(mDate))
    return (
        <Modal
            open={props.openModal}
            onClose={closeAdd}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={
                {
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '45rem',
                    bgcolor: 'background.paper',
                    boxShadow: 10,
                    p: 3,
                    borderRadius: 2
                }
            }>
                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'space-between' }} >
                    <div style={{ display: 'flex', flexDirection: 'row' }} >
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography sx={{ fontSize: 12, color: '#6C7389', fontWeight: 'normal' }} >Full Name</Typography>
                            <input onChange={handleUserChange} value={fullName} className={styles.titleInput} />
                            {pressed && validUser(fullName) && <Typography sx={{ color: '#911', marginTop: 1 }}>{validUser(fullName)}</Typography>}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '1rem' }}>
                            <Typography sx={{ fontSize: 12, color: '#6C7389', fontWeight: 'normal' }} >Gender</Typography>
                            <Button
                                id="basic-button2"
                                sx={{ color: '#6C7389', border: '1px solid', borderColor: '#E7E7E7', fontSize: 12 }}
                                aria-controls={open ? 'basic-menu2' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                            >
                                {gender}
                                <img src={dropDown} alt='dropDown' />
                            </Button>
                            <Menu
                                id="basic-menu2"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button2',
                                }}
                            >
                                {menu2Arr.map((x, i) => {
                                    return (
                                        <MenuItem key={i} id='basic-menu2' onClick={() => handleClose(x)}>{x}</MenuItem>
                                    )
                                })}
                            </Menu>
                            {pressed && validGender(gender) !== null && <Typography sx={{ color: '#911', marginTop: 1 }}>{validGender(gender)}</Typography>}
                        </div>
                    </div>
                    <img src={closeSvg} alt='closeSvg' onClick={closeAdd} className={styles.closeSvg} />
                </div>

                <div style={{ margin: '1.5rem 0rem 0rem 0rem', display: 'flex', flexDirection: 'column' }} >
                    <div style={{ display: 'flex', flexDirection: 'row' }} >
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography sx={{ fontSize: 12, color: '#6C7389', fontWeight: 'normal' }} >Phone</Typography>
                            {/* @ts-ignore */}
                            <div className={styles.phoneDiv}>
                                <label>+972</label>
                                <input onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }} onChange={handlePhoneChange} maxLength={10} value={mobile_no} className={styles.titleInput} style={{ width: 100 }} />
                            </div>
                            {pressed && validPhone(mobile_no) && <Typography sx={{ color: '#911', marginTop: 1 }}>{validPhone(mobile_no)}</Typography>}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', margin: '0rem 1rem' }}>
                            <Typography sx={{ fontSize: 12, color: '#6C7389', fontWeight: 'normal' }} >Email</Typography>
                            {/* @ts-ignore */}
                            <input value={mail} onChange={handleEmailChange} className={styles.emailInput} />
                            {pressed && validMail(mail) && <Typography sx={{ color: '#911', marginTop: 1 }}>{validMail(mail)}</Typography>}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '2rem', justifyContent: 'end' }}>
                            {/* @ts-ignore */}
                            <DateComponent maxDate={maxDate} views={['year', 'month', 'day']} minDate={minDate} setDate={setBirthDate} date={dob} name='Birth Date' />
                            {pressed && validDate(dob) && <Typography sx={{ color: '#911', marginTop: 1 }}>{validDate(dob)}</Typography>}
                        </div>
                    </div>


                    <div className={styles.addUserMedia}>
                        <input
                            ref={el => setInputImageRef(el)}
                            type="file"
                            style={{
                                display: 'none'
                            }}
                            className="ImageInput_input"
                            onChange={handleImageFileChange}
                            accept="image/*"
                        />
                        <div>
                            {selectedImageFile ? <Button onClick={handleImageChoose}
                                sx={{
                                    padding: '10px 15px',
                                    cursor: 'auto',
                                    borderRadius: '4px',
                                    marginLeft: 0,
                                    maxHeight: 40,
                                    border: '0.5px solid'
                                }}>
                                <img src={attach} alt="Attach File" className={styles.attach} />
                                <Typography sx={{ fontSize: 13, fontWeight: '400', textAlign: 'center', textOverflow: 'ellipsis', overflow: 'hidden', minHeight: '21px', whiteSpace: 'nowrap', width: '135px' }}>
                                    {selectedImageFile.name}
                                </Typography></Button> : <Button
                                    variant="outlined"
                                    onClick={handleImageChoose}
                                    sx={{
                                        color: '#fff',
                                        backgroundColor: '#12BFE0',
                                        '&:hover': {
                                            backgroundColor: '#12bfe0',
                                        },
                                        fontSize: '1.6vh',
                                        padding: '5px 15px',
                                        fontWeight: '500',
                                        cursor: 'auto',
                                        borderRadius: '4px',
                                        marginLeft: 0,
                                    }}
                                >
                                <img src={uploadSvg} alt="uploadSvg" className={styles.upload} />
                                <Typography sx={{ fontSize: 19, fontWeight: '400' }}>
                                    {'Upload Image'}
                                </Typography>

                            </Button>}
                            {pressed && validImageFile(selectedImageFile) && <Typography sx={{ color: '#911', marginTop: 1, marginLeft: '16px', position: 'absolute' }}>{validImageFile(selectedImageFile)}</Typography>}
                        </div>



                        <input
                            ref={el => setInputRef(el)}
                            type="file"
                            style={{
                                display: 'none'
                            }}
                            className="VideoInput_input"
                            onChange={handleFileChange}
                            accept=".mov,.mp4"
                        />
                        <div>
                            {selectedVideoFile ? <Button onClick={handleChoose}
                                sx={{
                                    padding: '10px 15px',
                                    cursor: 'auto',
                                    borderRadius: '4px',
                                    marginLeft: 0,
                                    maxHeight: 40,
                                    border: '0.5px solid'
                                }}>
                                <img src={attach} alt="Attach File" className={styles.attach} />
                                <Typography sx={{ fontSize: 13, fontWeight: '400', textAlign: 'center', textOverflow: 'ellipsis', overflow: 'hidden', minHeight: '21px', whiteSpace: 'nowrap', width: '135px' }}>
                                    {selectedVideoFile.name}
                                </Typography></Button> : <Button
                                    variant="outlined"
                                    onClick={handleChoose}
                                    sx={{
                                        color: '#fff',
                                        backgroundColor: '#12BFE0',
                                        '&:hover': {
                                            backgroundColor: '#12bfe0',
                                        },
                                        fontSize: '1.6vh',
                                        padding: '5px 15px',
                                        fontWeight: '500',
                                        cursor: 'auto',
                                        borderRadius: '4px',
                                        marginLeft: 0,
                                    }}
                                >
                                <img src={uploadSvg} alt="uploadSvg" className={styles.upload} />
                                <Typography sx={{ fontSize: 19, fontWeight: '400' }}>
                                    {'Upload Video'}
                                </Typography>

                            </Button>}
                            {pressed && validFile(selectedVideoFile) && <Typography sx={{ color: '#911', marginTop: 1, marginLeft: '16px', position: 'absolute' }}>{validFile(selectedVideoFile)}</Typography>}
                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '2rem 0rem 0rem 0rem' }} >
                        <Button
                            onClick={handleAddUser}
                            variant="outlined"
                            sx={{
                                color: '#fff', backgroundColor: '#12BFE0', '&:hover': {
                                    backgroundColor: "#12bfe0"
                                }, fontSize: '1.6vh', padding: '6px 30px', fontWeight: '500', cursor: 'auto', borderRadius: "5px", marginLeft: 2
                            }}
                        >Add User
                            {addUserLoader && (<Box sx={{ display: 'flex', color: '#fff' }}>
                                <CircularProgress />
                            </Box>)}
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{
                                color: '#fff', backgroundColor: '#12BFE0', '&:hover': {
                                    backgroundColor: "#12bfe0"
                                }, fontSize: '1.6vh', padding: '6px 20px', fontWeight: '500', cursor: 'auto', borderRadius: "5px", marginLeft: 2
                            }}
                            onClick={() => props.setOpenModal(false)}
                        >Cancel</Button>
                    </div>
                    {err === 'MESSAGE_ADMIN_USER_EXISTS' && <Typography sx={{ color: '#911', marginTop: 1 }}>{'This user already exist'}</Typography>}
                </div>
            </Box >
        </Modal >
    )
}

export default memo(AddModal)