import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  UserManagementContainer: {
    height: 'calc(100vh - 76px)',
    width: "100%",
    padding: '1rem',
    position: 'relative',
    backgroundColor: '#F9FAFB'
  },
  closebutton: {
    width: '20%',
    height: '25px',
    borderRadius: '25px',
    background: 'none',
    border: 'none',
    margin: '0 auto',
    color: '#12bfe0',
  },
  userName: {
    textTransform: 'capitalize',
  },
  space: {
    height: '20px',
  },
    unblockButton: {
    width: '20%',
    height: '25px',
    borderRadius: '25px',
    backgroundColor: '#12BFE0',
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 20px',
    color: '#ffffff',
    border: 'none',
    margin: '0 auto',
  },
  UserManagementSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  Topbar: {
    display: 'flex', flexDirection: 'row', flex: 2
  },
  TopbarButtons: {
    color: '#fff !important',
    fontWeight: '500 !important',
    padding: '5px 30px !important',
    cursor: 'auto !important',
    borderRadius: "5px !important",
    marginLeft: '16px !important'
  },
  SendMessageButton: {
    color: '#fff !important',
    backgroundColor: '#12BFE0 !important',
    padding: '6px 10px !important',
    fontWeight: '500 !important',
    cursor: 'pointer !important',
    borderRadius: "5px !important",
    marginLeft: '16px !important',
    '&:hover': {
      backgroundColor: "#12bfe0 !important"
    },
  },
  checked: {
    width: 13,
    height: 13,
    objectFit: "contain",
  },
  searchDiv: {
    display: 'flex',
    border: '1px solid',
    padding: 5,
    borderRadius: 6,
    width: '22%',
    borderColor: '#E7E7E7',
    backgroundColor: '#FFF',
    alignItems: 'center'
  },
  filterCalIcon: {
    height: '18px',
    width: '16px',
    marginRight: '5px'
  },
  searchIcon: {
    objectFit: 'contain',
    height: '25px',
    width: '25px',
    fill: '#000',
    paddingRight: 5,
    paddingLeft: 2,
    cursor: 'pointer'
  },
  searchInput: {
    borderWidth: 0,
    width: '100%',
    borderRadius: 100,
    '&:focus': {
      outline: 'none',

    },
  },
  filterIcon: {
    height: '18px',
    width: '16px',
    marginRight: '5px'
  },
  FilterDatePicker: {
    flex: '40% !important',
    border: '1px solid lightgray !important',
    color: 'slategrey !important',
    margin: '0.5% !important',
    marginInlineStart: '0% !important',
    marginInlineEnd: '3% !important',
    marginTop: '6% !important'
  },
  modalDiv: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  closeSvg: {
    cursor: 'pointer',
    height: 24,
    width: 24,
    padding: 5
  },
  tableData: {
    height: '60vh'
  },
  playTagD: {
    marginLeft: '2%',
    cursor: 'pointer',
    height: '1.5rem',
    width: '1.5rem',
    transform: 'scale(1.5)',
    position: 'relative',
    top: '2px'
  },
  filterText: {
    fontWeight: 'bold',
    fontSize: 28,
    color: '#12BFE0'
  },
  datePickerDiv: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 27,
    margin: '1rem 0rem 5vh 0rem'
  },
  sendMsgModal: {
    fontSize: 8,
    fontWeight: 'normal'
  },
  titleInput: {
    width: '100%',
    marginTop: 3,
    border: '1px #E7E7E7 solid',
    padding: 6,
    borderRadius: 5,
    fontFamily: 'inherit',
    outline: 'none'
  },
  emailInput: {
    width: '120%',
    marginTop: 3,
    border: '1px #E7E7E7 solid',
    padding: 6,
    borderRadius: 5,
    fontFamily: 'inherit',
    outline: 'none'
  },
  textArea: {
    borderRadius: 5,
    outline: 'none',
    padding: 6,
    border: '1px #E7E7E7 solid',
    maxHeight: '50%',
    resize: 'none',
    height: '10rem',
    fontFamily: 'inherit'
  },
  fabBtn: {
    background: 'linear-gradient(90deg, #0EC8E1 0.06%, #0866D5 99.99%)',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  userDataTable: {

  },
}));
