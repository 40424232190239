import './landingstyles.css';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router';
import { useLocation } from 'react-router-dom';

import { Alert, Box, Button, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { selectCheckAvailability } from '../availabilitySaga/selectors';
import { selectUserDetails } from '../Auth/selectors';
import { actions as checkAvailabilityActions } from '../availabilitySaga/slice';
import LandingPage from '../LandingPage';
import Content from './components/content';
import SocketManager from './components/SocketManager';

type Props = {};

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: 'flex',
    backgroundColor: '#F9FAFB'
  },
  contentDiv: {
    backgroundColor: '#F9FAFB',
    padding: '2rem 1rem 0rem 1.7rem'
  },
  dateInput: {
    paddingTop: 20,
    borderRadius: 10,
    gap: 10,
    display: 'flex'
  },
  noOfUser: {
    width: '100%',
    height: '4.7%',
    borderRadius: "10px",
  },
  content: {
    padding: '1%',
    backgroundColor: '#F9FAFB'
  },
  error: {
    width: '100%',
    textAlign: 'center',
    justifyContent: 'center',
    marginBottom: '10px',
  },
}));

export const MainScren = () => {
  const styles = useStyles()

  return (
    <div className={styles.content} >
      <Button
        variant="contained"
        disableElevation
        disableRipple
        disableTouchRipple
        sx={{ color: '#fff', fontSize: '1.6vh', fontWeight: '500', cursor: 'auto' }}
        className={styles.noOfUser}
      >
        Number of users: 20800
      </Button>
    </div>
  )
}


const DashboardPage = (props: Props) => {
  const styles = useStyles()
  const location = useLocation();
  const dispatch = useDispatch();
  const checkAvailability = useSelector(selectCheckAvailability);
  const selectUserDetails1 = useSelector(selectUserDetails);

  const userId = localStorage.getItem('userId');
  console.log("selectUserDetails", userId);

  return <Box className={styles.main}>
    <Content>
      <SocketManager userId={userId} />
      {/* {!checkAvailability && <Alert className={styles.error} severity="error">
        Someone is using this page, You can not edit or update this page
      </Alert>} */}
      {location.pathname === '/dashboard' ? <LandingPage /> :
        <Outlet />
      }
    </Content>
  </Box >;
};

export default DashboardPage;
