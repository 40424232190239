import { call, delay, put, takeLatest } from "@redux-saga/core/effects";
import { select } from "redux-saga/effects";
import { APP_API_URL } from "utils/constants";
import { request } from "utils/request";
import { selectDate } from "./selector";
import { actions } from "./slice";
import { InitialDashboardState } from "./types";
import format from "date-fns/format";

export function* getDashboardRequest(){
  yield delay(500)
  const options = {
    method: 'GET',
  };

  const date:InitialDashboardState = yield select(
    selectDate
  )

  // const dDate = format(new Date(date), 'dd-MMMM-yyyy')

  const todayDate = format(new Date(), 'dd-MM-yyyy')
  console.log("todayDate",todayDate,date)

  try{
    const response = yield call(
      request,
      `${APP_API_URL}/api/users/admin/dashboard/${date ?? new Date().toISOString()}`,
      options
      )
      console.log(response,'<=response');
      yield put(actions.getDashboardSuccess(response))
    }
    catch (err:any) {
      yield put(actions.getDashboardFailure(err))
  }
}

export function* dashboardSaga(){
  yield takeLatest(actions.getDashboard.type, getDashboardRequest)
}