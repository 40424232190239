import React from 'react';

import { FormControl, InputLabel, MenuItem, Radio, Select } from '@mui/material';

type Props = {
    label?: string | any,
    array?: any,
    setUser?: void | any,
    user?: string | any,
    widthNo?: string,
    isCategory?: boolean,
    isDisable?: boolean,
    type?: string
};

const DropDownComponent = ({ type, label, array, setUser, user, widthNo, isCategory, isDisable }: Props) => {
    const handleChange = (event) => {
        if (type === "Admin") {
            setUser(event)
        } else
            setUser(event?.target?.value);
    };
    console.log(array, "This is array")

    return (
        <FormControl disabled={isDisable} size='small' sx={{ width: widthNo ?? '11rem' }} >
            <InputLabel id="demo-label">{label}</InputLabel>
            <Select
                labelId="demo-label"
                id="demo-simple-select"
                value={user}
                label={label}
                sx={{
                    "& .MuiRadio-root": {
                        display: 'none'
                    }
                }}
                onChange={handleChange}
            >
                {array?.map((x, i) => {
                    return (
                        <MenuItem key={i} sx={{
                            "& .MuiSvgIcon-root": {
                                color: '#12BFE0'
                            },
                            flex: 1, justifyContent: 'space-between',
                        }} value={x.id ?? x}> {x.name ?? x}
                            {isCategory && <Radio checked={user === x.id} />}
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl >
    )
}

export default DropDownComponent