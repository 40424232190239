import React from 'react';

const Media = ({ color }) => {
    return (
        <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.34131 9.15967V14.7963L11.2734 11.978L6.34131 9.15967Z" fill={color} />
            <path d="M15.5008 18.3192H1.40917C1.03555 18.3188 0.677337 18.1702 0.413148 17.906C0.148958 17.6418 0.00037306 17.2836 0 16.91V7.04583C0.00037306 6.67221 0.148958 6.314 0.413148 6.04981C0.677337 5.78562 1.03555 5.63704 1.40917 5.63667H15.5008C15.8745 5.63704 16.2327 5.78562 16.4969 6.04981C16.761 6.314 16.9096 6.67221 16.91 7.04583V16.91C16.9096 17.2836 16.761 17.6418 16.4969 17.906C16.2327 18.1702 15.8745 18.3188 15.5008 18.3192ZM1.40917 7.04583V16.91H15.5008V7.04583H1.40917ZM1.40917 2.81833H15.5008V4.2275H1.40917V2.81833ZM2.81833 0H14.0917V1.40917H2.81833V0Z" fill={color} />
        </svg>
    )
}
export default Media;
