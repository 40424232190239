import React from 'react';

import { Modal } from '@mui/material';
import { Box } from '@mui/system';

import closeSvg from '../../../../assets/close.svg';
import { useStyles } from './styles';

type Props = {
    isVideo?: boolean
    openProfileModal: boolean
    setOpenProfileModal: any
    url: string
    isImage?: boolean
}

const ShowUserProfile = ({ isVideo, openProfileModal, setOpenProfileModal, url, isImage }: Props) => {
    const styles = useStyles();
    return (
        <Modal
            open={openProfileModal}
            onClose={() => setOpenProfileModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className={styles.ShowUserModalContainer}
                sx={{ bgcolor: 'background.paper', boxShadow: 10, borderRadius: 2, }}
            >
                <div className={styles.ShowUserModalSection} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <img
                        src={closeSvg}
                        alt="closeSvg"
                        onClick={() => { setOpenProfileModal() }}
                        className={styles.closeSvg}

                    />
                </div>

                <div style={{ height: '95%' }}>
                    {openProfileModal && isVideo && (<video  id={url} width="100%" height="100%" src={url} controls>
                        {/* <source src={url} type="video/mp4" /> */}
                    </video>)}
                    {!isVideo && (<img src={url} className={styles.profileImage} alt='profileImg' />)}
                </div>
            </Box>
        </Modal>
    );
};

export default ShowUserProfile