import { createSlice } from 'utils/@reduxjs/toolkit';

import { createAction } from '@reduxjs/toolkit';

import { ContainerState, LoginFormDefault } from './types';

export const storageLoad = createAction('REDUX_STORAGE_LOAD');
export const initialState: ContainerState = {
  form: LoginFormDefault,
  uploadUrl: null,
  imageUploadUrl: null,
  addSuccess: null,
  loading: false,
  error: null,
  file: undefined,
  profileVideoFormData: {
    title: '',
    size: 0,
    url: ''
  },
  profileImageFormData: {
    title: '',
    size: 0,
    url: ''
  }
};

const githubRepoFormSlice = createSlice({
  name: 'mediaState',
  initialState,
  reducers: {
    setForm(state, action) {
      state.form = {
        ...state.form,
        ...action.payload
      }
    },
    getUploadUrl(state, action) {
      // state.loading = true;
      state.error = null;
    },
    getUploadUrlSuccess(state, action) {
      state.loading = false;
      state.uploadUrl = action.payload;
    },
    getUploadUrlError(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
    getImageUploadUrl(state, action) {
      state.loading = true;
      state.error = null;
    },
    getImageUploadUrlSuccess(state, action) {
      state.loading = false;
      state.imageUploadUrl = action.payload;
    },
    getImageUploadUrlError(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
    addMedia(state) {
      state.loading = true;
      state.error = null;
    },
    addMediaSuccess(state, action) {
      state.loading = false;
      state.addSuccess = action.payload;
    },
    addMediaError(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
    clearAddData(state) {
      state.addSuccess = null;
      state.file = undefined;
      state.loading = false;
    },
    clearUploadUrl(state) {
      state.uploadUrl = null;
    },
    startLoading(state) {
      state.loading = true;
    },
    setFile(state, action) {
      state.file = action.payload.file;
    },
    getDownloadUrl(state, action) {
      state.loading = true;
    },
    getDownloadUrlSuccess(state, action) {
      state.loading = true;
      state.downloadUrl = action.payload.url;
    },
    getDownloadUrlError(state, action) {
      state.loading = true;
      state.error = action.payload
    },
    getUploadTranscoder(state, action) {
      console.log("state")
    },
    getUploadTranscoderSuccess(state, action) {
      console.log("transcoder-success")
    },
    getUploadTranscoderError(state, action) {
      console.log("error", action.payload)
    },
    uploadQuestionVideo(state, action) {
      console.log("state")
    },
    uploadQuestionVideoSuccess(state, action) {
    },
    uploadQuestionVideoError(state, action) {
      console.log("error", action.payload)
    },
    addMediaProfileVideo(state, action) {
      console.log("state")
    },
    addMediaProfileVideoSuccess(state, action) {
      console.log(action.payload, "This is payload")
    },
    addMediaProfileVideoError(state, action) {
      console.log("error", action.payload)
    },
    addMediaProfileImage(state, action) {
      console.log("state")
    },
    addMediaProfileImageSuccess(state, action) {
    },
    addMediaProfileImageError(state, action) {
      console.log("error", action.payload)
    },
    setProfileVideoForm(state, action) {
      state.profileVideoFormData = action.payload
    },
    setProfileImageForm(state, action) {
      state.profileImageFormData = action.payload
    },

    stopLoading(state) {
      state.loading = false;
    }
  },
});

export const { actions, reducer, name: sliceKey } = githubRepoFormSlice;
