import React from 'react';

import { Backdrop, CircularProgress } from '@mui/material';

const Loader = () => {
    return (
        <div>
            <Backdrop
                sx={{ color: '#12BFE0', position: 'absolute', zIndex: '10000' }}
                open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default Loader