import React from 'react';

import { Modal, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { useStyles } from '../styles';
import { useDispatch } from 'react-redux';
import { actions } from '../slice';


const ShowBlockedUserModal = ({ visible, closeMsg, showObj }) => {
    const styles = useStyles()
    const dispatch = useDispatch();

    const handleUnblockClick = (event, showObj) => {
        console.log("handleUnblockClick", event, showObj);
       dispatch(actions.unblockBlockedUser(showObj))
       closeMsg()
    };

    return (
        <Modal
            open={visible}
            onClose={closeMsg}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={
                {
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '35rem',
                    bgcolor: 'background.paper',
                    boxShadow: 10,
                    p: 3,
                    borderRadius: 2
                }
            }>
                <div style={{ margin: '1.5rem 0rem 0rem 0rem', display: 'flex', flexDirection: 'column', textAlign: 'center' }} >
                    <Typography sx={{ fontSize: 13, color: '#6C7389', fontWeight: 'bold', marginBottom: '0.5rem' }} >Do you want to unblock user <span className={styles.userName}>{showObj?.fullName}</span> for user <span className={styles.userName}>{showObj?.blockedByFullName}</span>?</Typography>
                    <Typography sx={{ fontSize: 13, color: '#6C7389', fontWeight: 'normal', }} >They will be able to view each other's stories and messages, send messages, and follow each other.</Typography>
                </div>
                <div style={{ margin: '1.5rem 0rem 0rem 0rem', display: 'flex', flexDirection: 'column', textAlign: 'center' }} >
                    <button className={styles.unblockButton} onClick={r => handleUnblockClick(r, showObj)}>Unblock</button>
                    <div className={styles.space}></div>
                    <button onClick={closeMsg} className={styles.closebutton}>Cancel</button>
                </div>
            </Box >
        </Modal >
    )
}

export default ShowBlockedUserModal;