import closeSvg from 'assets/close.svg';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { validateEmail, validateName } from 'utils/constants';

import {
    Alert, Button, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Modal, OutlinedInput,
    Select, TextField, Typography
} from '@mui/material';
import { Box } from '@mui/system';

import { selectAdminForm, selectError, selectPermissionsList } from '../selectors';
import { actions } from '../slice';
import { useStyles } from './style';

// ==========global const =============================
const AddAdminModal = ({ openModal, closeAdd, onSuccessAdd }) => {

    const styles = useStyles();
    const dispatch = useDispatch();
    const permissionList = useSelector(selectPermissionsList);
    const error = useSelector(selectError);
    // =====const=======================================
    const adminList = permissionList.map((permission) => ({ id: permission.name, name: permission.name }))
    const formValue = useSelector(selectAdminForm)

    // =================states ============================
    const [variantName, setVariantName] = React.useState<any>([])
    const [showDropDown, setShowDropDown] = React.useState(false)

    // =================handle changes ====================
    const handleChange = (event, val) => {
        const {
            target: { value },
        } = event;

        const selectedId = val.props.value.id
        let count = 0;
        value.forEach(element => {
            if (element.id === selectedId) {
                count += 1;
            }
        });

        if (count > 1) {
            const new_value = value.filter(v => v.id !== selectedId)
            setVariantName(new_value)
            dispatch(actions.updateSingleAdminFormValue({ name: 'permission', value: new_value }))
        } else {
            setVariantName(value)
            dispatch(actions.updateSingleAdminFormValue({ name: 'permission', value: value }))
        }
    }

    const handleChangeFields = (evt) => {
        dispatch(actions.updateSingleAdminFormValue({ name: evt.target.name, value: evt.target.value }))
    }

    const handleOnSuccessAdd = () => {
        onSuccessAdd()
        setVariantName([])
    }

    const handleSubmitAdd = () => {
        if (formValue && !validateName.test(formValue?.fullName ?? '')) {
            dispatch(actions.updateAdminFormError({ name: 'fullNameError', value: 'INVALID_USERNAME' }))
            dispatch(actions.updateStatusFailure('Invalid Admin User Name.'))
            return
        } else {
            dispatch(actions.updateAdminFormError({ name: 'fullNameError', value: '' }))
            dispatch(actions.updateStatusFailure(''))
        }
        if (formValue && !validateEmail.test(formValue?.email ?? '')) {
            dispatch(actions.updateAdminFormError({ name: 'emailError', value: 'Please enter valid email' }))
            dispatch(actions.updateStatusFailure('Please enter valid Email.'))
            return
        } else {
            dispatch(actions.updateAdminFormError({ name: 'emailError', value: '' }))
            dispatch(actions.updateStatusFailure(''))
        }
        if (formValue && formValue?.permission?.length === 0) {
            dispatch(actions.updateStatusFailure('Please select atleast one permission'))
            return
        } else {
            dispatch(actions.updateStatusFailure(''))
        }

        if (formValue.type === "Add") {
            dispatch(actions.addAdminUser({ callback: handleOnSuccessAdd }))
        }
        if (formValue.type === "Edit") {
            dispatch(actions.updateAdminUser({ callback: handleOnSuccessAdd }))
        }
    }

    const handleOnCloseModal = () => {
        setVariantName([])
        closeAdd()
    }

    useEffect(() => {
        return () => {
            setVariantName([])
        }
    }, [])

    useEffect(() => {
        if (formValue && formValue?.permission) {
            if (formValue.permission.length > 0 && typeof formValue.permission[0] === 'string') {
                let permissions: any = []
                permissions = formValue.permission.map(v => ({ id: v, name: v }))
                setVariantName(permissions)
            }
        }
    }, [formValue])

    return (
        <>
            <Modal
                open={openModal}
                onClose={handleOnCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '25rem',
                        bgcolor: 'background.paper',
                        boxShadow: 10,
                        p: 3,
                        borderRadius: 2,
                    }}
                >
                    <div className={styles.ModalHeader}>
                        <Typography sx={{ fontSize: 16, fontWeight: '900' }}>
                            {formValue.type} Admin
                        </Typography>
                        <img
                            src={closeSvg}
                            alt="closeSvg"
                            onClick={closeAdd}
                            className={styles.closeSvg}
                        />
                    </div>
                    <div className={styles.BodyContainer}>
                        <div className={styles.BodySection}>
                            {error &&
                                <Alert className={styles.error} severity="error">
                                    {loginError(error)?.message}
                                </Alert>
                            }
                            <FormControl sx={{ m: 1, width: 335 }}>
                                <TextField
                                    variant='outlined'
                                    label={'Admin User Name'}
                                    name={'fullName'}
                                    value={formValue.fullName}
                                    onChange={handleChangeFields}
                                />
                            </FormControl>
                            <FormControl sx={{ m: 1, width: 335 }}>
                                <TextField
                                    variant='outlined'
                                    label={'Email'}
                                    type={'email'}
                                    name={'email'}
                                    value={formValue.email}
                                    onChange={handleChangeFields}
                                />
                            </FormControl>
                            <FormControl sx={{ m: 1, width: 335 }}>
                                <InputLabel id="permission-model-label">Permission By Module</InputLabel>
                                <Select
                                    labelId="permission-model-label"
                                    multiple
                                    name={'permission'}
                                    value={variantName}
                                    onChange={(e, variant) => handleChange(e, variant)}
                                    input={<OutlinedInput label="Permission By Module" />}
                                    renderValue={(selected) => selected.map((x: any) => x.name).join(', ')}
                                    // MenuProps={MenuProps}
                                    onOpen={() => setShowDropDown(true)}
                                    onClose={() => setShowDropDown(false)}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left"
                                        },
                                    }}
                                >
                                    {adminList.map((variant: any) => (
                                        <MenuItem
                                            sx={{
                                                "& .MuiSvgIcon-root": {
                                                    color: '#12BFE0'
                                                },
                                                flex: 1, justifyContent: 'space-between',
                                            }}
                                            key={variant.id}
                                            value={variant}
                                        >
                                            <ListItemText primary={variant.name} />
                                            <Checkbox
                                                checked={
                                                    variantName.findIndex((item: any) => item.id === variant.id) >= 0
                                                }
                                            />
                                        </MenuItem>
                                    )
                                    )}
                                </Select>
                                {showDropDown && adminList.map((variant: any) => {
                                    return (<MenuItem
                                        sx={{
                                            "& .MuiSvgIcon-root": {
                                                color: '#12BFE0'
                                            },
                                            flex: 1, justifyContent: 'space-between',
                                        }}
                                        key={variant.id}
                                        value={variant}
                                    >
                                        <ListItemText primary={variant.name} />
                                        <Checkbox
                                            checked={
                                                variantName.findIndex((item: any) => item.id === variant.id) >= 0
                                            }
                                        />
                                    </MenuItem>)
                                }
                                )}
                            </FormControl>
                        </div>
                    </div>

                    <div className={styles.ButtonContainer}>
                        <div className={styles.ButtonSection}>
                            <Button
                                variant="outlined"
                                className={styles.CloseButton}
                                sx={{
                                    backgroundColor: '#E7E7E7',
                                    borderColor: '#e7e7e7',
                                    color: '#6C7389',
                                    '&:hover': {
                                        borderColor: '#e7e7e7',
                                        backgroundColor: '#E7E7E7',
                                        cursor: 'pointer'
                                    },
                                }}
                                onClick={handleOnCloseModal}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={handleSubmitAdd}
                                variant="outlined"
                                sx={{
                                    color: '#fff',
                                    backgroundColor: '#12BFE0',
                                    '&:hover': {
                                        backgroundColor: '#12bfe0',
                                        cursor: 'pointer'
                                    },
                                    fontSize: '0.75rem',
                                    padding: '6px 30px',
                                    fontWeight: '500',
                                    cursor: 'auto',
                                    borderRadius: '5px',
                                    marginLeft: 2,
                                }}
                                disabled={formValue.email?.length === 0 || formValue.fullName?.length === 0 || formValue.permission?.length === 0}
                            >
                                {/* {quesloading ? <CircularProgress style={{
                                    width: "25px",
                                    height: "25px",
                                    position: "absolute",
                                    color: "#000000"
                                }} /> : ''} */}
                                Done
                            </Button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    )
}

export const loginError = (error: string | undefined | null) => {
    if (!error) return null;
    switch (error) {
        case 'MESSAGE_ADMIN_USER_EXISTS':
            return { message: 'Admin user with this email already exist.' };
        case 'INVALID_USERNAME':
            return { message: 'Invalid Admin User Name.' };
        default:
            return { message: error };
    }
};

export default AddAdminModal;