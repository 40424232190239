import { editValidPhone, validMail } from 'app/components/AddModal';
import { actions as answerActions } from 'app/containers/Answers/slice';
import { selectImageUploadUrl, selectUploadUrl } from 'app/containers/mediaSaga/selectors';
import { actions as mediaActions } from 'app/containers/mediaSaga/slice';
import addProfilePencil from 'assets/addProfilePencil.png';
import AnsweredQuestionsImage from 'assets/AnsweredQuestions';
import BlockedUsers from 'assets/BlockedUsers';
import BackSvg from 'assets/Back.svg';
import MediaImage from 'assets/Media';
import MessageImage from 'assets/Message';
import PersonalInfoImage from 'assets/PersonalInfo';
import play from 'assets/play.png';
import defaultUser from 'assets/User.png';
import userDefaultVideo from 'assets/userVideo.png';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { uploadFile } from 'utils/s3Helper';

import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Alert, Box, Tab, Typography } from '@mui/material';

import {
    selectAddUser, selectDefaultQuestionId, selectError, selectImageFile, selectLoading,
    selectStatusUpdateMessage, selectUserObj, selectuserProfileImage, selectuserProfileVideo,
    selectuserProfileVideoSize, selectuserThumbnailProfileVideo, selectVideoFile
} from '../../selectors';
import { actions } from '../../slice';
import AnsweredQuestionsSection from '../AnsweredQuestionsSection';
import MediaInfoSection from '../MediaInfoSection';
import MessageInfoSection from '../MessageInfoSection';
import PersonalInfoSection from '../PersonalInfoSection';
import ShowUserProfile from '../ShowUserProfile';
import { useStyles } from '../styles';
import BlockedUsersSection from '../BlockedUsersSection';

const AddEditUserManagement = () => {

    // ========consts ===============================
    const location = useLocation()
    const id = location?.pathname?.split("/").at(-1)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const styles = useStyles();

    const userData = useSelector(selectUserObj)
    const err = useSelector(selectError)
    const selectedVideoFile: any = useSelector(selectVideoFile);
    const selectedImageFile: any = useSelector(selectImageFile);
    const imageUploadUrl = useSelector(selectImageUploadUrl)
    const head = useSelector(selectUserObj)
    const data = useSelector(selectAddUser)
    const uploadUrl = useSelector(selectUploadUrl)
    const userProfileImage = useSelector(selectuserProfileImage)
    const userProfileVideo = useSelector(selectuserProfileVideo)
    const userThumbnailProfileVideo = useSelector(selectuserThumbnailProfileVideo)
    const userProfileVideoSize = useSelector(selectuserProfileVideoSize)
    const defaultQuestionId: string = useSelector(selectDefaultQuestionId);
    const statusUpdateMessage: string = useSelector(selectStatusUpdateMessage);

    const userGenderArr = ['MEN', 'WOMEN'];
    const lookingArr = ['MEN', 'WOMEN', 'ALL'];
    const phoneNumber = userData.phone.split('+972')[1];
    const { looking_for, phone, email, dob } = userData
    const { mobile_no, email: mail } = data

    // ================================states=======================
    const [userGender, setUserGender] = useState(head.gender)
    const [pressed, setPressed] = useState<boolean>(false)
    const [openProfileModal, setOpenProfileModal] = useState(false)
    const [selectedProfileData, setSelectedProfileData] = useState({
        isVideoSelected: false,
        url: ''
    })

    const [inputRef, setInputRef] = useState<HTMLInputElement | null>()
    const [inputImageRef, setInputImageRef] = useState<HTMLInputElement | null>()
    const [fileProgress, setFileProgress] = useState<any>(0)
    const [isImageClicked, setIsImageClicked] = useState(false)
    const [isVideoClicked, setIsVideoClicked] = useState(false)

    // =============================================hangle changes ======================
    const onBack = () => {
        navigate('/dashboard/user-management')
        dispatch(actions.getSelectedUserOnly([]));
    }
    const handleSubmit = () => {
        let isCode = false;
        if (phone.includes('+972')) {
            isCode = true
        }
        setPressed(true)
        if (!editValidPhone(isCode ? phone : `+972${phone}`) && !validMail(email) && !validFile(selectedVideoFile) && !validImageFile(selectedImageFile)) {
            setPressed(false)
            dispatch(mediaActions.getImageUploadUrl({ fileName: selectedImageFile?.name, contentType: selectedImageFile?.type }));
            dispatch(mediaActions.getUploadUrl({ fileName: selectedVideoFile?.name, contentType: selectedVideoFile?.type }));
            dispatch(actions.getEdit())
        }
    }
    const setPhoneNo = (e) => {
        setPressed(false)
        dispatch(actions.getUserEditPhone(`+972${e?.nativeEvent?.target?.value}`))
    }
    const setEmail = (e) => {
        setPressed(false)
        dispatch(actions.getUserEditEmail(e?.nativeEvent?.target?.value))
    }
    const setLF = (e) => {
        dispatch(actions.getUserEditLookingFor(e))
    }
    const handleChoose = () => {
        inputRef?.click()
    }
    const handleImageChoose = () => {
        inputImageRef?.click()
    }

    const onProgress = (fileName: any, data: any) => {
        if (data !== 'start' && data !== "end") {
            setFileProgress(data)
        }
        if (data === "end") {
            var video: any = document.createElement('video');
            video.preload = 'metadata';
            video.onloadedmetadata = function () {
                window.URL.revokeObjectURL(video.src);
                const dur = Math.floor(parseInt(video.duration, 10));
                const duration = `${moment.duration(dur, 'seconds').minutes()} min ${moment
                    .duration(dur, 'seconds')
                    .seconds()} sec `;
                dispatch(mediaActions.getUploadTranscoder({ fileName: uploadUrl?.mediaKey }))
                let uploadAnsData = { questionId: defaultQuestionId, videoUrl: uploadUrl.mediaKey, videoLength: duration, email: mail, mobile_no: mobile_no }
                dispatch(answerActions.setAddUserAnswerData(uploadAnsData))
                let data = {
                    title: selectedVideoFile.name,
                    size: selectedVideoFile.size,
                    url: uploadUrl.mediaKey,
                };
                dispatch(
                    mediaActions.uploadQuestionVideo({
                        questionId: defaultQuestionId,
                        videoUrl: uploadUrl?.mediaKey,
                        videoLength: duration,
                        intro: true
                    }));
                dispatch(mediaActions.setProfileVideoForm(data));
                dispatch(mediaActions.addMediaProfileVideo({
                    userId: head?.id,
                    file_type: '',
                    media_type: '',
                    title: selectedVideoFile.name,
                    size: selectedVideoFile.size,
                    url: uploadUrl.mediaKey
                }))
                dispatch(mediaActions.clearAddData())
                dispatch(mediaActions.clearUploadUrl())
                dispatch(actions.getAddClose())
            };
            video.src = selectedVideoFile.url;
        }
    }
    const imageOnProgress = (fileName: any, data: any) => {
        if (data !== 'start' && data !== "end") {
            setFileProgress(data)
        }
        if (data === "end") {
            let data = {
                title: selectedImageFile.name,
                size: selectedImageFile.size,
                url: imageUploadUrl.mediaKey,
            };
            dispatch(mediaActions.setProfileImageForm(data));
            dispatch(mediaActions.addMediaProfileImage({
                userId: head?.id,
                file_type: '',
                media_type: '',
                title: selectedImageFile.name,
                size: selectedImageFile.size,
                url: imageUploadUrl.mediaKey
            }));
            var image: any = document.createElement('image');
            image.preload = 'metadata';
            image.onloadedmetadata = function () {
                window.URL.revokeObjectURL(image.src);
            };
            image.src = selectedImageFile.url;
        }
    }

    const uploadFilesToS3 = async () => {
        dispatch(mediaActions.startLoading());
        setFileProgress(0)
        uploadFile(selectedVideoFile, uploadUrl.mediaUrl, onProgress);
    }
    const uploadImageFilesToS3 = async () => {
        dispatch(mediaActions.startLoading());
        setFileProgress(0)
        uploadFile(selectedImageFile, imageUploadUrl.mediaUrl, imageOnProgress);
    }

    const validFile = (file) => {
        if (!isVideoClicked) return null
        if (!file) {
            return 'Please select a video'
        }
        if (file.type.search("mp4") === -1) {
            return 'Please select a mp4 video file'
        }
        if (file.size / 1024000 > 100) {
            return 'Please select less than 100mb'
        }
        return null
    }
    const validImageFile = (file) => {
        if (!isImageClicked) return null
        if (!file || !file.type.includes('image')) {
            return 'Please select a image'
        }
        if (file.size / 1024000 > 100) {
            return 'Please select less than 100mb'
        }
        return null
    }

    const handleFileChange = (event) => {
        setIsVideoClicked(true)
        dispatch(actions.setVideoFile({
            file: {
                name: event.target.files[0].name,
                type: event.target.files[0].type,
                size: event.target.files[0].size,
                url: URL.createObjectURL(event.target.files[0])
            }
        }))

        try {
            getVideoCover(event.target.files[0], 1.5);
        } catch (ex) {
            console.log("ERROR: ", ex);
        }

        function getVideoCover(file, seekTo = 0.0) {
            return new Promise((resolve, reject) => {
                // load the file to a video player
                const videoPlayer = document.createElement('video');
                videoPlayer.setAttribute('src', URL.createObjectURL(file));
                videoPlayer.load();
                videoPlayer.addEventListener('error', (ex: any) => {
                    reject("error when loading video file");
                });
                // load metadata of the video to get video duration and dimensions
                videoPlayer.addEventListener('loadedmetadata', () => {
                    // seek to user defined timestamp (in seconds) if possible
                    if (videoPlayer.duration < seekTo) {
                        reject("video is too short.");
                        return;
                    }
                    // delay seeking or else 'seeked' event won't fire on Safari
                    setTimeout(() => {
                        videoPlayer.currentTime = seekTo;
                    }, 200);
                    // extract video thumbnail once seeking is complete
                    videoPlayer.addEventListener('seeked', () => {
                        const canvas = document.createElement("canvas");
                        canvas.width = videoPlayer.videoWidth;
                        canvas.height = videoPlayer.videoHeight;
                        const ctx: any = canvas.getContext("2d");
                        ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
                        dispatch(actions.updateUserThumbnailProfileVideo(canvas.toDataURL()))
                        ctx.canvas.toBlob(
                            blob => {
                                resolve(blob);
                            },
                            "image/jpeg",
                            0.75 /* quality */
                        );

                    });
                });
            });
        }
    }
    const handleImageFileChange = (event) => {
        setIsImageClicked(true)
        dispatch(actions.setImageFile({
            file: {
                name: event.target.files[0].name,
                type: event.target.files[0].type,
                size: event.target.files[0].size,
                url: URL.createObjectURL(event.target.files[0])
            }
        }))
    }

    const setUserProfileData = (isVideo: boolean, url: string) => {
        setOpenProfileModal(true)
        setSelectedProfileData({
            isVideoSelected: isVideo,
            url: url
        })
    }
    const [value, setValue] = React.useState('1');
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    // ===========================useEffects===========================
    useEffect(() => {
        dispatch(actions.setSelectUserId(id))
        dispatch(actions.getUserProfileMedia())
        dispatch(actions.getUserEditPhone(head.phone.split('+972')[1]))
        dispatch(actions.getSelectedUserObj(head))
        dispatch(actions.getSelectUserQuestionAnswers())
        dispatch(actions.getMedia())
        return () => {
            dispatch(mediaActions.clearAddData())
            dispatch(mediaActions.clearUploadUrl())
            dispatch(actions.getAddClose())
            dispatch(actions.getUserProfileMediaSuccess({ image: '', video: '', videoThumbnailUrl: '' }))
        }
    }, [id])

    useEffect(() => {
        if (uploadUrl && uploadUrl.mediaUrl && isVideoClicked) {
            uploadFilesToS3();
        }
    }, [uploadUrl])//for profile video

    useEffect(() => {
        if (imageUploadUrl && imageUploadUrl.mediaUrl && isImageClicked) {
            uploadImageFilesToS3();
        }
    }, [imageUploadUrl])//for profile image

    useEffect(() => {
        const timer = setTimeout(() => dispatch(actions.changeUpdateStatusMessage("")), 3000);
        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusUpdateMessage.length > 0])

    if (!head.phone) {
        navigate('/dashboard/user-management')
    }

    return (
        <div className={styles.AddEditContainer}>
            {statusUpdateMessage && <Alert severity="success">{statusUpdateMessage}</Alert>}
            <div className={styles.headerDiv} style={{ borderBottom: '1px solid #DFDFDF' }}>
                <div className={styles.backDiv} >
                    <img src={BackSvg} alt='backIcon' className={styles.backImg} onClick={onBack} />
                    <Typography sx={{ fontSize: 14, color: '#086ED6', fontWeight: 500, margin: '0rem 2rem 0rem 0rem' }}>Back</Typography>
                </div>
                <div className={styles.addUserMedia} >
                    <input
                        ref={el => setInputImageRef(el)}
                        type="file"
                        style={{ display: 'none' }}
                        className="ImageInput_input"
                        onChange={handleImageFileChange}
                        accept="image/*"
                    />
                    <div style={{ marginRight: '1rem' }}>
                        <div>
                            <div>
                                <div style={{ height: '100px', width: '100px' }}>
                                    {userProfileImage && (
                                        <img
                                            onClick={() => { setUserProfileData(false, userProfileImage) }}
                                            style={{ height: '100%', width: '100%', borderRadius: '50%' }}
                                            src={userProfileImage}
                                            alt='profileImage'
                                        />
                                    )}
                                    {!userProfileImage && (
                                        <img
                                            style={{ height: '100%', width: '100%', borderRadius: '50%' }}
                                            src={defaultUser}
                                            alt='profileImage'
                                        />)}
                                </div>
                                {userProfileImage && (<img onClick={handleImageChoose} src={addProfilePencil} alt="Attach File" className={styles.imageAttach} />)}
                                {!userProfileImage && (<img onClick={handleImageChoose} src={play} alt="Attach File" className={styles.imageAttach} />)}
                            </div>
                            {/* <div>
                                {selectedImageFile && (<Typography sx={{ fontSize: 13, fontWeight: '400', textAlign: 'center', textOverflow: 'ellipsis', overflow: 'hidden', minHeight: '21px', whiteSpace: 'nowrap', width: '135px' }}>
                                    {selectedImageFile.name}
                                </Typography>)}
                            </div> */}
                        </div>
                        {pressed && validImageFile(selectedImageFile) && <Typography sx={{ color: '#911', marginTop: 1, marginLeft: '16px', position: 'absolute' }}>{validImageFile(selectedImageFile)}</Typography>}
                    </div>

                    <div style={{ marginLeft: '1rem', marginRight: '1rem' }}>
                        <Typography sx={{ fontSize: 22, color: '#086ED6', fontWeight: 500 }}>{head.fullName}</Typography>
                        <Typography sx={{ fontSize: 18, color: '#086ED6', fontWeight: 400 }}>{head.age} years old</Typography>
                    </div>

                    <input
                        ref={el => setInputRef(el)}
                        type="file"
                        style={{ display: 'none' }}
                        className="VideoInput_input"
                        onChange={handleFileChange}
                        accept=".mov,.mp4"
                    />
                    <div style={{ marginLeft: '1rem' }}>
                        <div>
                            <div>
                                <div style={{ height: '100px', width: '100px' }}>
                                    {userThumbnailProfileVideo && (
                                        <img
                                            id='imgCanva'
                                            onClick={() => setUserProfileData(true, userProfileVideo)}
                                            style={{ height: '100%', width: '100%' }}
                                            src={userThumbnailProfileVideo}
                                            alt='profileImage'
                                        />)}
                                    {!userThumbnailProfileVideo && (<img style={{ height: '100%', width: '100%' }} src={userDefaultVideo} alt='profileImage' />)}
                                </div>
                                {userThumbnailProfileVideo && (<img onClick={handleChoose} src={addProfilePencil} alt="Attach File" className={styles.imageAttach} />)}
                                {!userThumbnailProfileVideo && (<img onClick={handleChoose} src={play} alt="Attach File" className={styles.imageAttach} />)}
                            </div>
                        </div>
                        {pressed && validFile(selectedVideoFile) && <Typography sx={{ color: '#911', marginTop: 1, marginLeft: '16px', position: 'absolute' }}>{validFile(selectedVideoFile)}</Typography>}
                    </div>

                    <div style={{ marginLeft: '1.5rem', marginRight: '1rem' }}>
                        {selectedVideoFile && (<><Typography sx={{ fontSize: 22, color: '#086ED6', fontWeight: 500, textAlign: 'center', textOverflow: 'ellipsis', overflow: 'hidden', minHeight: '21px', whiteSpace: 'nowrap', width: '135px' }}>
                            {selectedVideoFile.name}
                        </Typography>
                            <Typography sx={{ fontSize: 16, color: '#086ED6', fontWeight: 400 }}>Video: {(selectedVideoFile.size / 1024000).toString().slice(0, 4)} Mb * Mp4</Typography>
                        </>)}
                        {!selectedVideoFile && (<><Typography sx={{ fontSize: 22, color: '#086ED6', fontWeight: 500, textOverflow: 'ellipsis', overflow: 'hidden', minHeight: '21px', whiteSpace: 'nowrap', width: '135px' }}>{userProfileVideo ? userProfileVideo.replace('https://zipper-video-dev.s3.eu-west-1.amazonaws.com/test/media/', '') : 'Download Video'}</Typography>
                            <Typography sx={{ fontSize: 16, color: '#086ED6', fontWeight: 400 }}>Video: {(userProfileVideoSize / 1024000).toString().slice(0, 4)} Mb * Mp4</Typography></>)}
                    </div>
                </div>
                <div>
                    {openProfileModal &&
                        <ShowUserProfile isVideo={selectedProfileData?.isVideoSelected} openProfileModal={openProfileModal} setOpenProfileModal={setOpenProfileModal} url={selectedProfileData?.url} />
                    }
                </div>
            </div>
            {/* ========================================new design============================== */}
            <Box sx={{ width: '100%', typography: 'body1', marginTop: '32px' }}>
                <TabContext value={value}>
                    <Box>
                        <TabList
                            className={styles.TabList}
                            onChange={handleChange}
                            aria-label="lab API tabs example"
                        >
                            <Tab label={<TabsLabel width={'135px'} label={'Personal Info'} logo={<PersonalInfoImage color={value === '1' ? '#fff' : '#0B96DB'} />} />} value="1" />
                            <Tab label={<TabsLabel width={'114px'} label={'Message'} logo={<MessageImage color={value === '2' ? '#fff' : '#0B96DB'} />} />} value="2" />
                            <Tab label={<TabsLabel width={'100px'} label={'Media'} logo={<MediaImage color={value === '3' ? '#fff' : '#0B96DB'} />} />} value="3" />
                            <Tab label={<TabsLabel width={'172px'} label={'Answered Question'} logo={<AnsweredQuestionsImage color={value === '4' ? '#fff' : '#0B96DB'} />} />} value="4" />
                            <Tab label={<TabsLabel width={'172px'} label={'Blocked Users'} logo={<BlockedUsers color={value === '5' ? '#fff' : '#0B96DB'} />} />} value="5" />
                        </TabList>
                    </Box>
                    <TabPanel value="1" sx={{ p: 0, mt: 7 }}>
                        <PersonalInfoSection
                            head={head}
                            setPhoneNo={setPhoneNo}
                            phoneNumber={phoneNumber}
                            pressed={pressed}
                            phone={phone}
                            setEmail={setEmail}
                            email={email}
                            userGenderArr={userGenderArr}
                            setUserGender={setUserGender}
                            userGender={userGender}
                            lookingArr={lookingArr}
                            looking_for={looking_for}
                            setLF={setLF}
                            dob={dob}
                            handleSubmit={handleSubmit}
                            err={err}
                            onBack={onBack}
                        />
                    </TabPanel>
                    <TabPanel value="2" sx={{ p: 0, mt: 2, mb: 4 }}>
                        <MessageInfoSection />
                    </TabPanel>
                    <TabPanel value="3" sx={{ p: 0, mt: 2, mb: 4 }}>
                        <MediaInfoSection />
                    </TabPanel>
                    <TabPanel value="4" sx={{ p: 0, mt: 2, mb: 4 }}>
                        <AnsweredQuestionsSection />
                    </TabPanel>
                    <TabPanel value="5" sx={{ p: 0, mt: 2, mb: 4 }}>
                        <BlockedUsersSection />
                    </TabPanel>
                </TabContext>
            </Box>
        </div >
    )
}
export default AddEditUserManagement;

// =======================sections common component ======================

const TabsLabel = ({ logo, label, width }) => {
    const style = useStyles();
    return (
        <div className={style.TabsLabelContainer} style={{ width: width }}>
            {logo}
            <text style={{ marginLeft: '5px' }}>{label}</text>
        </div>
    )
}