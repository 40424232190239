import React, { useState } from 'react';

import { Button, Popover, Switch, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';

type Props = {
    onChangeToggle: () => void,
    onSubmitToggle: () => void,
    isChecked: boolean,
    header: string,
    isEditing?: boolean
}

const IOSSwitchComponent = styled((props: Props) => {
    const { isChecked, onChangeToggle, onSubmitToggle, header, isEditing } = props;
    const [pressed, setPressed] = useState(false);
    const [anchorEl, setAnchorEl] = useState<any>(null);

    const onEnableDisable = () => {
        onSubmitToggle();
        setPressed(!pressed)
        setAnchorEl(null)
    }

    const handleClick = (event) => {
        onChangeToggle();
        setAnchorEl(event.currentTarget);
    };

    const handleOnClose = () => setAnchorEl(null);

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <div style={{ position: 'relative', display: 'flex' }} >
            <Button disableRipple aria-describedby={id} sx={{ zIndex: 2, minWidth: 'auto' }} onClick={(event) => isEditing ? null : handleClick(event)}>
                <Switch checked={isChecked} focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} disabled={isEditing} />
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleOnClose}
                sx={{ display: 'flex', flex: 1, padding: '2rem' }}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            >
                <Box sx={{ boxShadow: 10, p: 2, borderRadius: 2 }}>
                    <div>
                        <Typography>{header} this User ?</Typography>
                        <div style={{ display: 'flex', flexDirection: 'column' }} >
                            <Button
                                sx={{
                                    backgroundColor: '#12BFE0',
                                    color: '#fff',
                                    borderRadius: '20px',
                                    margin: '10px 0px 0px 0px',
                                    '&:hover': {
                                        backgroundColor: "#12bfe0"
                                    }
                                }}
                                onClick={onEnableDisable}
                            >{header}</Button>
                            <Button onClick={handleOnClose}>Close</Button>
                        </div>
                    </div>
                </Box>
            </Popover>
        </div>
    )
})(({ theme }) => ({
    width: 36,
    height: 21,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(15px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 17,
        height: 17,
    },
    '& .MuiSwitch-track': {
        borderRadius: 32 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
    },
}));

export default IOSSwitchComponent