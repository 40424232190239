import React, { useState } from 'react';

import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DatePickerView } from '@mui/lab/DatePicker/shared';
import { TextField } from '@mui/material';

type UserProps = {
    name: string,
    setDate?: any,
    date?: string | any,
    readOnly?: boolean,
    wd?: string,
    landingPage?: boolean,
    maxDate?: object | any,
    minDate?: object | any,
    views?: readonly DatePickerView[] | undefined,
};

const readOnlyOnFocus = (e) => {
    e.preventDefault();
    e.stopPropogation();
    console.log(e.target.readOnly = true)
}

const DateComponent = ({ name, setDate, date, readOnly, wd, landingPage, maxDate, minDate, views }: UserProps) => {

    const [isEmpty, setIsEmpty] = useState(false)
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} >
            <DatePicker
                orientation='portrait'
                label={name}
                disabled={readOnly}
                okText='Done'
                inputFormat={landingPage ? isEmpty ? 'MM/dd/yyyy' : 'MMMMMM dd, yyyy' : 'dd/MM/yyyy'}
                value={date ?? null}
                minDate={minDate}
                views={views}
                openTo={maxDate}
                maxDate={maxDate ?? new Date()}
                onChange={(newValue) => {
                    if (!newValue) {
                        setIsEmpty(true)
                    } else {
                        setIsEmpty(false)
                    }
                    setDate(newValue);
                }}
                renderInput={(params) => <TextField size='small' sx={{
                    width: wd || '15rem',

                }}  {...params}
                    onKeyPress={readOnlyOnFocus}
                    defaultValue=""
                />}
            />
        </LocalizationProvider>
    )
}

export default DateComponent