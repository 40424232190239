import closeSvg from 'assets/close.svg';
import React, { useEffect, useMemo, useState } from 'react';

import {
    Autocomplete, Box, Button, CircularProgress, Fab, LinearProgress, Modal, Popover, TextField,
    Typography
} from '@mui/material';

import { useStyles } from '../styles';

const ShowUserProfile = ({ isVideo, openProfileModal, setOpenProfileModal, url }) => {
    const styles = useStyles();
    useEffect(() => {
        return () => {
            setOpenProfileModal(false)
        }
    }, [])
    return (
        <Modal
            open={openProfileModal}
            onClose={() => setOpenProfileModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '25rem',
                    height: '35rem',
                    bgcolor: 'background.paper',
                    boxShadow: 10,
                    borderRadius: 2,
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flex: 1,
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography sx={{ fontSize: 16, fontWeight: '900' }}>
                        Answer Video
                    </Typography>
                    <img
                        src={closeSvg}
                        alt="closeSvg"
                        onClick={() => { setOpenProfileModal() }}
                        className={styles.closeSvg}
                    />
                </div>
                <div style={{ height: '95%', }}>
                    {isVideo && (<video width="100%" height="100%" controls >
                        <source src={url} type="video/mp4" />
                    </video>)}
                </div>
            </Box>
        </Modal>
    );
};

export default ShowUserProfile