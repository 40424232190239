import React from 'react';

const PersonalInfo = ({ color }) => {
    return (
        <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.4999 7.50033C12.4999 8.38438 12.1487 9.23223 11.5236 9.85735C10.8985 10.4825 10.0506 10.8337 9.16659 10.8337C8.28253 10.8337 7.43468 10.4825 6.80956 9.85735C6.18444 9.23223 5.83325 8.38438 5.83325 7.50033C5.83325 6.61627 6.18444 5.76842 6.80956 5.1433C7.43468 4.51818 8.28253 4.16699 9.16659 4.16699C10.0506 4.16699 10.8985 4.51818 11.5236 5.1433C12.1487 5.76842 12.4999 6.61627 12.4999 7.50033ZM10.8333 7.50033C10.8333 7.94235 10.6577 8.36628 10.3451 8.67884C10.0325 8.9914 9.60861 9.16699 9.16659 9.16699C8.72456 9.16699 8.30063 8.9914 7.98807 8.67884C7.67551 8.36628 7.49992 7.94235 7.49992 7.50033C7.49992 7.0583 7.67551 6.63437 7.98807 6.32181C8.30063 6.00925 8.72456 5.83366 9.16659 5.83366C9.60861 5.83366 10.0325 6.00925 10.3451 6.32181C10.6577 6.63437 10.8333 7.0583 10.8333 7.50033Z" fill={color} />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.16667 0.833496C4.10417 0.833496 0 4.93766 0 10.0002C0 15.0627 4.10417 19.1668 9.16667 19.1668C14.2292 19.1668 18.3333 15.0627 18.3333 10.0002C18.3333 4.93766 14.2292 0.833496 9.16667 0.833496ZM1.66667 10.0002C1.66667 11.7418 2.26083 13.3452 3.25667 14.6185C3.95603 13.7001 4.85825 12.9558 5.89286 12.4438C6.92747 11.9317 8.06645 11.6658 9.22083 11.6668C10.3603 11.6657 11.4849 11.9248 12.5091 12.4241C13.5333 12.9235 14.43 13.6501 15.1308 14.5485C15.8529 13.6015 16.339 12.4962 16.5491 11.324C16.7591 10.1518 16.687 8.94644 16.3387 7.80765C15.9905 6.66886 15.376 5.62937 14.5462 4.77519C13.7165 3.92102 12.6952 3.27671 11.567 2.89559C10.4388 2.51446 9.236 2.40747 8.05822 2.58347C6.88043 2.75947 5.76149 3.21341 4.79397 3.90771C3.82646 4.60201 3.03818 5.51672 2.49436 6.57616C1.95054 7.6356 1.66682 8.8093 1.66667 10.0002ZM9.16667 17.5002C7.44496 17.5027 5.77522 16.9105 4.44 15.8235C4.97744 15.0541 5.69278 14.4259 6.52516 13.9924C7.35754 13.5589 8.28232 13.3328 9.22083 13.3335C10.1476 13.3328 11.0612 13.5531 11.8858 13.9763C12.7103 14.3996 13.422 15.0134 13.9617 15.7668C12.6161 16.8891 10.9188 17.5026 9.16667 17.5002Z" fill={color} />
        </svg>
    )
}

export default PersonalInfo

