import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { actions, reducer, sliceKey } from './slice';
import { selectSnackbar } from './selectors';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const Alert = React.forwardRef(
  function Alert(props, ref:any) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

export default function SnackbarComponent() {
    useInjectReducer({ key: sliceKey, reducer: reducer });

  const { message, open, variant } = useSelector(selectSnackbar);

  const dispatch = useDispatch();
  function handleClose() {
    dispatch(actions.closeSnackbar());
  }

  return (
    <>
    <Snackbar
      open={open}
      onClose={handleClose}
      message={message}
    />

      {/* <Snackbar
        open={open}
        onClose={handleClose}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert severity={variant} >{message}</Alert>
      </Snackbar> */}
    </>
  );
}
