/*
 * GithubRepoForm Slice
 *
 * Here we define:
 * - The shape of our container's slice of Redux store,
 * - All the actions which can be triggered for this slice, including their effects on the store.
 *
 * Note that, while we are using dot notation in our reducer, we are not actually mutating the state
 * manually. Under the hood, we use immer to apply these updates to a new copy of the state.
 * Please see https://immerjs.github.io/immer/docs/introduction for more information.
 *
 */
import { Color } from '@mui/material';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { ContainerState, SnackbarPrototype } from './types';

// The initial state of the GithubRepoForm container
export const initialState: ContainerState = {
  snackbar: { open: false, variant: 'error', message: '' } as SnackbarPrototype,
};

const githubRepoFormSlice = createSlice({
  name: 'SnackbarState',
  initialState,
  reducers: {
    closeSnackbar(state) {
      state.snackbar.open = false;
      state.snackbar.message = '';
    },
    showSnackbar(
      state,
      action: PayloadAction<{ message: string; variant?: Color }>,
    ) {
      state.snackbar.message = action.payload.message;
      state.snackbar.variant = action.payload.variant || "error";
      state.snackbar.open = true;
    },
  }
});

export const { actions, reducer, name: sliceKey } = githubRepoFormSlice;
