import './userStyle.css';

import AddModal from 'app/components/AddModal';
import DataTableComponent from 'app/components/DataTableComponent';
import HomeDateComponent from 'app/components/DateComponent/homeDate';
import SendMsgModal from 'app/components/SendMsgModal';
import closeSvg from 'assets/close.svg';
import filterSvg from 'assets/filter.svg';
import calendarIcon from 'assets/icons8-calendar-24.png';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Alert, Button, Fab, Modal, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { GridColDef } from '@mui/x-data-grid';

import playButton from '../../../assets/videoPlay.png';
import searchIcon from '../../containers/Dashboard/components/topbar/assets/IconSearch.png';
import { selectError, selectStatusUpdateMessage } from '../UserManagement/selectors';
import CustomeDatePicker from './Components/CustomeDatePicker';
import Dropdown from './Components/Dropdown';
import ShowUserProfileNew from './Components/ShowUserProfileNew';
import {
  selectAddUserModalOpen, selectFilters, selectLoading, selectPageSize, selectRows, selectSearch,
  selectTotalUsers, selectuserProfileVideo, selectUserRow
} from './selectors';
import { actions } from './slice';
import { useStyles } from './styles';

type UserProps = {
  name?: string,
  val?: string,
  setDate?: any,
  date?: string,
};

const UserManagement = () => {

  const styles = useStyles();
  const statusUpdateMessage = useSelector(selectStatusUpdateMessage);
  const rows = useSelector(selectRows);
  const addUserModalOpen = useSelector(selectAddUserModalOpen)
  const selectedUserProfileVideo = useSelector(selectuserProfileVideo)
  const rows2 = useSelector(selectUserRow)
  const dispatch = useDispatch();
  const err = useSelector(selectError)
  const searchText = useSelector(selectSearch)
  const loading = useSelector(selectLoading)
  const pageSize = useSelector(selectPageSize)
  const totalUsers = useSelector(selectTotalUsers)
  const filterData = useSelector(selectFilters)

  // ===================state=================
  const [modal, setModal] = useState(false);
  const [sendMsg, setSendMsg] = useState(false);
  const [header, setHeader] = useState(false);
  const [, setHead] = useState<any>()
  const [, setVisible] = useState<boolean>(false);
  const [openProfileModal, setOpenProfileModal] = useState(false)
  const [selectedProfileData, setSelectedProfileData] = useState({
    isVideoSelected: false,
    url: ''
  })
  const [showVideoLoader, setshowVideoLoader] = useState(false);
  const [, setShowObj] = useState<any>()
  const [keyPress, setKeyPress] = useState<number>()
  const [selectDatePicker, setSelectDatePicker] = useState('');
  const [selectedUsers, setSelectedUsers] = useState(0)
  const [selectedActionStatus, setSelectedActionStatus] = useState('VIDEO_UPLOADED');

  // =============consts===============================
  const { birthFrom, birthTo, abuseFrom, abuseTo, enrollFrom, enrollTo, followerFrom, followerTo, followingFrom, followingTo, lastLoginFrom, lastLoginTo, loginsFrom, loginsTo, matchesFrom, matchesTo, blockedFrom, blockedTo, messagesFrom, messagesTo, videosFrom, videosTo } = filterData;
  const dropDownList = [
    { id: 'ALL', value: 'ALL' },
    { id: 'VIDEO_NOT_UPLOADED', value: 'VIDEO NOT UPLOADED' },
    { id: 'VIDEO_UPLOADED', value: 'VIDEO UPLOADED' },
    { id: 'ACCEPTED', value: 'ACCEPTED' },
    { id: 'REJECTED', value: 'REJECTED' },
  ]

  // ==================handle change=======================
  const handlePlayVideo = (_event, row) => {
    if (row?.id) {
      dispatch(actions.setSelectUserId(row.id))
      dispatch(actions.getUserProfileMedia())
      setshowVideoLoader(true)
      setOpenProfileModal(true)
      setTimeout(() => setshowVideoLoader(false), 1500);
    }
  };

  const checkStatusType = (value) => {
    switch (value) {
      case "VIDEO_UPLOADED":
        return "VIDEO UPLOADED";
      case "VIDEO_NOT_UPLOADED":
        return "VIDEO NOT UPLOADED";
      case "ACCEPTED":
        return "ACCEPTED";
      case "REJECTED":
        return "REJECTED"
      default:
        return ""
    }
  }

  const handleChangeDropdownValueSingle = (val, id) =>
    dispatch(actions.updateAproveRejectForSingleUsersStatus({ id, statusType: val.target.value }));

  const columns: GridColDef[] = [
    {
      field: "sno", headerName: "S No.", width: 20,
      renderCell: e => {
        return e.row.index;
      }
    },
    { field: "fullName", headerName: "Full name", width: 118 },
    { field: "gender", headerName: "Gender", width: 118 },
    { field: "phone", headerName: "Phone", width: 148 },
    { field: "email", headerName: "Email", width: 220 },
    { field: "dob", headerName: "Birth Date", width: 118, },
    { field: "age", headerName: "Age", width: 70 },
    {
      field: "status", headerName: "Status", width: 120,
      renderCell: e => {
        return checkStatusType(e.row.actionStep)
      }
    },
    {
      field: "actions", headerName: "Actions", sortable: false, minWidth: 200, flex: 2,
      renderCell: e => {
        return (
          <div
            style={{
              display: 'flex',
              flex: 0.2,
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <img
              src={playButton}
              onClick={r => handlePlayVideo(r, e.row)}
              alt="playSvg"
              className={styles.playTagD}
            />
            <Dropdown
              dropDownlist={dropDownList}
              selectedItem={e.row.actionStep}
              onChange={(value) => handleChangeDropdownValueSingle(value, e.row.id)}
              isDisabled={e.row.isDisabled}
            />
          </div>
        );
      },
    },
    { field: "enroll_date", headerName: "Enrollment Date", width: 118 },
    { field: "last_login_date", headerName: "Last Login Date", width: 118 },
    { field: "logins", headerName: "Logins", width: 95 },
    { field: "messages", headerName: "Messages", width: 95 },
    { field: "matches", headerName: "Matches", width: 95 },
    { field: "blocked", headerName: "Blocked", width: 95 },
    { field: "videos", headerName: "Videos", width: 95 },
    { field: "following", headerName: "Following", width: 95 },
    { field: "followers", headerName: "Followers", width: 95 },
    { field: "abuses", headerName: "Abuse Reports", width: 120 },
  ];

  const columns2: GridColDef[] = [
    {
      field: "date",
      headerName: "Date/Time",
      flex: 1,
    },
    { field: "to_user", headerName: "To User", flex: 1 },

    { field: "message", sortable: false, headerName: "Message", flex: 2.5 },
    {
      field: "button", headerName: "", sortable: false, flex: 1, align: 'right', renderCell: (e) => <Button
        variant="outlined"
        onClick={() => {
          setShowObj(e.row)
          setVisible(true)
        }}
        sx={{
          color: '#fff', backgroundColor: '#12BFE0', '&:hover': {
            backgroundColor: "#12bfe0"
          }, fontSize: '0.75rem', padding: '0px 10px', fontWeight: '400', cursor: 'auto', borderRadius: "20px", marginLeft: 2
        }}
      >Show all</Button>
    },

  ];

  const open = () => setModal(true)
  const closeModal = () => setModal(false)
  const openAdd = () => dispatch(actions.openCloseAddUserModal(true));

  if (keyPress === 8 && !searchText?.trim()) {
    if (searchText != '') dispatch(actions.getUser())
  }

  const onSearch = (event) => {
    if (event.nativeEvent.target.value) {
      dispatch(actions.setAddMoreUsers(false))
      dispatch(actions.getSearch(event.nativeEvent.target.value))
      dispatch(actions.getUser());
    }
    else {
      dispatch(actions.setAddMoreUsers(false))
      dispatch(actions.getSearch(''))
      dispatch(actions.getUser())
    }
  }

  const setBdFrom = (value) => dispatch(actions.getBirthFrom(value))
  const setBdTo = (value) => dispatch(actions.getBirthTo(value))
  const setEnrollFrom = (value) => dispatch(actions.getEnrollmentFrom(value))
  const setEnrollTo = (value) => dispatch(actions.getEnrollmentTo(value))
  const setLastLoginFrom = (value) => dispatch(actions.getLastLoginFrom(value))
  const setLastLoginTo = (value) => dispatch(actions.getLastLoginTo(value))

  const setLoginsFrom = (event) => dispatch(actions.getLoginsFrom(event.nativeEvent.target.value))
  const setLoginsTo = (event) => dispatch(actions.getLoginsTo(event.nativeEvent.target.value))
  const setMsgFrom = (event) => dispatch(actions.getMessagesFrom(event.nativeEvent.target.value))
  const setMsgTo = (event) => dispatch(actions.getMessagesTo(event.nativeEvent.target.value))
  const setMatchesFrom = (event) => dispatch(actions.getMatchesFrom(event.nativeEvent.target.value))
  const setMatchesTo = (event) => dispatch(actions.getMatchesTo(event.nativeEvent.target.value))
  const setBlockedFrom = (event) => dispatch(actions.getBlockedFrom(event.nativeEvent.target.value))
  const setBlockedTo = (event) => dispatch(actions.getBlockedTo(event.nativeEvent.target.value))
  const setVideosFrom = (event) => dispatch(actions.getVideosFrom(event.nativeEvent.target.value))
  const setVideosTo = (event) => dispatch(actions.getVideosTo(event.nativeEvent.target.value))
  const setFollowingFrom = (event) => dispatch(actions.getFollowingFrom(event.nativeEvent.target.value))
  const setFollowingTo = (event) => dispatch(actions.getFollowingTo(event.nativeEvent.target.value))
  const setFollowersFrom = (event) => dispatch(actions.getFollowerFrom(event.nativeEvent.target.value))
  const setFollowersTo = (event) => dispatch(actions.getFollowerTo(event.nativeEvent.target.value))
  const setAbuseFrom = (event) => dispatch(actions.getAbuseFrom(event.nativeEvent.target.value))
  const setAbuseTo = (event) => dispatch(actions.getAbuseTo(event.nativeEvent.target.value))

  const handleSearch = () => {
    dispatch(actions.setAddMoreUsers(false))
    dispatch(actions.getUser())
  }
  const handleSubmit = (e) => {
    if (e.key === 'Enter') {
      dispatch(actions.getUser())
    }
  }

  const handleExport = () => {
    dispatch(actions.getExport())
  }

  const handleSearchFilter = () => {
    dispatch(actions.getPageNo(0))
    dispatch(actions.setAddMoreUsers(false))
    dispatch(actions.getUser())
    setModal(false)
  }

  const setDateSelector = (select: string) => {
    setSelectDatePicker(select)
  }

  const handleChangeMultipleDropdownValue = (val) => {
    setSelectedActionStatus(val.target.value)
  }

  const handleSubmitActionStatus = () => {
    dispatch(actions.updateAproveRejectForMultipleUsersStatus({ statusType: selectedActionStatus }))
    setSelectedUsers(0)
    setSelectedActionStatus('')

  }
  // =========================================useEffects============================

  useEffect(() => {
    if (err === 'MESSAGE_ADMIN_USER_ADDED') {
      dispatch(actions.getError(null))
    }
    if (err === 'MESSAGE_ADMIN_USER_MESSAGE_SENT') {
      setSendMsg(false)
      dispatch(actions.getError(null))
    }
    return () => {
      dispatch(actions.getSearch(""))
      dispatch(actions.resetUsersList())
      setSelectedProfileData({
        isVideoSelected: false,
        url: ''
      })
    }
  }, [])

  useEffect(() => {
    dispatch(actions.getUser())
    dispatch(actions.getSelectedUserOnly([]));
    dispatch(actions.getCategory());
    return () => {
      // dispatch(actions.getPageNo(0));
      dispatch(actions.updateFilters())
      dispatch(actions.setAddMoreUsers(true));
      setSelectedProfileData({
        isVideoSelected: false,
        url: ''
      })
    }
  }, [])

  useEffect(() => {

    if (selectedUserProfileVideo) {
      console.log("SelectedUserProfileVideo If", selectedUserProfileVideo);
      setSelectedProfileData({
        isVideoSelected: true,
        url: selectedUserProfileVideo
      })
    }
    return () => {
      console.log("SelectedUserProfileVideo Return", selectedUserProfileVideo);
      setSelectedProfileData({
        isVideoSelected: false,
        url: ''
      })
    }
  }, [selectedUserProfileVideo])

  useEffect(() => {
    if (!sendMsg) {
      dispatch(actions.setSendMessageData())
    }
  }, [sendMsg])

  useEffect(() => {
    const timer = setTimeout(() => dispatch(actions.changeUpdateStatusMessage("")), 3000);
    return () => clearTimeout(timer);
  }, [statusUpdateMessage.length > 0])

  // useEffect(() => {
  //   if (selectedUserProfileVideo) {
  //     setSelectedProfileData({
  //       isVideoSelected: true,
  //       url: selectedUserProfileVideo
  //     })
  //   }
  // }, [selectedUserProfileVideo])

  return (
    <div style={{ height: 'calc(100vh - 76px)', width: "100%", padding: '1rem', position: 'relative', backgroundColor: '#F9FAFB' }}>
      {statusUpdateMessage && statusUpdateMessage.length > 0 && <Alert sx={{ mb: 2 }} severity="success" >{statusUpdateMessage}</Alert>}
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
        {<> <div style={{ display: 'flex', flexDirection: 'row', flex: 2 }} >
          <div className={styles.searchDiv}>
            <img src={searchIcon} className={styles.searchIcon} alt='searchIcon' />
            <input id="inputUser" onKeyDown={handleSubmit} onKeyDownCapture={(e) => setKeyPress(e.keyCode)} onChange={onSearch} defaultValue={searchText} type='text' placeholder='Search...' className={styles.searchInput} />
          </div>
          <Button
            variant="contained"
            disableElevation
            onClick={handleSearch}
            sx={{ color: '#fff', fontSize: '1.6vh', fontWeight: '500', padding: '5px 30px', cursor: 'auto', borderRadius: "5px", marginLeft: 2 }}
          >Search</Button>
          <Button
            disableElevation
            onClick={handleExport}
            variant="contained"
            sx={{ color: '#fff', fontSize: '1.6vh', fontWeight: '500', cursor: 'auto', borderRadius: "5px", marginLeft: '2%' }}
          >Export To Excel</Button>
          <Button
            variant="outlined"
            onClick={() => setSendMsg(true)}
            sx={{
              color: '#fff', backgroundColor: '#12BFE0', '&:hover': {
                backgroundColor: "#12bfe0"
              }, fontSize: '1.6vh', padding: '6px 10px', fontWeight: '500', cursor: 'auto', borderRadius: "5px", marginLeft: 2
            }}
          >Send Message</Button>
          <Dropdown dropDownlist={dropDownList} onChange={(value) => handleChangeMultipleDropdownValue(value)}
            // isDisabled={Boolean(selectedUsers === 0)}
            selectedItem={selectedActionStatus} />
          <Button
            variant="outlined"
            onClick={() => {
              if (selectedActionStatus) handleSubmitActionStatus()
            }
            }
            disabled={Boolean(selectedUsers === 0 || !selectedActionStatus)}
            sx={{
              color: '#fff',
              backgroundColor: '#12BFE0',
              '&:hover': {
                backgroundColor: "#12bfe0"
              },
              fontSize: '1.6vh',
              padding: '5px 30px',
              fontWeight: '500',
              cursor: Boolean(selectedUsers === 0 || !selectedActionStatus) ? 'not-allowed' : 'pointer',
              borderRadius: "5px",
              marginLeft: 0
            }}
          >Change</Button>
        </div>
          <Button
            onClick={open}
            variant="contained"
            disableElevation
            sx={{ color: '#fff', fontSize: '1.6vh', fontWeight: '500', cursor: 'auto', borderRadius: "5px", marginLeft: 2 }}
          >
            <img src={filterSvg} alt='filterSvg' className={styles.filterIcon} />
            <Typography sx={{ fontSize: 12 }} >Filter</Typography></Button></>}
        <Modal
          open={modal}
          onClose={closeModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            "& .MuiModal-root": {
              display: 'none',
              overflow: 'scroll'
            }
          }}
        >
          <Box sx={{
            position: 'absolute',
            top: 80,
            right: 0,
            bottom: 0,
            maxWidth: 400,
            bgcolor: 'background.paper',
            p: 2,
            boxShadow: 7,
            overflow: 'scroll'
          }}>
            <div className={styles.modalDiv} >
              <Typography id="modal-modal-title" className={styles.filterText} variant="h6" component="h2">
                Filter
              </Typography>
              <img src={closeSvg} alt='closeSvg' onClick={closeModal} className={styles.closeSvg} />
            </div>
            <div className={styles.datePickerDiv} >
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {<Button className={styles.FilterDatePicker} onClick={() => setDateSelector('Birth date from')}>{filterData.birthFrom ? new Date(filterData.birthFrom).toISOString().split('T')[0] : 'Birth date from'}<img src={calendarIcon} alt='calendarIcon' className={styles.filterCalIcon} /></Button>}
                {<Button className={styles.FilterDatePicker} onClick={() => setDateSelector('Birth date to')}>{filterData.birthTo ? new Date(filterData.birthTo).toISOString().split('T')[0] : 'Birth date to'}<img src={calendarIcon} alt='calendarIcon' className={styles.filterCalIcon} /></Button>}
                {<Button className={styles.FilterDatePicker} onClick={() => setDateSelector('Enrollment date from')}>{filterData.enrollFrom ? new Date(filterData.enrollFrom).toISOString().split('T')[0] : 'Enrollment date from'}<img style={{ marginRight: '17px' }} src={calendarIcon} alt='calendarIcon' className={styles.filterCalIcon} /></Button>}
                {<Button className={styles.FilterDatePicker} onClick={() => setDateSelector('Enrollment date to')}>{filterData.enrollTo ? new Date(filterData.enrollTo).toISOString().split('T')[0] : 'Enrollment date to'}<img style={{ marginRight: '25px' }} src={calendarIcon} alt='calendarIcon' className={styles.filterCalIcon} /></Button>}
                {<Button className={styles.FilterDatePicker} onClick={() => setDateSelector('last login from')}>{filterData.lastLoginFrom ? new Date(filterData.lastLoginFrom).toISOString().split('T')[0] : 'last login from'}<img src={calendarIcon} alt='calendarIcon' className={styles.filterCalIcon} /></Button>}
                {<Button className={styles.FilterDatePicker} onClick={() => setDateSelector('last login to')}>{filterData.lastLoginTo ? new Date(filterData.lastLoginTo).toISOString().split('T')[0] : 'last login to'}<img src={calendarIcon} alt='calendarIcon' className={styles.filterCalIcon} /></Button>}
              </div>
              {selectDatePicker !== '' && selectDatePicker === 'Birth date from' && <CustomeDatePicker name={'Birth date from'} setDate={setBdFrom} date={birthFrom} changeDate={setDateSelector} />}
              {selectDatePicker !== '' && selectDatePicker === 'Birth date to' && <CustomeDatePicker name={'Birth date to'} setDate={setBdTo} date={birthTo} changeDate={setDateSelector} />}
              {selectDatePicker !== '' && selectDatePicker === 'Enrollment date from' && <CustomeDatePicker name={'Enrollment date from'} setDate={setEnrollFrom} date={enrollFrom} changeDate={setDateSelector} />}
              {selectDatePicker !== '' && selectDatePicker === 'Enrollment date to' && <CustomeDatePicker name={'Enrollment date to'} setDate={setEnrollTo} date={enrollTo} changeDate={setDateSelector} />}
              {selectDatePicker !== '' && selectDatePicker === 'last login from' && <CustomeDatePicker name={'last login from'} setDate={setLastLoginFrom} date={lastLoginFrom} changeDate={setDateSelector} />}
              {selectDatePicker !== '' && selectDatePicker === 'last login to' && <CustomeDatePicker name={'last login to'} setDate={setLastLoginTo} date={lastLoginTo} changeDate={setDateSelector} />}
              <TextField size='small' onChange={setLoginsFrom} value={loginsFrom} type='number' sx={{ width: '10rem' }} label={'logins from'} />
              <TextField size='small' onChange={setLoginsTo} value={loginsTo} type='number' sx={{ width: '10rem' }} label={'logins to'} />
              <TextField size='small' onChange={setMsgFrom} value={messagesFrom} type='number' sx={{ width: '10rem' }} label={'Messages from'} />
              <TextField size='small' onChange={setMsgTo} value={messagesTo} type='number' sx={{ width: '10rem' }} label={'Messages to'} />
              <TextField size='small' onChange={setMatchesFrom} value={matchesFrom} type='number' sx={{ width: '10rem' }} label={'Matches from'} />
              <TextField size='small' onChange={setMatchesTo} value={matchesTo} type='number' sx={{ width: '10rem' }} label={'Matches to'} />
              <TextField size='small' onChange={setBlockedFrom} value={blockedFrom} type='number' sx={{ width: '10rem' }} label={'Blocked from'} />
              <TextField size='small' onChange={setBlockedTo} value={blockedTo} type='number' sx={{ width: '10rem' }} label={'Blocked to'} />
              <TextField size='small' onChange={setVideosFrom} value={videosFrom} type='number' sx={{ width: '10rem' }} label={'Videos from'} />
              <TextField size='small' onChange={setVideosTo} value={videosTo} type='number' sx={{ width: '10rem' }} label={'Videos to'} />
              <TextField size='small' onChange={setFollowingFrom} value={followingFrom} type='number' sx={{ width: '10rem' }} label={'Following from'} />
              <TextField size='small' onChange={setFollowingTo} value={followingTo} type='number' sx={{ width: '10rem' }} label={'Following to'} />
              <TextField size='small' onChange={setFollowersFrom} value={followerFrom} type='number' sx={{ width: '10rem' }} label={'Followers from'} />
              <TextField size='small' onChange={setFollowersTo} value={followerTo} type='number' sx={{ width: '10rem' }} label={'Followers to'} />
              <TextField size='small' onChange={setAbuseFrom} value={abuseFrom} type='number' sx={{ width: '10rem' }} label={'Abuse from'} />
              <TextField size='small' onChange={setAbuseTo} value={abuseTo} type='number' sx={{ width: '10rem' }} label={'Abuse to'} />
            </div>
            <Button
              variant="contained"
              disableElevation
              onClick={handleSearchFilter}
              sx={{ color: '#fff', fontSize: '1.6vh', fontWeight: '500', padding: '5px 30px', cursor: 'auto', borderRadius: "5px" }}
            >Search</Button>
          </Box>
        </Modal>
        {
          sendMsg &&
          <SendMsgModal sendMsg={sendMsg} setSendMsg={setSendMsg} isSelectAll={true} />
        }
        <AddModal openModal={addUserModalOpen} setOpenModal={(value: boolean) => dispatch(actions.openCloseAddUserModal(value))} />
      </div>
      <div>

        {/* <ShowUserProfile
          isVideo={selectedProfileData?.isVideoSelected}
          openProfileModal={openProfileModal}
          setOpenProfileModal={(val) => {
            setOpenProfileModal(val);
            setSelectedProfileData({
              isVideoSelected: false,
              url: ''
            })
          }}
          url={selectedProfileData?.url}
        /> */}
        <ShowUserProfileNew
          isVideo={selectedProfileData?.isVideoSelected}
          openProfileModal={openProfileModal}
          setOpenProfileModal={setOpenProfileModal}
          setSelectedProfileData={setSelectedProfileData}
          url={selectedProfileData?.url}
          showVideoLoader={showVideoLoader}
        />
      </div>

      <div className={styles.tableData}>{/*  Filter  */}
        <div style={{ height: '100%', width: '100%' }}>
          {totalUsers > 0 && <DataTableComponent
            rows={header ? rows2 : rows}
            columns={header ? columns2 : columns}
            header={header}
            loading={loading}
            mainType={'USERS'}
            pageSize={pageSize}
            rowCount={totalUsers}
            pageCount={rows.length}
            selectedActionStatus={selectedActionStatus}
            // page={pageNo}
            onPageChange={
              (pageNo) => {
                dispatch(actions.setAddMoreUsers(true))
                dispatch(actions.getUser())
              }
            }
            onPageSizeChange={() => {
              dispatch(actions.getPageSize(pageSize))
              dispatch(actions.getUser())
            }}
            className={styles.userDataTable}
            setHead={setHead}
            setHeader={setHeader}
            userListTable={true}
            setSelectedUsers={setSelectedUsers}
          />}
          {totalUsers == 0 && (
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center',
                height: '80%'
              }}
            >No Result found</div>)}
          <Fab
            className={styles.fabBtn}
            onClick={openAdd}
            aria-label="like"
            sx={{ marginTop: 2 }}
          >
            <Typography sx={{ color: '#fff', fontSize: 32, fontWeight: '500' }}>
              +
            </Typography>
          </Fab>

        </div >
      </div>
    </div>
  )
};

export default memo(UserManagement);
