
import closeSvg from 'assets/close.svg';
import moment from 'moment';
import React from 'react';

import { Modal, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { useStyles } from '../styles';

const ShowAllModalMedia = ({ visible, closeMsg, showObj }) => {
    const styles = useStyles()
    return (
        <Modal
            open={visible}
            onClose={closeMsg}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={
                {
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '35rem',
                    bgcolor: 'background.paper',
                    boxShadow: 10,
                    p: 3,
                    borderRadius: 2
                }
            }>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }} >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flex: 1,
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography sx={{ fontSize: 16, fontWeight: '900' }}>
                        Media
                    </Typography>
                    <img
                        src={closeSvg}
                        alt="closeSvg"
                        onClick={closeMsg}
                        className={styles.closeSvg}
                    />
</div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: "flex-start", justifyContent: 'space-between', flex: 1 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'space-between' }}>
                        <img src={showObj?.url} alt="media" style={{ width: '100%'}} />
                        </div>
                    </div>
                </div>
            </Box >
        </Modal >
    )
}

export default ShowAllModalMedia;