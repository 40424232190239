import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: 'flex',
    // backgroundColor: '#abb'
  },
  contentDiv: {
    backgroundColor: '#F9FAFB',
    padding: '1rem'
  },
  allDates: {
    border: '1px solid lightgray',
    height: '2.5rem',
    fontSize: 11,
  },
  fabBtn: {
    background: 'linear-gradient(90deg, #0EC8E1 0.06%, #0866D5 99.99%)',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  dateInput: {
    paddingTop: 20,
    borderRadius: 10,
    gap: 10,
    display: 'flex'
  },
  noOfUser: {
    width: '100%',
    height: '4.7%',
    // margin: theme.spacing(3, 0, 2),
    // padding: theme.spacing(1, 4),
    // [theme.breakpoints.down('xs')]: {
    //   // margin: theme.spacing(1, 0, 1),
    // },
    borderRadius: "10px",
  },
  content: {
    padding: '1%',
    backgroundColor: '#F9FAFB'
  },
  checked: {
    width: 13,
    height: 13,
    objectFit: "contain",
  },
  closeSvg: {
    cursor: 'pointer',
    height: 24,
    width: 24,
    padding: 5
  },

}));
