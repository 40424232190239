import queryString from 'query-string';
import { toast } from 'react-toastify';
import { select } from 'redux-saga/effects';
import { APP_API_URL } from 'utils/constants';
import { request } from 'utils/request';

import { call, delay, put, takeLatest } from '@redux-saga/core/effects';

import { selectAddMoreQuestion, selectQuestionState } from './selectors';
import { actions } from './slice';
import { InitialQuestionState } from './types';

export function* getQuestionRequest() {
  yield delay(500)
  const data: InitialQuestionState = yield select(
    selectQuestionState
  )
  if (data.stopFetch) {
    return
  }
  const getIsQuestionAdd = yield select(selectAddMoreQuestion);
  const options = {
    method: 'POST',
    body: JSON.stringify(
      {
        "category_id": data.category,
        "page_no": data.addMoreQuestions ? data.page_no + 1 : data.page_no,
        "page_size": data.page_size
      }
    ),
  };
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/question-answer/questions`,
      options
    )
    if (getIsQuestionAdd) {
      yield put(actions.setPageNoIncrement())
    }
    !getIsQuestionAdd ? yield put(actions.getQuestionSuccess(response))
      : yield put(actions.getQuestionSuccess({ ...response, getIsQuestionAdd: getIsQuestionAdd }))
  }
  catch (err: any) {
    yield put(actions.getQuestionFailure(err))
  }
}
export function* getQuestionFullRequest() {
  yield delay(500)
  const data: InitialQuestionState = yield select(
    selectQuestionState
  )
  const options = {
    method: 'POST',
    body: JSON.stringify(
      {
        "page_no": 0,
        "page_size": 500
      }
    ),
  };
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/question-answer/questions`,
      options
    )
    console.log(response, '<=response123');
    yield put(actions.getQuestionFullSuccess(response))

  }
  catch (err: any) {
    yield put(actions.getQuestionFailure(err))
  }
}
export function* getQuestionCategoryRequest() {
  yield delay(500);
  const options = {
    method: "GET",
  }
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/question-answer/categories`,
      options
    )
    // console.log(response,'<=response')
    yield put(actions.getCategorySuccess(response))
  } catch (error) {
    yield put(actions.getCategoryFailure(error))
  }
}
export function* getAnswerCategoryRequest() {
  yield delay(500);
  const options = {
    method: "GET",
  }
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/question-answer/categories/answer`,
      options
    )
    // console.log(response,'<=response')
    yield put(actions.getCategorySuccess(response))
  } catch (error) {
    yield put(actions.getCategoryFailure(error))
  }
}
export function* getQuestionChangeStatus() {
  yield delay(500);
  const data: InitialQuestionState = yield select(
    selectQuestionState
  )
  const options = {
    method: 'GET',
  };
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/question-answer/questions/change-status/${data.questionId}`,
      options
    )
    yield put(actions.getQuestionIdSuccess(response.message))
  } catch (err: any) {
    yield put(actions.getQuestionIdFailure(err.message))
  }
}
export function* getDeleteQuestion() {
  yield delay(500);
  const data: InitialQuestionState = yield select(
    selectQuestionState
  )
  const options = {
    method: 'GET',
  };
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/question-answer/questions/delete/${data.questionId}`,
      options
    )
    if (response.message === 'MESSAGE_QUESTION_DELETED') {
      yield put(actions.getDeleteQuestionSuccess({ message: response.message, questionId: data.questionId }))
      toast.success('Deleted successfully')
    } else {
      yield put(actions.getDeleteQuestionFailure(response.message))
      toast.error('Deletion failed!')
    }
  } catch (err: any) {
    yield put(actions.getDeleteQuestionFailure(err.message))
  }
}
export function* getAddQuestion(action) {
  yield delay(500)
  const data: InitialQuestionState = yield select(
    selectQuestionState
  )
  const options = {
    method: "POST",
    body: JSON.stringify(
      {
        "question": data.addQuestion.question,
        "body": data.addQuestion.body,
        "category_id": data.addQuestion.category_id,
        "videoLength": data.addQuestion.videoLength,
        "video": action.payload
      }
    ),
  }
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/question-answer/questions/add`,
      options
    )
    // console.log(response,'<=response Add')
    if (response.message === 'MESSAGE_QUESTION_EXIST') {
      yield put(actions.getAddQuestionFailure(response.message))
      toast.warning('Question already exists')
    } else {
      yield put(actions.setAddMoreQuestions(false))
      yield put(actions.getQuestion())
      yield put(actions.getAddQuestionSuccess(response))
      toast.success('Question added successfully')
    }
  } catch (err: any) {
    toast.error('Question added failed')
    yield put(actions.getAddQuestionFailure(err.message))
  }
}

export function* checkQuestion(action: any) {
  console.log(action)
  const options = {
    method: 'GET'
  };
  const queries = queryString.stringify({
    question: action.payload.question,
  });
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/question-answer/question_check?${queries}`,
      options
    )
    if (response.message == "Success") {
      yield put(actions.checkQuestionSuccess(response));
    } else {
      yield put(actions.checkQuestionError(response));
    }
  } catch (err: any) {
  }
}

export function* uploadAnswerRequest(action: any) {
  const queries = queryString.stringify({ fileName: action.payload.fileName, contentType: action.payload.contentType })
  const options = {
    method: 'GET'
  };
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/categories/upload/url/fetch?${queries}`,
      options
    )
    yield put(actions.uploadAnswerSuccess(response));

  } catch (err: any) {
    yield put(actions.uploadAnswerVideoError(err));
  }
}

export function* uploadAnswerVideoRequest(action: any) {
  const options = {
    method: "POST",
    body: JSON.stringify(
      {
        "questionId": action.payload.questionId,
        "videoUrl": action.payload.videoUrl,
        "customUserId": action.payload.customUserId,
        "videoLength": action.payload.videoLength
      }
    )
  };
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/categories/question/reply`,
      options
    )
    yield put(actions.uploadAnswerVideoSuccess(response));
  } catch (err: any) {
    yield put(actions.uploadAnswerVideoError(err));
  }
}

export function* getUserRequest() {
  yield delay(500)
  const options = {
    method: 'POST',
    body: JSON.stringify(
      {
        "page_no": 0,
        "page_size": 500
      }
    ),
  };
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/users/admin`,
      options
    )
    yield put(actions.getUserSuccess(response))
  }
  catch (err: any) {
    yield put(actions.getUserError(err.message))
  }
}

export function* userQuestion() {
  yield takeLatest(actions.getQuestion.type, getQuestionRequest)
  yield takeLatest(actions.getQuestionFull.type, getQuestionFullRequest)
  // yield takeLatest(actions.getDeleteQuestionSuccess.type,getQuestionRequest)
  // yield takeLatest(actions.getAddQuestionSuccess.type,getQuestionRequest)
  yield takeLatest(actions.checkQuestions.type, checkQuestion)
  yield takeLatest(actions.getCategory.type, getQuestionCategoryRequest)
  yield takeLatest(actions.getAnswerCategory.type, getAnswerCategoryRequest)
  yield takeLatest(actions.getQuestionId.type, getQuestionChangeStatus)
  yield takeLatest(actions.getDeleteQuestion.type, getDeleteQuestion)
  yield takeLatest(actions.getAddQuestion.type, getAddQuestion)
  yield takeLatest(actions.uploadAnswer.type, uploadAnswerRequest)
  yield takeLatest(actions.uploadAnswerVideo.type, uploadAnswerVideoRequest)
  yield takeLatest(actions.getUser.type, getUserRequest)
}