import React, { useState } from 'react';

import { DatePickerView } from '@mui/lab/DatePicker/shared';
import { Button, Popover, TextField } from '@mui/material';
import { LocalizationProvider, StaticDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

type UserProps = {
    name: string,
    setDate?: any,
    date?: string | any,
    readOnly?: boolean,
    wd?: string,
    landingPage?: boolean,
    maxDate?: object | any,
    minDate?: object | any,
    views?: readonly DatePickerView[],
    changeDate?: any
};

const readOnlyOnFocus = (e) => {
    e.preventDefault();
    e.stopPropogation();
}

const CustomeDatePicker = ({ name, setDate, changeDate, date, readOnly, wd, landingPage, maxDate, minDate, views }: UserProps) => {
    const [anchor, setAnchor] = useState<any>(true);
    const handleCloseEl = () => {
        setAnchor(false);
    };
    const closeWithOk = () => {
        changeDate('')
        handleCloseEl()
    }
    const closeWithCancel = () => {
        setDate(undefined)
        changeDate('')
        handleCloseEl()
    }
    return (
        <Popover
            open={anchor}
            anchorEl={anchor}
            onClose={handleCloseEl}
            sx={{
                display: 'flex',
                flex: 1,
                padding: '2rem',
                position: 'absolute',
                left: '-390px'
            }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    openTo="day"
                    value={date ?? null}
                    maxDate={maxDate ?? new Date()}
                    onChange={(newValue) => {
                        setDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} onKeyPress={readOnlyOnFocus} defaultValue="" />}
                />
            </LocalizationProvider>

            <div className='customDateBtn'>
                <Button className="customDateOption" onClick={() => closeWithCancel()}>Cancel</Button>
                <Button className="customDateOption" onClick={() => closeWithOk()}>Done</Button>
            </div>

        </Popover>
    )
}

export default CustomeDatePicker