
import moment from 'moment';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { PayloadAction } from '@reduxjs/toolkit';

import { UserState } from './types';

export const initialState: UserState = {
  rows: [],
  userRow: [],
  selectedUser: [],
  selectedUserOnly: [],
  sentMsgData: {
    users: [],
    title: '',
    message: '',
  },
  addUser: {
    fullName: "",
    gender: "",
    mobile_no: "",
    email: "",
    dob: "" || undefined,
  },
  search: '' || undefined,
  loading: false,
  isCellClick: false,
  error: null,
  page_no: 0,
  userPage_no: 0,
  page_size: 10,
  totalUsers: 0,
  sendUserCompleted: false,
  filters: {
    birthFrom: '' || undefined,
    birthTo: '' || undefined,
    enrollFrom: '' || undefined,
    enrollTo: '' || undefined,
    lastLoginFrom: '' || undefined,
    lastLoginTo: '' || undefined,
    loginsFrom: 0 || undefined,
    loginsTo: 0 || undefined,
    messagesFrom: 0 || undefined,
    messagesTo: 0 || undefined,
    matchesFrom: 0 || undefined,
    matchesTo: 0 || undefined,
    blockedFrom: 0 || undefined,
    blockedTo: 0 || undefined,
    videosFrom: 0 || undefined,
    videosTo: 0 || undefined,
    followingFrom: 0 || undefined,
    followingTo: 0 || undefined,
    followerFrom: 0 || undefined,
    followerTo: 0 || undefined,
    abuseFrom: 0 || undefined,
    abuseTo: 0 || undefined,
    orderBy: -1 || undefined,
    orderByField: "createdAt",
  },
  userObj: {
    id: '',
    phone: '',
    email: '',
    looking_for: '',
    is_active: true,
    enroll_date: '' || undefined,
    dob: '' || undefined,
    last_login_date: '' || undefined,
    logins: 0,
    followers: 0,
    following: 0,
    gender: '',
    fullName: '',
    age: 0,
    answeredQuestions: [] || undefined,
    mediaList: [],
    blockedUsersList: [] || undefined,
    selectedMediaId: '',
    postCount: 0,
    rangeInMeters: 0,
    ageFrom: 0,
    ageTo: 0,
  },
  addMoreUsers: false,
  addUserModalOpen: false,
  selectedUserId: '',
  videoFile: undefined,
  imageFile: undefined,
  defaultQuestionId: '62d53ac417dab9cc0848887b',
  userProfileImage: '',
  userProfileVideo: '',
  disableMessage: '',
  userThumbnailProfileVideo: '',
  userProfileVideoSize: 0,
  categories: [],
  introductionVideoQuestionId: '',
  statusUpdateMessage: '',
  selectedQuestionedAnswerId: ''
};

const getUserManagementOnSlice = createSlice({
  name: 'userManagement',
  initialState,
  reducers: {
    getUser(state) {
      state.loading = true;
      state.error = null
    },
    getUserEdit(state) {
      state.loading = true;
      state.error = null
    },
    getUserEditSuccess(state, action) {
      state.loading = false;
      state.userRow = action.payload;
      state.error = action.payload.message
    },
    getEdit(state) {
      state.loading = true;
      state.error = null
    },
    openCloseAddUserModal(state, action) {
      state.addUserModalOpen = action.payload;
    },
    getEditSuccess(state, action) {
      state.loading = false;
      // state.userObj.id = action.payload.user.id;
      // state.rows[state.rows.findIndex(x => x.id === action.payload.user.id)].phone = action.payload.user.phone;
      // state.rows[state.rows.findIndex(x => x.id === action.payload.user.id)].email = action.payload.user.email;
      // state.userObj.is_active = action.payload.user.is_active;
      // state.userObj.looking_for = action.payload.user.looking_for;
      // state.rows[state.rows.findIndex(x => x.id === action.payload.user.id)].looking_for = action.payload.user.looking_for;
    },
    // =====status update message====
    updateStatusMessage(state, action) {
      state.statusUpdateMessage = action.payload
    },
    getEditFailure(state, action) {
      state.loading = false;
      state.error = action.payload
    },
    getUserEditFailure(state, action) {
      state.loading = false;
      state.error = action.payload
    },
    getSearch(state, action) {
      state.search = action.payload.trim()
    },
    updateFilters(state) {
      state.filters = {
        birthFrom: '',
        birthTo: '',
        enrollFrom: '',
        enrollTo: '',
        lastLoginFrom: '',
        lastLoginTo: '',
        loginsFrom: 0,
        loginsTo: 0,
        messagesFrom: 0,
        messagesTo: 0,
        matchesFrom: 0,
        matchesTo: 0,
        blockedFrom: 0,
        blockedTo: 0,
        videosFrom: 0,
        videosTo: 0,
        followingFrom: 0,
        followingTo: 0,
        followerFrom: 0,
        followerTo: 0,
        abuseFrom: 0,
        abuseTo: 0,
        orderBy: -1,
        orderByField: "createdAt",

      }
    },
    setCellClick(state, action) {
      state.isCellClick = action.payload
    },
    getSelectedUser(state, action) {
      state.selectedUser = action.payload
      if (!state.isCellClick) {
        return
      }

      state.isCellClick = false
    },
    removeSelectedUser(state, action) {
      let index = state.selectedUser.findIndex(action.payload)
      if (index != -1) state.selectedUser.splice(index, 1)

    },
    getSelectedUserOnly(state, action) {
      state.selectedUserOnly = action.payload
    },
    getBirthFrom(state, action) {
      state.filters.birthFrom = action.payload
    },
    getBirthTo(state, action) {
      state.filters.birthTo = action.payload;
    },
    getSelectedUserObj(state, action) {
      state.userObj = action.payload
    },
    getUserEditPhone(state, action) {
      state.userObj.phone = action.payload
    },
    getUserId(state, action) {
      state.userObj.id = action.payload
    },
    getUserEditEmail(state, action) {
      state.userObj.email = action.payload
    },
    getUserEditActive(state, action) {
      state.userObj.is_active = action.payload
    },
    getUserEditLookingFor(state, action) {
      state.userObj.looking_for = action.payload
    },
    getEnrollmentFrom(state, action) {
      state.filters.enrollFrom = action.payload
    },
    getEnrollmentTo(state, action) {
      state.filters.enrollTo = action.payload;
    },
    getLastLoginFrom(state, action) {
      state.filters.lastLoginFrom = action.payload
    },
    getLastLoginTo(state, action) {
      state.filters.lastLoginTo = action.payload;
    },
    getLoginsFrom(state, action) {
      state.filters.loginsFrom = parseInt(action.payload);
    },
    getLoginsTo(state, action) {
      state.filters.loginsTo = parseInt(action.payload);
    },
    getMessagesFrom(state, action) {
      state.filters.messagesFrom = parseInt(action.payload);
    },
    getMessagesTo(state, action) {
      state.filters.messagesTo = parseInt(action.payload);
    },
    getMatchesFrom(state, action) {
      state.filters.matchesFrom = parseInt(action.payload);
    },
    getMatchesTo(state, action) {
      state.filters.matchesTo = parseInt(action.payload);
    },
    getBlockedFrom(state, action) {
      state.filters.blockedFrom = parseInt(action.payload);
    },
    getBlockedTo(state, action) {
      state.filters.blockedTo = parseInt(action.payload);
    },
    getVideosFrom(state, action) {
      state.filters.videosFrom = parseInt(action.payload);
    },
    getVideosTo(state, action) {
      state.filters.videosTo = parseInt(action.payload);
    },
    getFollowingFrom(state, action) {
      state.filters.followingFrom = parseInt(action.payload);
    },
    getFollowingTo(state, action) {
      state.filters.followingTo = parseInt(action.payload);
    },
    getFollowerFrom(state, action) {
      state.filters.followerFrom = parseInt(action.payload);
    },
    getFollowerTo(state, action) {
      state.filters.followerTo = parseInt(action.payload);
    },
    getAbuseFrom(state, action) {
      state.filters.abuseFrom = parseInt(action.payload);
    },
    getAbuseTo(state, action) {
      state.filters.abuseTo = parseInt(action.payload);
    },
    getUserSuccess(state, action) {
      state.loading = false;
      state.error = null;

      state.totalUsers = action.payload.meta[0].total
      if (action.payload?.getIsUserAdd) {
        // action.payload.users = state.rows.concat(action.payload.users)
        let addRows = action.payload.users.map((value) => {
          value.dob = value.dob ? moment(value.dob).format("DD/MM/YYYY") : '';
          value.enroll_date = value.enroll_date ? moment(value.enroll_date).format("DD/MM/YYYY") : '';
          value.last_login_date = value.last_login_date ? moment(value.last_login_date).format("DD/MM/YYYY") : '';
          return value;
        });
        state.rows = [...state.rows, ...addRows]
      } else {
        state.rows = action.payload.users.map((value) => {
          value.dob = value.dob ? moment(value.dob).format("DD/MM/YYYY") : '';
          value.enroll_date = value.enroll_date ? moment(value.enroll_date).format("DD/MM/YYYY") : '';
          value.last_login_date = value.last_login_date ? moment(value.last_login_date).format("DD/MM/YYYY") : '';
          return value;
        });
      }
    },
    getSendToSelectedUser(state) {
      state.loading = true;
      state.error = null;
    },
    getSendToAllUser(state) {
      state.loading = true;
      state.error = null;
    },
    getSendMessages(state, action) {
      state.loading = true;
      state.error = null;
    },
    getSendMessagesSuccess(state, action) {
      state.loading = false;
      state.error = null;
    },
    getSendMessagesFailure(state, action) {
      state.loading = false;
    },
    getSendTitle(state, action) {
      state.sentMsgData.title = action.payload
    },
    getSendMessage(state, action) {
      state.sentMsgData.message = action.payload
    },
    getSendUserSuccess(state, action) {
      state.loading = false;
      state.error = action.payload.message;
      state.selectedUser = [];
      state.sendUserCompleted = true
    },
    setSendMessageData(state) {
      state.sendUserCompleted = false
    },
    getSendUserFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getUserFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getExport(state) {
      state.loading = true;
      state.error = null;
    },
    getExportSuccess(state, action) {
      state.loading = false;
    },
    getExportFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getAddMobile(state, action) {
      state.addUser.mobile_no = action.payload;
    },
    getAddUserName(state, action) {
      state.addUser.fullName = action.payload;
    },
    getAddEmail(state, action) {
      state.addUser.email = action.payload;
    },
    getAddBirthDate(state, action) {
      state.addUser.dob = action.payload
    },
    getAddGender(state, action) {
      state.addUser.gender = action.payload;
    },
    getAddUser(state) {
      state.loading = true;
      state.error = null;
    },
    getAddSuccess(state, action) {
      state.loading = false;
      state.error = action.payload.message;
      state.rows = [
        {
          ...action.payload.user, dob: action.payload.user.dob ?
            moment(action.payload.user.dob).format("DD/MM/YYYY") :
            ''
        },
        ...state.rows
      ];
      state.addUser.fullName = '';
      state.addUser.mobile_no = ''
      state.addUser.email = '';
      state.addUser.dob = undefined;
      state.addUserModalOpen = false;
    },
    getAddClose(state) {
      state.loading = false;
      state.addUser.fullName = '';
      state.addUser.mobile_no = ''
      state.addUser.email = '';
      state.addUser.dob = undefined;
      state.videoFile = undefined;
      state.imageFile = undefined;
    },
    getAddfailure(state, action) {
      state.loading = false;
      state.error = action.payload
    },
    getError(state, action) {
      state.error = action.payload
    },
    getPageSize(state, action) {
      state.page_size = action.payload
    },
    getPageNo(state, action) {
      state.page_no = action.payload
    },
    setSortValues(state, action) {
      if (action.payload && action.payload.sort) {
        state.filters = {
          ...state.filters,
          orderBy: action.payload.sort == 'asc' ? -1 : 1,
          orderByField: action.payload.field
        }
      } else {
        state.filters = {
          ...state.filters,
          orderBy: null,
          orderByField: null
        }
      }
    },
    setPageNoIncrement(state) {
      state.page_no += 1
    },

    setAddMoreUsers(state, action: PayloadAction<boolean>) {
      state.addMoreUsers = action.payload
      // if (!action.payload) {
      //   state.page_no = 0
      // }
    },

    setUserPageNo(state, action) {
      state.userPage_no = action.payload
    },
    updateUser(state, action) {
      state.loading = true;
    },
    updateUserSuccess(state, action) {
      state.loading = false;
      if (action.payload.id) {
        state.rows.map((item) => {
          if (item?.id == action.payload?.id) {
            if (item?.is_active) {
              state.rows[state.rows.findIndex(x => x.id === action.payload?.id)].is_active = false
            } else {
              state.rows[state.rows.findIndex(x => x.id === action.payload?.id)].is_active = true
            }
          }
        })
      }
    },
    updateUserFailure(state, action) {
      state.loading = false;
    },
    updateAnswerFailure(state, action) {
      state.loading = false;
      state.error = action.payload
    },
    resetUsersList(state) {
      state.rows = []
    },
    setSelectUserId(state, action) {
      state.selectedUserId = action.payload
    },
    setVideoFile(state, action) {
      state.videoFile = action.payload.file
      state.userThumbnailProfileVideo = action.payload.file.url
      state.userProfileVideo = action.payload.file.url
    },
    updateUserThumbnailProfileVideo(state, action) {
      state.userThumbnailProfileVideo = action.payload
    },
    setImageFile(state, action) {
      state.imageFile = action.payload.file
      state.userProfileImage = action.payload.file.url
    },
    getUserProfileMedia(state) {

    },
    getUserProfileMediaSuccess(state, action) {
      state.userProfileImage = action.payload.image
      state.userProfileVideo = action.payload.video
      state.userThumbnailProfileVideo = action.payload.videoThumbnailUrl
      state.userProfileVideoSize = action.payload.size
    },
    getUserProfileMediaFailure(state, action) {
      console.log("err")
    },
    getUserCounts(state) {

    },
    getUserCountsSuccess(state, action) {
      state.totalUsers = action.payload.user
    },
    getUserCountsFailure(state, action) {
      console.log("err")
    },
    getAdminDisabledMessage(state) {

    },
    getAdminDisabledMessageSuccess(state, action) {
      state.disableMessage = action.payload.message
    },
    getAdminDisabledMessageFailure(state, action) {
      console.log("err")
    },
    setDisableMessageViaAdmin(state, action) {

    },
    setDisableMessageViaAdminSuccess(state, action) {
      state.disableMessage = action.payload.message
    },
    setDisableMessageViaAdminFailure(state, action) {
    },
    getDefaultQuestionId(state) {

    },
    getDefaultQuestionIdSuccess(state, action) {
      state.defaultQuestionId = action.payload;
    },
    getDefaultQuestionIdFailure(state, action) {
      console.log("err")
    },
    // ================categorylist=======================
    getCategory(state) {
      state.loading = true;
    },
    getCategorySuccess(state, action) {
      state.categories = action.payload;
      state.loading = false;
    },
    getCategoryFailure(state, action) {
      state.loading = false;
      state.error = action.payload
    },
    updateIntroductionVideoId(state, action) {
      state.introductionVideoQuestionId = action.payload
    },
    getSelectUserQuestionAnswers(state) {
      // state.loading = true;
      // state.error = undefined
    },
    getSelectUserQuestionAnswersSuccess(state, action) {
      state.loading = false;
      state.userObj.answeredQuestions = action.payload
    },
    getSelectUserQuestionAnswersFailure(state, action) {
      state.loading = false;
      state.error = action.payload
    },
    // =========================delete selected message=============
    deleteAnswer(state, action) {
      state.loading = true;
      state.error = null
    },
    deleteAnswerSuccess(state, action) {
      state.loading = false;
      state.error = null;
      let index = state.userObj.answeredQuestions.findIndex(function (o) {
        return o._id === action.payload.deletedAnswerId
      })
      if (index !== -1) {
        state.userObj.answeredQuestions.splice(index, 1)
      }
    },
    deleteAnswerFailure(state, action) {
      state.loading = false;
      state.error = action.payload
    },
    //================================blocked User List ==============
    getBlockedUser(state) {
      state.loading = true;
      state.error = null
    },
    getBlockedUserSuccess(state, action) {
      console.log("SLICE1", action.payload);
      state.loading = false;
      state.error = null;
      console.log("SLICE2", action.payload);
      state.userObj.blockedUsersList = action.payload;
    },
    getBlockedUserFailure(state, action) {
      state.loading = false;
      state.error = action.payload
    },
    // =============================postLists=======================
    getMedia(state) {
      state.loading = true;
      state.error = null
    },
    getMediaSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.userObj.mediaList = action.payload;
    },
    getMediaFailure(state, action) {
      state.loading = false;
      state.error = action.payload
    },
    updateSelectedMediaId(state, action) {
      state.userObj.selectedMediaId = action.payload;
    },
    deleteSelectedMedia(state, action: PayloadAction<{ callback: any }>) {
      state.loading = true
      state.error = ''
    },
    onDeleteSelectedMediaSuccess(state, action: PayloadAction<any>) {
      state.loading = false
      state.error = ''
    },
    onDeleteSelectedMediaFailure(state, action: PayloadAction<any>) {
      state.loading = false
      state.error = action.payload
    },
    // ===========================update media status=============
    updateStatus(state, action: PayloadAction<{ callback: () => void }>) {
      state.loading = true;
      state.error = ''
    },
    updateStatusSuccess(state) {
      state.loading = false;
      state.error = ''
    },
    updateStatusFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    // ===============update-reject user===============
    updateAproveRejectForSingleUsersStatus(state, action: PayloadAction<{ id: string, statusType: string }>) {
      state.loading = true;
      state.error = ''
    },
    updateAproveRejectForMultipleUsersStatus(state, action: PayloadAction<{ statusType: string }>) {
      state.loading = true;
      state.error = ''
    },
    updateAproveRejectStatusSuccess(state, action: PayloadAction<{
      message: string,
      statusType: string,
      users: string[]
    }>) {
      const users = action.payload.users
      const usersList = JSON.parse(JSON.stringify(state.rows))
      for (const user of users) {
        const idToBeUpdated = usersList.findIndex(tempUser => tempUser.id === user)
        usersList[idToBeUpdated].actionStep = action.payload.statusType
        console.log({
          user,
          idToBeUpdated,
          usersList,
          status: action.payload.statusType,
          message: 'USER TO BE UPDATED'
        })
      }
      state.rows = usersList

      state.loading = false;
      state.error = ''
      state.statusUpdateMessage = action.payload.message
    },
    updateAproveRejectStatusFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    changeUpdateStatusMessage(state, action: PayloadAction<string>) {
      state.statusUpdateMessage = action.payload
    },

    // ==================update AnsweredQuestion Status========================
    updateSelectedAnswerdQuestionId(state, action: PayloadAction<string>) {
      state.selectedQuestionedAnswerId = action.payload
    },
    updateAnsweredQuestionStatus(state) {
      state.error = ''
      state.loading = true
    },
    updateAnsweredQuestionStatusSuccess(state, action: PayloadAction<string>) {
      state.error = ''
      state.loading = false
      state.statusUpdateMessage = action.payload
    },
    updateAnsweredQuestionStatusFailure(state, action: PayloadAction<string>) {
      state.loading = false
      state.error = action.payload
    },

    unblockBlockedUser(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = '';
    },
    unblockBlockedUserSuccess(state) {
      state.loading = false;
      state.error = '';
    },
    unblockBlockedUserFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
})

export const { actions, reducer, name: sliceKey } = getUserManagementOnSlice