import React from 'react';
import { useTranslation } from 'react-i18next';

// import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
// import { makeStyles } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import SetPassForm from './form';
// import withWidth, { isWidthDown } from '@mui/material/withWidth';
// import { selectLoading, selectLoginForm } from '../selectors';
import { useLgStyles, useSmStyles } from './styles';

export function SetPassPage() {
  const classesLg = useLgStyles();
  const classesSm = useSmStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  if (matches) {
    return (
      <div className={classesSm.root}>
        <div className={classesSm.banner}>
          <Box className={classesSm.logoWrap}>

          </Box>
          <SetPassForm className={classesSm.paper} />
        </div>
      </div>
    );
  }
  return (
    <div className={classesLg.root}>
      <SetPassForm className={classesLg.paper} />
      <div className={classesLg.banner}>
        <Box className={classesLg.logoWrap}>

        </Box>
      </div>

    </div>
  );
}

export default SetPassPage;
