import { makeStyles } from "@mui/styles";
import { DASHBOARD_MENU_WIDTH, DASHBOARD_TOP_BAR_HEIGHT } from "utils/constants";

export const useStyles = makeStyles(() => ({
    root: {
        // position: 'relative',
        marginLeft: DASHBOARD_MENU_WIDTH,
        // marginLeft: DASHBOARD_MENU_WIDTH -36,
        marginTop: DASHBOARD_TOP_BAR_HEIGHT- 44,
        // minHeight: "100%",
        // overflow: "auto",
        // backgroundColor:'#aaa',
        flex:1


    }
}))