import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  mainDiv: {
    padding: '1rem',
    backgroundColor: '#F9FAFB'
  },
  backImg: {
    cursor: 'pointer',
    height: '4vh',
    width: '3vw',
  },
  backDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // backgroundColor:'#aaa'
  },
  upload: {
    height: '22px',
    width: '22px',
    marginRight: '10px',
  },
  attach: {
    height: '18px',
    width: '18px',
    marginRight: '5px',
  },
  imageAttach: {
    height: '50px',
    width: '50px',
    borderRadius: '50%',
    marginRight: '5px',
    position: 'relative',
    top: '-95px',
    left: '75px',
  },
  closeSvg: {
    cursor: 'pointer',
    height: 24,
    width: 24,
    padding: 5
  },
  profileImage: {
    height: 'auto',
    // maxWidth: '100%',
    width: '100%'
  },
  addUserMedia: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '2%',
    justifyContent: 'space-between'
  },
  headerDiv: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #DFDFDF',
  },
  inputDivs: {
    marginTop: '2rem',
    flex: 1,
    flexWrap: 'wrap',
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: "center",
    gap: '1rem',
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px'
    },
    [theme.breakpoints.up('xl')]: {
      gap: '1rem 1.5rem',
    },
    [theme.breakpoints.down('lg')]: {
      gap: '1rem 0.5rem',
    },
  },
  dataDiv: {
    height: '70vh'
  },

  // =========================TabList============================
  TabList: {
    zIndex: -2,
    "& .MuiTabs-indicator": {
      display: "none"
    },
    '& .MuiTab-root': {
      border: '1px solid',
      color: '#0B96DB',
      borderRadius: '4px',
      height: '34px',
      marginRight: '17px'
    },
    '& .Mui-selected': {
      background: 'linear-gradient(90deg, #0EC8E1 0.06%, #0866D5 99.99%)',
      color: '#fff !important',
      border: 'none'
    }
  },

  // =========================TabsLabelContainer=================
  TabsLabelContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '6px 10px',
    '& img': {
      marginRight: '5px'
    }
  },
  checked: {
    width: 13,
    height: 13,
    objectFit: "contain",
  },

  // ============================personalInfo======================
  PersonalInfoSexInfo: {
    width: '615px',
    marginTop: '28px',
    '& .MuiFormControl-root': {
      marginRight: '17px'
    }
  },
  // ===============================ShowUserModalSection============
  ShowUserModalContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '25rem',
    height: '35rem',
  },
  ShowUserModalSection: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
  },
  bgDark: {
    backgroundColor: "red !important",
  },

  // ====================================AddEditContainer==============
  AddEditContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1, padding: '16px'
  },
  SendMessageButton: {
    color: '#fff !important',
    backgroundColor: '#12BFE0 !important',
    '&:hover': {
      backgroundColor: "#12bfe0 !important"
    },
    padding: '6px 10px !important',
    fontWeight: '500 !important',
    cursor: 'pointer !important',
    borderRadius: "5px !important",
    marginLeft: 2
  },
  userDataTable: {

  },
  // ================================
  unblockButton: {
    width: 'auto',
    height: '25px',
    borderRadius: '25px',
    backgroundColor: '#12BFE0',
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 20px',
    color: '#ffffff',
  },
  deleteImg: {
    width: '25px',
    height: '25px',
    borderRadius: '5px',
    backgroundColor: '#12BFE0',
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      width: '12px',
    }
  },
  NoResultFound: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: '80%'
  }
}));
