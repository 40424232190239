import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import { Box, Button, Popover, Typography } from '@mui/material';

import { actions } from '../slice';

const PopOverDiv = () => {

    const dispatch = useDispatch()
    // =====================states==============================
    const [anchor, setAnchor] = useState<any>(null);
    const [deleteId, setDeleteId] = useState<string>('');

    // ===================handlechange===========================
    const open = Boolean(anchor);
    const id = open ? 'simple-popover' : undefined;
    const handleCloseEl = () => {
        setAnchor(null);
    };
    const handleDelete = () => {
        dispatch(actions.getDeleteQuestion(deleteId));
        setAnchor(null);
    };

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchor}
            onClose={handleCloseEl}
            sx={{
                display: 'flex',
                flex: 1,
                padding: '2rem',
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Box
                sx={{
                    boxShadow: 0,
                    p: 2,
                    borderRadius: 2,
                }}
            >
                <div>
                    <Typography>Delete This question ?</Typography>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Button
                            onClick={handleDelete}
                            sx={{
                                backgroundColor: '#12BFE0',
                                color: '#fff',
                                borderRadius: '20px',
                                margin: '10px 0px 0px 0px',
                                '&:hover': {
                                    backgroundColor: '#12bfe0',
                                },
                            }}
                        >
                            {'Delete'}
                        </Button>
                        <Button onClick={() => setAnchor(null)}>Close</Button>
                    </div>
                </div>
            </Box>
            <ToastContainer />
        </Popover>
    );
};

export default PopOverDiv