import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { LoginResponse } from 'types/LoginResponse';
import { APP_API_URL, validateEmail } from 'utils/constants';
import { request } from 'utils/request';

import { selectLoginForm } from './selectors';
import { actions, logoutSuccess } from './slice';
import { LoginErrorType } from './types';

export function* logoutRequest() {
  yield delay(500);
  yield localStorage.setItem('sessionToken', '');
  yield put(logoutSuccess());
}

export function* loginRequest() {

  yield delay(500);
  const form: { username: string; password: string } = yield select(
    selectLoginForm,
  );
  if (form.username.length === 0) {
    yield put(actions.loginError(LoginErrorType.USERNAME_EMPTY));
    return;
  }
  if (form.password.length === 0) {
    yield put(actions.loginError(LoginErrorType.PASSWORD_EMPTY));
    return;
  }
  const options = {
    method: 'POST',
    body: JSON.stringify({
      email: form.username,
      password: form.password,
    }),
  };

  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/auth/admin/login`,
      options,
    );

    if (response.message === 'MESSAGES.USER.LOGIN_SUCCESS') {
      yield localStorage.setItem('sessionToken', response.token);
      yield localStorage.setItem('name', response.fullName);
      yield localStorage.setItem('userType', response.userType);
      yield localStorage.setItem('userId', response._id);
      if (response.userType !== 0) {
        yield localStorage.setItem('permissions', response.permissions);
      }
      yield put(actions.loginSuccess(response));
      yield put(actions.updateUserDetails(response))
    } else {
      yield put(actions.loginError(response.message));
    }
  } catch (err: any) {
    if (err.response?.status) {
      if (JSON.parse(err.message).name === "DocumentNotFoundError") {
        yield put(actions.loginError(LoginErrorType.NO_RECORD_FOUND));
        return
      }
    } else {
      yield put(actions.loginError(LoginErrorType.ERROR));
    }
  }
}

export function* forgotPassRequest(action) {
  yield delay(500);
  const form: { email: string } = yield select(
    selectLoginForm,
  );
  if (form.email.length === 0) {
    yield put(actions.loginError(LoginErrorType.USERNAME_EMPTY));
    return;
  }
  if (!validateEmail.test(form.email)) {
    yield put(actions.loginError(LoginErrorType.INVALID_EMAIL));
    return;
  }
  const options = {
    method: 'POST',
    body: JSON.stringify({
      email: form.email
    }),
  };
  try {
    const response: LoginResponse = yield call(
      request,
      `${APP_API_URL}/api/auth/password`,
      options,
    );
    if (response.message === 'MESSAGES.AUTH.FORGOT_PASSWORD_MAIL_SENT') {
      yield put(actions.forgotPassSuccess({ message: "Please check your entered email." }));
      yield put(action?.payload?.callback)
    } else {
      if (response.message === 'MESSAGES.AUTH.LINK_EXPIRED') {
        yield put(actions.forgotPassFailure(LoginErrorType.MESSAGES_AUTH_LINK_EXPIRED));
      } else
        yield put(actions.forgotPassFailure(LoginErrorType[response.message]));
    }
  } catch (err: any) {
    if (err.response?.status) {
      yield put(actions.forgotPassFailure(err.message));
    } else {
      yield put(actions.forgotPassFailure(LoginErrorType.ERROR));
    }
  }
}

export function* resetPassRequest(action) {
  yield delay(500);
  const form: { token: string, password: string, confirmPassword: string } = yield select(
    selectLoginForm,
  );
  if (form.password.length === 0) {
    yield put(actions.loginError(LoginErrorType.PASSWORD_EMPTY));
    return;
  }
  if (form.confirmPassword.length === 0) {
    yield put(actions.loginError(LoginErrorType.PASSWORD_EMPTY));
    return;
  }
  if (form.confirmPassword.length > 0 && form.password.length > 0 && form.confirmPassword !== form.password) {
    yield put(actions.loginError(LoginErrorType.PASSWORD_NOT_MATCH));
    return;
  }
  if (form.token.length === 0) {
    yield put(actions.loginError(LoginErrorType.PASSWORD_NOT_MATCH));
    return;
  }
  const options = {
    method: 'POST',
    body: JSON.stringify({
      password: form.password,
      token: form.token
    }),
  };
  try {
    const response: LoginResponse = yield call(
      request,
      `${APP_API_URL}/api/auth/password`,
      options,
    );
    if (response.message === 'MESSAGES.AUTH.PASSWORD_UPDATED') {
      yield put(actions.resetPassSuccess({ message: "Password has been updated." }));
      yield put(action?.payload?.callback.onSuccess)
    } else {
      if (response.message === 'MESSAGES.AUTH.LINK_EXPIRED') {
        yield put(actions.resetPassFailure(LoginErrorType.MESSAGES_AUTH_LINK_EXPIRED));
        yield put(action?.payload?.callback.onFailure)
        return;
      }
      yield put(actions.resetPassFailure(LoginErrorType[response.message]));
    }
  } catch (err: any) {
    if (err.response?.status) {
      yield put(actions.resetPassFailure(err.message));
    } else {
      yield put(actions.resetPassFailure(LoginErrorType.ERROR));
    }
  }
}

export function* userRepoSaga() {
  yield takeLatest(actions.login.type, loginRequest);
  yield takeLatest(actions.logout.type, logoutRequest);
  yield takeLatest(actions.forgotPass.type, forgotPassRequest);
  yield takeLatest(actions.resetPass.type, resetPassRequest);
}
