import Loader from 'app/components/Loader';
import attach from 'assets/attach.svg';
import closeSvg from 'assets/close.svg';
import uploadSvg from 'assets/upload.svg';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uploadFile } from 'utils/s3Helper';

import {
    Autocomplete, Box, Button, CircularProgress, Fab, Modal, TextField, Typography
} from '@mui/material';

import { validPhone } from '../';
import { selectFile, selectUploadUrl } from '../../mediaSaga/selectors';
import { actions as mediaActions } from '../../mediaSaga/slice';
import {
    selectCategories, selectQuestionFullData, selectQuestionNameTable
} from '../../Questions/selectors';
import { actions as questionActions } from '../../Questions/slice';
import { selectAddAnswer, selectLoading, selectQuestionName, selectUserFound } from '../selectors';
import { actions } from '../slice';
import { useStyles } from '../styles';

const AddAnswerModal = ({ openModal, updateAnswer, updateModalData }) => {
    const dispatch = useDispatch();
    const uploadUrl = useSelector(selectUploadUrl)
    const selectedFile: any = useSelector(selectFile);
    const addAnswer = useSelector(selectAddAnswer);
    const loading = useSelector(selectLoading)
    const questionNameTable = useSelector(selectQuestionNameTable)
    const questionFullData = useSelector(selectQuestionFullData)
    const questionName = useSelector(selectQuestionName)
    const categories = useSelector(selectCategories);
    const userFound = useSelector(selectUserFound);

    const styles = useStyles();

    useEffect(() => {
        dispatch(mediaActions.clearAddData())
        dispatch(mediaActions.clearUploadUrl())
        dispatch(questionActions.clearAddData())
        dispatch(questionActions.clearFormData())
        return () => {
            dispatch(mediaActions.clearAddData())
            dispatch(mediaActions.clearUploadUrl())
            dispatch(questionActions.clearAddData())
            dispatch(questionActions.clearFormData())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        dispatch(actions.setQuestionData(questionFullData))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questionFullData])
    useEffect(() => {
        dispatch(actions.setQuestionTableData(questionNameTable))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questionNameTable])


    const { category_id, question_id, mobile_no, email: mail } = addAnswer;
    const [pressed, setPressed] = useState(false)
    const [startUploading, setStartUploading] = useState(false)
    const [categoryNames, setCategoryNames] = useState<{ label: string, value: string }[]>([])
    const setCategory = e => {
        if (e.target.innerText) {
            setPressed(false)
            let category = categories.filter((category => category.name === e.target.innerText));
            dispatch(actions.setCategoryId(category?.[0]?.id));
            dispatch(actions.getQuestions());

        }
    };
    const setQuestion = e => {
        if (e.target.innerText) {
            setPressed(false)
            dispatch(actions.setQuestionId(e.target.innerText));
        }
    };

    const validFile = (file) => {
        if (!file) {
            return 'Please select a video'
        }
        if (file.type.search("mp4") === -1) {
            return 'Please select a mp4 video file'
        }
        if (file.size / 1024000 > 100) {
            return 'Please select less than 100mb'
        }
        return null
    }
    const validCategory = (category) => {
        if (!category?.trim()) {
            return 'select a category'
        }
        return null
    }
    const validateQuestion = (category) => {
        if (!category?.trim()) {
            return 'select a question'
        }
        return null
    }

    const [inputRef, setInputRef] = useState<HTMLInputElement | null>()
    const handleChoose = () => {
        inputRef?.click()
    }
    const handleFileChange = (event) => {
        dispatch(mediaActions.setFile({
            file: {
                name: event.target.files[0].name,
                type: event.target.files[0].type,
                size: event.target.files[0].size,
                url: URL.createObjectURL(event.target.files[0])
            }
        }))
    }

    const handlePhoneChange = (e) => {
        setPressed(false)
        dispatch(actions.setQuestionMobileNumber(e?.nativeEvent?.target?.value))
    }

    const handleEmailChange = (e) => {
        setPressed(false)
        dispatch(actions.setQuestionEmail(e?.nativeEvent?.target?.value))
    }

    const validMail = (mail) => {
        if (!mail?.trim()) {
            return 'Email Id cannot be empty';
        }
        // eslint-disable-next-line no-useless-escape
        const regMail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!regMail.test(mail)) {
            return 'Please enter valid Email id';
        }
        return null
    }

    useEffect(() => {
        if (!openModal) setPressed(false)
    }, [openModal])

    useEffect(() => {
        if (userFound) {
            dispatch(mediaActions.getUploadUrl({ fileName: selectedFile?.name, contentType: selectedFile?.type }));
            setStartUploading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userFound]);
    const handleAddSubmit = () => {
        setPressed(true);
        if (!validCategory(category_id) && !validateQuestion(question_id)) {
            dispatch(actions.checkUser({ fileName: selectedFile?.name, contentType: selectedFile.type, email: mail, mobile_no: mobile_no, questionId: question_id, videoUrl: category_id }))
        }
    };

    const onProgress = (fileName: any, data: any) => {
        if (data !== 'start' && data !== "end") {
        }
        if (data === "end") {
            var video: any = document.createElement('video');
            video.preload = 'metadata';
            video.onloadedmetadata = function () {
                window.URL.revokeObjectURL(video.src);
                const dur = Math.floor(parseInt(video.duration, 10));
                const duration = `${moment.duration(dur, 'seconds').minutes()} min ${moment
                    .duration(dur, 'seconds')
                    .seconds()} sec `;
                dispatch(mediaActions.getUploadTranscoder({ fileName: uploadUrl?.mediaKey }))
                dispatch(actions.uploadAnswerVideo({ questionId: question_id, videoUrl: uploadUrl.mediaKey, videoLength: duration, email: mail, mobile_no: mobile_no }))

                dispatch(mediaActions.clearAddData())
                dispatch(mediaActions.clearUploadUrl())
                dispatch(questionActions.clearAddData())
                dispatch(questionActions.clearFormData())
                updateAnswer({
                    questionId: '',
                    videoUrl: ''
                })
            };
            video.src = selectedFile.url;
        }
    }

    useEffect(() => {
        if (categories.length > 0) {
            let nameData = categories.map((item) => {
                if (item?.name) return { label: item?.name, value: item.id };
                else return { label: "", value: "" };
            });
            setCategoryNames(nameData)
        }

    }, [categories])

    const uploadFilesToS3 = async () => {
        dispatch(mediaActions.startLoading());
        uploadFile(selectedFile, uploadUrl.mediaUrl, onProgress);
    }

    useEffect(() => {
        if (uploadUrl && uploadUrl.mediaUrl) {
            uploadFilesToS3();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadUrl])

    const clearAll = () => {
        dispatch(actions.openCloseModal(!openModal))
        dispatch(mediaActions.clearAddData())
        dispatch(mediaActions.clearUploadUrl())
        dispatch(questionActions.clearAddData())
        dispatch(questionActions.clearFormData())
        dispatch(actions.resetAnswerModalForm({}))
    }

    return (
        <Modal
            open={openModal}
            onClose={() => dispatch(actions.openCloseModal(false))}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '45rem',
                    bgcolor: 'background.paper',
                    boxShadow: 10,
                    p: 3,
                    borderRadius: 2,
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flex: 1,
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography sx={{ fontSize: 16, fontWeight: '900' }}>
                        Add Answer for Question
                    </Typography>
                    <img
                        src={closeSvg}
                        alt="closeSvg"
                        onClick={() => { clearAll() }}
                        className={styles.closeSvg}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'start',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <div style={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
                        <Typography sx={{ fontSize: 12, color: '#6C7389', fontWeight: 'normal' }} >Phone number</Typography>
                        {/* @ts-ignore */}
                        <input onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }} onChange={handlePhoneChange} maxLength={10} value={mobile_no} className={styles.phoneInput} />
                        {pressed && validPhone(mobile_no) && <Typography sx={{ color: '#911', marginTop: 1 }}>{validPhone(mobile_no)}</Typography>}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', margin: '0rem 1rem', width: '70%' }}>
                        <Typography sx={{ fontSize: 12, color: '#6C7389', fontWeight: 'normal' }} >Email</Typography>
                        {/* @ts-ignore */}
                        <input value={mail} onChange={handleEmailChange} className={styles.emailInput} />
                        {pressed && validMail(mail) && <Typography sx={{ color: '#911', marginTop: 1 }}>{validMail(mail)}</Typography>}
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'start',
                        alignItems: 'center',
                    }}
                >
                    <div style={{ display: "flex", flexDirection: "column", width: "28.5%", margin: "21px 11px 0px 0px", alignItems: "flex-start" }}>
                        <Autocomplete
                            onChange={(e) => setCategory(e)}
                            size='small'
                            disablePortal
                            filterOptions={(x) => x}
                            autoComplete
                            id="combo-box-demo"
                            options={categoryNames}
                            sx={{ width: '100%', m: 0 }}
                            renderInput={(params) => <TextField {...params} label="Category" />}
                        />
                        {pressed && validCategory(category_id) && <Typography sx={{ color: '#911', marginTop: 1 }}>{validCategory(category_id)}</Typography>}
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", width: "66.5%", margin: "21px 0px 0px 5px", alignItems: "flex-start" }}>
                        <Autocomplete
                            onChange={(e) => setQuestion(e)}
                            size='small'
                            disablePortal
                            filterOptions={(x) => x}
                            autoComplete
                            id="combo-box-demo"
                            options={questionName}
                            sx={{ width: '100%', m: 0 }}
                            renderInput={(params) => <TextField {...params} label="Select Question" />}
                        />
                        {pressed && validateQuestion(question_id) && <Typography sx={{ color: '#911', marginTop: 1 }}>{validateQuestion(question_id)}</Typography>}
                    </div>
                </div>


                <div
                    style={{
                        margin: '1.5rem 0rem 0rem 0rem',
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    <div style={{
                        display: 'flex', flexDirection: 'column', justifyContent: 'flex-start',
                    }}>

                        <input
                            ref={el => setInputRef(el)}
                            type="file"
                            style={{
                                display: 'none'
                            }}
                            className="VideoInput_input"
                            onChange={handleFileChange}
                            accept=".mov,.mp4"
                        />
                        <div>
                            {selectedFile ? <Button onClick={handleChoose}
                                sx={{
                                    padding: '10px 15px',
                                    cursor: 'auto',
                                    borderRadius: '4px',
                                    marginLeft: 0,
                                    maxHeight: 40,
                                    border: '0.5px solid'
                                }}>
                                <img src={attach} alt="Attach File" className={styles.attach} />
                                <Typography sx={{ fontSize: 13, fontWeight: '400', textAlign: 'center', textOverflow: 'ellipsis', overflow: 'hidden', minHeight: '21px', whiteSpace: 'nowrap', width: '135px' }}>
                                    {selectedFile.name}
                                </Typography></Button> : <Button
                                    variant="outlined"
                                    onClick={handleChoose}
                                    sx={{
                                        color: '#fff',
                                        backgroundColor: '#12BFE0',
                                        '&:hover': {
                                            backgroundColor: '#12bfe0',
                                        },
                                        fontSize: '1.6vh',
                                        padding: '5px 15px',
                                        fontWeight: '500',
                                        cursor: 'auto',
                                        borderRadius: '4px',
                                        marginLeft: 0,
                                    }}
                                >
                                <img src={uploadSvg} alt="uploadSvg" className={styles.upload} />
                                <Typography sx={{ fontSize: 19, fontWeight: '400' }}>
                                    {'Upload Video'}
                                </Typography>

                            </Button>}
                            {pressed && validFile(selectedFile) && <Typography sx={{ color: '#911', marginTop: 1, marginLeft: '16px', position: 'absolute' }}>{validFile(selectedFile)}</Typography>}
                        </div>

                    </div>
                    {loading && <Loader />}
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            // margin: '1rem 0rem 0rem 0rem',
                        }}
                    >
                        <Button
                            variant="outlined"
                            sx={{
                                color: '#6C7389',
                                backgroundColor: '#E7E7E7',
                                borderColor: '#e7e7e7',
                                '&:hover': {
                                    borderColor: '#e7e7e7',
                                    backgroundColor: '#E7E7E7',
                                },
                                fontSize: '0.75rem',
                                fontWeight: '500',
                                cursor: 'auto',
                                borderRadius: '5px',
                                marginRight: 2,
                            }}
                            onClick={() => {
                                if (!startUploading) {
                                    dispatch(actions.openCloseModal(false))
                                }
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleAddSubmit}
                            variant="outlined"
                            sx={{
                                color: '#fff',
                                backgroundColor: '#12BFE0',
                                '&:hover': {
                                    backgroundColor: '#12bfe0',
                                },
                                fontSize: '0.75rem',
                                padding: '6px 30px',
                                fontWeight: '500',
                                cursor: 'auto',
                                borderRadius: '5px',
                                marginRight: 2,
                            }}
                            disabled={loading}
                        >
                            {loading ? <CircularProgress style={{
                                width: "25px",
                                height: "25px",
                                position: "absolute",
                                color: "#000000"
                            }} /> : ''}
                            Done
                        </Button>
                    </div>
                </div>
            </Box>
        </Modal>
    );
};

export default AddAnswerModal;