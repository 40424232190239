import { createSelector } from "reselect";
import { RootState } from "types";
import { initialState } from "./slice";

const selectDomain = (state:RootState) => state.abuseReportState || initialState;

export const selectAbuseReportState = createSelector(
  [selectDomain],
  abuseReportState => abuseReportState
)

export const selectAbuseReports = createSelector(
  [selectDomain],
  abuseReportState =>  abuseReportState.data,
)
export const selectVideoData = createSelector(
  [selectDomain],
  abuseReportState =>  abuseReportState.videoData,
)
export const selectReportedData = createSelector(
  [selectDomain],
  abuseReportState =>  abuseReportState.reportedData,
)
export const selectReporterData = createSelector(
  [selectDomain],
  abuseReportState =>  abuseReportState.reporterData,
)
export const selectReporterIdsData = createSelector(
  [selectDomain],
  abuseReportState =>  abuseReportState.reporterIds,
)
export const selectReportedIdsData = createSelector(
  [selectDomain],
  abuseReportState =>  abuseReportState.reportedIds,
)
export const selectTotalAbuseReports = createSelector(
  [selectDomain],
  abuseReportState =>  abuseReportState?.meta[0]?.total,
)
export const selectPageNo = createSelector(
  [selectDomain],
  abuseReportState =>  abuseReportState?.meta[0]?.page_no,
)
export const selectreportFilteres = createSelector(
  [selectDomain],
  abuseReportState =>  abuseReportState?.filters,
)
export const selectAddMoreReports = createSelector(
  [selectDomain],
  abuseReportState =>  abuseReportState?.addMoreReports,
)


export const selectLoading = createSelector(
  [selectDomain],
  abuseReportState => abuseReportState.loading
)
