import { actions as AnswerActions } from 'app/containers/Answers/slice';
import { selectQuestions, selectQuestionState } from 'app/containers/Questions/selectors';
import { actions } from 'app/containers/Questions/slice';
import { actions as userActions } from 'app/containers/UserManagement/slice';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import theme from 'styles/theme/mui';

import { Button, Modal, Popover, Switch, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';

type Props = {
    isquestions?: boolean,
    isAnswers?: boolean,
    element?: any,
    isUsers?: boolean,
    usermanagementansweredquestion?: boolean,
    styles?: any,
    isEditing?: boolean
}


const IOSSwitchComponent = styled((props: Props) => {
    const dispatch = useDispatch()
    const [pressed, setPressed] = useState(false)
    const onDisable = () => {
        props.isquestions && dispatch(actions.getQuestionId(props?.element?.id))
        if (props.isAnswers) {
            dispatch(AnswerActions.updateAnswer({ id: props?.element?.id }));
            // dispatch(AnswerActions.getAnswer())
        }
        setPressed(!pressed)
        setAnchorEl(null)
    }

    console.log('isEditing', {
        message: 'Is Editing log',
        isEditing: props.isEditing
    })

    const [anchorEl, setAnchorEl] = useState<any>(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <div style={{ position: 'relative', display: 'flex' }} >
            <Button disableRipple aria-describedby={id} sx={{ zIndex: 2, minWidth: 'auto' }} onClick={(event) => !props.isEditing ? handleClick(event) : null
            }>
                {props?.isquestions && (<Switch checked={props?.isAnswers ? props?.element?.status === 'ENABLED' : props?.element?.is_blocked ?? pressed} focusVisibleClassName=".Mui-focusVisible" disableRipple {...props
                } disabled={props.isEditing} />)}
                {props?.isAnswers && (<Switch checked={props?.isAnswers ? props?.element?.status === 'ENABLED' : props?.element?.is_blocked ?? pressed} focusVisibleClassName=".Mui-focusVisible" disableRipple {...props
                } disabled={props.isEditing} />)}
                {props?.isUsers && (<Switch checked={props?.element?.is_active ?? pressed} focusVisibleClassName=".Mui-focusVisible" disableRipple {...props
                } disabled={props.isEditing} />)}
                {props?.isAnswers && (
                    <Switch
                        checked={props?.isAnswers ? props?.element?.status === 'ENABLED' : props?.element?.is_blocked ?? pressed}
                        focusVisibleClassName=".Mui-focusVisible"
                        disableRipple {...props}
                        disabled={props.isEditing}
                    />)}
                {props?.isUsers && (
                    <Switch
                        checked={props?.element?.is_active ?? pressed}
                        focusVisibleClassName=".Mui-focusVisible"
                        disableRipple {...props}
                        disabled={props.isEditing}
                    />
                )}
                {
                    props?.usermanagementansweredquestion &&
                    <Switch
                        checked={props?.element?.status ?? pressed}
                        focusVisibleClassName=".Mui-focusVisible"
                        disableRipple {...props}
                        disabled={props.isEditing}
                    />
                }
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                sx={{
                    display: 'flex',
                    flex: 1,
                    padding: '2rem',
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
            >
                <Box sx={
                    {
                        boxShadow: 10,
                        p: 2,
                        borderRadius: 2,
                    }
                }>
                    <div>
                        {props?.isquestions && <Typography>{props?.element?.is_blocked ?? pressed ? 'Disable' : 'Enable'} This User ?</Typography>}
                        {props?.isAnswers && <Typography>{props?.element?.status == 'ENABLED' ?? pressed ? 'Disable' : 'Enable'} This Answer ?</Typography>}
                        {props?.isUsers && <Typography>{props?.element?.is_active ?? pressed ? 'Disable' : 'Enable'} This User ?</Typography>}
                        <div style={{ display: 'flex', flexDirection: 'column' }} >
                            {props?.isquestions && <Button sx={{
                                backgroundColor: '#12BFE0', color: '#fff', borderRadius: '20px', margin: '10px 0px 0px 0px', '&:hover': {
                                    backgroundColor: "#12bfe0"
                                }
                            }} onClick={onDisable}>{props?.element?.is_blocked ?? pressed ? 'Disable' : "Enable"}</Button>}
                            {props?.isAnswers && <Button sx={{
                                backgroundColor: '#12BFE0', color: '#fff', borderRadius: '20px', margin: '10px 0px 0px 0px', '&:hover': {
                                    backgroundColor: "#12bfe0"
                                }
                            }} onClick={onDisable}>{props?.element?.status == 'ENABLED' ?? pressed ? 'Disable' : "Enable"}</Button>}
                            {props?.isUsers && <Button sx={{
                                backgroundColor: '#12BFE0', color: '#fff', borderRadius: '20px', margin: '10px 0px 0px 0px', '&:hover': {
                                    backgroundColor: "#12bfe0"
                                }
                            }} onClick={onDisable}>{props?.element?.is_active ?? pressed ? 'Disable' : "Enable"}</Button>}
                            <Button onClick={() => setAnchorEl(null)} >Close</Button>
                        </div>
                    </div>
                </Box>
                {/* <DisableModal /> */}
            </Popover>
        </div>
    )
})(({ theme }) => ({
    width: 36,
    height: 21,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(15px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 17,
        height: 17,
    },
    '& .MuiSwitch-track': {
        borderRadius: 32 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        // transition: theme.transitions.create(['background-color'], {
        //     duration: 500,
        // }),
    },
}));

export default IOSSwitchComponent