import DataTableComponent from 'app/components/DataTableComponent';
import DropDownComponent from 'app/components/DropDownComponent';
import IOSSwitchComponent from 'app/components/IOSSwitchComponent';
import attach from 'assets/attach.svg';
import closeSvg from 'assets/close.svg';
import downloadSvg from 'assets/download.svg';
import Remove from 'assets/remove.svg';
import uploadSvg from 'assets/upload.svg';
import moment from 'moment';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { uploadFile } from 'utils/s3Helper';

import {
    Autocomplete, Button, CircularProgress, Fab, LinearProgress, Modal, Popover, TextField,
    Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { GridColDef } from '@mui/x-data-grid';

import playButton from '../../../assets/videoPlay.png';
import {
    selectAddSuccess, selectDownloadUrl, selectFile, selectLoading, selectUploadUrl
} from '../mediaSaga/selectors';
import { actions as mediaActions } from '../mediaSaga/slice';
import { selectLoading as questionLoading } from '../Questions/selectors';
import {
    selectAddQuestion, selectAddSuccessData, selectAnswerMediaKey, selectAnswerMediaUrl,
    selectCategories, selectCategory, selectCheckQuestion, selectError, selectFileUploading,
    selectPageNo, selectPageSize, selectQuesLoading, selectQuestions, selectStopFetch,
    selectTotalQuestion, selectUserData
} from './selectors';
import { actions } from './slice';
import { useStyles } from './styles';
import { getIsSomeOneOnCurrentModule } from '../availabilitySaga/selectors';

type Props = {};

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

const AddModal = ({ openModal, closeAdd }) => {
    const dispatch = useDispatch();
    const err = useSelector(selectError);
    const uploadUrl = useSelector(selectUploadUrl)
    const addSuccessData = useSelector(selectAddSuccessData);
    const addSuccessDataMedia = useSelector(selectAddSuccess);
    const selectedFile: any = useSelector(selectFile);
    const addQuestion = useSelector(selectAddQuestion);
    const checkQuestion = useSelector(selectCheckQuestion);
    const loading = useSelector(selectLoading)
    const quesloading = useSelector(selectQuesLoading)

    const categories = useSelector(selectCategories);
    const styles = useStyles();

    const { category_id, question, body } = addQuestion;
    const [pressed, setPressed] = useState(false)
    const [startUplaoding, setStartUploading] = useState(false)
    const [showError, setShowError] = useState(false)
    const setCategory = e => {
        setPressed(false)
        dispatch(actions.getAddedCategoryId(e));
    };
    const validQuestion = (question) => {
        if (!question?.trim()) {
            return 'Question cannot be empty'
        }
        if (question?.length > 100) {
            return 'Question limit 100 characters'
        }
        return null
    }
    const validBody = (body) => {
        if (!body?.trim()) {
            return 'Body cannot be empty'
        }
        if (body?.length > 256) {
            return 'Body limit 256 characters'
        }
        return null
    }
    const validFile = (file) => {
        if (!file) {
            return 'Please select a video'
        }
        if (file.type.search("mp4") == -1) {
            return 'Please select a mp4 video file'
        }
        if (file.size / 1024000 > 100) {
            return 'Please select less than 100mb'
        }
        return null
    }
    const validCategory = (category) => {
        if (!category?.trim()) {
            return 'select a category'
        }
        return null
    }
    const validExist = (err) => {
        if (err === 'MESSAGE_QUESTION_EXIST') {
            return 'Question already exist'
        }
        return null
    }
    const checkUploading = (err) => {
        if (startUplaoding === true) {
            return 'Video is in progress can not cancel'
        }
        return null
    }

    const [inputRef, setInputRef] = useState<HTMLInputElement | null>()
    const handleChoose = () => {
        inputRef?.click()
        // inputRef.current.click()
    }
    const handleFileChange = (event) => {
        dispatch(mediaActions.setFile({
            file: {
                name: event.target.files[0].name,
                type: event.target.files[0].type,
                size: event.target.files[0].size,
                url: URL.createObjectURL(event.target.files[0])
            }
        }))
        dispatch(mediaActions.getUploadUrl({ fileName: event.target.files[0].name, contentType: event.target.files[0].type }));
    }
    const setQuestion = e => {
        setPressed(false)
        dispatch(actions.getAddedQuestion(e.nativeEvent.target.value));
    };
    const setBody = e => {
        setPressed(false)
        dispatch(actions.getAddedBody(e.nativeEvent.target.value));
    };
    const handleAddSubmit = () => {
        setPressed(true);
        if (!validQuestion(question) && !validBody(body) && !validCategory(category_id) && !validFile(selectedFile)) {
            dispatch(actions.checkQuestions({ question: question }))
        }
    };

    useEffect(() => {
        if (checkQuestion == "Success") {
            // if(!addQuestion.videoLength){
            //     toast.warning('Video is Uploading Please wait!')
            //     return
            // }
            dispatch(actions.getAddQuestion(uploadUrl.mediaKey));
            // dispatch(mediaActions.getUploadUrl({ fileName: selectedFile?.name, contentType: selectedFile?.type }));
            setStartUploading(true);
        } else {
            dispatch(mediaActions.stopLoading());
        }
        dispatch(actions.clearCheckQuestion());
    }, [checkQuestion])

    const [fileProgress, setFileProgress] = useState<any>(0)
    const onProgress = (fileName: any, data: any) => {
        if (data !== 'start' && data !== "end") {
            setFileProgress(data)
        }
        if (data == "end") {
            var video: any = document.createElement('video');
            video.preload = 'metadata';
            video.onloadedmetadata = function () {
                window.URL.revokeObjectURL(video.src);
                // var duration = video.duration;
                const dur = Math.floor(parseInt(video.duration, 10));
                const duration = `${moment.duration(dur, 'seconds').minutes()} min ${moment
                    .duration(dur, 'seconds')
                    .seconds()} sec `;

                dispatch(actions.getAddedVideoLength(duration));
                dispatch(mediaActions.getUploadTranscoder({ fileName: uploadUrl?.mediaKey }))
                // dispatch(actions.getAddQuestion());
            };
            video.src = selectedFile.url;

        }
    }

    const uploadFilesToS3 = async () => {
        dispatch(mediaActions.startLoading());
        setFileProgress(0)
        uploadFile(selectedFile, uploadUrl.mediaUrl, onProgress);
    }

    useEffect(() => {
        if (uploadUrl && uploadUrl.mediaUrl) {
            uploadFilesToS3();
        }
    }, [uploadUrl])


    useEffect(() => {
        if (err === 'MESSAGE_QUESTION_CREATED') {
            let data = {
                userId: addSuccessData.id,
                title: selectedFile?.name,
                thumbnail: uploadUrl.mediaThumbnailKey,
                size: selectedFile?.size,
                file_type: "VIDEO",
                media_type: "QUESTIONS",
                url: uploadUrl.mediaKey,
            }
            dispatch(mediaActions.setForm(data));
            dispatch(mediaActions.addMedia())
        }
    }, [err]);

    useEffect(() => {
        if (addSuccessDataMedia) {
            setStartUploading(false);
            closeAdd()
            dispatch(mediaActions.clearAddData())
            dispatch(mediaActions.clearUploadUrl())
            dispatch(actions.clearAddData())
            // dispatch(actions.getQuestion());

        }
    }, [addSuccessDataMedia]);

    useEffect(() => {
        return () => {
            dispatch(mediaActions.clearAddData())
            dispatch(mediaActions.clearUploadUrl())
            dispatch(actions.clearAddData())
            // dispatch(actions.getQuestion());
            dispatch(actions.setAddQuestionLoading(false));
        }
    }, [])

    return (
        <Modal
            open={openModal}
            onClose={closeAdd}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '35rem',
                    bgcolor: 'background.paper',
                    boxShadow: 10,
                    p: 3,
                    borderRadius: 2,
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flex: 1,
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography sx={{ fontSize: 16, fontWeight: '900' }}>
                        Add New Question
                    </Typography>
                    <img
                        src={closeSvg}
                        alt="closeSvg"
                        onClick={closeAdd}
                        className={styles.closeSvg}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'start',
                        alignItems: 'center',
                    }}
                >
                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1.5rem' }} >
                        <div>
                            <DropDownComponent
                                array={categories}
                                isCategory={false}
                                setUser={setCategory}
                                user={category_id}
                                widthNo="13rem"
                                label={'Category'}
                            />
                        </div>
                        {pressed && validCategory(category_id) && <Typography sx={{ color: '#911', marginTop: 1, position: 'absolute', top: '85px' }}>{validCategory(category_id)}</Typography>}
                    </div>
                    <input
                        ref={el => setInputRef(el)}
                        type="file"
                        style={{
                            display: 'none'
                        }}
                        className="VideoInput_input"
                        onChange={handleFileChange}
                        accept=".mov,.mp4"
                    />
                    <div style={{ marginTop: '1.5rem' }}>
                        {selectedFile ? <Button onClick={handleChoose}
                            sx={{
                                padding: '10px 15px',
                                cursor: 'auto',
                                borderRadius: '4px',
                                marginLeft: 2,
                                maxHeight: 40,
                                border: '0.5px solid'
                            }}>
                            <img src={attach} alt="Attach File" className={styles.attach} />
                            <Typography sx={{ fontSize: 13, fontWeight: '400', textAlign: 'center', textOverflow: 'ellipsis', overflow: 'hidden', minHeight: '21px', whiteSpace: 'nowrap', width: '135px' }}>
                                {selectedFile.name}
                            </Typography></Button> : <Button
                                variant="outlined"
                                onClick={handleChoose}
                                sx={{
                                    color: '#fff',
                                    backgroundColor: '#12BFE0',
                                    '&:hover': {
                                        backgroundColor: '#12bfe0',
                                    },
                                    fontSize: '1.6vh',
                                    padding: '5px 15px',
                                    fontWeight: '500',
                                    cursor: 'auto',
                                    borderRadius: '4px',
                                    marginLeft: 2,
                                }}
                            >
                            <img src={uploadSvg} alt="uploadSvg" className={styles.upload} />
                            <Typography sx={{ fontSize: 19, fontWeight: '400' }}>
                                {'Upload Video'}
                            </Typography>
                        </Button>}
                        {pressed && validFile(selectedFile) && <Typography sx={{ color: '#911', marginTop: 1, marginLeft: '16px', position: 'absolute' }}>{validFile(selectedFile)}</Typography>}
                    </div>
                </div>
                {
                    loading
                        ?
                        <Box sx={{ width: '100%' }}>
                            <LinearProgressWithLabel value={fileProgress} />
                        </Box>
                        :
                        ''
                }

                <div
                    style={{
                        margin: '1.5rem 0rem 0rem 0rem',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 12,
                                    color: '#6C7389',
                                    fontWeight: 'normal',
                                    marginBottom: 1,
                                }}
                            >
                                Question
                            </Typography>
                            <input
                                onChange={setQuestion}
                                value={question}
                                className={styles.titleInput}
                            />
                            {pressed && validQuestion(question) && <Typography sx={{ color: '#911', marginTop: 1 }}>{validQuestion(question)}</Typography>}

                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                marginTop: '1.5rem',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 12,
                                    color: '#6C7389',
                                    fontWeight: 'normal',
                                    marginBottom: 1,
                                }}
                            >
                                Body
                            </Typography>
                            <textarea
                                onChange={setBody}
                                value={body}
                                className={styles.textArea}
                            />
                            {pressed && validBody(body) && <Typography sx={{ color: '#911', marginTop: 1 }}>{validBody(body)}</Typography>}
                            {validExist(err) && <Typography sx={{ color: '#911', marginTop: 1 }}>{validExist(err)}</Typography>}
                            {showError && checkUploading(err) && <Typography sx={{ color: '#911', marginTop: 1 }}>{checkUploading(err)}</Typography>}
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            margin: '1rem 0rem 0rem 0rem',
                        }}
                    >
                        <Button
                            variant="outlined"
                            sx={{
                                color: '#6C7389',
                                backgroundColor: '#E7E7E7',
                                borderColor: '#e7e7e7',
                                '&:hover': {
                                    borderColor: '#e7e7e7',
                                    backgroundColor: '#E7E7E7',
                                },
                                fontSize: '0.75rem',
                                fontWeight: '500',
                                cursor: 'auto',
                                borderRadius: '5px',
                                marginLeft: 2,
                            }}
                            onClick={() => {
                                setShowError(true);
                                if (!startUplaoding) {
                                    closeAdd()
                                }
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleAddSubmit}
                            variant="outlined"
                            sx={{
                                color: '#fff',
                                backgroundColor: '#12BFE0',
                                '&:hover': {
                                    backgroundColor: '#12bfe0',
                                },
                                fontSize: '0.75rem',
                                padding: '6px 30px',
                                fontWeight: '500',
                                cursor: 'auto',
                                borderRadius: '5px',
                                marginLeft: 2,
                            }}
                            disabled={quesloading || !(addQuestion?.videoLength != '' || addQuestion?.videoLength)}
                        >
                            {quesloading ? <CircularProgress style={{
                                width: "25px",
                                height: "25px",
                                position: "absolute",
                                color: "#000000"
                            }} /> : ''}
                            Done
                        </Button>
                    </div>
                </div>
            </Box>
        </Modal>
    );
};

const ShowUserProfile = ({ isVideo, openProfileModal, setOpenProfileModal, url }) => {
    const styles = useStyles();
    useEffect(() => {
        return () => {
            setOpenProfileModal(false)
        }
    }, [])
    return (
        <Modal
            open={openProfileModal}
            onClose={() => setOpenProfileModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '25rem',
                    height: '35rem',
                    bgcolor: 'background.paper',
                    boxShadow: 10,
                    borderRadius: 2,
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flex: 1,
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography sx={{ fontSize: 16, fontWeight: '900' }}>
                        Question Video
                    </Typography>
                    <img
                        src={closeSvg}
                        alt="closeSvg"
                        onClick={() => { setOpenProfileModal() }}
                        className={styles.closeSvg}
                    />
                </div>
                <div
                    style={{
                        height: '95%',
                    }}
                >
                    {isVideo && (<video width="100%" height="100%" controls >
                        <source src={url} type="video/mp4" />
                    </video>)}
                </div>


            </Box>
        </Modal>
    );
};

const Questions = (props: Props) => {
    const dispatch = useDispatch();
    const questions = useSelector(selectQuestions);
    const pageSize = useSelector(selectPageSize)
    const pageNo = useSelector(selectPageNo)
    const totalQuestions = useSelector(selectTotalQuestion)

    const [openProfileModal, setOpenProfileModal] = useState(false)
    const [selectedProfileData, setSelectedProfileData] = useState({
        isVideoSelected: false,
        url: ''
    })

    const categories = useSelector(selectCategories);
    const category = useSelector(selectCategory);
    const isEditing = useSelector(getIsSomeOneOnCurrentModule);

    useEffect(() => {
        dispatch(actions.getPageNo(0));
        dispatch(actions.getQuestion());
        dispatch(actions.getCategory());
        return () => {
            dispatch(actions.getPageNo(0));
        };
    }, []);

    const [openModal, setOpenModal] = useState(false);
    const [answeropenModal, setAnswerOpenModal] = useState(false);
    const [answerModalData, setAnswerModalData] = useState({
        questionId: '',
        videoUrl: ''
    })

    const [anchorEl, setAnchorEl] = useState<any>(null);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [deleteId, setDeleteId] = useState<string>('');

    const [anchor, setAnchor] = useState<any>(null);
    const handleDelete = () => {
        dispatch(actions.getDeleteQuestion(deleteId));
        setAnchor(null);
    };

    const handleDownload = (event, row) => {
        dispatch(mediaActions.getDownloadUrl({ key: row.video, name: row.name + "_" + (row.video.split("/")[(row.video.split("/").length - 1)]) }));
    };
    const handlePlayVideo = (event, row) => {
        if (row?.video) {
            setOpenProfileModal(true)
            setSelectedProfileData({
                isVideoSelected: true,
                url: row?.video
            })
        }
    };

    useEffect(() => {
        dispatch(actions.setAddMoreQuestions(false));
    }, [])

    const handleCloseEl = () => {
        setAnchor(null);
    };
    const PopOverDiv = () => {
        const open = Boolean(anchor);
        const id = open ? 'simple-popover' : undefined;
        return (
            <Popover
                id={id}
                open={open}
                anchorEl={anchor}
                onClose={handleCloseEl}
                sx={{
                    display: 'flex',
                    flex: 1,
                    padding: '2rem',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box
                    sx={{
                        boxShadow: 0,
                        p: 2,
                        borderRadius: 2,
                    }}
                >
                    <div>
                        <Typography>Delete This question ?</Typography>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Button
                                onClick={handleDelete}
                                sx={{
                                    backgroundColor: '#12BFE0',
                                    color: '#fff',
                                    borderRadius: '20px',
                                    margin: '10px 0px 0px 0px',
                                    '&:hover': {
                                        backgroundColor: '#12bfe0',
                                    },
                                }}
                            >
                                {'Delete'}
                            </Button>
                            <Button onClick={() => setAnchor(null)}>Close</Button>
                        </div>
                    </div>
                </Box>
                <ToastContainer />
            </Popover>
        );
    };
    const questionLoader = useSelector(questionLoading)

    const columns: GridColDef[] = [
        {
            field: 'date',
            headerName: 'Date',
        },
        {
            field: 'id', headerName: 'Question ID',
            width: 130
        },
        {
            field: 'name', headerName: 'Name', width: 220,
            renderCell: e => {
                return (
                    <div
                        style={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            minHeight: '52px',
                            maxHeight: '52px',
                            lineHeight: '52px',
                            overflow: 'hidden',
                        }}
                    >
                        {e.row.name}
                    </div>
                );
            },
        },

        {
            field: 'body', sortable: false, headerName: 'Body', width: 230,
            renderCell: e => {
                return (
                    <div
                        style={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            minHeight: '52px',
                            maxHeight: '52px',
                            lineHeight: '52px',
                            overflow: 'hidden',
                        }}
                    >
                        {e.row.body}
                    </div>
                );
            },
        },
        {
            field: 'video_length',
            sortable: false,
            headerName: 'Video Length',
            width: 110
        },
        { field: 'category', sortable: false, headerName: 'Category', width: 130 },
        {
            field: 'actions',
            headerName: 'Actions',
            sortable: false,
            width: 150,
            renderCell: e => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            flex: 0.7,
                            justifyContent: 'space-between',
                        }}
                    >
                        <PopOverDiv />

                        <img
                            src={downloadSvg}
                            onClick={r => handleDownload(r, e.row)}
                            alt="downloadSvg"
                            className={styles.imgTagD}
                        />
                        <img
                            src={playButton}
                            onClick={r => handlePlayVideo(r, e.row)}
                            alt="playSvg"
                            className={styles.playTagD}
                        />
                        <IOSSwitchComponent isquestions={true} element={e.row} isEditing={isEditing} />
                    </div>
                );
            },
        },
    ];

    const openAdd = () => setOpenModal(true);

    const closeAdd = () => {
        setOpenModal(false);
        dispatch(actions.clearFormData());
        dispatch(mediaActions.clearAddData());
    };

    const setCategoryId = id => {
        dispatch(actions.getCategoryId(id));
        dispatch(actions.setAddMoreQuestions(false))
        dispatch(actions.getQuestion());
    };

    const styles = useStyles();

    return (
        <div className={styles.mainDiv}>
            <div className={styles.headerDiv}>
                <DropDownComponent
                    array={[...categories, { id: "", name: "All" }]}
                    widthNo={'15rem'}
                    label="Category"
                    isCategory
                    setUser={setCategoryId}
                    user={category}
                />
                <div>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <Typography sx={{ p: 2 }}>The content of the Popover.</Typography>
                    </Popover>
                </div>
                <Fab
                    className={styles.fabBtn}
                    sx={{ backgroundColor: '#0EC8E1' }}
                    aria-label="like"
                >
                    <Typography
                        sx={{ colors: '#086ED6', fontSize: 22, fontWeight: '500' }}
                    >
                        {totalQuestions}
                    </Typography>
                </Fab>
            </div>
            <div className={styles.dataTable} style={{ position: 'relative' }}>
                {
                    openModal &&
                    <AddModal openModal={openModal} closeAdd={closeAdd} />
                }

                <div>
                    {openProfileModal &&
                        <ShowUserProfile isVideo={selectedProfileData?.isVideoSelected} openProfileModal={openProfileModal} setOpenProfileModal={setOpenProfileModal} url={selectedProfileData?.url} />
                    }
                </div>


                <div className={styles.tableData}>
                    <div style={{ height: '100%', width: '100%' }}>
                        <DataTableComponent
                            mainType={'QUESTIONS'}
                            updateAnswer={setAnswerModalData}
                            openAnswerModal={setAnswerOpenModal}
                            pageSize={pageSize}
                            page={pageNo}
                            rowCount={totalQuestions}
                            pageCount={questions.length}
                            onPageChange={(dummypageNo) => {
                                // dispatch(actions.setAddMoreQuestions(true))
                                dispatch(actions.setAddMoreQuestions(dummypageNo != 0 ? true : false))
                                dispatch(actions.getQuestion())
                            }}
                            onPageSizeChange={(pageSize) => {
                                dispatch(actions.getPageSize(pageSize))
                                dispatch(actions.getQuestion())
                            }}
                            rows={questions}
                            columns={columns}
                            header
                            loading={questionLoader}
                        />
                    </div>
                </div>
                <Fab
                    className={styles.fabBtn}
                    onClick={openAdd}
                    aria-label="like"
                    sx={{ marginTop: 5 }}
                >
                    <Typography sx={{ color: '#fff', fontSize: 32, fontWeight: '500' }}>
                        +
                    </Typography>
                </Fab>
            </div>
        </div>
    );
};

export default memo(Questions);
