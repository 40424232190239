import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { APP_API_URL } from 'utils/constants';
import { request } from 'utils/request';

import { selectModuleType } from './selectors';
import { actions } from './slice';

export function* checkActivityRequest() {
  yield delay(500);
  const moduleType: string = yield select(selectModuleType);
  const options = {
    method: 'POST',
    body: JSON.stringify({
      type: moduleType
    }),
  };
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/super-admin/check-activity`,
      options
    )
    yield put(actions.setAvailability(response));
  } catch (err: any) {
    yield put(actions.setAvailabilityError(err.message));
  }
}
export function* updateActivityRequest() {
  yield delay(500);
  const moduleType: string = yield select(selectModuleType);
  const options = {
    method: 'POST',
    body: JSON.stringify({
      type: moduleType
    }),
  };
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/super-admin/update-activity`,
      options
    )
    if (response.message === 'MESSAGE.SUCCESSFULLY_UPDATED') {
      yield put(actions.getUpdateAvailabilitySuccess());
    } else {
      yield put(actions.getUpdateAvailabilityFailure('Something went wrong!'));
    }
  } catch (err: any) {
    yield put(actions.getUpdateAvailabilityFailure(err.message));
  }
}

export function* checkAvailabilitySaga() {
  yield takeLatest(actions.getCheckAvailability.type, checkActivityRequest);
  yield takeLatest(actions.getUpdateAvailability.type, updateActivityRequest);
}
