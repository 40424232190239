/*
 * GithubRepoForm Slice
 *
 * Here we define:
 * - The shape of our container's slice of Redux store,
 * - All the actions which can be triggered for this slice, including their effects on the store.
 *
 * Note that, while we are using dot notation in our reducer, we are not actually mutating the state
 * manually. Under the hood, we use immer to apply these updates to a new copy of the state.
 * Please see https://immerjs.github.io/immer/docs/introduction for more information.
 *
 */

import { ForgotPasswordResponse, LoginResponse } from 'types/LoginResponse';
import { UserDetails } from 'types/UserDetails';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { createAction, PayloadAction } from '@reduxjs/toolkit';

import { ContainerState, LoginErrorType } from './types';

export const logoutSuccess = createAction('LOGOUT_SUCCESS');
// The initial state of the GithubRepoForm container
export const initialState: ContainerState = {
  username: '',
  password: '',
  loading: false,
  error: null,
  token: localStorage.getItem('sessionToken') || '',
  email: '',
  message: '',
  confirmPassword: '',
  userDetail: {
    age: 0,
    createdAt: '',
    dob: '',
    email: '',
    fullName: '',
    gender: '',
    isActive: false,
    language: '',
    location: {
      name: '',
      geo: {
        type: '',
        coordinates: []
      },
      _id: ''
    },
    message: '',
    mobileNumber: '',
    onboardingStep: 0,
    permissions: [],
    rangeInMeters: 10000,
    role: '',
    token: '',
    updatedAt: '',
    userType: 0,
    __v: 0,
    _id: "",
  }
};

const githubRepoFormSlice = createSlice({
  name: 'authState',
  initialState,
  extraReducers: builder => {
    builder.addCase(logoutSuccess, state => {
      state.token = '';
    });
  },
  reducers: {
    changeUsername(state, action: PayloadAction<string>) {
      state.username = action.payload;
      state.error = null;
      state.message = "";
    },
    changePassword(state, action: PayloadAction<string>) {
      state.password = action.payload;
      state.error = null;
      state.message = "";
    },
    changeConfirmPassword(state, action: PayloadAction<string>) {
      state.confirmPassword = action.payload;
      state.error = null;
      state.message = "";
    },
    changeEmail(state, action: PayloadAction<string>) {
      state.email = action.payload;
      state.message = "";
      state.error = null;
    },
    updateToken(state, action: PayloadAction<string>) {
      state.token = action.payload;
    },
    logout(state) {
      state.token = '';
      state.username = '';
    },
    login(state) {
      state.loading = true;
      state.error = null;
    },
    loginSuccess(state, action: PayloadAction<LoginResponse>) {
      state.username = '';
      state.password = '';
      state.loading = false;
      state.error = null;
      state.token = action.payload.token;
      state.message = "";
    },
    updateUserDetails(state, action: PayloadAction<UserDetails>) {
      state.userDetail.age = action.payload.age;
      state.userDetail.token = action.payload.token;
      state.userDetail.__v = action.payload.__v;
      state.userDetail._id = action.payload._id;
      state.userDetail.location = action.payload.location;
      state.userDetail.mobileNumber = action.payload.mobileNumber;
      state.userDetail.permissions = action.payload.permissions;
      state.userDetail.email = action.payload.email;
      state.userDetail.dob = action.payload.dob;
      state.userDetail.fullName = action.payload.fullName;
      state.userDetail.createdAt = action.payload.createdAt;
      state.userDetail.gender = action.payload.gender;
      state.userDetail.isActive = action.payload.isActive;
      state.userDetail.rangeInMeters = action.payload.rangeInMeters;
      state.userDetail.role = action.payload.role;
      state.userDetail.language = action.payload.language;
      state.userDetail.message = action.payload.message;
      state.userDetail.onboardingStep = action.payload.onboardingStep;
      state.userDetail.userType = action.payload.userType;
    },

    loginError(state, action: PayloadAction<LoginErrorType>) {
      state.error = action.payload;
      state.loading = false;
      state.message = "";
    },
    forgotPass(state, action: PayloadAction<{ callback: () => void }>) {
      state.loading = true;
      state.error = null;
      state.message = "";
    },
    forgotPassSuccess(state, action: PayloadAction<ForgotPasswordResponse>) {
      state.loading = false;
      state.error = null;
      state.message = action.payload.message;
    },
    forgotPassFailure(state, action: PayloadAction<LoginErrorType>) {
      state.loading = false;
      state.error = action.payload;
      state.message = "";
    },
    forgotPassLoader(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    resetPass(state, action: PayloadAction<{ callback: { onSuccess: () => void, onFailure: () => void } }>) {
      state.loading = true;
      state.error = null;
      state.message = "";
    },
    resetPassSuccess(state, action: PayloadAction<ForgotPasswordResponse>) {
      state.message = action.payload.message;
      state.loading = false;
      state.error = null;
    },
    resetPassFailure(state, action: PayloadAction<LoginErrorType>) {
      state.error = action.payload;
      state.loading = false;
      state.message = "";
    },
  },
});

export const { actions, reducer, name: sliceKey } = githubRepoFormSlice;
