import { createSelector } from "reselect";
import { RootState } from "types";
import { initialState } from "./slice";

const selectDomain = (state:RootState) => state.answerState || initialState;

export const selectAnswerState = createSelector(
  [selectDomain],
  answerState => answerState
)

export const selectAnswers = createSelector(
  [selectDomain],
  answerState =>  answerState.answerData,
)

export const selectQuestionsData = createSelector(
  [selectDomain],
  answerState =>  answerState.questionsData,
)
export const selectQuestionsDataForSearch = createSelector(
  [selectDomain],
  answerState =>  answerState.questionsDataForSearch,
)
export const selectQuestionName = createSelector(
  [selectDomain],
  answerState =>  answerState.questionNameTable,
)

export const selectFileUploading = createSelector(
  [selectDomain],
  answerState => answerState.fileUploading
)
export const selectAnswerFilters = createSelector(
  [selectDomain],
  answerState => answerState.filters
)
export const selectAnswerCategory = createSelector(
  [selectDomain],
  answerState => answerState.category
)

export const selectSearchResultData = createSelector(
  [selectDomain],
  answerState =>  answerState.searchResultData,
)
export const selectCategoryData = createSelector(
  [selectDomain],
  answerState =>  answerState.categoryData,
)
export const selectError = createSelector(
  [selectDomain],
  questionState => questionState.error
)
export const selectPageSize = createSelector(
  [selectDomain],
  answerState => answerState.page_size
)
export const selectPageNo = createSelector(
  [selectDomain],
  answerState => answerState.page_no
)
export const selectAddUserAnsData = createSelector(
  [selectDomain],
  answerState => answerState.addUserAnsData
)

export const selectTotalAnswer = createSelector(
  [selectDomain],
  answerState => answerState.totalAnswers
)

export const selectAddAnswer = createSelector(
  [selectDomain],
  answerState =>  answerState.addAnswer,
)

export const selectAddMoreAnswer = createSelector(
  [selectDomain],
  answerState => answerState.addMoreAnswers
)
export const selectQuestionId = createSelector(
  [selectDomain],
  answerState => answerState.questionId
)
export const selectLoading = createSelector(
  [selectDomain],
  answerState => answerState.loading
)
export const selectAnswerMediaUrl = createSelector(
  [selectDomain],
  answerState => answerState.answerMediaUrl
)
export const selectAnswerMediaKey = createSelector(
  [selectDomain],
  answerState => answerState.answerMediaKey
)
export const selectUserFound = createSelector(
  [selectDomain],
  answerState => answerState.userFound
)
export const selectIsModalOpen = createSelector(
  [selectDomain],
  answerState => answerState.isModalOpen
)