import { createSlice } from 'utils/@reduxjs/toolkit';

import { InitialQuestionState } from './types';

export const initialState: InitialQuestionState = {
  category: '',
  questionId: '',
  loading: false,
  addQuesloading: false,
  error: null,
  questions: [],
  totalQuestions: 0,
  categories: [],
  addQuestion: {
    question: '',
    body: '',
    category_id: '',
    videoLength: '',
  },
  addSuccessData: null,
  checkQuestionResponse: '',
  page_no: 0,
  page_size: 10,
  addMoreQuestions: false,
  stopFetch: false,
  userData: [],
  fileUploading: false,
  answerMediaUrl: '',
  answerMediaKey: '',
  questionFullData: [],
  userNameTable: [],
  selectedUserId: '',
  questionNameTable: [],
  selectedQuestionId: '',
  introductionVideoQuestionId: ''
}

const getQueSlice = createSlice({
  name: 'questionState',
  initialState,
  reducers: {
    getQuestion(state) {
      state.stopFetch ?
        state.loading = false :
        state.loading = true;
      state.error = null
    },
    getCategory(state) {
      state.loading = true;
      state.error = null
    },
    getAnswerCategory(state) {
      state.loading = true;
      state.error = null
    },
    getQuestionSuccess(state, action) {
      // state.questions = action.payload;
      if (action.payload.getIsQuestionAdd) {
        state.questions = state.questions.concat(action.payload.questions)
      } else {
        state.questions = action.payload.questions;
      }
      console.log("action.payload.meta", action.payload.meta[0].total)
      state.totalQuestions = action.payload.meta[0].total
      state.loading = false;
      state.error = null;
    },
    getQuestionFailure(state, action) {
      state.loading = false;
      state.error = action.payload
    },
    getCategorySuccess(state, action) {
      state.categories = action.payload;
      state.loading = false;
      state.error = null
    },
    getCategoryFailure(state, action) {
      state.loading = false;
      state.error = action.payload
    },
    getQuestionId(state, action) {
      state.loading = true;
      state.error = null;
      state.questionId = action.payload
    },
    getQuestionIdSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.questions[state.questions.findIndex(x => x.id === state.questionId)].is_blocked = !state.questions[state.questions.findIndex(x => x.id === state.questionId)].is_blocked
    },
    getQuestionIdFailure(state, action) {
      state.loading = false;
      state.error = action.payload
    },
    setStopFetching(state, action) {
      state.stopFetch = action.payload
    },
    getDeleteQuestion(state, action) {
      state.loading = true;
      state.questionId = action.payload
      state.error = null
    },
    getDeleteQuestionSuccess(state, action) {
      state.loading = false;
      state.error = action.payload.message;
      let index = state.questions.findIndex(function (o) {
        return o.id === action.payload.questionId
      })
      if (index !== -1) {
        state.questions.splice(index, 1)
        state.totalQuestions -= 1
      }
      // state.questions = state.questions.filter(x=> x.id !== state.questionId)
    },
    getDeleteQuestionFailure(state, action) {
      state.loading = false;
      state.error = null
    },
    getAddQuestion(state, action) {
      state.loading = true;
      state.addQuesloading = true;
      state.error = null;
    },
    getAddedQuestion(state, action) {
      state.addQuestion.question = action.payload
    },
    getAddedBody(state, action) {
      state.addQuestion.body = action.payload
    },
    getAddedCategoryId(state, action) {
      state.addQuestion.category_id = action.payload
    },
    getAddedVideoLength(state, action) {
      state.addQuestion.videoLength = action.payload
    },
    getAddQuestionSuccess(state, action) {
      state.loading = false;
      state.addQuesloading = false;
      state.error = action.payload.message;
      state.addSuccessData = action.payload.question;
    },
    setAddQuestionLoading(state, action) {
      state.addQuesloading = action.payload
    },
    getAddQuestionFailure(state, action) {
      state.loading = false;
      state.addQuesloading = false;
      state.error = action.payload;
    },
    getCategoryId(state, action) {
      state.category = action.payload
    },
    getPageSize(state, action) {
      state.page_size = action.payload
    },
    getPageNo(state, action) {
      state.page_no = action.payload
    },
    getError(state, action) {
      state.error = action.payload
    },
    clearFormData(state) {
      state.addQuestion.question = ''
      state.addQuestion.body = ''
      state.addQuestion.videoLength = ''
      state.addQuestion.category_id = ''
      state.loading = false
    },
    checkQuestions(state, action) {
      state.loading = true;
    },
    checkQuestionSuccess(state, action) {
      state.checkQuestionResponse = action.payload.message
      state.loading = false;
    },
    checkQuestionError(state, action) {
      state.error = action.payload.message
      state.loading = false;
    },
    uploadAnswer(state, action) {
      state.loading = true;
    },
    uploadAnswerSuccess(state, action) {
      state.loading = false;
      state.fileUploading = true
      state.answerMediaUrl = action.payload.mediaUrl
      state.answerMediaKey = action.payload.mediaKey
    },
    resetAnswerModal(state, action) {
      state.fileUploading = action.payload;
      state.answerMediaUrl = ''
      state.answerMediaKey = ''
    },
    uploadAnswerError(state, action) {
      state.error = action.payload.message
      state.loading = false;
    },
    uploadAnswerVideo(state, action) {
      state.loading = true;
    },
    uploadAnswerVideoSuccess(state, action) {
      state.loading = false;
    },
    uploadAnswerVideoError(state, action) {
      state.error = action.payload.message
      state.loading = false;
    },
    getUser(state) {
      state.loading = true;
    },
    getUserSuccess(state, action) {
      let userData = action.payload.users.map((item) => {
        if (item?.fullName) {
          let obj = {
            id: item.id,
            name: item?.fullName
          }
          return obj
        }
      })
      userData = userData.filter(function (element) {
        return element !== undefined;
      });
      state.userData = userData
      let userNameTable = userData.map((item) => {
        let obj = {
          label: item.name
        }
        return obj;
      })
      state.userNameTable = userNameTable
      // state.checkQuestionResponse= action.payload.message
      state.loading = false;
    },
    setUserId(state, action) {
      state.userData.map((item) => {
        if (item.name == action.payload) {
          state.selectedUserId = item.id
        }
      })
    },

    getQuestionFull(state) {
      state.loading = true;
    },
    getQuestionFullSuccess(state, action) {
      let questionFullData = action.payload.questions.map((item) => {
        if (item?.name) {
          let obj = {
            id: item.id,
            name: item?.name
          }
          return obj
        }
      })
      questionFullData = questionFullData.filter(function (element) {
        return element !== undefined;
      });
      state.questionFullData = questionFullData
      state.questionNameTable = questionFullData.map((item) => {
        let obj = {
          label: item.name
        }
        return obj;
      })
      // state.checkQuestionResponse= action.payload.message
      state.loading = false;
    },
    setQuestionFullId(state, action) {
      state.questionFullData.map((item) => {
        if (item.name == action.payload) {
          state.selectedQuestionId = item.id
        }
      })
    },
    getUserError(state, action) {
      state.error = action.payload.message
      state.loading = false;
    },
    clearCheckQuestion(state) {
      state.error = null;
      state.checkQuestionResponse = "";
    },
    clearAddData(state) {
      state.error = null;
      state.addSuccessData = null;
      state.checkQuestionResponse = '';
    },
    setPageNoIncrement(state) { state.page_no += 1 },
    setAddMoreQuestions(state, action) {
      state.addMoreQuestions = action.payload
      if (action.payload) {
       // state.page_no += 1;
      }
      if (!action.payload) {
        state.page_no = 0
        state.stopFetch = action.payload
      }
    },
    getUserCountsSuccess(state, action) {
      console.log("media-payload", action.payload)
      state.totalQuestions = action.payload.question
    },
  }
})
export const { actions, name: sliceKey, reducer } = getQueSlice