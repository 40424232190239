import { RootState } from 'types';

import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.checkAvailability || initialState;

export const selectCheckAvailability = createSelector(
  [selectDomain],
  checkAvailabilityState => checkAvailabilityState.checkIsAvailable,
);

export const selectModuleType = createSelector(
  [selectDomain],
  checkAvailabilityState => checkAvailabilityState.moduleType,
);

export const selectListAvailability = createSelector(
  [selectDomain],
  checkAvailabilityState => {
    return Object.values(checkAvailabilityState.list).flat()
  }
);

export const getIsSomeOneOnCurrentModule = createSelector(
  [selectDomain],
  checkAvailabilityState => {
    return checkAvailabilityState.isEditing
  }
);