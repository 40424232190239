import { createSlice } from "utils/@reduxjs/toolkit";
import { InitialMessageState } from "./types";

export const initialState: InitialMessageState = {
  filters: {
    startDate: '',
    endDate: '',
    fromUser: '',
    toUser: '',
    pageNo: 0,
    pageSize: 10,
    toUserId: '',
    fromUserId: '',
    orderBy: -1 || null,
    orderByField: "dateTime" || null
  },
  error: null,
  loading: false,
  rows: [],
  totalMessages: 0,
  toUserId: '',
  fromUserId: '',
  fromUserData: [],
  toUserData: [],
  addMoreMessages: false,
  withoutFilterData: []
};

const getMsgOnSlice = createSlice({
  name: 'messageState',
  initialState,
  reducers: {
    sendMessageToAll(state, action) {
      state.loading = true;
      state.error = null;
    },
    sendMessageToAllSuccess(state) {
      state.loading = false;
      state.error = null;
    },
    getMessage(state) {
      state.loading = true;
      state.error = null;
    },
    getDateFrom(state, action) {
      state.filters.startDate = action.payload
    },
    getDateTo(state, action) {
      state.filters.endDate = action.payload;

    },
    getFromUser(state, action) {
      state.filters.fromUser = action.payload;

    },
    getToUser(state, action) {
      state.filters.toUser = action.payload;

    },
    getPageSize(state, action) {
      state.filters.pageSize = action.payload;

    },
    getPageNo(state, action) {
      state.filters.pageNo = action.payload;

    },
    getMessageSuccess(state, action) {
      // state.pageNo = action.payload;
      // state.pageSize = action.payload;
      let fromUserData;
      let toUserData;
      if (action.payload.isAddMoreMessages) {
        console.log("data-loading");

        state.rows = [...state.rows, ...action.payload.response.data];
        state.totalMessages = action.payload.response.meta[0] ? action.payload.response.meta[0].total : 0
        fromUserData = action.payload.response.withoutFilterData.map((item) => {
          if (item.fromUserName) {
            return item.fromUserName
          }
        })
        toUserData = action.payload.response.withoutFilterData.map((item) => {
          if (item.toUserName) {
            return item.fromUserName
          }
        })
      } else {
        state.rows = action.payload.data;
        state.totalMessages = action.payload.meta[0] ? action.payload.meta[0].total : 0
        fromUserData = action.payload.withoutFilterData.map((item) => {
          if (item.fromUserName) {
            return item.fromUserName
          }
        })
        toUserData = action.payload.withoutFilterData.map((item) => {
          if (item.toUserName) {
            return item.fromUserName
          }
        })
      }

      state.withoutFilterData = action.payload.isAddMoreMessages ? action.payload.response.withoutFilterData : action.payload.withoutFilterData
      fromUserData = Array.from(new Set(fromUserData))
      toUserData = Array.from(new Set(toUserData))
      state.fromUserData = fromUserData.filter(function (element) {
        return element !== undefined;
      });
      state.fromUserData = [...state.fromUserData, 'All']
      state.toUserData = toUserData.filter(function (element) {
        return element !== undefined;
      });
      state.toUserData = [...state.toUserData, 'All']
      // let idxFrom = fromUserData.indexOf('EMPTY')
      // let idxTo = toUserData.indexOf('EMPTY')
      // state.fromUserData = idxFrom!=-1 ? fromUserData.splice(idxFrom,1) : fromUserData
      // state.toUserData = idxTo!=-1 ? toUserData.splice(idxTo,1) : toUserData
      // state.fromUserData = Array.from(new Set(fromUserData))
      // state.toUserData = Array.from(new Set(toUserData))

      state.loading = false;
      state.error = null;

    },
    getMessageFailure(state, action) {
      state.error = action.payload.message;
      state.loading = false;
    },
    setPageNoIncrement(state) {
      state.filters.pageNo += 1
    },
    setAddMoreMessages(state, action) {
      state.addMoreMessages = action.payload
      if (action.payload) {
        // state.filters.pageNo +=1;
      } else {
        state.filters.pageNo = 0;
      }
    },

    setSortValues(state, action) {
      if (action.payload && action.payload.sort) {
        state.filters = {
          ...state.filters,
          orderBy: action.payload.sort == 'asc' ? -1 : 1,
          orderByField: action.payload.field
        }
      } else {
        state.filters = {
          ...state.filters,
          orderBy: null,
          orderByField: null
        }
      }
      state.addMoreMessages = false
    },
  }
})

export const { actions, reducer, name: sliceKey } = getMsgOnSlice;
