/**
 * Homepage selectors
 */

import { RootState } from 'types';

import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';

// TODO: Add an explanation for this
const selectDomain = (state: RootState) => state.mediaState || initialState;

export const selectLoading = createSelector(
  [selectDomain],
  mediaState => mediaState.loading,
);

export const selectError = createSelector(
  [selectDomain],
  mediaState => mediaState.error,
);

export const selectAuthState = createSelector(
  [selectDomain],
  mediaState => mediaState,
);

export const selectMediaForm = createSelector(
  [selectDomain],
  mediaState => mediaState.form,
);

export const selectAddSuccess = createSelector(
  [selectDomain],
  mediaState => mediaState.addSuccess,
);

export const selectUploadUrl = createSelector(
  [selectDomain],
  mediaState => mediaState.uploadUrl,
);
export const selectImageUploadUrl = createSelector(
  [selectDomain],
  mediaState => mediaState.imageUploadUrl,
);
export const selectprofileVideoFormData = createSelector(
  [selectDomain],
  mediaState => mediaState.profileVideoFormData,
);
export const selectprofileImageFormData = createSelector(
  [selectDomain],
  mediaState => mediaState.profileImageFormData,
);

export const selectFile = createSelector(
  [selectDomain],
  mediaState => mediaState.file,
);

export const selectDownloadUrl = createSelector(
  [selectDomain],
  mediaState => mediaState.downloadUrl,
);