import DataTableComponent from 'app/components/DataTableComponent';
import DropDownComponent from 'app/components/DropDownComponent';
import Loader from 'app/components/Loader';
import attach from 'assets/attach.svg';
import closeSvg from 'assets/close.svg';
import downloadSvg from 'assets/download.svg';
import Remove from 'assets/remove.svg';
import uploadSvg from 'assets/upload.svg';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uploadFile } from 'utils/s3Helper';

import {
    Autocomplete, Box, Button, CircularProgress, Fab, Modal, TextField, Typography
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';

import searchIcon from '../../../assets/search-icon.png';
import playButton from '../../../assets/videoPlay.png';
import { selectCheckAvailability } from '../availabilitySaga/selectors';
import { selectFile, selectUploadUrl } from '../mediaSaga/selectors';
import { actions as mediaActions } from '../mediaSaga/slice';
import {
    selectCategories, selectQuestionFullData, selectQuestionNameTable
} from '../Questions/selectors';
import { actions as questionActions } from '../Questions/slice';
import AddAnswerModal from './commonComponents/AddAnswerModule';
import IOSSwitchComponent2 from './commonComponents/IOSSwitchComponent';
import PopOverDiv from './commonComponents/PopOver';
import ShowUserProfile from './commonComponents/ShowUserProfile';
import {
    selectAddAnswer, selectAnswerCategory, selectAnswers, selectIsModalOpen, selectLoading,
    selectQuestionName, selectTotalAnswer, selectUserFound
} from './selectors';
import { actions } from './slice';
import { useStyles } from './styles';
import { getIsSomeOneOnCurrentModule } from '../availabilitySaga/selectors';

type Props = {
    label?: string,
    array?: any,
    setUser?: any,
    user?: string,
    wd?: string | any,
    setPersonName?: any,
    personName?: any
};

export const validPhone = phone => {
    if (!phone?.trim()) {
        return 'Phone cannot be empty';
    }
    const regPhone = /\+?(\d[\d-. ]+)?(\([\d-. ]+\))?[\d-. ]+\d$/;

    if (!regPhone.test(phone) || phone.length < 9 || phone.length > 12) {
        return 'Please enter valid phone number';
    }
    return null;
};

const Answers = (props: Props) => {

    const columns: GridColDef[] = [
        {
            field: "dateTime",
            headerName: "Date",
            flex: 1,
        },
        { field: "fromUser", headerName: "Full Name", flex: 1 },
        { field: "questionId", headerName: "Question ID", flex: 1 },
        { field: "message", sortable: false, headerName: "Question Name", flex: 1 },
        { field: "answers", sortable: false, headerName: "Video Length", flex: 1 },
        { field: "category", sortable: false, headerName: "Category", flex: 1, renderCell: e => e.row.category.category },
        { field: "status", sortable: false, headerName: "Status", flex: 1 },
        {
            field: "actions", headerName: "Actions", sortable: false, minWidth: 110, flex: 1,
            renderCell: e => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            flex: 0.7,
                            justifyContent: 'space-between',
                        }}
                    >
                        <img
                            src={Remove}
                            alt="removeSvg"
                            style={{ cursor: (isEnabled && !isEditing) ? 'pointer' : 'default' }}
                            onClick={r => (isEnabled && !isEditing) && handleClick(r, e.row)}
                            className={styles.imgTag}
                        />
                        <img
                            src={downloadSvg}
                            onClick={r => handleDownload(r, e.row)}
                            alt="downloadSvg"
                            className={styles.imgTagD}
                        />
                        <img
                            src={playButton}
                            onClick={r => handlePlayVideo(r, e.row)}
                            alt="playSvg"
                            className={styles.playTagD}
                        />
                        <IOSSwitchComponent2 disabled={!isEnabled} header={e.row.status === 'ENABLED' ? "Disable" : "Enable"} isChecked={e.row.status === 'ENABLED'} onChangeToggle={() => console.log(e.row)} onSubmitToggle={() => handleOnChangeSubmit(e.row)} isEditing={isEditing} />
                    </div>
                );
            },
        },
        { field: "abuseReports", sortable: false, headerName: "Abuse Reports", flex: 1 },
    ];

    const styles = useStyles()
    const StatusData = [
        { label: 'ENABLE' },
        { label: 'DISABLE' }
    ]

    const dispatch = useDispatch()
    const rows = useSelector(selectAnswers)
    const totalAnswers = useSelector(selectTotalAnswer)
    const isEnabled = useSelector(selectCheckAvailability)
    const CategoryData = useSelector(selectCategories)
    const loading = useSelector(selectLoading)
    const isModalOpen = useSelector(selectIsModalOpen);
    const isEditing = useSelector(getIsSomeOneOnCurrentModule);
    const answerCategoryId: string[] | undefined = useSelector(selectAnswerCategory);

    useEffect(() => {
        dispatch(actions.getAnswer())
        return () => { }
    }, [dispatch])

    useEffect(() => {
        if (CategoryData.length > 0) {
            let nameData = CategoryData.map((item) => {
                return item.name
            });
            nameData = Array.from(new Set(nameData))
        }

    }, [CategoryData])


    useEffect(() => {
        dispatch(actions.setCategoryArr([]))
        dispatch(actions.setAddMoreAnswers(false))
        dispatch(actions.getAnswer())
    }, [])


    const onStatusSelect = (e: any) => {
        if (e.target.innerText) {
            dispatch(actions.setStatus(e.target.innerText))
            dispatch(actions.setAddMoreAnswers(false))
            dispatch(actions.getAnswer())
        } else {
            dispatch(actions.setStatus(''))
            dispatch(actions.setAddMoreAnswers(false))
            dispatch(actions.getAnswer())
        }
    }

    const onQuestionSelect = (e: any, data?: any) => {
        dispatch(actions.setSearchQuestionQuery(e.target.value))
        dispatch(actions.setAddMoreAnswers(false))
        dispatch(actions.getAnswer())
    }
    const [deleteId, setDeleteId] = useState<string>('');
    const [openProfileModal, setOpenProfileModal] = useState(false)
    const [selectedProfileData, setSelectedProfileData] = useState({
        isVideoSelected: false,
        url: ''
    })

    const [anchor, setAnchor] = useState<any>(null);
    const handleDelete = () => {
        dispatch(actions.deleteAnswer({ id: deleteId }));
        setAnchor(null);
    };

    const handleOnChangeSubmit = (row) => dispatch(actions.updateAnswer({ id: row.id }));

    const handleClick = (event, row) => {
        setDeleteId(row.id);
        setAnchor(event.currentTarget);
    };

    const handleDownload = (event, row) => {
        dispatch(mediaActions.getDownloadUrl({ key: row.video, name: "_" + (row.video.split("/")[(row.video.split("/").length - 1)]) }));
    };

    const handlePlayVideo = (event, row) => {
        if (row?.tag) {
            setOpenProfileModal(true)
            setSelectedProfileData({
                isVideoSelected: true,
                url: row?.tag
            })
        }
    };

    const handleCloseEl = () => {
        setAnchor(null);
    };

    const [answerModalData, setAnswerModalData] = useState({
        questionId: '',
        videoUrl: ''
    })

    const setDataCategory = e => {
        dispatch(actions.setCategoryArr(e == "" ? [] : [e]))
        dispatch(actions.setAddMoreAnswers(false))
        dispatch(actions.getAnswer())
    };

    return (
        <div className={styles.main} >
            <div className={styles.heading} >
                <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                    <div>
                        <DropDownComponent
                            array={[...CategoryData, { id: "", name: "All" }]}
                            widthNo={'13rem'}
                            label="Category"
                            isCategory={true}
                            setUser={setDataCategory}
                            user={answerCategoryId ? answerCategoryId[0] : ''}
                        />
                    </div>
                    <Autocomplete
                        onChange={(e) => onStatusSelect(e)}
                        size='small'
                        disablePortal
                        filterOptions={(x) => x}
                        autoComplete
                        id="combo-box-demo"
                        options={StatusData}
                        sx={{ width: '20%', m: 2 }}
                        renderInput={(params) => <TextField {...params} label="Status" />}
                    />
                    <input className={styles.searchInput} type={'text'} onChange={(e) => onQuestionSelect(e)} placeholder="Enter Question name" /><img className={styles.searchIcon} src={searchIcon} alt='searchicon' />
                </div>
                <Fab sx={{ backgroundColor: '#0EC8E1' }} aria-label="like">
                    <Typography sx={{ colors: '#086ED6', fontSize: 22, fontWeight: '500' }}>{totalAnswers}</Typography>
                </Fab>
            </div>

            <AddAnswerModal openModal={isModalOpen} updateAnswer={setAnswerModalData} updateModalData={answerModalData} />
            <div className={styles.tableData}>
                <div style={{ height: '100%', width: '100%' }}>
                    <DataTableComponent
                        rows={rows}
                        columns={columns}
                        header
                        loading={loading}
                        mainType={'ANSWER'}
                        rowCount={totalAnswers}
                        pageCount={rows.length}
                        onPageChange={(dummypageNo) => {
                            dispatch(actions.setAddMoreAnswers(true))
                            dispatch(actions.getAnswer())
                        }}
                        onPageSizeChange={(pageSize) => {
                            dispatch(actions.getAnswer())
                        }} />
                </div>
            </div>
            <ShowUserProfile isVideo={selectedProfileData?.isVideoSelected} openProfileModal={openProfileModal} setOpenProfileModal={setOpenProfileModal} url={selectedProfileData?.url} />
            <PopOverDiv
                anchor={anchor}
                handleCloseEl={handleCloseEl}
                handleDelete={handleDelete}
                handleClose={handleCloseEl}
            />
            <Fab
                className={styles.fabBtn}
                onClick={() => dispatch(actions.openCloseModal(!isModalOpen))}
                aria-label="like"
            >
                <Typography sx={{ color: '#fff', fontSize: 32, fontWeight: '500' }}>
                    +
                </Typography>
            </Fab>
        </div>
    );
};

export default Answers;
