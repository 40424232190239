import { select } from 'redux-saga/effects';
import { APP_API_URL } from 'utils/constants';
import { request } from 'utils/request';

import { call, delay, put, takeLatest } from '@redux-saga/core/effects';

import {
  selectAddMoreReports, selectPageNo, selectReportedIdsData, selectReporterIdsData,
  selectreportFilteres
} from './selector';
// import { selectAddMoreAnswer, selectAnswerState } from "./selectors";
import { actions } from './slice';

export function* getAbuseReportReq() {
  yield delay(500)
  const addMoreData = yield select(selectAddMoreReports);
  const pageNo = yield select(selectPageNo);
  const reporterIdsData = yield select(selectReporterIdsData);
  const reportedIdsData = yield select(selectReportedIdsData);
  const reportFilters = yield select(selectreportFilteres);
  const options = {
    method: 'POST',
    body: JSON.stringify(
      {
        "page_no": pageNo + 1,
        "page_size": 10,
        "reporterIds": reporterIdsData?.length > 0 ? reporterIdsData : null,
        "reportedIds": reportedIdsData?.length > 0 ? reportedIdsData : null,
        "orderBy": reportFilters?.orderBy,
        "orderByField": reportFilters?.orderByField == 'dateTime' ? 'createdAt' : reportFilters?.orderByField,
      }
    ),
  };
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/abuse-reports/admin/get`,
      options
    )
    yield put(actions.getAbuseReportSuccess(response))
  }
  catch (err: any) {
    yield put(actions.getAbuseReportFailure(err))
  }
}

export function* userAbuseReport() {
  yield takeLatest(actions.getAbuseReport.type, getAbuseReportReq)
}