import { createSlice } from 'utils/@reduxjs/toolkit';

import { InitialAnswerState } from './types';

export const initialState: InitialAnswerState = {
  category: [],
  loading: false,
  error: null,
  answerData: [],
  totalAnswers: 0,
  addAnswer: {
    question_id: '',
    category_id: '',
    mobile_no: '',
    email: '',
    videoLength: '',
  },
  page_no: 0,
  page_size: 10,
  addMoreAnswers: false,
  questionsData: [],
  questionsDataForSearch: [],
  categoryData: [],
  questionId: '',
  status: '',
  searchResultData: [],
  questionNameTable: [],
  fileUploading: false,
  answerMediaUrl: '',
  answerMediaKey: '',
  userFound: false,
  isModalOpen: false,
  searchQuestion: '',
  filters: {
    orderBy: -1 || null,
    orderByField: "dateTime" || null
  },
  addUserAnsData: {
    questionId: '', videoUrl: '', videoLength: '', email: '', mobile_no: 0
  }
}

const getAnsSlice = createSlice({
  name: 'answerState',
  initialState,
  reducers: {
    getAnswer(state) {
      state.loading = true;
      state.error = null
    },
    getQuestions(state) {
      state.loading = true;
      state.error = null
    },

    openCloseModal(state, action) {
      state.error = null;
      state.isModalOpen = action.payload;
    },

    getAnswerSuccess(state, action) {
      // state.questions = action.payload;
      if (action.payload.data) {

        console.log("reponse-data", action.payload)
        let answerData: any[] = []
        let categoryData: any[] = []
        if (action.payload.getIsAnswerAdd) {
          answerData = [...state.answerData, ...action.payload.data]
          categoryData = answerData.map((item) => {
            if (item?.category) {
              return item.category
            }
          })
        } else {
          answerData = action.payload.data

          categoryData = action.payload.data.map((item) => {
            if (item?.category) {
              return item.category
            }
          })
        }
        state.answerData = answerData
        state.categoryData = categoryData
      }
      state.totalAnswers = action.payload.meta.length > 0 ? action.payload.meta[0].total : 0
      state.loading = false;
      state.error = null;
    },
    getAnswerFailure(state, action) {
      state.loading = false;
      state.error = action.payload
    },
    getQuestionFullSuccess(state, action) {
      let questionFullData = action.payload.questions.map((item) => {
        if (item?.name) {
          let obj = {
            id: item.id,
            name: item?.name
          }
          return obj
        }
      })
      questionFullData = questionFullData.filter(function (element) {
        return element !== undefined;
      });
      state.questionsData = questionFullData
      state.questionNameTable = questionFullData.map((item) => {
        let obj = {
          label: item.name
        }
        return obj;
      })
      // state.checkQuestionResponse= action.payload.message
      console.log('state.loading', state.loading);

      state.loading = false;
    },
    uploadAnswer(state, action) {
      state.loading = true;
    },
    uploadAnswerSuccess(state, action) {
      state.loading = false;
      state.fileUploading = true
      state.answerMediaUrl = action.payload.mediaUrl
      state.answerMediaKey = action.payload.mediaKey
    },
    resetAnswerModal(state, action) {
      state.fileUploading = action.payload;
      state.answerMediaUrl = ''
      state.answerMediaKey = ''
    },
    resetAnswerModalForm(state, action) {
      state.addAnswer = {
        question_id: '',
        category_id: '',
        mobile_no: '',
        email: '',
        videoLength: '',
      }
    },
    uploadAnswerError(state, action) {
      state.error = action.payload.message
      state.loading = false;
    },
    uploadAnswerVideo(state, action) {
      state.loading = true;
    },
    checkUser(state, action) {
      state.loading = true;
    },
    uploadAnswerVideoSuccess(state, action) {
      state.loading = false;
      state.isModalOpen = false;
      state.userFound = false;
      state.addAnswer = {
        question_id: '',
        category_id: '',
        mobile_no: '',
        email: '',
        videoLength: '',
      }
      state.addUserAnsData.email = ''
      state.addUserAnsData.questionId = '';
      state.addUserAnsData.videoLength = '';
      state.addUserAnsData.videoUrl = '';
      state.addUserAnsData.mobile_no = 0;
    },
    uploadAnswerVideoError(state, action) {
      state.error = action.payload.message
      state.loading = false;
      state.addAnswer = {
        question_id: '',
        category_id: '',
        mobile_no: '',
        email: '',
        videoLength: '',
      }
    },
    checkUserSuccess(state, action) {
      // state.loading = false;
      state.isModalOpen = false;
      state.userFound = !(action.payload.message === 'NO USER FOUND');
    },
    checkUserError(state, action) {
      state.error = action.payload.message
      state.loading = false;
    },
    getQuestionFailure(state, action) {
      state.loading = false;
      state.error = action.payload
    },
    setQuestionId(state, action) {
      // state.addAnswer.question_id = action.payload
      state.questionsData.map((item) => {
        if (item.name == action.payload) {
          state.addAnswer.question_id = item.id
        }
      })
    },
    setSearchQuestionQuery(state, action) {
      state.searchQuestion = action.payload;
    },
    setCategoryId(state, action) {
      state.addAnswer.category_id = action.payload;
    },
    setQuestionMobileNumber(state, action) {
      state.addAnswer.mobile_no = action.payload;
    },
    setQuestionEmail(state, action) {
      state.addAnswer.email = action.payload;
    },
    deleteAnswer(state, action) {
      state.loading = true;
      state.error = null
    },
    deleteAnswerSuccess(state, action) {
      state.loading = false;
      state.error = null
      // action.payload.deletedAnswerId

      // let index = state.answerData.findIndex(function (o) {
      //   return o.id === action.payload.deletedAnswerId
      // })
      // if (index !== -1) {
      //   state.answerData.splice(index, 1)
      //   state.totalAnswers = state.totalAnswers - 1
      // }
      state.answerData = state.answerData.filter((f, index) => {
        return f._id !== action.payload.deletedAnswerId
      })
      state.totalAnswers = state.answerData.length
    },
    deleteAnswerFailure(state, action) {
      state.loading = false;
      state.error = action.payload
    },
    updateAnswer(state, action) {
      state.loading = true;
      state.error = null
    },
    updateAnswerSuccess(state, action) {
      state.loading = false;
      state.error = null
      if (action.payload.id) {
        state.answerData.map((item) => {
          if (item?.id == action.payload?.id) {
            if (item?.status == 'ENABLED') {
              state.answerData[state.answerData.findIndex(x => x.id === action.payload?.id)].status = 'DISABLED'
            } else {
              state.answerData[state.answerData.findIndex(x => x.id === action.payload?.id)].status = 'ENABLED'
            }
          }
        })
      }
      // state.questions[state.questions.findIndex(x=>x.id===state.questionId)].is_blocked = !state.questions[state.questions.findIndex(x=>x.id===state.questionId)].is_blocked
      // state.answerData[state.answerData.findIndex(x=>x.id===state.questionId)].status =='ENABLED' ?
      // state.answerData[state.answerData.findIndex(x=>x.id===state.questionId)].status = 'DISABLED' : 'ENABLED'
    },
    updateAnswerFailure(state, action) {
      state.loading = false;
      state.error = action.payload
    },
    setStatus(state, action) {
      state.status = action.payload
    },
    setCategoryArr(state, action) {
      state.category = action.payload
    },
    getSearchResult(state, action) {

    },
    setQuestionData(state, action) {
      state.questionsData = action.payload;
    },

    setQuestionDataForSearch(state, action) {
      state.questionsDataForSearch = action.payload;
    },
    setQuestionTableData(state, action) {
      state.questionNameTable = action.payload;
    },
    getSearchResultSuccess(state, action) {
      console.log("action-payload", action.payload)
      state.questionsDataForSearch = action.payload.data.map((item) => {
        if (item?.questionName) {
          item.messages = { label: item?.questionName, id: item?.questionId }
          return item.messages
        }
      })
      state.searchResultData = action.payload.data
    },
    getSearchResultFailure(state, action) {
      console.log("err")
    },
    setPageNoIncrement(state) { state.page_no += 1 },
    setAddMoreAnswers(state, action) {
      state.addMoreAnswers = action.payload
      if (action.payload) {
        // state.page_no += 1
      } else {
        state.page_no = 0
      }
    },
    getUserCountsSuccess(state, action) {
      console.log("media-payload", action.payload)
      state.totalAnswers = action.payload.answer
    },
    setSortValues(state, action) {
      if (action.payload && action.payload.sort) {
        state.filters = {
          ...state.filters,
          orderBy: action.payload.sort == 'asc' ? -1 : 1,
          orderByField: action.payload.field
        }
        state.addMoreAnswers = false
      } else {
        state.filters = {
          ...state.filters,
          orderBy: null,
          orderByField: null
        }
        state.addMoreAnswers = false
      }
    },
    setAddUserAnswerData(state, action) {
      state.addUserAnsData = action.payload
    }


  }
})
export const { actions, name: sliceKey, reducer } = getAnsSlice