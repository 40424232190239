import { colors, createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    background: {
      default: colors.common.white,
      paper: colors.common.white,
    },
    primary: {
      main: '#0EC8E1',
    },
    secondary: {
      main: "#3C4858",
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
    },

  },
  typography: {
    allVariants: {
      textTransform: 'none'
    }
  },
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        contained: {
          // Some CSS
          background: 'linear-gradient(90deg, #0EC8E1 0.06%, #0866D5 99.99%)'
        },
      },
    },
  },
});

export default theme;
