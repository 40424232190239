import { selectTotalAbuseReports } from 'app/containers/AbuseReports/selector';
import { selectTotalAnswer } from 'app/containers/Answers/selectors';
import { selectTotalQuestion } from 'app/containers/Questions/selectors';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { Box, Button, Typography } from '@mui/material';

import { actions as questionsManagementActions } from '../../../../containers/Questions/slice';
import { actions as userManagementActions } from '../../../../containers/UserManagement/slice';
import AbuseSvg from './assets/abuse.svg';
import AbuseFocusedSvg from './assets/abuseFocused.svg';
import AdminManagementSvg from './assets/adminManagement.svg';
import AnswersSvg from './assets/answers.svg';
import AnswersFocusedSvg from './assets/answersFocused.svg';
import DashboardSvg from './assets/dashboard.svg';
import DashboardFocusedSvg from './assets/dashboardFocused.svg';
import MessageSvg from './assets/messages.svg';
import MessageFocusedSvg from './assets/messagesFocused.svg';
import QuestionsSvg from './assets/questions.svg';
import QuestionsFocusedSvg from './assets/questionsFocused.svg';
import UserSvg from './assets/user.svg';
import UserFocusedSvg from './assets/userFocused.svg';
import { useStyles } from './styles';
import { LinkItemType } from './types';

export type SidebarTabsProps = {
    backgroundColor: string,
    title: string,
    totalCounts?: number,
    imgSrc: string,
    route: string,
    onClick?: () => void
}

const SidebarTabs = (props: SidebarTabsProps) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const totalQuestions = useSelector(selectTotalQuestion);
    const totalAnswers = useSelector(selectTotalAnswer);
    const totalAbuseData = useSelector(selectTotalAbuseReports);
    const isDashboard = location.pathname === '/dashboard';
    const isUserManagement = location.pathname === '/dashboard/user-management';
    const isAdminSettingManagement = location.pathname === '/dashboard/admin-management';
    const isMessageHistory = location.pathname === '/dashboard/message-history';
    const isQuestions = location.pathname === '/dashboard/questions';
    const isAnswers = location.pathname === '/dashboard/answers';
    const isAbuse = location.pathname === '/dashboard/abuse-reports';

    const { title, route, onClick } = props;
    const resetUserPageNo = () => {
        dispatch(userManagementActions.setUserPageNo(0))
        dispatch(userManagementActions.getPageNo(0))
        dispatch(userManagementActions.setAddMoreUsers(false))
        dispatch(questionsManagementActions.getPageNo(0))
    }

    const linkItems: LinkItemType[] = [
        {
            type: 'DASHBOARD',
            title: 'Dashboard',
            route: '/dashboard',
            backgroundColor: isDashboard ? '#F3F4F6' : '#fff',
            imgSrc: isDashboard ? DashboardFocusedSvg : DashboardSvg,
        },
        {
            type: 'USER_MANAGEMENT',
            title: 'User Management',
            route: '/dashboard/user-management',
            backgroundColor: isUserManagement ? '#F3F4F6' : '#fff',
            onClick: resetUserPageNo,
            imgSrc: isUserManagement ? UserFocusedSvg : UserSvg,
        },
        {
            type: 'MESSAGE_HISTORY',
            title: 'Message History',
            route: '/dashboard/message-history',
            backgroundColor: isMessageHistory ? '#F3F4F6' : '#fff',
            onClick: resetUserPageNo,
            imgSrc: isMessageHistory ? MessageFocusedSvg : MessageSvg,
        },
        {
            type: 'QUESTIONS',
            title: 'Questions',
            route: '/dashboard/questions',
            backgroundColor: isQuestions ? '#F3F4F6' : '#fff',
            onClick: resetUserPageNo,
            imgSrc: isQuestions ? QuestionsFocusedSvg : QuestionsSvg,
            totalCounts: totalQuestions,
        },
        {
            type: 'ANSWERS',
            title: 'Answers',
            route: '/dashboard/answers',
            backgroundColor: isAnswers ? '#F3F4F6' : '#fff',
            onClick: resetUserPageNo,
            imgSrc: isAnswers ? AnswersFocusedSvg : AnswersSvg,
            totalCounts: totalAnswers,
        },
        {
            type: 'ABUSE_REPORT',
            title: 'Abuse Reports',
            route: '/dashboard/abuse-reports',
            backgroundColor: isAbuse ? '#F3F4F6' : '#fff',
            imgSrc: isAbuse ? AbuseFocusedSvg : AbuseSvg,
            totalCounts: totalAbuseData,
        },
        {
            type: 'ADMIN_MANAGEMENT',
            title: 'Admin Management',
            route: '/dashboard/admin-management',
            backgroundColor: isAdminSettingManagement ? '#F3F4F6' : '#fff',
            imgSrc: AdminManagementSvg,
        },
    ]

    const classes = useStyles();
    const selectedUserDetailInfo: LinkItemType = linkItems.find(v => v.title === title) ?? linkItems[0];
    return (
        <Link className={classes.sideDiv} to={route} >
            <Button sx={{
                padding: '1.5rem 10px', backgroundColor: `${selectedUserDetailInfo.backgroundColor}`, width: '100%',
                borderRadius: '20px',
                height: '4vh',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                justifyContent: 'space-between',
                flexDirection: 'row',
                fontFamily: '',
            }} onClick={onClick}>
                <Box
                    sx={{
                        width: '100%',
                        height: '4vh',
                        borderRadius: 10,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }} >
                        <img src={selectedUserDetailInfo.imgSrc} alt="" className={classes.sideLogo} />
                        <Typography variant="body1" component="p" color={'#323232'} lineHeight={"18px"} marginLeft={1} sx={{ fontSize: '1.2rem' }} >
                            {title}
                        </Typography>
                    </div>
                    {selectedUserDetailInfo.totalCounts &&
                        <Box sx={{ backgroundColor: '#0EC8E1', padding: '0.1rem 0.56rem', borderRadius: 10 }} aria-label="like">
                            <Typography sx={{ color: '#086ED6' }}>{selectedUserDetailInfo.totalCounts}</Typography>
                        </Box>
                    }
                </Box>
            </Button>
        </Link>
    )
}

export default SidebarTabs