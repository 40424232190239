import React, { useEffect, useMemo, useState } from 'react';
import { ToastContainer } from 'react-toastify';

import {
    Autocomplete, Box, Button, CircularProgress, Fab, LinearProgress, Modal, Popover, TextField,
    Typography
} from '@mui/material';

type Props = {
    anchor: any
    handleCloseEl: any
    handleDelete: any
    onClose: any
}

const DeletePopOverDiv = ({
    anchor,
    handleCloseEl,
    handleDelete,
    onClose
}: Props) => {
    const open = Boolean(anchor);
    const id = open ? 'simple-popover' : undefined;
    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchor}
            onClose={handleCloseEl}
            sx={{
                display: 'flex',
                flex: 1,
                padding: '2rem',
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Box
                sx={{
                    boxShadow: 0,
                    p: 2,
                    borderRadius: 2,
                }}
            >
                <div>
                    <Typography>Delete This answer ?</Typography>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Button
                            onClick={handleDelete}
                            sx={{
                                backgroundColor: '#12BFE0',
                                color: '#fff',
                                borderRadius: '20px',
                                margin: '10px 0px 0px 0px',
                                '&:hover': {
                                    backgroundColor: '#12bfe0',
                                },
                            }}
                        >
                            {'Delete'}
                        </Button>
                        <Button onClick={onClose}>Close</Button>
                    </div>
                </div>
            </Box>
            <ToastContainer />
        </Popover>
    );
};

export default DeletePopOverDiv