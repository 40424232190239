/*
 * GithubRepoForm Slice
 *
 * Here we define:
 * - The shape of our container's slice of Redux store,
 * - All the actions which can be triggered for this slice, including their effects on the store.
 *
 * Note that, while we are using dot notation in our reducer, we are not actually mutating the state
 * manually. Under the hood, we use immer to apply these updates to a new copy of the state.
 * Please see https://immerjs.github.io/immer/docs/introduction for more information.
 *
 */
import set from 'lodash/set';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { createAction, PayloadAction } from '@reduxjs/toolkit';

import { CheckAvailabilityState } from './types';

export const storageLoad = createAction('REDUX_STORAGE_LOAD');

export const initialState: CheckAvailabilityState = {
  checkIsAvailable: true,
  moduleType: '',
  error: '',
  list: {},
  isEditing: false
};

const checkAvailabilitySlice = createSlice({
  name: 'checkAvailability',
  initialState,
  reducers: {
    setModuleType(state, action: PayloadAction<string>) {
      state.moduleType = action.payload
    },
    setAvailability(state, action: PayloadAction<boolean>) {
      state.checkIsAvailable = action.payload
    },
    getCheckAvailability(state) {
      // checkAvailability status
      state.error = ''
    },
    getCheckAvailabilitySuccess(state) {
      // checkAvailability status
      state.error = ''
    },
    getCheckAvailabilityFailure(state, action: PayloadAction<string>) {
      // checkAvailability status
      state.error = action.payload
    },
    getUpdateAvailability(state) {
      // checkAvailability status
      state.error = ''
    },
    getUpdateAvailabilitySuccess(state) {
      // checkAvailability status
      state.error = ''
    },
    getUpdateAvailabilityFailure(state, action: PayloadAction<string>) {
      // checkAvailability status
      state.error = action.payload
    },

    setAvailabilityError(state, action: PayloadAction<string>) {
      state.error = action.payload
    },

    setList(state, action: PayloadAction<any>) {
      const userId = action.payload.userId
      delete action.payload.data[userId]
      state.list = action.payload.data
    },

    setIsEditing(state, action: PayloadAction<any>) {
      state.isEditing = action.payload.flag
    }

  },
});

export const { actions, reducer, name: sliceKey } = checkAvailabilitySlice;
