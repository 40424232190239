import React from 'react';

const AnsweredQuestions = ({ color }) => {
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.58734 10.4681L0 13.2864V0.805238C0 0.591676 0.0848373 0.38686 0.235849 0.235849C0.38686 0.0848373 0.591676 0 0.805238 0H12.8838C13.0974 0 13.3022 0.0848373 13.4532 0.235849C13.6042 0.38686 13.689 0.591676 13.689 0.805238V10.4681H3.58734ZM3.03011 8.85762H12.0786V1.61048H1.61048V9.97287L3.03011 8.85762ZM5.63667 12.0786H13.8799L15.2995 13.1938V4.83143H16.1048C16.3183 4.83143 16.5231 4.91627 16.6742 5.06728C16.8252 5.21829 16.91 5.4231 16.91 5.63667V16.5074L13.3227 13.689H6.4419C6.22834 13.689 6.02353 13.6042 5.87252 13.4532C5.7215 13.3022 5.63667 13.0974 5.63667 12.8838V12.0786Z" fill={color} />
        </svg>
    )
}
export default AnsweredQuestions;
