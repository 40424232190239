import { ApexOptions } from 'apexcharts';
import React, { useState, memo, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import format from "date-fns/format";
import { Box, Button, TextField, Typography } from '@mui/material';
import { useStyles } from './styles';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import DateFnsUtils from "@date-io/date-fns";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DateComponent from 'app/components/DateComponent';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from './slice';
import { selectData, selectDate } from './selector';


type Props = {
    name?: string | any,
    val?: string | any,

};

const LandingPage = () => {
    const dispatch = useDispatch()
    const date = useSelector(selectDate)
    const data = useSelector(selectData)

    const options: ApexOptions = {
        tooltip: {
            enabled: true,
            // custom: function ({ series, seriesIndex, dataPointIndex }) {
            //     console.log(series[seriesIndex][dataPointIndex], 'series, seriesIndex, dataPointIndex ');
            //     const userCount = series[seriesIndex][dataPointIndex]
            //     return '<div>' +
            //         '<span>' + userCount + '</span>' +
            //         '</div>'

            // }
        },
        chart: {
            height: 350,
            toolbar: {
                show: false,
                tools: {
                    zoom: false,
                    selection: false
                }
            }
        },
        // forecastDataPoints: {
        //     count: 7
        // },
        stroke: {
            width: 5,
            curve: 'smooth'
        },
        xaxis: {

            type: 'category',
            categories: data.keys,
            tickAmount: 7,
            tooltip: {
                enabled: false,
            },
            // labels: {
            //     formatter: function (value, timestamp, opts) {
            //         console.log(opts.dateFormatter(new Date(timestamp || ''), 'dd MMM'), 'timestamp')
            //         return opts.dateFormatter(new Date(timestamp || ''), 'dd MMM')
            //     }
            // }
        },
        title: {
            align: 'left',
            style: {
                fontSize: "16px",
                color: '#666'
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'dark',
                // gradientToColors: ['#1DC5E1', '#5C1ACD'],
                gradientToColors: ['#1DC5E1', '#2E8EDB', '#3E61D6', '#5B25CF', '#5C1ACD'],
                shadeIntensity: 5,
                type: 'horizontal',
                opacityFrom: 1,
                opacityTo: 2,
                stops: [20, 90],
            },
        },
        // yaxis: {
        //     min: -10,
        //     max: 40
        // }
    }

    const obj = {
        series: [{
            name: 'Users',
            data: data.values
        }],
    };

    const styles = useStyles()
    const [value, setValue] = useState<string>()

    // const todayDate = format(new Date(), 'MMMMMM dd, yyyy')
    useEffect(() => {
        dispatch(actions.getDashboard())
        return () => {

        }
    }, [])
    const dateChange = (e) => {
        dispatch(actions.getDate(e))
        dispatch(actions.getDashboard())
    }



    return (
        <div className={styles.content} >
            <Button
                variant="contained"
                disableElevation
                disableRipple
                disableTouchRipple
                sx={{ color: '#fff', fontSize: '1rem', minHeight: '56px',height: '56px', fontWeight: '500', cursor: 'auto', borderRadius: "10px" }}
                className={styles.noOfUser}
            >
                Number of users: {data.noOfUser}
            </Button>
            <Box sx={{ backgroundColor: `#fff`, borderRadius: 3, boxShadow: 2, marginTop: 5 }}>
                <div className={styles.graphHead} >
                    <Typography component="p" color={'#12BFE0'} lineHeight={"18px"} sx={{ fontSize: 18, flex: 1, fontWeight: '600' }} >
                        New Enrollments
                    </Typography>
                    <DateComponent date={date ?? new Date()} setDate={dateChange} name='' wd='15%' landingPage />
                    <div className={styles.dropDowns} >
                    </div>
                </div>
                <ReactApexChart
                    options={options}
                    series={obj.series}
                    width={'100%'}
                    height={350}
                />

            </Box >
        </div >)
};

export default memo(LandingPage);
