
export interface MediaState {
  form: typeof LoginFormDefault;
  uploadUrl:any;
  imageUploadUrl:any;
  loading: boolean;
  addSuccess?: any;
  downloadUrl?:any;
  error?: null;
  file?: File;
  profileVideoFormData: selectProfileVideoData
  profileImageFormData: selectProfileVideoData
}
type selectProfileVideoData = {
  title: string,
  size: number,
  url: string,
}

export const LoginFormDefault = {
    title: "",
    thumbnail: "",
    size: "",
    file_type: "",
    media_type: "",
    url: "",
    userId:""
} ;

/*
  If you want to use 'ContainerState' keyword everywhere in your feature folder,
  instead of the 'HomePageState' keyword.
*/
export type ContainerState = MediaState;
