import { createSelector } from 'reselect';
import { RootState } from 'types';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.questionState || initialState;

export const selectQuestionState = createSelector(
  [selectDomain],
  questionState => questionState
)

export const selectQuestionFullData = createSelector(
  [selectDomain],
  questionState => questionState?.questionFullData
)

export const selectQuestions = createSelector(
  [selectDomain],
  questionState => questionState.questions,
)
export const selectCategories = createSelector(
  [selectDomain],
  questionState => questionState.categories,
)
export const selectCategory = createSelector(
  [selectDomain],
  questionState => questionState.category,
)
export const selectIntroductionVideoQuestionId = createSelector(
  [selectDomain],
  questionState => questionState.introductionVideoQuestionId,
)
export const selectAddQuestion = createSelector(
  [selectDomain],
  questionState => questionState.addQuestion,
)
export const selectError = createSelector(
  [selectDomain],
  questionState => questionState.error
)
export const selectAddSuccessData = createSelector(
  [selectDomain],
  questionState => questionState.addSuccessData
)
export const selectPageSize = createSelector(
  [selectDomain],
  questionState => questionState.page_size
)
export const selectPageNo = createSelector(
  [selectDomain],
  questionState => questionState.page_no
)

export const selectTotalQuestion = createSelector(
  [selectDomain],
  questionState => questionState.totalQuestions
)

export const selectCheckQuestion = createSelector(
  [selectDomain],
  questionState => questionState.checkQuestionResponse
)

export const selectAddMoreQuestion = createSelector(
  [selectDomain],
  questionState => questionState.addMoreQuestions
)
export const selectLoading = createSelector(
  [selectDomain],
  questionState => questionState.loading
)
export const selectQuesLoading = createSelector(
  [selectDomain],
  questionState => questionState.addQuesloading
)
export const selectUserData = createSelector(
  [selectDomain],
  questionState => questionState.userData
)
export const selectFileUploading = createSelector(
  [selectDomain],
  questionState => questionState.fileUploading
)

export const selectUserNameTable = createSelector(
  [selectDomain],
  questionState => questionState.userNameTable
)

export const selectQuestionNameTable = createSelector(
  [selectDomain],
  questionState => questionState.questionNameTable
)
export const selectStopFetch = createSelector(
  [selectDomain],
  questionState => questionState.stopFetch
)
//selectedUserId
export const selectSelectedUserId = createSelector(
  [selectDomain],
  questionState => questionState.selectedUserId
)
//selectedQuestionId
export const selectSelectedQuestionId = createSelector(
  [selectDomain],
  questionState => questionState.selectedQuestionId
)

export const selectAnswerMediaUrl = createSelector(
  [selectDomain],
  questionState => questionState.answerMediaUrl
)
export const selectAnswerMediaKey = createSelector(
  [selectDomain],
  questionState => questionState.answerMediaKey
)
