import { createSlice } from "utils/@reduxjs/toolkit";
import { InitialDashboardState } from "./types";

export const initialState:InitialDashboardState ={
  noOfUser:''||0,
  filterDate:""||undefined,
  keys:[],
  values:[],
  loading:false,
  error:undefined,
}

const getDashboardOnSlice = createSlice({
  name:'dashboardState',
  initialState,
  reducers:{
    getDashboard(state){
      state.loading = true;
      state.error = undefined
    },
    getDashboardSuccess(state,action) {
      state.loading = false;
      state.error = undefined;
      state.noOfUser = action.payload.total_users;
      state.keys = action.payload.users.map(x => x?.date);
      state.values = action.payload.users.map(x => x?.value)
    },
    getDashboardFailure(state,action){
      state.loading = false;
      state.error = action.payload;
      state.noOfUser = action.payload.total_users;
      state.keys = action.payload.users.map(x => x?.date);
      state.values = action.payload.users.map(x => x?.value)
    },
    getDate(state,action){
      state.filterDate = action.payload
    }
  }
})

export const {actions,reducer,name:sliceKey} = getDashboardOnSlice