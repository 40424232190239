import queryString from 'query-string';
import { toast } from 'react-toastify';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { APP_API_URL } from 'utils/constants';
import { request, requestDownloadFromS3 } from 'utils/request';

import { selectMediaForm } from './selectors';
import { actions } from './slice';

export function* addMediaRequest() {
  yield delay(500);
  // Select username from store
  const form: {} = yield select(selectMediaForm);
  const options = {
    method: 'POST',
    body: JSON.stringify(form),
  };
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/media/questions_video`,
      options
    )
    yield put(actions.addMediaSuccess(response));
  } catch (err: any) {
    yield put(actions.addMediaError(err.message));
  }
}

export function* getUploadUrlRequest(action: any) {
  const options = {
    method: 'GET'
  };
  const queries = queryString.stringify({
    fileName: action.payload.fileName,
    contentType: action.payload.contentType
  });
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/media/uploadUrl?${queries}`,
      options
    )
    yield put(actions.getUploadUrlSuccess(response));
  } catch (err: any) {
    yield put(actions.getUploadUrlError(err));
  }
}
export function* getImageUploadUrlRequest(action: any) {
  const options = {
    method: 'GET'
  };
  const queries = queryString.stringify({
    fileName: action.payload.fileName,
    contentType: action.payload.contentType
  });
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/media/uploadUrl?${queries}`,
      options
    )
    yield put(actions.getImageUploadUrlSuccess(response));
  } catch (err: any) {
    yield put(actions.getImageUploadUrlError(err));
  }
}

export function* getDownloadUrlRequest(action: any) {
  const options = {
    method: 'GET'
  };
  const queries = queryString.stringify({
    key: action.payload.key
  });
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/media/questions_video_download?${queries}`,
      options
    )
    const responseBlob = yield call(
      requestDownloadFromS3,
      response.url
    )
    const a = document.createElement('a')
    const objectUrl = URL.createObjectURL(responseBlob)
    a.href = objectUrl
    a.download = action.payload.name;
    a.click();
    URL.revokeObjectURL(objectUrl);
    yield put(actions.getDownloadUrlSuccess(response));
    toast.success('Downloaded successfully')
  } catch (err: any) {
    yield put(actions.getDownloadUrlError(err));
    toast.error('Downloading failed!')
  }
}
export function* getUploadTranscoder(action: any) {
  yield delay(1000)
  console.log(action)
  const options = {
    method: 'GET'
  };
  const queries = queryString.stringify({
    fileName: action.payload.fileName
  });
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/aws/transcoder?${queries}`,
      options
    )

  } catch (err: any) {
    yield put(actions.getUploadTranscoderError(err));
  }
}
export function* uploadQuestionVideo(action: any) {
  const form = {
    questionId: action.payload.questionId,
    videoUrl: action.payload.videoUrl,
    videoLength: action.payload.videoLength,
  }
  const options = {
    method: 'POST',
    body: JSON.stringify(form),
  };

  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/categories/question/reply`,
      options
    )

    yield put(actions.uploadQuestionVideoSuccess(response))

  } catch (err: any) {
    yield put(actions.uploadQuestionVideoError(err))
  }
}
export function* addMediaProfileVideo(action: any) {
  const options = {
    method: 'POST',
    body: JSON.stringify(action.payload),
  };

  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/media/profile_video/admin`,
      options
    )

    yield put(actions.addMediaProfileVideoSuccess(response))

  } catch (err: any) {
    yield put(actions.addMediaProfileVideoError(err))
  }
}
export function* addMediaProfileImage(action: any) {
  // yield delay(1000)
  console.log(action)
  const options = {
    method: 'POST',
    body: JSON.stringify(action.payload),
  };

  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/media/profile_image/admin`,
      options
    )

    yield put(actions.addMediaProfileImageSuccess(response))

  } catch (err: any) {
    yield put(actions.addMediaProfileImageError(err))
  }
}

export function* mediaSaga() {
  yield takeLatest(actions.addMedia.type, addMediaRequest);
  yield takeLatest(actions.getDownloadUrl.type, getDownloadUrlRequest);
  yield takeLatest(actions.getUploadUrl.type, getUploadUrlRequest);
  yield takeLatest(actions.getImageUploadUrl.type, getImageUploadUrlRequest);
  yield takeLatest(actions.getUploadTranscoder.type, getUploadTranscoder);
  yield takeLatest(actions.uploadQuestionVideo.type, uploadQuestionVideo);
  yield takeLatest(actions.addMediaProfileVideo.type, addMediaProfileVideo);
  yield takeLatest(actions.addMediaProfileImage.type, addMediaProfileImage);
}
