import { memo, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { io, Socket } from 'socket.io-client';
import { actions } from '../../../availabilitySaga/slice';
import { selectListAvailability } from '../../../availabilitySaga/selectors';
import React from 'react';
import { Alert, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { APP_SOCKET_URL } from 'utils/constants';
// const APP_SOCKET_URL = 'https://io.staging.zipper.zangula.net/';


type SocketRef = {
  io: Socket | undefined;
};

type ConnectionType = {
  isConnected: boolean;
  clientTime: number;
};

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    width: '100%',
    textAlign: 'center',
    justifyContent: 'center',
    marginBottom: '10px',
  },
}));

const SocketManager = ({ userId }: { userId: any }) => {
  const styles = useStyles()
  console.log({ userId });
  const dispatch = useDispatch();
  const webSocket = useRef<SocketRef>({ io: undefined });
  const [connection, setConnection] = useState<ConnectionType>({
    isConnected: false,
    clientTime: Date.now(),
  });

  const location = useLocation();

  const getList: any = useSelector(selectListAvailability);

  const pathName = location?.pathname.split("/");
  let moduleName = "";
  if (pathName.length > 3) {
    moduleName = `${pathName[pathName.length - 2]}-${pathName[pathName.length - 1]}`;
  } else {
    moduleName = pathName[pathName.length - 1];
  }

  const isSomeOneOnCurrentModule = getList.includes(moduleName);

  const onMessage = async (data: any) => {
    console.log("List Data", data);
    dispatch(actions.setList({ data, userId }));
    console.log("Message getting");
    console.log({ ONMESSAGE: data });
  };

  useEffect(() => {
    dispatch(actions.setIsEditing({
      flag: isSomeOneOnCurrentModule
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSomeOneOnCurrentModule])

  useEffect(() => {
    console.log("method calling");
    establishConnection();
  }, [userId]);

  const establishConnection = () => {
    console.log("Called");
    console.log('establishConnection', webSocket);
    if (webSocket?.current?.io) {
      webSocket.current.io.disconnect();
    }
    webSocket.current.io = io(APP_SOCKET_URL);  //, {forceNew: true}//, transports: ['websocket']
    webSocket.current.io.removeAllListeners();
    webSocket.current.io.on('connect', () => {
      console.log('socket connected', userId);
      webSocket?.current?.io?.emit('JOIN-ADMIN', userId);
      setConnection(prev => ({ ...prev, isConnected: true }));
    });

    webSocket.current.io.on('connect_error', err => {
      console.log(err);
      setConnection(prev => ({ ...prev, isConnected: false }));
    });

    webSocket.current.io.on('disconnect', () => {
      console.log('Disconnected Socket!');

      setConnection(prev => ({ ...prev, isConnected: false }));
    });

    webSocket.current.io.on('PAGE-DATA', onMessage);
  };


  useEffect(() => {
    const pathName = location?.pathname.split("/");
    let moduleName = "";
    if (pathName.length > 3) {
      moduleName = `${pathName[pathName.length - 2]}-${pathName[pathName.length - 1]}`;
    } else {
      moduleName = pathName[pathName.length - 1];
    }
    console.log("ModelName:", moduleName);
    webSocket?.current?.io?.emit('ONPAGE', { moduleID: moduleName });
    return () => {
      console.log("Stored Module in server");
      webSocket?.current?.io?.emit('OFFPAGE', { moduleID: moduleName });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  //return null;
  return (
    isSomeOneOnCurrentModule && <Alert className={styles.error} severity="error">
      Someone is using this page, You can not edit or update this page
    </Alert>
  );
};

export default memo(SocketManager);