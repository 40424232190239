import React from 'react'
import { Navigate } from 'react-router'


const PrivetRoutes = ({ component: Component, ...props }) => {
    const accessToken = localStorage.getItem('sessionToken')
    return (
        accessToken ? <Component {...props} /> : <Navigate to={'/login'} />
    )
}

export default PrivetRoutes