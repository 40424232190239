import { actions } from 'app/containers/Auth/slice';
import logoutSvg from 'assets/logout.svg';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import theme from 'styles/theme/mui';
import { DASHBOARD_MENU_WIDTH } from 'utils/constants';

import { Avatar, Button, Menu, MenuItem, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { borderRadius, Box } from '@mui/system';

import ellipse from './assets/Ellipse.png';
import searchIcon from './assets/IconSearch.png';
import vector from './assets/Vector.png';

const useStyles = makeStyles(() => ({
    root: {
        position: 'fixed',
        zIndex: 2,
        top: 0,
        left: DASHBOARD_MENU_WIDTH,
        height: 76,
        width: '80%',
        borderBottom: "1px solid #E7E7E7",
        justifyContent: "end",
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        backgroundColor: '#fff',
        [theme.breakpoints.up('lg')]: {
            // paddingLeft: theme.spacing(10),
            // paddingRight: theme.spacing(10),
            // padding: theme.spacing(3),
            // backgroundColor: 'red',
        },
        [theme.breakpoints.up('xl')]: {
            // paddingLeft: theme.spacing(10),
            // paddingRight: theme.spacing(10),
            // padding: theme.spacing(3),
            // backgroundColor: 'green',
        },

    },
    searchInput: {
        borderWidth: 0,
        width: '100%',
        borderRadius: 100,
        backgroundColor: '#F9FAFB',
        '&:focus': {
            outline: 'none',

        },
    },
    searchDiv: {
        display: 'flex',
        border: '1px solid',
        padding: 5,
        borderRadius: 10,
        width: '20%',
        borderColor: '#E7E7E7',
        backgroundColor: '#F9FAFB',
    },
    searchIcon: {
        objectFit: 'contain',
        height: '25px',
        width: '25px',
        fill: '#000',
        paddingRight: 5,
        paddingLeft: 2,
        cursor: 'pointer'
    },

    userDiv: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'end',
        paddingRight: '1%',
        cursor: 'pointer',
    },
    dropDown: {
        objectFit: 'contain',
        width: '5%',
        cursor: 'pointer',
        marginLeft: "8%"
    }
}))

const Topbar = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [userName, setUserName] = useState<string | any>('')
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (item: any) => {
        setAnchorEl(null)
    };
    useEffect(() => {
        setUserName(localStorage.getItem('name'))

        return () => {
        }
    }, [userName])



    const logout = () => {
        dispatch(actions.logout());
        navigate('/login');
    }

    const styles = useStyles()
    return (<Box className={styles.root}>
        <div className={styles.userDiv} id="basic-menu"  >
            <Button
                id="basic-button"
                onClick={handleClick}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}

                sx={{ color: '#323232' }}
            >
                <Avatar src={ellipse} sx={{
                    width: 28,
                    height: 28
                }} />
                <Typography sx={{ marginLeft: 1 }} >{userName}</Typography>
                <img src={vector} className={styles.dropDown} />
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                sx={{ flex: 1 }}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem sx={{ width: '12rem', display: 'flex', justifyContent: 'space-between' }} onClick={() => logout()}><Typography>{'Logout'}</Typography><img src={logoutSvg} /></MenuItem>
            </Menu>
        </div>
    </Box >);
};

export default Topbar;
