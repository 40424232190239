import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  checked: {
    width: 13,
    height: 13,
    objectFit: "contain",
  },
  searchDiv:{
    display: 'flex',
    border: '1px solid',
    padding: 5,
    borderRadius: 6,
    width: '22%',
    borderColor: '#E7E7E7',
    backgroundColor: '#FFF',
    alignItems:'center'
  },
  searchIcon:{
    objectFit: 'contain',
    height: '25px',
    width: '25px',
    fill: '#000',
    paddingRight: 5,
    paddingLeft: 2,
    cursor: 'pointer'
  },
  searchInput:{
    borderWidth: 0,
    width: '100%',
    borderRadius: 100,
    '&:focus': {
        outline: 'none',

    },
  },
  filterIcon:{
    height:'18px',
    width:'16px',
    marginRight:'5px'
  },
  modalDiv:{
    display:'flex',
    flexDirection:'row',
    justifyContent:'space-between',
    alignItems:'center'
  },
  closeSvg:{
    cursor:'pointer',
    height:24,
    width:24,
    padding:5
  },
  filterText:{
    fontWeight:'bold',
    fontSize: 28,
    color:'#12BFE0'
  },
  attach:{
    height:'18px',
    width:'18px',
    marginRight:'5px',
  },
  addUserMedia:{
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '2%',
    justifyContent: 'space-between'
  },
  upload:{
    height:'22px',
    width:'22px',
    marginRight:'10px',
  },
  datePickerDiv:{
    display:'flex',
    flexWrap:'wrap',
    gap:15,
    margin:'1rem 0rem'
  },
  sendMsgModal:{
    fontSize:8,
    fontWeight:'normal'
  },
  titleInput:{
    width:'100%',
    marginTop:3,
    border:'1px #E7E7E7 solid',
    padding:6,
    borderRadius:5,
    fontFamily:'inherit',
    outline:'none'
  },
  emailInput:{
    width:'120%',
    marginTop:3,
    border:'1px #E7E7E7 solid',
    padding:6,
    borderRadius:5,
    fontFamily:'inherit',
    outline:'none'
  },
  textArea:{
    borderRadius:5,
    outline:'none',
    padding:6,
    border:'1px #E7E7E7 solid',
    maxHeight:'50%',
    resize:'none',
    height:'10rem',
    fontFamily:'inherit'
  },
  phoneDiv:{
    display:'flex',
    "& label":{
      lineHeight: '38px',
      marginRight: '10px'
    }
  }
}));
