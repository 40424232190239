import React from 'react';

type Props = {};

export const PrivacyPolicy = (props: Props) => {
  return <div>

    <div>
      <object data="/terms.pdf" type="application/pdf" style={{ width: '100%', height: window.innerHeight }}>
        alt : <a href="test.pdf">test.pdf</a>
      </object>
    </div>

  </div>;
};
