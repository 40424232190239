import { selectTotalAbuseReports } from 'app/containers/AbuseReports/selector';
import { selectTotalAnswer } from 'app/containers/Answers/selectors';
import { selectTotalQuestion } from 'app/containers/Questions/selectors';
import logo from 'assets/logo.svg';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Box } from '@mui/material';

import { actions as questionsManagementActions } from '../../../../containers/Questions/slice';
import { actions as userManagementActions } from '../../../../containers/UserManagement/slice';
import AbuseSvg from './assets/abuse.svg';
import AbuseFocusedSvg from './assets/abuseFocused.svg';
import AdminManagementSvg from './assets/adminManagement.svg';
import AnswersSvg from './assets/answers.svg';
import AnswersFocusedSvg from './assets/answersFocused.svg';
import DashboardSvg from './assets/dashboard.svg';
import DashboardFocusedSvg from './assets/dashboardFocused.svg';
import MessageSvg from './assets/messages.svg';
import MessageFocusedSvg from './assets/messagesFocused.svg';
import QuestionsSvg from './assets/questions.svg';
import QuestionsFocusedSvg from './assets/questionsFocused.svg';
import UserSvg from './assets/user.svg';
import UserFocusedSvg from './assets/userFocused.svg';
import SidebarTabs from './SideNavbarTabs';
import { useStyles } from './styles';
import { LinkItemType, SidebarTabsProps } from './types';

type Props = {};

const Sidebar = (props: Props) => {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const id = location?.pathname.split("/").at(-1);
  const totalQuestions = useSelector(selectTotalQuestion);
  const totalAnswers = useSelector(selectTotalAnswer);
  const totalAbuseData = useSelector(selectTotalAbuseReports);
  const isDashboard = location.pathname === '/dashboard';
  const isUserManagement = location.pathname === '/dashboard/user-management';
  const isAdminSettingManagement = location.pathname === '/dashboard/admin-management';
  const isMessageHistory = location.pathname === '/dashboard/message-history';
  const isQuestions = location.pathname === '/dashboard/questions';
  const isAnswers = location.pathname === '/dashboard/answers';
  const isAbuse = location.pathname === '/dashboard/abuse-reports';

  const classes = useStyles();

  // ==============state================================
  const [sidebarTabState, setSidebarTabState] = useState<LinkItemType[]>([])

  // ================handleChange=======================
  const getFilteredRoutes = (inputArray: string[] | [], listItems: LinkItemType[]) => {
    const routItems: LinkItemType[] = [];
    if (inputArray.length > 0) {
      for (const i in inputArray) {
        for (const j in listItems) {
          if (listItems[j].title === inputArray[i]) {
            routItems.push(listItems[j])
          }
        }
      }
    }
    return routItems;
  }

  const resetUserPageNo = () => {
    dispatch(userManagementActions.setUserPageNo(0))
    dispatch(userManagementActions.getPageNo(0))
    dispatch(userManagementActions.setAddMoreUsers(false))
    dispatch(questionsManagementActions.getPageNo(0))
  }

  const linkItems: LinkItemType[] = [
    {
      type: 'DASHBOARD',
      title: 'Dashboard',
      route: '/dashboard',
      backgroundColor: isDashboard ? '#F3F4F6' : '#fff',
      imgSrc: isDashboard ? DashboardFocusedSvg : DashboardSvg,
    },
    {
      type: 'USER_MANAGEMENT',
      title: 'User Management',
      route: '/dashboard/user-management',
      backgroundColor: isUserManagement ? '#F3F4F6' : '#fff',
      onClick: resetUserPageNo,
      imgSrc: isUserManagement ? UserFocusedSvg : UserSvg,
    },
    {
      type: 'MESSAGE_HISTORY',
      title: 'Message History',
      route: '/dashboard/message-history',
      backgroundColor: isMessageHistory ? '#F3F4F6' : '#fff',
      onClick: resetUserPageNo,
      imgSrc: isMessageHistory ? MessageFocusedSvg : MessageSvg,
    },
    {
      type: 'QUESTIONS',
      title: 'Questions',
      route: '/dashboard/questions',
      backgroundColor: isQuestions ? '#F3F4F6' : '#fff',
      onClick: resetUserPageNo,
      imgSrc: isQuestions ? QuestionsFocusedSvg : QuestionsSvg,
      totalCounts: totalQuestions,
    },
    {
      type: 'ANSWERS',
      title: 'Answers',
      route: '/dashboard/answers',
      backgroundColor: isAnswers ? '#F3F4F6' : '#fff',
      onClick: resetUserPageNo,
      imgSrc: isAnswers ? AnswersFocusedSvg : AnswersSvg,
      totalCounts: totalAnswers,
    },
    {
      type: 'ABUSE_REPORT',
      title: 'Abuse Reports',
      route: '/dashboard/abuse-reports',
      backgroundColor: isAbuse ? '#F3F4F6' : '#fff',
      imgSrc: isAbuse ? AbuseFocusedSvg : AbuseSvg,
      totalCounts: totalAbuseData,
    },
    {
      type: 'ADMIN_MANAGEMENT',
      title: 'Admin Management',
      route: '/dashboard/admin-management',
      backgroundColor: isAdminSettingManagement ? '#F3F4F6' : '#fff',
      imgSrc: AdminManagementSvg,
    },
  ]

  // ==================side effect=======================
  useEffect(() => {
    dispatch(userManagementActions.getUserCounts())
    if (localStorage.getItem('userType') === '0') {
      setSidebarTabState(linkItems)
    } else {
      const permissions: string[] | [] = localStorage.getItem('permissions')?.split(',') ?? [];
      const newPermissions = getFilteredRoutes(permissions, linkItems)
      setSidebarTabState(newPermissions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const pathName = location.pathname;
    const routes = sidebarTabState.map(v => v.route);
    const isSuperAdmin = localStorage.getItem('userType') === '0';

    if (!isSuperAdmin && sidebarTabState && sidebarTabState.length > 0 && !routes.includes(pathName)) {
      if (id) {
        return
      }
      navigate(routes[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, sidebarTabState])

  return (
    <Box className={classes.root}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: 2.5,
          height: 56,
          borderBottom: 1,
          borderColor: '#E7E7E7',
        }}
      >
        <img src={logo} alt="" className={classes.logo} />
      </Box>
      <Box
        sx={{
          borderRight: 1,
          borderColor: '#E7E7E7',
          height: "calc(100% - 76px)"
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            py: 5,
            gap: 3,

          }}
        >
          {sidebarTabState && sidebarTabState.length > 0 && sidebarTabState.map((item: SidebarTabsProps, keys) => {
            return (
              <SidebarTabs
                key={`Tab-${keys}`}
                title={item.title}
                route={item.route}
                imgSrc={item.imgSrc}
                backgroundColor={item.backgroundColor}
                totalCounts={item.totalCounts}
                onClick={item.onClick}
              />
            )
          })}
        </Box>
      </Box >
    </Box >
  );
};

export default Sidebar;