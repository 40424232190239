import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material'
import React from 'react'

type Props = {}

const DropMultipleComponent = ({ label, setMulti, multi, array }) => {
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const handleChange = (event: SelectChangeEvent<typeof multi>) => {
        const {
            target: { value },
        } = event;
        setMulti(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    return (
        <FormControl
            size='small'
            sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
            <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={multi}
                onChange={handleChange}
                input={<OutlinedInput label={label} />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
            >
                {array?.map((name) => (
                    <MenuItem key={name} sx={{
                        "& .MuiSvgIcon-root": { color: '#12BFE0' },
                    }} value={name}>
                        <Checkbox checked={multi.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default DropMultipleComponent