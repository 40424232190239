import DropMultipleComponent from 'app/components/DataMultipleComponent';
import DataTableComponent from 'app/components/DataTableComponent';
import React, { useEffect, useState } from 'react';

import {
    Button,
    Checkbox, Fab, FormControl, InputLabel, ListItemText, MenuItem, Modal, OutlinedInput, Select,
    SelectChangeEvent, Typography
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';

// import { useStyles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { actions as userActions } from '../UserManagement/slice';
import { Box } from '@mui/system';
import closeSvg from 'assets/close.svg';
import moment from 'moment';
import { useStyles } from "./style";
import { selectDisableMessage } from '../UserManagement/selectors';

type Props = {};

const SettingsPage = (props: Props) => {
    const styles = useStyles();
    const dispatch = useDispatch()
    const disabledMessage = useSelector(selectDisableMessage)
    const [adminDisableMessage, setAdminDisableMessage] = useState('');

    const setDisableMessage = (e: any) => {
        setAdminDisableMessage(e?.target?.value)
    }

    const addDisableMessage = () => {
        dispatch(userActions.setDisableMessageViaAdmin(adminDisableMessage!='' ?adminDisableMessage : disabledMessage))
    }

    useEffect(() => {
        dispatch(userActions.getAdminDisabledMessage())
    }, [])

    return (
        <>
        <div className={styles.heading}>
            Settings Page
        </div>
            <div>
                <input onChange={(e) => setDisableMessage(e)} className={styles.addMsgInput} type={"text"} placeholder="Enter message for disabled user" defaultValue={disabledMessage} />

                <Button onClick={() => addDisableMessage()} className={styles.addMsgButton}>+Add Message</Button>
            </div>
        </>
    )

};

export default SettingsPage;