const fetchResourceFromURI = async (uri:any) => {
  const response = await fetch(uri);
  console.log(response);
  const blob = await response.blob();
  return blob;
};

export async function uploadFile(file:any, signedRequest:any, callback:any ){
    console.log(file,signedRequest)
    file = await fetchResourceFromURI(file.url);
    console.log(file,signedRequest)
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', signedRequest);

    xhr.upload.onprogress = function (e) {
      if (e.lengthComputable) {
          if(callback){
            callback(file.name, ((e.loaded/ e.total)*100))
          }
      }
    }

    xhr.onloadstart = function (e) {
          if(callback){
            callback(file.name, "start")
          }
    }

    xhr.onloadend = function (e) {
          if(callback){
            callback(file.name, "end")
          }
    }

    xhr.onreadystatechange = () => {
      if(xhr.readyState === 4){
        if(xhr.status === 200){
          console.log("xhr.status", xhr)
        }
        else{
           console.log(xhr,'Could not upload file.');
        }
      }
    };
    xhr.send(file);
}