import DataTableComponent from 'app/components/DataTableComponent';
import DropDownComponent from 'app/components/DropDownComponent';
import deleteSvg from 'assets/deleteBtn.svg';
import editButton from 'assets/EditPen.svg';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate } from 'utils/DateFormate';

import { Fab, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';

import AddAdminModal from './component/AddAdmin';
import IOSSwitchComponent from './component/IOSSwitchComponent';
import PopOverDiv from './component/Popover';
import {
    selectAdminUsersFullName, selectAdminUsersList, selectFilters, selectIsAddEditPopUp,
    selectLoading
} from './selectors';
import { getIsSomeOneOnCurrentModule } from '../availabilitySaga/selectors';
import { actions } from './slice';
import { useStyles } from './style';

type Props = {};

const AdminManagement = (props: Props) => {
    // ==============constants ====================
    const styles = useStyles();
    const dispatch = useDispatch();
    const adminUsersList = useSelector(selectAdminUsersList);
    const isAddEditPopup = useSelector(selectIsAddEditPopUp);
    const filterData = useSelector(selectFilters)
    const isLoading = useSelector(selectLoading);
    const adminUsersFullName = useSelector(selectAdminUsersFullName);
    const isEditing = useSelector(getIsSomeOneOnCurrentModule);

    const dropdownList = adminUsersFullName.map(v => ({ id: v._id, name: v.fullName, ...v }))
    const rows = adminUsersList.map(v => ({ id: v._id, ...v }));
    const pageNo = filterData.page_no;
    const pageSize = filterData.page_size;
    // ================states========================
    const [anchor, setAnchor] = useState<any>(null);
    // =================handle Events ================
    const handleChange = (event) => {
        const id = event?.target?.value;
        const name = dropdownList.find(v => v.id === id)?.fullName ?? ''
        dispatch(actions.updateFilters({
            page_no: 0,
            page_size: filterData.page_size,
            total: filterData.total,
        }))
        dispatch(actions.updateFilterFullName(name))
        dispatch(actions.getAdminUsers())
    };

    const OpenCloseAddModel = () => {
        dispatch(actions.toggleAddEditPopup())
        dispatch(actions.updateSelectedAdminUserId(''))
        dispatch(actions.updateAdminFormValue({
            type: 'Add',
            fullName: '',
            email: '',
            permission: [],
            fullNameError: '',
            emailError: '',
            permissionError: '',
        }))
    };

    const handleEditAdminUser = (evt, row) => {
        dispatch(actions.toggleAddEditPopup())
        dispatch(actions.updateSelectedAdminUserId(row.id))
        dispatch(actions.updateAdminFormValue({
            type: 'Edit',
            fullName: row.fullName,
            email: row.email,
            permission: row.permissions,
            fullNameError: '',
            emailError: '',
            permissionError: '',
        }))
    };

    const handleAddNewAdminUser = () => dispatch(actions.toggleAddEditPopup())

    const handleDeleteClick = (event, row) => {
        setAnchor(event.currentTarget);
        dispatch(actions.updateSelectedAdminUserId(row.id))
    }

    const onDeleteSuccess = () => {
        dispatch(actions.updateSelectedAdminUserId(''))
        dispatch(actions.updateFilters({
            page_no: 0,
            page_size: filterData.page_size,
            total: filterData.total,
        }))
        dispatch(actions.getAdminUsers())
    }

    const handleDelete = () => {
        dispatch(actions.deleteAdminUser({ callback: onDeleteSuccess }));
        setAnchor(null);
    };

    const handleCloseEl = () => setAnchor(null);

    const onSuccessAdd = () => {
        dispatch(actions.getAdminUsers())
        dispatch(actions.toggleAddEditPopup())
        dispatch(actions.updateAdminFormValue({
            type: 'Add',
            fullName: '',
            email: '',
            permission: [],
            fullNameError: '',
            emailError: '',
            permissionError: '',
        }))
        dispatch(actions.updateFilters({
            page_no: 0,
            page_size: filterData.page_size,
            total: filterData.total,
        }))
    }

    const handleOnChangeToggle = (rowInfo) => {
        dispatch(actions.updateSelectedAdminUserId(rowInfo.id))
    }
    const onStatusChangeSuccess = () => {
        dispatch(actions.updateFilters({
            page_no: 0,
            page_size: filterData.page_size,
            total: filterData.total,
        }))
        dispatch(actions.updateSelectedAdminUserId(''))
        dispatch(actions.getAdminUsers())
    }

    const handleOnChangeSubmit = () => dispatch(actions.updateStatus({ callback: onStatusChangeSuccess }))

    const columns: GridColDef[] = [
        {
            field: "datetime",
            headerName: "Created Date",
            flex: 0.5,
            renderCell: e => { return formatDate(e.row.createdAt, 'dd/mm/yy', true) }
        },
        { field: "adminUserName", headerName: "Admin User Name", flex: 1, renderCell: e => e.row.fullName },
        { field: "email", headerName: "Email", flex: 1, renderCell: e => e.row.email },
        { field: "permissionsByModule", headerName: "Permissions by Module", flex: 1, renderCell: e => e.row.permissions.map((v, index) => { return `${v}, ` }) },
        {
            field: 'actions',
            headerName: 'Actions',
            sortable: false,
            width: 150,
            renderCell: e => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            flex: 0.7,
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <img
                            src={deleteSvg}
                            onClick={r => isEditing ? null : handleDeleteClick(r, e.row)}
                            alt="deleteSvg"
                            className={styles.imgTagD}
                            style={{
                                cursor: isEditing ? 'default' : 'pointer'
                            }}
                        />

                        <img
                            src={editButton}
                            onClick={r => handleEditAdminUser(r, e.row)}
                            alt="editSvg"
                            className={styles.imgTagD}
                            style={{ marginLeft: '8px' }}
                        />
                        <IOSSwitchComponent header={e.row.isActive ? "Disable" : "Enable"} isChecked={e.row.isActive} onChangeToggle={() => handleOnChangeToggle(e.row)} onSubmitToggle={handleOnChangeSubmit} isEditing={isEditing} />
                    </div>
                );
            },
        },
    ];

    // ======================useEffect=========================
    useEffect(() => {
        dispatch(actions.getAdminUsers())
        dispatch(actions.getAdminUserFullName())
        dispatch(actions.getPermissionsList())
        return () => {
            dispatch(actions.updateFilterFullName(''))
            dispatch(actions.updateFilters({
                page_no: 0,
                page_size: filterData.page_size,
                total: filterData.total,
            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        dispatch(actions.getAdminUserFullName())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterData.fullName])


    return (
        <>
            <div className={styles.mainDiv}>
                <div className={styles.headerDiv}>
                    <DropDownComponent
                        array={[...dropdownList, { id: "All", name: "All" }]}
                        widthNo={'15rem'}
                        label="Admin User Name"
                        type='Admin'
                        isCategory
                        setUser={handleChange}
                    />
                    <Fab
                        className={styles.fabBtn}
                        sx={{ backgroundColor: '#0EC8E1' }}
                        aria-label="like"
                    >
                        <Typography sx={{ colors: '#086ED6', fontSize: 22, fontWeight: '500' }}>
                            {adminUsersList.length}
                        </Typography>
                    </Fab>
                </div>
                <div className={styles.dataTable} style={{ position: 'relative' }}>
                    <div style={{ height: '85%', width: '100%' }}>
                        <DataTableComponent
                            rowCount={rows.length}
                            pageCount={rows.length}
                            onPageChange={(dummypageNo) => {

                            }}
                            mainType={'QUESTIONS'}
                            pageSize={pageSize}
                            page={pageNo}
                            loading={isLoading}
                            rows={rows}
                            columns={columns}
                            header
                        />
                    </div>
                    <Fab
                        className={styles.fabBtn}
                        onClick={handleAddNewAdminUser}
                        aria-label="like"
                        sx={{ marginTop: 5 }}
                    >
                        <Typography sx={{ color: '#fff', fontSize: 32, fontWeight: '500' }}>
                            +
                        </Typography>
                    </Fab>
                </div>
            </div>
            <AddAdminModal openModal={isAddEditPopup} closeAdd={OpenCloseAddModel} onSuccessAdd={onSuccessAdd} />
            <PopOverDiv
                anchor={anchor}
                handleCloseEl={handleCloseEl}
                handleDelete={handleDelete}
                handleClose={handleCloseEl}
            />
        </>
    )
};

export default AdminManagement;