import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  mainDiv: {
    padding: '1rem'
  },
  checked: {
    width: 13,
    height: 13,
    objectFit: "contain",
  },
  dataTable: {
    height: '70vh',
  },
  headerDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  fabBtn: {
    background: 'linear-gradient(90deg, #0EC8E1 0.06%, #0866D5 99.99%)',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  closeSvg: {
    cursor: 'pointer',
    height: 24,
    width: 24,
    padding: 5
  },
  textArea: {
    borderRadius: 5,
    outline: 'none',
    padding: 6,
    border: '1px #E7E7E7 solid',
    maxHeight: '50%',
    resize: 'none',
    height: '10rem',
    fontFamily: 'inherit'
  },
  titleInput: {
    width: '100%',
    marginTop: 3,
    border: '1px #E7E7E7 solid',
    padding: 6,
    borderRadius: 5,
    fontFamily: 'inherit',
    outline: 'none'
  },
  imgTag: {
    cursor: 'pointer',
    height: '1.5rem',
    width: '1.5rem',
    marginRight: '5px'
  },
  imgTagD: {
    cursor: 'pointer',
    height: '1.5rem',
    width: '1.5rem',
  },
  playTagD: {
    marginLeft: '2%',
    cursor: 'pointer',
    height: '1.5rem',
    width: '1.5rem',
    transform: 'scale(1.5)',
    position: 'relative',
    top: '2px'
  },
  upload: {
    height: '22px',
    width: '22px',
    marginRight: '10px',
  },
  attach: {
    height: '18px',
    width: '18px',
    marginRight: '5px',
  }
}))