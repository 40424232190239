import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  checked: {
    width: 13,
    height: 13,
    objectFit: "contain",
  },
  disabledRowClass: {
    backgroundColor: "rgb(253, 237, 237) !important",
    '&:hover': {
      backgroundColor: "rgb(253, 237, 237) !important",
    }
  },
  videoNotUploaded: {
    backgroundColor: "rgb(255 255 255) !important",
    '&:hover': {
      backgroundColor: "rgb(255 255 255) !important",
    }
  },
  videoUploaded: {
    backgroundColor: "rgb(223 244 16) !important",
    '&:hover': {
      backgroundColor: "rgb(223 244 16) !important",
    }
  },
  accepted: {
    backgroundColor: "rgb(13 240 45 / 52%) !important",
    '&:hover': {
      backgroundColor: "rgb(13 240 45 / 52%) !important",
    }
  },
  rejected: {
    backgroundColor: "red !important",
    '&:hover': {
      backgroundColor: "red !important",
    },
  },
  removePadding: {
    paddingTop: "0px !important",
    paddingBottom: "0px !important"
  }
}));
