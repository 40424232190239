import {
  reducer as abuseReducer, sliceKey as abuseSliceKey
} from 'app/containers/AbuseReports/slice';
import { reducer as ansReducer, sliceKey as ansSliceKey } from 'app/containers/Answers/slice';
import { userRepoSaga } from 'app/containers/Auth/saga';
import { actions, reducer, sliceKey } from 'app/containers/Auth/slice';
import {
  reducer as msgReducer, sliceKey as msgSliceKey
} from 'app/containers/MessageHistory/slice';
import { PrivacyPolicy } from 'app/containers/PrivacyPolicy';
import { reducer as quesReducer, sliceKey as quesSliceKey } from 'app/containers/Questions/slice';
import {
  reducer as userReducer, sliceKey as userSliceKey
} from 'app/containers/UserManagement/slice';
import logoSvg from 'assets/logo.svg';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { Typography } from '@mui/material';

import PrivetRoutes from './components/PrivetRoutes';
import AbuseReports from './containers/AbuseReports';
import { userAbuseReport } from './containers/AbuseReports/saga';
import AdminManagement from './containers/AdminManagement';
import { adminManagementSaga } from './containers/AdminManagement/saga';
import {
  reducer as adminManagementReducer, sliceKey as adminManagementSlice
} from './containers/AdminManagement/slice';
import Answers from './containers/Answers';
import { userAns } from './containers/Answers/saga';
import { ForgotPassPage } from './containers/Auth/ForgotPassPage/Loadable';
import LoginPage from './containers/Auth/LoginPage';
import SetPassPage from './containers/Auth/SetPassPage';
import { checkAvailabilitySaga } from './containers/availabilitySaga/saga';
import {
  reducer as checkAvailabilityReducer, sliceKey as checkAvailabilitySliceKey
} from './containers/availabilitySaga/slice';
// import { reducer as authReducer, sliceKey as authSlice } from './containers/Auth/slice';
import DashboardPage from './containers/Dashboard';
import { dashboardSaga } from './containers/LandingPage/saga';
import { reducer as dashReducer, sliceKey as dashSlice } from './containers/LandingPage/slice';
import { mediaSaga } from './containers/mediaSaga/saga';
import { reducer as mediaReducer, sliceKey as mediaSlice } from './containers/mediaSaga/slice';
import MessageHistory from './containers/MessageHistory';
import { userMsg } from './containers/MessageHistory/saga';
import Questions from './containers/Questions';
import { userQuestion } from './containers/Questions/sagas';
import SettingsPage from './containers/SettingsPage';
import SnackbarComponent from './containers/Snackbar';
import UserManagementRoutes from './containers/UserManagement/Routes';
import { userManagementSaga } from './containers/UserManagement/saga';
import { selectError } from './containers/UserManagement/selectors';

export function App() {
  useInjectReducer({ key: checkAvailabilitySliceKey, reducer: checkAvailabilityReducer });
  useInjectSaga({ key: checkAvailabilitySliceKey, saga: checkAvailabilitySaga });

  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: userRepoSaga });

  useInjectReducer({ key: dashSlice, reducer: dashReducer });
  useInjectSaga({ key: dashSlice, saga: dashboardSaga });

  useInjectReducer({ key: msgSliceKey, reducer: msgReducer });
  useInjectSaga({ key: msgSliceKey, saga: userMsg });

  useInjectReducer({ key: ansSliceKey, reducer: ansReducer });
  useInjectSaga({ key: ansSliceKey, saga: userAns });

  useInjectReducer({ key: abuseSliceKey, reducer: abuseReducer });
  useInjectSaga({ key: abuseSliceKey, saga: userAbuseReport });

  useInjectReducer({ key: userSliceKey, reducer: userReducer });
  useInjectSaga({ key: userSliceKey, saga: userManagementSaga });

  useInjectReducer({ key: quesSliceKey, reducer: quesReducer });
  useInjectSaga({ key: quesSliceKey, saga: userQuestion });

  useInjectReducer({ key: mediaSlice, reducer: mediaReducer });
  useInjectSaga({ key: mediaSlice, saga: mediaSaga });

  useInjectReducer({ key: adminManagementSlice, reducer: adminManagementReducer });
  useInjectSaga({ key: adminManagementSlice, saga: adminManagementSaga });

  useInjectReducer({ key: adminManagementSlice, reducer: adminManagementReducer });
  useInjectSaga({ key: adminManagementSlice, saga: adminManagementSaga });

  const error = useSelector(selectError)
  const dispatch = useDispatch()

  if (error?.includes('AccessDeniedError')) {
    dispatch(actions.logout())
  }

  const [wdWidth, setWdWidth] = useState<number>(window.innerWidth)
  const smallWindow = wdWidth < 1024
  const [accessToken, setAccessToken] = useState<string | any>('')

  useEffect(() => {
    setAccessToken(localStorage.getItem('sessionToken'))
    return () => { }
  }, [accessToken])


  useLayoutEffect(() => {
    function updateSize() {
      setWdWidth(window.innerWidth)
    }
    window.addEventListener('resize', updateSize)
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [wdWidth])

  return (
    <BrowserRouter>
      <Helmet titleTemplate="%s - Zipper" defaultTitle="zipper">
        <meta name="description" content="A zipper application" />
      </Helmet>

      {!smallWindow ? <Routes>
        <Route
          path="/"
          element={<Navigate to="/login" />}
        />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/forgot-password" element={<ForgotPassPage />} />
        <Route path="/reset-password" element={<SetPassPage />} />
        <Route path="dashboard" element={<PrivetRoutes component={DashboardPage} />}>
          <Route path='user-management/*' element={<PrivetRoutes component={UserManagementRoutes} />} />
          <Route path='message-history' element={<PrivetRoutes component={MessageHistory} />} />
          <Route path='questions' element={<PrivetRoutes component={Questions} />} />
          <Route path='answers' element={<PrivetRoutes component={Answers} />} />
          <Route path='abuse-reports' element={<PrivetRoutes component={AbuseReports} />} />
          <Route path='settings' element={<PrivetRoutes component={SettingsPage} />} />
          <Route path='admin-management' element={<PrivetRoutes component={AdminManagement} />} />
        </Route>
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes> :
        <div style={{ display: 'flex', backgroundColor: '#fff', flex: 1, height: '100vh', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <img src={logoSvg} alt='mainAppIcon' />
          <Typography sx={{ fontSize: 20, fontWeight: '600', marginTop: '2rem' }} >This site doesnot support window width less than 1024px</Typography>
          <Typography sx={{ fontSize: 20, fontWeight: '600', marginTop: '1rem' }} >Your current pixel size is {window.innerWidth}</Typography>
        </div>
      }
      <SnackbarComponent />

    </BrowserRouter >
  );
}
