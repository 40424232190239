/**
 * Homepage selectors
 */

import { RootState } from 'types';

import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';

// TODO: Add an explanation for this
const selectDomain = (state: RootState) => state.authState || initialState;

export const selectToken = createSelector(
  [selectDomain],
  authState => authState.token,
);

export const selectLoginForm = createSelector(
  [selectDomain],
  authState => authState,
);

export const selectLoading = createSelector(
  [selectDomain],
  authState => authState.loading,
);

export const selectError = createSelector(
  [selectDomain],
  authState => authState.error,
);

export const selectUserDetails = createSelector(
  [selectDomain],
  authState => authState.userDetail,
);
