import queryString from 'query-string';
import { toast } from 'react-toastify';
import { APP_API_URL } from 'utils/constants';
import { request } from 'utils/request';

import { call, delay, put, select, takeLatest } from '@redux-saga/core/effects';

import { selectAdminForm, selectFilters, selectSelectedAdminUser } from './selectors';
import { actions } from './slice';

export function* getUserRequest() {
  yield delay(500)
  const { page_no, page_size, fullName, orderBy, orderByField } = yield select(selectFilters)
  const queries = queryString.stringify({
    page_no,
    page_size,
    fullName,
    orderBy,
    orderByField
  });

  const options = {
    method: 'GET',
  };
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/super-admin/get/admin?${queries}`,
      options
    )
    yield put(actions.getAdminUsersSuccess(response[0].users))
    yield put(actions.updateFilters({
      page_no: response[0].meta[0].page_no,
      page_size: response[0].meta[0].page_size,
      total: response[0].meta[0].total,
    }))
  }
  catch (err: any) {
    yield put(actions.getAdminUsersFailure(err.message))
  }
}

export function* addUserRequest(action) {
  yield delay(500)
  const { fullName, email, permission } = yield select(selectAdminForm);
  const options = {
    method: 'POST',
    body: JSON.stringify(
      {
        "fullName": fullName,
        "email": email,
        "permissions": permission.map(v => v.name)
      }
    ),
  };
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/super-admin/add/admin`,
      options
    )
    if (response.message === "MESSAGE.ADMIN_CREATED_SUCCESSFULLY_SENT_MAIL") {
      yield call(action.payload?.callback)
      yield put(actions.addAdminSuccess())
      toast.success("Admin created successfully.")
    } else {
      yield put(actions.addAdminFailure(response.message))
    }
  }
  catch (err: any) {
    yield put(actions.addAdminFailure(err.message))
  }
}

export function* updateUserRequest(action) {
  yield delay(500)
  const { fullName, email, permission } = yield select(selectAdminForm);
  const id = yield select(selectSelectedAdminUser)
  const options = {
    method: 'PUT',
    body: JSON.stringify(
      {
        "fullName": fullName,
        "email": email,
        "permissions": typeof permission[0] === 'object' ? permission.map(v => v.name) : permission
      }
    ),
  };
  try {
    yield call(
      request,
      `${APP_API_URL}/api/super-admin/${id}`,
      options
    )
    yield call(action.payload?.callback)
    yield put(actions.updateAdminSuccess())
  }
  catch (err: any) {
    yield put(actions.updateAdminFailure(err.message))
  }
}

export function* deleteUserRequest(action) {
  yield delay(500)
  const id = yield select(selectSelectedAdminUser)
  const options = {
    method: 'DELETE',
  };
  try {
    yield call(
      request,
      `${APP_API_URL}/api/super-admin/${id}`,
      options
    )
    yield call(action.payload?.callback)
    yield put(actions.deleteAdminUserSuccess())
  }
  catch (err: any) {
    yield put(actions.addAdminFailure(err.message))
  }
}

export function* getAdminUsersDropdownListRequest() {
  yield delay(500)
  const options = {
    method: 'GET',
  };
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/super-admin/get/fullName`,
      options
    )
    yield put(actions.getAdminUserFullNameSuccess(response))
  }
  catch (err: any) {
    yield put(actions.addAdminFailure(err.message))
  }
}

export function* getPermissionListsRequest() {
  yield delay(500)
  const options = {
    method: 'GET',
  };
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/super-admin/permissions`,
      options
    )
    yield put(actions.getPermissionsListSuccess(response))
  }
  catch (err: any) {
    yield put(actions.addAdminFailure(err.message))
  }
}

export function* updateStatusRequest(action) {
  yield delay(500)
  const options = {
    method: 'GET',
  };
  const id = yield select(selectSelectedAdminUser);
  try {
    yield call(
      request,
      `${APP_API_URL}/api/super-admin/admin/change-status/${id}`,
      options
    )
    yield put(action?.payload?.callback)
    yield put(actions.updateStatusSuccess())
  }
  catch (err: any) {
    yield put(actions.updateStatusFailure(err.message))
  }
}

export function* adminManagementSaga() {
  yield takeLatest(actions.getAdminUsers.type, getUserRequest)
  yield takeLatest(actions.addAdminUser.type, addUserRequest)
  yield takeLatest(actions.updateAdminUser.type, updateUserRequest)
  yield takeLatest(actions.deleteAdminUser.type, deleteUserRequest)
  yield takeLatest(actions.getAdminUserFullName.type, getAdminUsersDropdownListRequest)
  yield takeLatest(actions.getPermissionsList.type, getPermissionListsRequest)
  yield takeLatest(actions.updateStatus.type, updateStatusRequest)
}