import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  checked: {
    width: 13,
    height: 13,
    objectFit: "contain",
  },
  searchDiv:{
    display: 'flex',
    border: '1px solid',
    padding: 5,
    borderRadius: 6,
    width: '22%',
    borderColor: '#E7E7E7',
    backgroundColor: '#FFF',
    alignItems:'center'
  },
  filterCalIcon:{
    height:'18px',
    width:'16px',
    marginRight:'5px'
  },
  searchIcon:{
    objectFit: 'contain',
    height: '25px',
    width: '25px',
    fill: '#000',
    paddingRight: 5,
    paddingLeft: 2,
    cursor: 'pointer'
  },
  searchInput:{
    borderWidth: 0,
    width: '100%',
    borderRadius: 100,
    '&:focus': {
        outline: 'none',

    },
  },
  filterIcon:{
    height:'18px',
    width:'16px',
    marginRight:'5px'
  },
  modalDiv:{
    display:'flex',
    flexDirection:'row',
    justifyContent:'space-between',
    alignItems:'center'
  },
  closeSvg:{
    cursor:'pointer',
    height:24,
    width:24,
    padding:5
  },
  playTagD:{
    marginLeft: '2%',
    cursor:'pointer',
    height:'1.5rem',
    width:'1.5rem',
    transform: 'scale(1.5)',
    position: 'relative',
    top:'2px'
  },
  addMsgInput:{
    marginLeft: '5%',
    height: '45px',
    border: '1px solid darkgray',
    borderRadius: '10px',
    width: '50%',
    textAlign: 'center',
    position: 'relative',
    top: '7px',
    color: 'darkslategrey',
    outline: 'none'
  },
  addMsgButton:{
    position: 'relative',
    top: '5px'
  },
  filterText:{
    fontWeight:'bold',
    fontSize: 28,
    color:'#12BFE0'
  },
  datePickerDiv:{
    display:'flex',
    flexWrap:'wrap',
    gap:27,
    margin:'1rem 0rem 5vh 0rem'
  },
  sendMsgModal:{
    fontSize:8,
    fontWeight:'normal'
  },
  titleInput:{
    width:'100%',
    marginTop:3,
    border:'1px #E7E7E7 solid',
    padding:6,
    borderRadius:5,
    fontFamily:'inherit',
    outline:'none'
  },
  emailInput:{
    width:'120%',
    marginTop:3,
    border:'1px #E7E7E7 solid',
    padding:6,
    borderRadius:5,
    fontFamily:'inherit',
    outline:'none'
  },
  textArea:{
    borderRadius:5,
    outline:'none',
    padding:6,
    border:'1px #E7E7E7 solid',
    maxHeight:'50%',
    resize:'none',
    height:'10rem',
    fontFamily:'inherit'
  },
  fabBtn:{
    background: 'linear-gradient(90deg, #0EC8E1 0.06%, #0866D5 99.99%)',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  heading:{
    fontSize: '30px'
  },
  userDataTable:{
    // "& .MuiSvgIcon-root": {
    //       color: '#fff'
    //   },
  }
}));
