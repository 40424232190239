import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  closeSvg: {
    cursor: 'pointer',
    height: 24,
    width: 24,
    padding: 5
  },
  ModalHeader: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
  },
  BodyContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'start',
    alignItems: 'center',
  },
  BodySection: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1.5rem'
  },
  ButtonContainer: {
    margin: '1.5rem 0rem 0rem 0rem',
    display: 'flex',
    flexDirection: 'column',
  },
  ButtonSection: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '1rem 0rem 0rem 0rem',
  },
  CloseButton: {
    color: '#6C7389',
    backgroundColor: '#E7E7E7',
    borderColor: '#e7e7e7',
    '&:hover': {
      borderColor: '#e7e7e7',
      backgroundColor: '#E7E7E7',
      cursor: 'pointer'
    },
    fontSize: '0.75rem',
    fontWeight: '500',
    cursor: 'auto',
    borderRadius: '5px',
    marginLeft: 2,
  },
  DoneButton: {
    color: '#fff',
    backgroundColor: '#12BFE0',
    '&:hover': {
      backgroundColor: '#12bfe0',
      cursor: 'pointer'
    },
    fontSize: '0.75rem',
    padding: '6px 30px',
    fontWeight: '500',
    cursor: 'auto',
    borderRadius: '5px',
    marginLeft: 2,
  },
  error: {
    width: '100%',
    textAlign: 'center',
    justifyContent: 'center',
    marginBottom: '10px'
  },
  // ModalContainer: {
  //   position: 'absolute',
  //   top: '50%',
  //   left: '50%',
  //   transform: 'translate(-50%, -50%)',
  //   width: '25rem',
  //   bgcolor: 'background.paper',
  //   boxShadow: 10,
  //   padding: 3,
  //   borderRadius: 2,
  // }
}))