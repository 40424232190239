import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  main: {
    padding: '1rem'
  },
  header: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  closeSvg: {
    cursor: 'pointer',
    height: 24,
    width: 24,
    padding: 5
  },
  checked: {
    width: 13,
    height: 13,
    objectFit: "contain",
  },
  dataTable: {
    height: '60vh'
  },
  clickableRow: {
    width: '100%',
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));
