import {
    selectError, selectSelectedOnly, selectSelectedRows, selectSendUserCompleted
} from 'app/containers/UserManagement/selectors';
import { actions } from 'app/containers/UserManagement/slice';
import closeSvg from 'assets/close.svg';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Modal, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { useStyles } from './styles';

type Props = {
    sendMsg: boolean,
    setSendMsg: any,
    isSelectAll?: boolean,
}

const SendMsgModal = (props: Props) => {
    const err = useSelector(selectError)
    const closeMsg = () => props.setSendMsg(false)
    const [title, setTitle] = useState<string>()
    const [message, setMessage] = useState<string>()
    const [pressed, setPressed] = useState<boolean>(false)
    const styles = useStyles()
    const handleChangeTitle = (e) => {
        setTitle(e.nativeEvent.target.value)
        dispatch(actions.getSendTitle(e.nativeEvent.target.value))
    }
    const handleChangeMessage = (e) => {
        setMessage(e.nativeEvent.target.value)
        dispatch(actions.getSendMessage(e.nativeEvent.target.value))
    }
    const dispatch = useDispatch()
    const selectedRows = useSelector(selectSelectedRows)
    const selectUser = useSelector(selectSelectedOnly)
    const isUserCompleted = useSelector(selectSendUserCompleted)

    const validTitle = (title) => {
        if (!title?.trim() && pressed) {
            return 'Please enter title';
        }
        if (title && title.length > 40 && pressed) {
            return 'Title limit 40 characters'
        }
        return null
    }
    const validMessage = (message) => {
        if (!message?.trim() && pressed) {
            return 'Please enter message';
        }
        if (message && message.length > 256 && pressed) {
            return 'Message limit 256 characters'
        }
        return null
    }
    const validUsers = () => {
        if (selectedRows[0] === undefined && pressed) {
            return 'Please select users';
        }
        return null
    }

    const [sendAll, setSendAll] = useState(false)
    const handleSendSelected = () => {
        setPressed(true)
        validTitle(title)
        validMessage(message)
        setSendAll(false)
        if (title?.trim() && message?.trim() && (selectUser[0] !== undefined || selectedRows[0] !== undefined)) {
            if (title?.length > 40 || message?.length > 256) {
                return
            }
            setPressed(false)
            setTitle('')
            setMessage('')
            dispatch(actions.getSendToSelectedUser())
        }
    }
    const handleSendAll = () => {
        setPressed(true)
        validTitle(title)
        setSendAll(true)
        validMessage(message)
        if (title?.trim() && message?.trim()) {
            setPressed(false)
            setTitle('')
            setMessage('')
            dispatch(actions.getSendToAllUser())
        }
    }

    useEffect(() => {
        if (isUserCompleted) { closeMsg() }

    }, [isUserCompleted])

    return (
        <Modal
            open={props.sendMsg}
            onClose={closeMsg}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={
                {
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '35rem',
                    bgcolor: 'background.paper',
                    boxShadow: 10,
                    p: 3,
                    borderRadius: 2
                }

            }>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }} >
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: "space-between" }}>
                        <Typography sx={{ fontSize: 14, color: '#6C7389', fontWeight: 'normal' }} >Title</Typography>
                        <img src={closeSvg} alt='closeSvg' onClick={() => props.setSendMsg(false)} className={styles.closeSvg} />
                    </div>
                    <input className={styles.titleInput} value={title} onChange={handleChangeTitle} />
                    <Typography sx={{ color: '#911', marginTop: 1 }} >{validTitle(title)}</Typography>
                </div>
                <div style={{ margin: '1.5rem 0rem 0rem 0rem', display: 'flex', flexDirection: 'column' }} >
                    <Typography sx={{ fontSize: 14, color: '#6C7389', fontWeight: 'normal', marginBottom: '1rem' }} >Message</Typography>
                    <textarea value={message} onChange={handleChangeMessage} className={styles.textArea} />
                    <Typography sx={{ color: '#911', marginTop: 1 }} >{validMessage(message)}</Typography>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '1rem 0rem 0rem 0rem' }} >
                        {
                            props.isSelectAll && <Button
                                variant="outlined"
                                onClick={handleSendAll}
                                sx={{
                                    color: '#fff', backgroundColor: '#12BFE0', '&:hover': {
                                        backgroundColor: "#12bfe0"
                                    }, fontSize: 12, padding: '7px 15px', fontWeight: '500', cursor: 'auto', borderRadius: "5px", marginLeft: 2
                                }}
                            >Send to All</Button>
                        }
                        <Button
                            variant="outlined"
                            onClick={handleSendSelected}
                            sx={{
                                color: '#fff', backgroundColor: '#12BFE0', '&:hover': {
                                    backgroundColor: "#12bfe0"
                                }, fontSize: 12, padding: '7px 15px', fontWeight: '500', cursor: 'auto', borderRadius: "5px", marginLeft: 2
                            }}
                        > {props.isSelectAll ? "Send to Selected" : "Send"}</Button>
                    </div>
                    {!sendAll && validUsers() && <Typography sx={{ color: '#911', marginTop: 1 }} >{selectUser.length == 0 ? validUsers() : null}</Typography>}
                    {err === 'MESSAGE_ADMIN_USER_MESSAGE_SENT' && <Typography sx={{ color: '#191', marginTop: 1 }} >{'Message sent successfully'}</Typography>}
                </div>
            </Box >
        </Modal >
    )
}

export default SendMsgModal