import { createSelector } from "reselect";
import { RootState } from "types";
import { initialState } from "./slice";

const selectDomain = (state:RootState) => state.dashboardState || initialState;

export const selectDate = createSelector(
  [selectDomain],
  dashboardState => dashboardState.filterDate,
)

export const selectData = createSelector(
  [selectDomain],
  dashboardState => dashboardState,
)
