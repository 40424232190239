import { createSelector } from 'reselect';
import { RootState } from 'types';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.adminManagement || initialState;

export const selectAdminState = createSelector(
  [selectDomain],
  adminManagement => adminManagement
)
export const selectAdminUsersList = createSelector(
  [selectDomain],
  adminManagement => adminManagement.adminUserLists
)
export const selectSelectedAdminUser = createSelector(
  [selectDomain],
  adminManagement => adminManagement.selectedAdminId
)

export const selectAdminForm = createSelector(
  [selectDomain],
  adminManagement => adminManagement.adminForm
)

export const selectIsAddEditPopUp = createSelector(
  [selectDomain],
  adminManagement => adminManagement.isAddEditOpen
)
export const selectIsDeletePopUp = createSelector(
  [selectDomain],
  adminManagement => adminManagement.isDeleteOpen
)
export const selectIsEnableDisable = createSelector(
  [selectDomain],
  adminManagement => adminManagement.isEnableDisableOpen
)

export const selectAdminUsersFullName = createSelector(
  [selectDomain],
  adminManagement => adminManagement.adminUsersFullname
)

export const selectPermissionsList = createSelector(
  [selectDomain],
  adminManagement => adminManagement.permissionList
)
export const selectError = createSelector(
  [selectDomain],
  adminManagement => adminManagement.error
)
export const selectLoading = createSelector(
  [selectDomain],
  adminManagement => adminManagement.loading
)
export const selectFilters = createSelector(
  [selectDomain],
  adminManagement => adminManagement.filters
)