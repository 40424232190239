import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { APP_VERSION } from 'utils/constants';

// import Container from '@mui/material/Container';
import { Checkbox, FormControlLabel, Theme } from '@mui/material';
// import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Alert from '@mui/material/Alert';
// import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
// import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
// import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Grid from '@mui/material/Grid';
// import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';

import logo from '../../../../assets/logo.svg';
import { selectError, selectLoading, selectLoginForm } from '../selectors';
import { actions } from '../slice';
import { LoginErrorType } from '../types';

function Version() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      Version: {APP_VERSION}
    </Typography>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  logo: {
    height: '84px',
    // width: '176.89px',
    objectFit: "contain",
    alignSelf: 'flex-start',
  },
  loginButtonWrapper: {
    position: 'relative',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },

  form: {
    width: '80%', // Fix IE 11 issue.
    // backgroundColor: '#aaa',
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    [theme.breakpoints.up('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      padding: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      padding: theme.spacing(2),
      // backgroundColor: '#aaa',
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      padding: theme.spacing(3),
      // backgroundColor: '#911',
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      padding: theme.spacing(3),
      // backgroundColor: '#bba',
    },
    [theme.breakpoints.up('xl')]: {
      // paddingLeft: theme.spacing(10),
      // paddingRight: theme.spacing(10),
      // padding: theme.spacing(3),
      // backgroundColor: 'red',
      padding: theme.spacing(3),
      width: '20vw'
    },
  },
  inputField: {
    // height:0,
    // backgroundColor:'rgba(000,000,255,255)'
  },
  error: {
    width: '100%',
    textAlign: 'center',
    justifyContent: 'center',
  },
  submit: {
    color: '#fff !important',
    fontWeight: 'bold',
    margin: theme.spacing(3, 0, 2),
    padding: theme.spacing(1, 4),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1, 0, 1),
    },
    borderRadius: "10px",

  },
  languageSwitch: {
    position: 'absolute',
    top: '10px',
    right: '10px'
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  forgotPassword: {
    color: '#000',
    fontSize: 14,
    fontWeight: 'bold'
  },
  formDiv: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    // backgroundColor: '#aba'

  },
  loginText: {
    fontSize: 30,
    alignSelf: 'flex-start',
    fontWeight: 'bold'
  },
  imgDiv:
  {
    // backgroundColor: '#999',
    width: '50%',
    flex: 1,
    alignItems: 'center',
    marginBottom: -50,
    [theme.breakpoints.up('sm')]: {
      width: '68%'
    },
    [theme.breakpoints.up('md')]: {
      width: '62%'
    },
    [theme.breakpoints.up('xl')]: {
      // paddingLeft: theme.spacing(10),
      // paddingRight: theme.spacing(10),
      // padding: theme.spacing(3),
      // backgroundColor: 'red',
      padding: theme.spacing(3),
      width: '20vw'
    },
    // marginTop:60,

  },
  // rememberText:{
  //   fontFamily:'cursive',
  //   fontSize:90,
  // }
  noMember: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'baseline'
  },
  notP: {
    marginRight: 10,
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '18px',
    color: "#BEC2C4",
  }
}));

export function LoginForm({ className }) {
  const classes = useStyles();
  const form = useSelector(selectLoginForm);

  const isLoading = useSelector(selectLoading);
  const error = useSelector(selectError);
  const isLoggedIn = !!form.token;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onChangeUsername = (evt: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(actions.changeUsername(evt?.currentTarget?.value?.trim()));
  };

  const onChangePassword = (evt: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(actions.changePassword(evt.currentTarget.value));
  };

  const onSubmitForm = (evt?: React.FormEvent<HTMLFormElement>) => {
    /* istanbul ignore next  */
    if (evt !== undefined && evt.preventDefault) {
      evt.preventDefault();
      dispatch(actions.login());
    }
  };

  if (isLoggedIn) {
    if (localStorage.getItem('userType') !== '0') {
      return (
        <Navigate
          to={{
            pathname: '/dashboard/',
          }}
        />
      );
    }
    return (
      <Navigate
        to={{
          pathname: '/dashboard',
        }}
      />
    );
  }

  return (
    <Card className={className}>
      {/* <LanguageSwitch className={classes.languageSwitch}/> */}
      <div className={classes.formDiv}>
        <div className={classes.imgDiv} >
          <img src={logo} alt="" className={classes.logo} />
          <p className={classes.loginText}>{t('LOGIN.BUTTON_TEXT')}</p>
        </div>
        <form className={classes.form} noValidate onSubmit={onSubmitForm}>
          <TextField
            variant="outlined"
            margin="normal"
            className={classes.inputField}
            sx={{
              ".css-17401ng-MuiInputBase-root-MuiOutlinedInput-root": {
                borderRadius: '10px'
              }
            }}
            // required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            error={loginError(error)?.username}
            autoFocus
            value={form.username}
            onChange={onChangeUsername}
          />

          <TextField
            variant="outlined"
            margin="normal"
            // required
            sx={{
              ".css-17401ng-MuiInputBase-root-MuiOutlinedInput-root": {
                borderRadius: '10px'
              }
            }}

            className={classes.inputField}
            fullWidth
            error={loginError(error)?.password}
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={form.password}
            onChange={onChangePassword}
          />
          <div className={classes.flex}>
            <FormControlLabel control={<Checkbox />} label="Remember Me" />
            <a href="/forgot-password" className={classes.forgotPassword}>Forget Password?</a>
          </div>
          {loginError(error)?.message ? (
            <Alert className={classes.error} severity="error">
              {loginError(error)?.message}
            </Alert>
          ) : null}
          <div className={classes.loginButtonWrapper}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={isLoading}
              type="submit"
            >
              {t("LOGIN.BUTTON_TEXT")}
            </Button>
            {isLoading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
          <div className={classes.noMember}>
            <p className={classes.notP}>© All rights reserved by Zipper</p>
          </div>
        </form>
      </div>
      {/* <Box mt={1}>
        <Version />
      </Box> */}
    </Card>
  );
}

export const loginError = (error: LoginErrorType | undefined | null) => {
  if (!error) return null;
  switch (error) {
    case LoginErrorType.ERROR:
      return {
        message: 'An error has occurred! 😞',
        username: true,
        password: true,
      };
    case LoginErrorType.USERNAME_EMPTY:
      return { message: 'Please enter username', username: true };
    case LoginErrorType.PASSWORD_EMPTY:
      return { message: 'Please enter password', password: true };
    case LoginErrorType.USER_NOT_FOUND:
      return { message: 'No admin found with provided email', username: true };
    case LoginErrorType.LOGIN_FAILED:
      return { message: 'Please enter valid credentials.', username: true, password: true };
    default:
      return { message: error, password: true, username: true };
  }
};
export default LoginForm;
