import { call, delay, put, select, takeLatest } from "@redux-saga/core/effects";
import format from "date-fns/format";
import { APP_API_URL } from "utils/constants";
import { request } from "utils/request";
import { selectIsMoreMessageAdd, selectRows, selectWithoutFilterData } from "../MessageHistory/selectors";
import { selectData } from "./selectors";
import { actions } from "./slice";
import { InitialMessageState } from "./types";

export function* getMessageRequest() {
  yield delay(500)

  const data: InitialMessageState = yield select(
    selectData,
  );
  const rowsData = yield select(selectRows);
  const withoutFilterData = yield select(selectWithoutFilterData);
  const isAddMoreMessages = yield select(selectIsMoreMessageAdd);

  const { endDate, startDate, fromUser, toUser, pageNo, pageSize, orderBy, orderByField } = data.filters;
  let startTime = startDate;
  if (startTime == '') {
    startTime = new Date().toISOString().split('T')[0] + 'T00:00:00.000Z'
  }
  if (startTime == null) {
    startTime = ''
  }
  let fromUserId = '';
  let toUserId = '';
  if (fromUser && fromUser != 'All') {
    for (let i = 0; i < withoutFilterData.length; i++) {
      if (fromUser == withoutFilterData[i]?.fromUserName) {
        fromUserId = withoutFilterData[i]?.fromUserId
      }
    }
  }
  if (toUser && toUser != 'All') {
    for (let i = 0; i < withoutFilterData.length; i++) {
      if (toUser == withoutFilterData[i]?.toUserName) {
        toUserId = withoutFilterData[i]?.toUserId
      }
    }
  }

  const options = {
    method: 'POST',
    body: JSON.stringify(
      {
        "start_date": startTime,
        "end_date": endDate,
        "page_no": data?.addMoreMessages ? pageNo + 1 : pageNo,
        "page_size": pageSize,
        "from_user": fromUserId,
        "to_user": toUserId,
        "orderBy": orderBy,
        "orderByField": orderByField
      }
    ),
  };
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/messages/admin/messages/get`,
      options
    )
    if (isAddMoreMessages) {
      yield put(actions.setPageNoIncrement())
      yield put(actions.getMessageSuccess({ response, isAddMoreMessages: true }))
    }
    else {
      yield put(actions.getMessageSuccess(response))
    }
  }
  catch (err: any) {
    yield put(actions.getMessageFailure(err.message))
  }
}
export function* sendBroadcastMessages(action) {
  debugger;
  const options = {
    method: 'POST',
    body: JSON.stringify({ message: action.payload, receiverUserId: '' }),
  }
  console.log(options.body, options)
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/messages/sendAll`,
      options
    )

    console.log(response)
    yield put(actions.sendMessageToAllSuccess())
  }
  catch (err: any) {
    yield put(actions.sendMessageToAllSuccess())
  }
}
export function* userMsg() {
  yield takeLatest(actions.getMessage.type, getMessageRequest)
  yield takeLatest(actions.sendMessageToAll.type, sendBroadcastMessages)

}