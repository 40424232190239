import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DASHBOARD_MENU_WIDTH } from 'utils/constants';
// import { DASHBOARD_MENU_WIDTH } from 'utils/constants';



export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: '30%',
    display:'flex',
    position: 'fixed',
    top: 0,
    flexDirection:'column',
    left: 0,
    backgroundColor:'#fff',
    zIndex:2,
    width: DASHBOARD_MENU_WIDTH,
    height:"100%",
    // [theme.breakpoints.up('sm')]: {
      //   width: '20vw'
      // },
      // [theme.breakpoints.up('md')]: {
        //   width: '20vw'
        // },
        [theme.breakpoints.up('sm')]: {
          // paddingLeft: theme.spacing(10),
          // paddingRight: theme.spacing(10),
          // padding: theme.spacing(3),
      // backgroundColor: 'red',
      width: DASHBOARD_MENU_WIDTH + 10,

    },
  },
  logo: {
    height: '36px',
    width: '10vw',
    objectFit: 'contain',
  },
  sideLogo: {
    height: '1.3rem',
    width: '1.3rem',
    objectFit: 'contain',


  },
  sideDiv:{
    width: '90%',
    height: '4vh',
    // backgroundColor: '#aaa',
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    flexDirection:'row',
    fontFamily:'',
    textDecoration:'none',
  //   '&:hover': {
  //     backgroundColor:'#F3F4F6',

  // },
  //   '&:focus': {
  //     backgroundColor:'#F3F4F6',
  // },
  }
}));
