import { createSelector } from 'reselect';
import { RootState } from 'types';
import { selectListAvailability } from '../availabilitySaga/selectors';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.userManagement || initialState;

export const selectData = createSelector(
  [selectDomain],
  userManagement => userManagement
)
export const selectError = createSelector(
  [selectDomain],
  userManagement => userManagement.error
)
export const selectAddUserModalOpen = createSelector(
  [selectDomain],
  userManagement => userManagement.addUserModalOpen
)
export const selectLoading = createSelector(
  [selectDomain],
  userManagement => userManagement.loading
)
export const selectSendUserCompleted = createSelector(
  [selectDomain],
  userManagement => userManagement.sendUserCompleted
)
export const selectSearch = createSelector(
  [selectDomain],
  userManagement => userManagement.search
)
export const selectUserRow = createSelector(
  [selectDomain],
  userManagement => userManagement.userRow
)
export const selectRows = createSelector(
  [selectDomain, selectListAvailability],
  (userManagement, moduleNames) => userManagement.rows.map((row, index) => {
    return {
      ...row,
      isDisabled: moduleNames.includes(`user-management-${row.id}`),
      index: `${index+1}`
    }
  })
)
export const selectSelectedRows = createSelector(
  [selectDomain],
  userManagement => userManagement.selectedUser
)
export const selectSelectedOnly = createSelector(
  [selectDomain],
  userManagement => userManagement.selectedUserOnly
)
export const selectUserSelectId = createSelector(
  [selectDomain],
  userManagement => userManagement.selectedUserId
)
export const selectFilters = createSelector(
  [selectDomain],
  userManagement => userManagement.filters
)
export const selectUserObj = createSelector(
  [selectDomain],
  userManagement => userManagement.userObj
)
export const selectUserActive = createSelector(
  [selectDomain],
  userManagement => userManagement.userObj.is_active
)
export const selectUserID = createSelector(
  [selectDomain],
  userManagement => userManagement.userObj.id
)
export const selectSentMsg = createSelector(
  [selectDomain],
  userManagement => userManagement.sentMsgData
)
export const selectAddUser = createSelector(
  [selectDomain],
  userManagement => userManagement.addUser
)
export const selectVideoFile = createSelector(
  [selectDomain],
  userManagement => userManagement.videoFile
)
export const selectImageFile = createSelector(
  [selectDomain],
  userManagement => userManagement.imageFile
)
export const selectDefaultQuestionId = createSelector(
  [selectDomain],
  userManagement => userManagement.defaultQuestionId
)
export const selectuserProfileImage = createSelector(
  [selectDomain],
  userManagement => userManagement.userProfileImage
)
export const selectuserThumbnailProfileVideo = createSelector(
  [selectDomain],
  userManagement => userManagement.userThumbnailProfileVideo
)
export const selectuserProfileVideoSize = createSelector(
  [selectDomain],
  userManagement => userManagement.userProfileVideoSize
)
export const selectuserProfileVideo = createSelector(
  [selectDomain],
  userManagement => userManagement.userProfileVideo
)
export const selectDisableMessage = createSelector(
  [selectDomain],
  userManagement => userManagement.disableMessage
)
export const selectLoader = createSelector(
  [selectDomain],
  userManagement => userManagement.loading
)
export const selectPageSize = createSelector(
  [selectDomain],
  userManagement => userManagement.page_size
)
export const selectPageNo = createSelector(
  [selectDomain],
  userManagement => userManagement.page_no
)

export const selectAddMoreUsers = createSelector(
  [selectDomain],
  userManagement => userManagement.addMoreUsers
)

export const selectUserPageNo = createSelector(
  [selectDomain],
  userManagement => userManagement.userPage_no
)

export const selectCategoryList = createSelector(
  [selectDomain],
  userManagement => userManagement.categories
)

export const selectIntroductionVideoQuestionId = createSelector(
  [selectDomain],
  userManagement => userManagement.introductionVideoQuestionId
)

export const selectTotalUsers = createSelector(
  [selectDomain],
  userManagement => userManagement.totalUsers
)

export const selectedUserAnswerdQuestions = createSelector(
  [selectDomain],
  userManagement => userManagement.userObj.answeredQuestions
)

export const selectedUserMediaData = createSelector(
  [selectDomain],
  userManagement => userManagement.userObj.mediaList
)

export const selectedBlockedUserData = createSelector(
  [selectDomain],
  userManagement => userManagement.userObj.blockedUsersList
)

export const selectedSelectedUserMediaInfo = createSelector(
  [selectDomain],
  userManagement => userManagement.userObj.selectedMediaId
)

export const selectStatusUpdateMessage = createSelector(
  [selectDomain],
  userManagement => userManagement.statusUpdateMessage
)

export const selectSelectedQuestionedAnswerId = createSelector(
  [selectDomain],
  userManagement => userManagement.selectedQuestionedAnswerId
)

