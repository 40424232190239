import DataTableComponent from 'app/components/DataTableComponent';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//import _ from 'lodash';
import { Box } from '@mui/system';
import { GridColDef } from '@mui/x-data-grid';
import ShowBlockedUserModal from './ShowBlockedUserModal';
import {
    selectedBlockedUserData, selectLoading, selectPageNo, selectPageSize, selectTotalUsers
} from '../selectors';
import { actions } from '../slice';
import { useStyles } from './styles';

const BlockedUsersSection = () => {
    // =====consts===============================
    const styles = useStyles();
    const dispatch = useDispatch();
    const pageSize = useSelector(selectPageSize)
    const pageNo = useSelector(selectPageNo)
    const totalUsers = useSelector(selectTotalUsers)
    const rows = useSelector(selectedBlockedUserData) ?? [];
    console.log("blocked users rows", rows, totalUsers);
    //const [data_rows, setRows] = useState(rows.length && rows?.map((y) => ({ id: y.user_id+y.blockedBy, ...y })))

    const data_rows = rows.length && rows?.map((y) => ({ id: y.user_id+y.blockedBy, ...y }))
    const loading = useSelector(selectLoading)


    const columns: GridColDef[] = [
        {
            field: "fullName", headerName: "Full Name", flex: 1, renderCell: e =>  e.row.fullName
        },
        {
            field: 'actions',
            headerName: 'Actions',
            sortable: false,
            flex: 1,
            maxWidth: 130,
            renderCell: e => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            flex: 0.7,
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <div className={styles.unblockButton} style={{ marginLeft: '8px' }} onClick={() => {
                        setShowObj(e.row)
                        setVisible(true)
                    }}>
                          Unblock
                        </div>
                    </div>
                );
            },
        },
    ];

    const [visible, setVisible] = useState<boolean>(false);
    const [showObj, setShowObj] = useState<any>()

    const closeMsg = () => setVisible(false)

    useEffect(() => {
            dispatch(actions.getBlockedUser());
    }, [])


    // useEffect(() => {
    //     if(isArrayEqual(rows, data_rows)){
    //         dispatch(actions.getBlockedUser());
    //     }
    // }, [rows])

    // const isArrayEqual = function(x, y) {
    //     return _(x).differenceWith(y, _.isEqual).isEmpty();
    //   };

    return (
        <>
            <div className={styles.inputDivs} style={{ marginTop: '10px' }}>
                <Box sx={{ height: 400, width: '100%' }}>
                    <DataTableComponent
                        mainType={'USERS'}
                        pageSize={pageSize}
                        page={pageNo}
                        pageCount={rows?.length}
                        rowCount={totalUsers}
                        onPageChange={
                            (pageNo) => {
                                dispatch(actions.setAddMoreUsers(pageNo != 0 ? true : false))
                                dispatch(actions.getBlockedUser())
                            }
                        }
                        onPageSizeChange={() => {
                            dispatch(actions.getPageSize(pageSize))
                            dispatch(actions.getBlockedUser())
                        }}
                        loading={loading}
                        className={styles.userDataTable}
                        rows={data_rows}
                        columns={columns}
                        header
                    />
                </Box>
            </div>
            <ShowBlockedUserModal
                visible={visible}
                closeMsg={closeMsg}
                showObj={showObj}
            />
        </>
    )
}
export default BlockedUsersSection;