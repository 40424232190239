import { createSelector } from "reselect";
import { RootState } from "types";
import { initialState } from "./slice";

const selectDomain = (state:RootState) => state.messageState || initialState;

export const selectData = createSelector(
  [selectDomain],
  messageState => messageState,
)
export const selectFilters = createSelector(
  [selectDomain],
  messageState => messageState.filters,
)
export const selectRows = createSelector(
  [selectDomain],
  messageState => messageState.rows,
)
export const selectFromUserData = createSelector(
  [selectDomain],
  messageState => messageState.fromUserData,
)
export const selectToUserData = createSelector(
  [selectDomain],
  messageState => messageState.toUserData,
)
export const selectTotalMessage = createSelector(
  [selectDomain],
  messageState => messageState.totalMessages,
)
export const selectLoading = createSelector(
  [selectDomain],
  messageState => messageState.loading,
)
export const selectWithoutFilterData = createSelector(
  [selectDomain],
  messageState => messageState.withoutFilterData,
)
export const selectIsMoreMessageAdd = createSelector(
  [selectDomain],
  messageState => messageState.addMoreMessages,
)
