export const formatDate = (date: any, type: string, time?: boolean) => {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    const getTime = (value: string) => {
        let newTime = value.split(":")
        newTime.pop()
        let s = `${newTime[0]}:${newTime[1]}`
        return s
    }
    const formateTime = time ? " | " + getTime(new Date(date).toTimeString().split(" ")[0]) : '';
    if (type === 'dd/mm/yy') return [day, month, year].join('/') + formateTime;
    if (type === 'yy/mm/dd') return [year, month, day].join('/') + formateTime;
    if (type === 'mm/dd/yy') return [month, day, year].join('/') + formateTime;
}


export const fromDateFormator = (value) => {
    if (value) {
        const event = new Date(value);
        event.setUTCHours(0);
        event.setUTCMinutes(1);
        event.setUTCSeconds(0);
        return event.toISOString()
    } else {
        return ""
    }
}

export const toDateFormator = (value) => {
    if (value) {
        const event = new Date(value);
        event.setUTCHours(23);
        event.setUTCMinutes(59);
        event.setUTCSeconds(0);
        return event.toISOString()
    } else {
        return ""
    }
}