import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useSearchParams } from 'react-router-dom';
import { history } from 'utils/history';

import { Theme } from '@mui/material';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';

import logo from '../../../../assets/logo.svg';
import { selectError, selectLoading, selectLoginForm } from '../selectors';
import { actions } from '../slice';
import { LoginErrorType } from '../types';

const useStyles = makeStyles((theme: Theme) => ({
  logo: {
    height: '84px',
    // width: '176.89px',
    objectFit: "contain",
    alignSelf: 'flex-start',
  },
  loginButtonWrapper: {
    position: 'relative',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },

  form: {
    width: '80%', // Fix IE 11 issue.
    // backgroundColor: '#aaa',
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    [theme.breakpoints.up('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      padding: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      padding: theme.spacing(2),
      // backgroundColor: '#aaa',
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      padding: theme.spacing(3),
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      padding: theme.spacing(3),
    },
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(3),
      width: '20vw'
    },
  },
  inputField: {

  },
  error: {
    width: '100%',
    textAlign: 'center',
    justifyContent: 'center',
  },
  submit: {
    color: '#fff',
    fontWeight: 'bold',
    margin: theme.spacing(3, 0, 2),
    padding: theme.spacing(1, 4),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1, 0, 1),
    },
    borderRadius: "10px",

  },
  languageSwitch: {
    position: 'absolute',
    top: '10px',
    right: '10px'
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  forgotPassword: {
    color: '#000',
    fontSize: 14,
    fontWeight: 'bold'
  },
  formDiv: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    // backgroundColor: '#aba'
  },
  loginText: {
    fontSize: 24,
    alignSelf: 'flex-start',
    fontWeight: 'bold',
  },
  imgDiv:
  {
    // backgroundColor: '#999',
    width: '50%',
    flex: 1,
    alignItems: 'center',
    marginBottom: -50,
    [theme.breakpoints.up('sm')]: {
      width: '68%'
    },
    [theme.breakpoints.up('md')]: {
      width: '62%'
    },
    [theme.breakpoints.up('xl')]: {
      // paddingLeft: theme.spacing(10),
      // paddingRight: theme.spacing(10),
      // padding: theme.spacing(3),
      // backgroundColor: 'red',
      padding: theme.spacing(3),
      width: '20vw'
    },
    // marginTop:60,

  },
  // rememberText:{
  //   fontFamily:'cursive',
  //   fontSize:90,
  // }
  noMember: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'baseline'
  },
  notP: {
    marginRight: 10,
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '18px',
    color: "#BEC2C4",
  },
  infoText: {
    fontSize: '10px',
    fontWeight: 500,
    marginBottom: '35px'
  }
}));

function SetPassForm({ className }) {
  const [searchParams] = useSearchParams();
  const classes = useStyles();
  const form = useSelector(selectLoginForm);
  const tokenId = searchParams.get('token');
  const isLoading = useSelector(selectLoading);
  const error = useSelector(selectError);
  const message = form?.message;
  const dispatch = useDispatch();

  const handleOnChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    console.log(evt, "This is evt..")
    if (evt?.target?.name === 'password') {
      dispatch(actions.changePassword(evt?.target?.value?.trim()));
    }
    if (evt?.target?.name === 'confirmPassword') {
      dispatch(actions.changeConfirmPassword(evt?.target?.value?.trim()));
    }
  };

  const onSubmitFormSuccess = () => {
    dispatch(actions.forgotPassLoader(true))
    setTimeout(() => {
      history.push('/login')
      window.location.reload();
      dispatch(actions.forgotPassLoader(false))
    }, 2000);
  }

  const onSubmitFormFailure = () => {
    dispatch(actions.forgotPassLoader(true))
    setTimeout(() => {
      history.push('/login')
      window.location.reload();
      dispatch(actions.forgotPassLoader(false))
    }, 2000);
  }

  const onSubmitForm = (evt?: React.FormEvent<HTMLFormElement>) => {
    if (evt !== undefined && evt.preventDefault) {
      evt.preventDefault();
      dispatch(actions.resetPass({ callback: { onSuccess: onSubmitFormSuccess, onFailure: onSubmitFormFailure } }));
    }
  };

  if (!tokenId || tokenId?.length <= 0) {
    return (
      <Navigate
        to={{
          pathname: '/login',
        }}
      />
    )
  }

  if (tokenId) {
    dispatch(actions.updateToken(tokenId))
  }

  return (
    <Card className={className}>
      {/* <LanguageSwitch className={classes.languageSwitch}/> */}
      <div className={classes.formDiv} style={{ width: '80%' }}>
        <div className={classes.imgDiv} >
          <img src={logo} alt="" className={classes.logo} />
          <p className={classes.loginText}>Reset Password</p>
        </div>
        <form className={classes.form} noValidate onSubmit={onSubmitForm}>
          {message ? (
            <Alert className={classes.error} severity="success">
              {message}
            </Alert>
          ) : null}
          {setPassError(error)?.message ? (
            <Alert className={classes.error} severity="error">
              {setPassError(error)?.message}
            </Alert>
          ) : null}

          <TextField
            variant="outlined"
            margin="normal"
            className={classes.inputField}
            sx={{
              ".css-17401ng-MuiInputBase-root-MuiOutlinedInput-root": {
                borderRadius: '10px'
              }
            }}
            fullWidth
            id="password"
            label="Password"
            name="password"
            type="password"
            autoComplete="password"
            error={setPassError(error)?.password}
            autoFocus
            value={form.password}
            onChange={handleOnChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            className={classes.inputField}
            sx={{
              ".css-17401ng-MuiInputBase-root-MuiOutlinedInput-root": {
                borderRadius: '10px'
              }
            }}
            fullWidth
            id="confirmPassword"
            label="Confirm Password"
            name="confirmPassword"
            type="password"
            autoComplete="confirmPassword"
            error={setPassError(error)?.email}
            autoFocus
            value={form.confirmPassword}
            onChange={handleOnChange}
          />

          <div className={classes.loginButtonWrapper}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={isLoading}
              type="submit"
              style={{ color: '#fff' }}
            >
              Submit
            </Button>
            {isLoading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
          <div className={classes.noMember}>
            <p className={classes.notP}>© All rights reserved by Zipper</p>
          </div>
        </form>
      </div>
    </Card>
  );
}

export const setPassError = (error: LoginErrorType | undefined | null) => {
  if (!error) return null;
  switch (error) {
    case LoginErrorType.ERROR:
      return {
        message: 'An error has occurred! 😞',
        email: true,
        password: true,
      };
    case LoginErrorType.USERNAME_EMPTY:
      return { message: 'Please enter email', email: true };
    case LoginErrorType.PASSWORD_EMPTY:
      return { message: 'Please enter password', password: true };
    case LoginErrorType.USER_NOT_FOUND:
      return { message: 'No admin found with provided email', email: true };
    case LoginErrorType.LOGIN_FAILED:
      return { message: 'Please enter valid credentials.', email: true, password: true };
    case LoginErrorType.MESSAGES_AUTH_LINK_EXPIRED:
      return { message: 'Message link expired. Please try again.', email: true, password: true };
    default:
      return { message: error, password: true, email: true };
  }
};
export default SetPassForm;
