import DataTableComponent from 'app/components/DataTableComponent';
import SendMsgModal from 'app/components/SendMsgModal';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { GridColDef } from '@mui/x-data-grid';

import { selectPageNo, selectPageSize, selectTotalUsers, selectUserRow } from '../selectors';
import { actions } from '../slice';
import ShowAllModal from './ShowAllModal';
import { useStyles } from './styles';

type Props = {

};
const MessageInfoSection = (props: Props) => {
    // =====consts===============================
    const styles = useStyles();
    const dispatch = useDispatch();
    const pageSize = useSelector(selectPageSize)
    const pageNo = useSelector(selectPageNo)
    const totalUsers = useSelector(selectTotalUsers)
    const rows = useSelector(selectUserRow);

    // =====================states=====================
    const [sendMsg, setSendMsg] = useState(false);
    const [visible, setVisible] = useState<boolean>(false);
    const [showObj, setShowObj] = useState<any>()

    const columns: GridColDef[] = [
        {
            field: "date",
            headerName: "Date/Time",
            flex: 1,
        },
        { field: "to_user", headerName: "To User", flex: 1 },
        { field: "message", sortable: false, headerName: "Message", flex: 2.5 },
        {
            field: "button", headerName: "", sortable: false, flex: 1, align: 'right', renderCell: (e) => <Button
                variant="outlined"
                onClick={() => {
                    setShowObj(e.row)
                    setVisible(true)
                }}
                sx={{
                    color: '#fff', backgroundColor: '#12BFE0', '&:hover': {
                        backgroundColor: "#12bfe0"
                    }, fontSize: '0.75rem', padding: '0px 10px', fontWeight: '400', cursor: 'auto', borderRadius: "20px", marginLeft: 2
                }}
            >Show all</Button>
        },
    ];

    // =====handleChange===============================
    const closeMsg = () => setVisible(false)

    // =====useEffects===============================

    // useEffect(() => {
    //     if (!sendMsg) {
    //         dispatch(actions.setSendMessageData())
    //     }
    // }, [sendMsg])

    return (
        <>
            <div className={styles.inputDivs} style={{ marginTop: '10px' }}>
                <Box sx={{ height: 400, width: '100%' }}>
                    {totalUsers > 0 && <DataTableComponent
                        mainType={'USERS'}
                        pageSize={pageSize}
                        page={pageNo}
                        pageCount={rows.length}
                        rowCount={totalUsers}
                        onPageChange={
                            (pageNo) => {
                                dispatch(actions.setAddMoreUsers(pageNo != 0 ? true : false))
                                dispatch(actions.getUser())
                            }
                        }
                        onPageSizeChange={() => {
                            dispatch(actions.getPageSize(pageSize))
                            dispatch(actions.getUser())
                        }}
                        className={styles.userDataTable}
                        rows={rows}
                        columns={columns}
                        header
                    />}
                    {totalUsers === 0 && (<div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'flex-start', height: '80%' }}>No Result found</div>)}
                </Box>
            </div>

            <div className={styles.inputDivs} style={{ justifyContent: 'start' }}>
                <Button
                    variant="outlined"
                    onClick={() => setSendMsg(true)}
                    className={styles.SendMessageButton}
                >Send Message</Button>
            </div>
            <ShowAllModal
                visible={visible}
                closeMsg={closeMsg}
                showObj={showObj}
            />
            <SendMsgModal sendMsg={sendMsg} setSendMsg={setSendMsg} isSelectAll={false} />
        </>
    )
}
export default MessageInfoSection;