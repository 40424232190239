import React from 'react';

import { Box } from '@mui/material';

import { useStyles } from './styles';
import Sidebar from '../Sidebar';
import Topbar from '../topbar';



const Content = ({ children }) => {
  const styles = useStyles();
  // return <Box className={styles.root}>{children}</Box>;
  return <Box className={styles.root}>
    <Sidebar />
    <>
      <Topbar />
      <div >
        {children}
      </div>
    </>
  </Box>;
};

export default Content;
