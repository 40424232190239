import DataTableComponent from 'app/components/DataTableComponent';
import PopOverDiv from 'app/containers/Questions/commonComponent/Popover';
import deleteSvg from 'assets/delete.svg';
import downloadSvg from 'assets/downloadNew.svg';
import videoSvg from 'assets/videoNew.svg';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate } from 'utils/DateFormate';

import { Box } from '@mui/system';
import { GridColDef } from '@mui/x-data-grid';

import { actions as answersActions } from '../../Answers/slice';
import { actions as mediaActions } from '../../mediaSaga/slice';
import {
    selectedUserAnswerdQuestions, selectLoading, selectPageSize, selectTotalUsers
} from '../selectors';
import { actions } from '../slice';
import DeletePopOverDiv from './DeletePopOver';
import IOSSwitchComponent from './IOSSwitchComponent';
import ShowUserProfile from './ShowUserProfile';
import { useStyles } from './styles';

const AnsweredQuestionsSection = () => {
    // =====consts===============================
    const styles = useStyles();
    const dispatch = useDispatch();
    const pageSize = useSelector(selectPageSize)
    const totalUsers = useSelector(selectTotalUsers)
    // const rows = useSelector(selectAnswers);selectedUserAnswerdQuestions
    const rows = useSelector(selectedUserAnswerdQuestions);
    const data_rows = rows.map(({ _id, ...rest }) => ({ id: _id, ...rest }))
    console.log(rows, "This are rows")
    const loading = useSelector(selectLoading)
    console.log(rows, "This are rows")
    // =====================states=====================
    const [openProfileModal, setOpenProfileModal] = useState(false)
    const [selectedProfileData, setSelectedProfileData] = useState({
        isVideoSelected: false,
        url: ''
    })
    const [anchor, setAnchor] = useState<any>(null);
    const [deleteId, setDeleteId] = useState<string>('');
    // =====handleChange===============================
    const handleDownload = (event, row) => {
        dispatch(mediaActions.getDownloadUrl({ key: row.video, name: "_" + (row.video.split("/")[(row.video.split("/").length - 1)]) }));
    };

    const handlePlayVideo = (event, row) => {
        console.log("play-row", row);
        if (row?.video) {
            setOpenProfileModal(true)
            setSelectedProfileData({
                isVideoSelected: true,
                url: row?.video
            })
        }
    };

    const handleDelete = () => {
        dispatch(answersActions.deleteAnswer({ id: deleteId }));
        setAnchor(null);
    };
    const handleDeleteClick = (event, row) => {
        setDeleteId(row.id);
        setAnchor(event.currentTarget);
    };

    const handleCloseEl = () => {
        setAnchor(null);
    };

    const handleOnChangeToggle = (rowInfo) => { dispatch(actions.updateSelectedAnswerdQuestionId(rowInfo.id)); }

    const handleOnChangeSubmit = () => { dispatch(actions.updateAnsweredQuestionStatus()) }

    const columns: GridColDef[] = [
        { field: "date", headerName: "Date", flex: 1, renderCell: e => formatDate(e.row.createdAt, 'dd/mm/yy') },
        { field: 'questionId', headerName: "Question ID", flex: 1, minWidth: 90, renderCell: e => e.row.questionId._id },
        { field: "questionName", sortable: false, headerName: "Question Name", flex: 1, minWidth: 85, renderCell: e => e.row.questionId.questionName },
        { field: "videoLength", sortable: false, headerName: "Video Length", flex: 1 },
        { field: "status", headerName: "Status", flex: 1, renderCell: e => e.row.status === true ? "ENABLE" : "DISABLE" },
        {
            field: 'actions', headerName: 'Actions', sortable: false, minWidth: 100, flex: 1, renderCell: e => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            flex: 0.7,
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <PopOverDiv />
                        <div className={styles.deleteImg}>
                            <img
                                src={videoSvg}
                                onClick={r => handlePlayVideo(r, e.row)}
                                alt="videoSvg"
                            />
                        </div>

                        <div className={styles.deleteImg} style={{ marginLeft: '8px' }}>
                            <img
                                src={deleteSvg}
                                onClick={r => handleDeleteClick(r, e.row)}
                                alt="deleteSvg"
                            />
                        </div>
                        <div className={styles.deleteImg} style={{ marginLeft: '8px' }}>
                            <img
                                width={20}
                                src={downloadSvg}
                                onClick={r => handleDownload(r, e.row)}
                                alt="downloadSvg"
                            />
                        </div>
                        <IOSSwitchComponent
                            header={`${e.row.status ? "Disable" : "Enable"} this AnsweredQuestion?`}
                            isChecked={e.row.status}
                            onChangeToggle={() => handleOnChangeToggle(e.row)}
                            onSubmitToggle={handleOnChangeSubmit}
                        />
                    </div>
                );
            },
        },
        { field: "abuseReports", sortable: false, headerName: "Abuse Reports", flex: 1 },
    ];

    // =====useEffects===============================


    useEffect(() => {
        dispatch(actions.getSelectUserQuestionAnswers)
    }, [rows])

    return (
        <>
            <div className={styles.inputDivs} style={{ marginTop: '10px' }}>
                <Box sx={{ height: 400, width: '100%' }}>
                    {totalUsers > 0 && <DataTableComponent
                        rows={data_rows}
                        columns={columns}
                        header
                        loading={loading}
                        mainType={'USERS'}
                        rowCount={totalUsers}
                        pageCount={rows && rows?.length}
                        onPageChange={
                            (pageNo) => {
                                dispatch(actions.setAddMoreUsers(pageNo != 0 ? true : false))
                                dispatch(actions.getUser())
                            }
                        }
                        onPageSizeChange={() => {
                            dispatch(actions.getPageSize(pageSize))
                            dispatch(actions.getUser())
                        }}
                        className={styles.userDataTable}
                    />}
                    {totalUsers === 0 && (<div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'flex-start', height: '80%' }}>No Result found</div>)}
                </Box>
            </div>
            <ShowUserProfile
                isVideo={selectedProfileData?.isVideoSelected}
                openProfileModal={openProfileModal}
                setOpenProfileModal={setOpenProfileModal}
                url={selectedProfileData?.url}
            />
            <DeletePopOverDiv
                anchor={anchor}
                handleCloseEl={handleCloseEl}
                handleDelete={handleDelete}
                onClose={handleCloseEl}
            />
        </>
    )
}
export default AnsweredQuestionsSection;